export default function cloudFunctionsEndpoint() {
    const isDevelopment = process.env.REACT_APP_STAGE === "development"
    const isStaging = process.env.REACT_APP_STAGE === "staging"
    const isProduction = process.env.REACT_APP_STAGE === "production"
    const isQAProduction = process.env.REACT_APP_STAGE === "qaproduction"
    
    const REACT_APP_CLOUD_FUNCTIONS_DEV="https://app-dev.myrecoverylink.com/functions"
    const REACT_APP_CLOUD_FUNCTIONS_STAGE="https://app-stage.myrecoverylink.com/functions"
    const REACT_APP_CLOUD_FUNCTIONS_QAPROD="https://app-qaprod.myrecoverylink.com/functions"
    const REACT_APP_CLOUD_FUNCTIONS_PROD="https://app.myrecoverylink.com/functions"

      if (isProduction) {
        return REACT_APP_CLOUD_FUNCTIONS_PROD
      }
      if (isQAProduction) {
        return REACT_APP_CLOUD_FUNCTIONS_QAPROD
      }
      if (isStaging) {
        return REACT_APP_CLOUD_FUNCTIONS_STAGE
      }
      if (isDevelopment) {
        return REACT_APP_CLOUD_FUNCTIONS_DEV
      }
      
      return REACT_APP_CLOUD_FUNCTIONS_DEV
    }
    
  

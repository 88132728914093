import { useState, useContext, useEffect } from "react"

import axios from "axios"
import { UserContext } from "../../context/userContext"
import { FirebaseContext } from "../../firebase"

import { LoaderProgressBar } from "../../components/Loaders"

import _ from "lodash"
import { NavLink } from "react-router-dom"

export default function NavMain(props) {
  const [loading, setLoading] = useState(false)
  const [menu, setMenu] = useState([])
  const [parentActive, setParentActive] = useState(null)

  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    setLoading(true)
    const getMenuUrl = async () => {
      const { getStorage, ref, getDownloadURL } = firebase.storage
      const storage = getStorage()
      const menuRef = ref(
        storage,
        `config/menus/secondary/role_${user.staff_role}.json`
      )
      return await getDownloadURL(menuRef)
    }
    const fetchMenu = async () => {
      const menuUrl = await getMenuUrl()
      const res = await axios.get(menuUrl)
      setMenu(res.data)
      setLoading(false)
    }
    fetchMenu()
  }, [firebase.storage, user.staff_role])

  const handleLevelThird = (items) => {
    return (
      <ul className="menu-inner">
        {_.map(items, (row) => {
          if (row.path) {
            return (
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? "active" : null
                  }}
                  to={row.path ? row.path : "/"}
                >
                  {row.icon && (
                    <span className="material-icons">{row.icon}</span>
                  )}
                  {row.label}
                  {!_.isEmpty(row.items) && handleLevelThird(row.items)}
                </NavLink>
              </li>
            )
          } else {
            return (
              <li>
                <a href="#" onClick={() => setParentActive(null)}>
                  {row.icon && (
                    <span className="material-icons">{row.icon}</span>
                  )}
                  {row.label}
                  {!_.isEmpty(row.items) && handleLevelThird(row.items)}
                </a>
              </li>
            )
          }
        })}
      </ul>
    )
  }

  const handleLevelSecond = (items, parentFieldId) => {
    if (parentFieldId !== parentActive) return null
    return (
      <ul className="menu-dropdown expand">
        {_.map(items, (row) => {
          if (row.path) {
            return (
              <li
                className={!_.isEmpty(row.items) ? "menu-dropdown-inner" : null}
              >
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? "active" : null
                  }}
                  title={row.label}
                  to={row.path ? row.path : ""}
                >
                  {row.icon && (
                    <span className="material-icons">{row.icon}</span>
                  )}
                  <div className="label">{row.label}</div>
                </NavLink>
                {!_.isEmpty(row.items) && handleLevelThird(row.items)}
              </li>
            )
          } else {
            return (
              <li
                className={!_.isEmpty(row.items) ? "menu-dropdown-inner" : null}
              >
                <a href="#" title={row.label}>
                  {row.icon && (
                    <span className="material-icons">{row.icon}</span>
                  )}
                  <div className="label">{row.label}</div>
                </a>
                {!_.isEmpty(row.items) && handleLevelThird(row.items)}
              </li>
            )
          }
        })}
      </ul>
    )
  }

  const handleLevelFirst = () => {
    const handleClickParent = (e) => {
      e.target.id === parentActive
        ? setParentActive(null)
        : setParentActive(e.target.id)
    }
    return (
      <ul>
        {_.map(menu, (row, i) => {
          const fieldId = `parent_${i}`
          const handleClassName =
            parentActive === fieldId
              ? "btn-dropdown-toogle expand"
              : "btn-dropdown-toogle"
          return (
            <li key={fieldId}>
              <button
                id={fieldId}
                onClick={handleClickParent}
                className={handleClassName}
              >
                {row.label}
              </button>
              {!_.isEmpty(row.items) && handleLevelSecond(row.items, fieldId)}
            </li>
          )
        })}
      </ul>
    )
  }

  if (loading)
    return (
      <nav className="nav-main">
        <LoaderProgressBar />
      </nav>
    )

  return (
    <nav className="nav-main">
      <button
        className="nav-main-toogle"
        onClick={() => props.setToogle(!props.toogle)}
      >
        Toggle
      </button>
      {handleLevelFirst()}
      <button className="btn-logout" onClick={() => firebase.signOut()}>
        Logout
      </button>
    </nav>
  )
}

export default function AppointmentsCalendarRecurringControllers({
  controllers,
  onEditController,
}) {
  return (
    <div
      style={{
        border: "2px solid #8C28E4",
        borderRight: 0,
        borderBottomLeftRadius: "2px",
        width: "30%",
        height: "300px",
        padding: "10px 30px 30px 30px",
        fontFamily: "Lato",
        overflow: "auto",
      }}
    >
      <h3>Recurring Controllers</h3>
      <br />
      {controllers.map((controller, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <div className="">
              <b style={{ color: "#666666" }}>{controller.localFrequency}</b>
              <br />
              <span style={{ color: "#666666" }}>
                {" with "}
                <span style={{ color: "#8C28E4" }}>{controller.staff}</span>
              </span>
              <button
                type="submit"
                className="btn-sm"
                style={{ display: "block" }}
                onClick={() => {
                  onEditController(controller)
                }}
                disabled={controller.status === "BOOKED.PENDING_CHANGE"}
              >
                Request Change
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

//Styles
import devToolStyles from "../../styles/developerTools/DevTools.module.css"

export default function DevMasquerade({ expandedCode, onSetExpandedCode }) {
  const cardCode = "Masquerade"

  return (
    <div
      className={`${devToolStyles.cardLayout} ${
        expandedCode === cardCode ? devToolStyles.cardLayoutExpanded : ""
      } ${
        expandedCode !== "" && expandedCode !== cardCode
          ? devToolStyles.cardLayoutHidden
          : ""
      }`}
      onClick={() => {
        onSetExpandedCode(cardCode)
      }}
    >
      <div className={devToolStyles.cardLayoutTitle}>Masquerade (TBC)</div>
      <div className={devToolStyles.cardLayoutStatus}></div>
      <div className={devToolStyles.cardLayoutDescription}>
        Masquerade as another user
      </div>
      <div className={devToolStyles.cardLayoutBody}>
        This functionality has not been implemented and serves as a placeholder
        for future tools
      </div>
    </div>
  )
}

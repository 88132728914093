/* eslint-disable no-undef */
var logLevelKey = "info";
var logLevel = LogLevelKeyToValue(logLevelKey);

export function SetLogLevel(level) {
  logLevelKey = level;
  logLevel = LogLevelKeyToValue(level);
  LogInfo(`Log level set to ${level}`);
}

export function LogDebug(message, ...params) {
  if (logLevel >= LogLevelKeyToValue("debug")) console.debug(message, params);
}

export function LogInfo(message, ...params) {
  if (logLevel >= LogLevelKeyToValue("info")) console.info(message, params);
}

export function LogWarning(message, ...params) {
  if (logLevel >= LogLevelKeyToValue("warning")) console.warn(message, params);
}

export function LogError(message, ...params) {
  if (logLevel >= LogLevelKeyToValue("error")) console.error(message, params);
}

function LogLevelKeyToValue(key) {
  switch (key) {
    case "debug":
      return 3;

    case "info":
      return 2;

    case "warning":
      return 1;

    case "error":
      return 0;

    default:
      return 2;
  }
}

export function GetLogLevelKey() {
  return logLevelKey;
}

export function GetLogLevelValue() {
  return LogLevelKeyToValue(process.env.REACT_APP_LOG_LEVEL);
}

import React from "react"
import { Dialog } from "primereact/dialog"
import { useFormikContext } from "formik"

export default function ConsentDialog(props) {
  const formik = useFormikContext()

  const handleButton = () => {
    return (
      <>
        <button
          onClick={async () => {
            formik.setFieldValue("consent_data_store", "No")
            props.setDialog(false)
          }}
          className="btn"
          style={{ marginRight: "7px", marginTop: "7px" }}
        >
          Yes, terminate my RecoveryLink services
        </button>
        <button
          onClick={async () => {
            formik.setFieldValue("consent_data_store", "Yes")
            props.setDialog(false)
          }}
          className="btn"
          style={{ marginRight: "7px", marginTop: "7px" }}
        >
          Cancel
        </button>
      </>
    )
  }

  const handleOnHide = () => {
    formik.setFieldValue("consent_data_store", "Yes")
    props.setDialog(false)
  }

  return (
    <Dialog
      header={"Consent for Services"}
      visible={props.dialog}
      style={{ width: "50vw" }}
      onHide={handleOnHide}
    >
      <p>
        Revoking your consent will initiate termination of services with all
        engaged Organizations, and will move your personal health information
        into RecoveryLink PHI deletion protocol.
      </p>
      <p>
        Do you understand that this action will terminate all RecoveryLink
        services, will delete all participant data, and will terminate this
        participant account permanently?
      </p>
      {handleButton()}
    </Dialog>
  )
}

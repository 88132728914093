import Header from './Header'
import Footer from './Footer'
import { useState } from "react"

import NavMain from "../nav/NavMain"

export default function Layout({ children }) {
  const [toogle, setToogle] = useState(true)
  const handleClassName = toogle ? "expand" : "collapse"
  return (
    <div className={`theme-wrapper ${handleClassName}`}>
      <Header />  
      
      <NavMain setToogle={setToogle} toogle={toogle} />  
         
      <div className="body-main">{children}</div>
      <Footer />
    </div>
  )
}
import React, { useState, useContext } from "react"
import { Dialog } from "primereact/dialog"
import { UserContext } from "../../../context/userContext"
import { LoaderSmall } from "../../../components/Loaders"
import { notificationsUrl } from "../../../config/microservicesUrls"
import axios from "axios"

import _ from "lodash"
import { GET_ORGANIZATION_USERS } from "../queries"
import { useLazyQuery } from "@apollo/client"

export default function RequestDialog(props) {
  const user = useContext(UserContext)
  const [fetching, setFetching] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(null)
  const serviceNotificacionURL = `${notificationsUrl()}/Notification`
  const serviceSendNotificacionSignalR = `${notificationsUrl()}/Notification/SendSignalR`

  const unnasignedRoles = ["-2", "-3", "-4", "-5"]

  let title
  switch (props.notificationType) {
    case "discharge":
      title = "New Discharge Request"
      break
    case "staff_assignment":
    case "new_staff":
      title = "New Staff Assignment Request"
      break
    case "request_peer_service":
    case "request_services_advocacy":
    case "request_services_prosocial":
    case "request_services_resource_referral":
    case "request_services_educational":
    case "request_services_employment":
    case "request_services_family":
    case "request_services_naloxone":
    case "request_services_mutual_aid_meetings":
    case "request_services_harm_reduction":
    case "request_services_reentry":
    case "request_services_other":
    case "request_services_other_type":
      title = "New Service  Request"
      break
    case "discontinue_peer_service":
    case "discontinue_services_resource_referral":
    case "discontinue_services_prosocial":
    case "discontinue_services_advocacy":
    case "discontinue_services_educational":
    case "discontinue_services_employment":
    case "discontinue_services_family":
    case "discontinue_services_naloxone":
    case "discontinue_services_mutual_aid_meetings":
    case "discontinue_services_harm_reduction":
    case "discontinue_services_reentry":
    case "discontinue_services_other":
    case "discontinue_services_other_type":
      title = "Service Discontinuation Request"
      break
    default:
      title = ""
  }
  let description
  switch (props.notificationType) {
    case "discharge":
      description = `Participant ${user.participant_name_first} ${user.participant_name_last} has requested to be discharged.`
      break
    case "staff_assignment":
    case "new_staff":
      description = `Participant ${user.participant_name_first} ${user.participant_name_last} has requested to be assigned to a new staff member.`
      break
    case "request_peer_service":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Peer services.`
      break
    case "discontinue_peer_service":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Peer services      .`
      break
    case "discontinue_services_resource_referral":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Resource referral.`
      break
    case "request_services_resource_referral":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Resource referral.`
      break
    case "discontinue_services_prosocial":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Pro-social activities.`
      break
    case "request_services_prosocial":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Pro-social activities.`
      break
    case "discontinue_services_advocacy":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Advocacy activities.`
      break
    case "request_services_advocacy":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Advocacy activities.`
      break
    case "discontinue_services_educational":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Educational activities.`
      break
    case "request_services_educational":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Educational activities.`
      break
    case "discontinue_services_employment":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Employment support.`
      break
    case "request_services_employment":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Employment support.`
      break
    case "discontinue_services_family":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Family recovery support.`
      break
    case "request_services_family":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Family recovery support.`
      break
    case "discontinue_services_naloxone":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Naloxone.`
      break
    case "request_services_naloxone":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Naloxone.`
      break
    case "discontinue_services_mutual_aid_meetings":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Mutual aid meetings.`
      break
    case "request_services_mutual_aid_meetings":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Mutual aid meetings.`
      break
    case "discontinue_services_harm_reduction":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Harm reduction services.`
      break
    case "request_services_harm_reduction":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Harm reduction services.`
      break
    case "discontinue_services_reentry":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Reentry support.`
      break
    case "request_services_reentry":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Reentry support.`
      break
    case "discontinue_services_other":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Other services.`
      break
    case "request_services_other":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Other services.`
      break
    case "discontinue_services_other_type":
      description = `Participant ${user.participant_alias} has requested for a service type to be discontinued as part of their current service episode. The service to be discontinued is Other services.`
      break
    case "request_services_other_type":
      description = `Participant ${user.participant_alias} has requested to initiate a new service as part of their current service episode. The service to be initiated is Other services.`
      break

    default:
      description = ""
  }
  let eventType
  switch (props.notificationType) {
    case "discharge":
      eventType = "participant_discharge_request"
      break
    case "staff_assignment":
    case "new_staff":
      eventType = "participant_staff_request"
      break
    case "request_peer_service":
    case "discontinue_peer_service":
    case "discontinue_services_resource_referral":
    case "request_services_resource_referral":
    case "discontinue_services_prosocial":
    case "request_services_prosocial":
    case "discontinue_services_advocacy":
    case "request_services_advocacy":
    case "discontinue_services_educational":
    case "request_services_educational":
    case "discontinue_services_employment":
    case "request_services_employment":
    case "discontinue_services_family":
    case "request_services_family":
    case "discontinue_services_naloxone":
    case "request_services_naloxone":
    case "discontinue_services_mutual_aid_meetings":
    case "request_services_mutual_aid_meetings":
    case "discontinue_services_harm_reduction":
    case "request_services_harm_reduction":
    case "discontinue_services_reentry":
    case "request_services_reentry":
    case "discontinue_services_other":
    case "request_services_other":
    case "discontinue_services_other_type":
    case "request_services_other_type":
      eventType = "participant_service_request"
      break
    default:
      eventType = ""
  }
  let primaryActionLabel
  switch (props.notificationType) {
    case "discharge":
      primaryActionLabel = "Discharge participant"
      break
    case "staff_assignment":
      primaryActionLabel = "Manage participant service"
      break
    case "request_peer_service":
    case "discontinue_peer_service":
    case "discontinue_services_resource_referral":
    case "request_services_resource_referral":
    case "discontinue_services_prosocial":
    case "request_services_prosocial":
    case "discontinue_services_advocacy":
    case "request_services_advocacy":
    case "discontinue_services_educational":
    case "request_services_educational":
    case "discontinue_services_employment":
    case "request_services_employment":
    case "discontinue_services_family":
    case "request_services_family":
    case "discontinue_services_naloxone":
    case "request_services_naloxone":
    case "discontinue_services_mutual_aid_meetings":
    case "request_services_mutual_aid_meetings":
    case "discontinue_services_harm_reduction":
    case "request_services_harm_reduction":
    case "discontinue_services_reentry":
    case "request_services_reentry":
    case "discontinue_services_other":
    case "request_services_other":
    case "discontinue_services_other_type":
    case "request_services_other_type":
      primaryActionLabel = "Modify Engaged Services"
      break

    default:
      primaryActionLabel = "Manage participant service"
  }

  const primaryActionUrl = "/participant/list"

  const fetchService = async (args) => {
    const { staffIDs, isAssigned } = args

    try {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + user.tokenNotification,
          "Content-Type": "application/json",
        },
      }

      if (isAssigned) {
        const res = await axios.post(
          serviceNotificacionURL,
          {
            title: title,
            description: description,
            eventType: eventType,
            highPriority: true,
            systemNotification: false,
            systemNotificationFilter: "",
            showToast: true,
            showToastOnly: true,
            primaryActionLabel: primaryActionLabel,
            primaryActionUrl: primaryActionUrl,
            primaryActionPayload: {},
            secondaryActionLabel: "",
            secondaryActionUrl: "",
            secondaryActionPayload: {},
            enterpriseId: props.assignedEnterprise,
            groupId: props.assignedOrganization,
            userId: props.assignedStaff,
            peerId: "",
            caseId: "",
          },
          requestConfig
        )
        const notificationId = res?.data?.id

        //send pop up notification
        await axios.post(
          serviceSendNotificacionSignalR,
          {
            notificationId: notificationId,
          },
          requestConfig
        )
      } else {
        staffIDs.forEach(async (staffID) => {
          const res = await axios.post(
            serviceNotificacionURL,
            {
              title: title,
              description: description,
              eventType: eventType,
              highPriority: true,
              systemNotification: false,
              systemNotificationFilter: "",
              showToast: true,
              showToastOnly: true,
              primaryActionLabel: primaryActionLabel,
              primaryActionUrl: primaryActionUrl,
              primaryActionPayload: {},
              secondaryActionLabel: "",
              secondaryActionUrl: "",
              secondaryActionPayload: {},
              enterpriseId: props.assignedEnterprise,
              groupId: props.assignedOrganization,
              userId: staffID,
              peerId: "",
              caseId: "",
            },
            requestConfig
          )
          const notificationId = res?.data?.id

          //send pop up notification
          await axios.post(
            serviceSendNotificacionSignalR,
            {
              notificationId,
            },
            requestConfig
          )
        })
      }

      setFetching(false)
      setCompleted(true)
    } catch (err) {
      setFetching(false)
      setError(true)
    }
  }

  const [getAdminData] = useLazyQuery(GET_ORGANIZATION_USERS, {
    fetchPolicy: "no-cache",
    variables: {
      staff_id: props.staffID,
      organization_id: props.assignedOrganization,
    },
    onCompleted: async (data) => {
      const allStaff = data?.users || []
      const filterStaff = _.filter(allStaff, { staff_role: 3 })

      const staffIDs = filterStaff.map((staff) => staff.staff_id)

      const staffIDlist = _.includes(unnasignedRoles, props.assignedStaff)
        ? staffIDs.join(",")
        : props.assignedStaff

      const fetchServiceParams = {
        staffIDs,
        staffIDlist,
        isAssigned: !_.includes(unnasignedRoles, props.assignedStaff),
      }

      await fetchService(fetchServiceParams)
    },
  })

  const handleHeader = () => {
    switch (props.notificationType) {
      case "discharge": {
        return "Request Discharge"
      }
      case "new_staff": {
        return "New Service  Request"
      }
      case "request_peer_service":
      case "request_services_advocacy":
      case "request_services_prosocial":
      case "request_services_resource_referral":
      case "request_services_educational":
      case "request_services_employment":
      case "request_services_family":
      case "request_services_naloxone":
      case "request_services_mutual_aid_meetings":
      case "request_services_harm_reduction":
      case "request_services_reentry":
      case "request_services_other":
      case "request_services_other_type": {
        return "Request  Service"
      }
      case "discontinue_peer_service":
      case "discontinue_services_resource_referral":
      case "discontinue_services_prosocial":
      case "discontinue_services_advocacy":
      case "discontinue_services_educational":
      case "discontinue_services_employment":
      case "discontinue_services_family":
      case "discontinue_services_naloxone":
      case "discontinue_services_mutual_aid_meetings":
      case "discontinue_services_harm_reduction":
      case "discontinue_services_reentry":
      case "discontinue_services_other":
      case "discontinue_services_other_type": {
        return "Service Discontinuation Request"
      }
      default:
        return null
    }
  }

  const handleBody = () => {
    switch (props.notificationType) {
      case "discharge": {
        return <h3>Please confirm that you want to request a Discharge</h3>
      }
      case "new_staff": {
        return <h3>Please confirm that you want to request a new Staff</h3>
      }
      case "request_peer_service": {
        return <h3>Please confirm that you want to request a Peer Service</h3>
      }
      case "discontinue_peer_service": {
        return (
          <h3>Please confirm that you want to discontinue a Peer Service</h3>
        )
      }
      case "discontinue_services_resource_referral": {
        return (
          <h3>
            Please confirm that you want to discontinue a Resource Referral
            Service
          </h3>
        )
      }
      case "request_services_resource_referral": {
        return (
          <h3>Please confirm that you want to request a Resource Referral</h3>
        )
      }
      case "discontinue_services_prosocial": {
        return (
          <h3>
            Please confirm that you want to discontinue a Prosocial Service
          </h3>
        )
      }
      case "request_services_prosocial": {
        return (
          <h3>Please confirm that you want to request a Prosocial Service</h3>
        )
      }
      case "discontinue_services_advocacy": {
        return (
          <h3>
            Please confirm that you want to discontinue an Advocacy Service
          </h3>
        )
      }
      case "request_services_advocacy": {
        return (
          <h3>Please confirm that you want to request an Advocacy Service</h3>
        )
      }
      case "discontinue_services_educational": {
        return (
          <h3>
            Please confirm that you want to discontinue an Educational Service
          </h3>
        )
      }
      case "request_services_educational": {
        return (
          <h3>
            Please confirm that you want to request an Educational Service
          </h3>
        )
      }
      case "discontinue_services_employment": {
        return (
          <h3>
            Please confirm that you want to discontinue an Employment Service
          </h3>
        )
      }
      case "request_services_employment": {
        return (
          <h3>Please confirm that you want to request an Employment Service</h3>
        )
      }
      case "discontinue_services_family": {
        return (
          <h3>Please confirm that you want to discontinue a Family Service</h3>
        )
      }
      case "request_services_family": {
        return <h3>Please confirm that you want to request a Family Service</h3>
      }
      case "discontinue_services_naloxone": {
        return (
          <h3>
            Please confirm that you want to discontinue a Naloxone Service
          </h3>
        )
      }
      case "request_services_naloxone": {
        return (
          <h3>Please confirm that you want to request a Naloxone Service</h3>
        )
      }
      case "discontinue_services_mutual_aid_meetings": {
        return (
          <h3>
            Please confirm that you want to discontinue a Mutual Aid Meetings
            Service
          </h3>
        )
      }
      case "request_services_mutual_aid_meetings": {
        return (
          <h3>Please confirm that you want to request a Mutual Aid Meetings</h3>
        )
      }
      case "discontinue_services_harm_reduction": {
        return (
          <h3>
            Please confirm that you want to discontinue a Harm Reduction Service
          </h3>
        )
      }
      case "request_services_harm_reduction": {
        return (
          <h3>
            Please confirm that you want to request a Harm Reduction Servie
          </h3>
        )
      }
      case "discontinue_services_reentry": {
        return (
          <h3>Please confirm that you want to discontinue a Reentry Service</h3>
        )
      }
      case "request_services_reentry": {
        return (
          <h3>Please confirm that you want to request a Reentry Service</h3>
        )
      }
      case "discontinue_services_other": {
        return (
          <h3>Please confirm that you want to discontinue an Other Service</h3>
        )
      }
      case "request_services_other": {
        return <h3>Please confirm that you want to request an Other Service</h3>
      }
      case "discontinue_services_other_type": {
        return (
          <h3>
            Please confirm that you want to discontinue an Other Type Service
          </h3>
        )
      }
      case "request_services_other_type": {
        return (
          <h3>Please confirm that you want to request an Other Type Service</h3>
        )
      }

      default:
        return null
    }
  }

  const handleButton = () => {
    if (fetching) return <LoaderSmall />

    if (completed)
      return (
        <>
          <p>The request has been completed</p>
          <button
            onClick={handleOnHide}
            className="btn"
            style={{ display: "block" }}
          >
            Close
          </button>
        </>
      )

    if (error)
      return (
        <>
          <p>There was an error with the request. Please try again.</p>
          <button
            onClick={handleOnHide}
            className="btn"
            style={{ display: "block" }}
          >
            Close
          </button>
        </>
      )

    return (
      <button
        onClick={async () => {
          setFetching(true)
          getAdminData()
        }}
        type={props.notificationType}
        className="btn"
      >
        Confirm
      </button>
    )
  }

  const handleOnHide = () => {
    setFetching(false)
    setMessage(null)
    setCompleted(false)
    setError(false)
    props.setAssignedStaff(null)
    props.setDialog(false)
    props.setAssignedStaff(null)
  }

  return (
    <Dialog
      header={handleHeader()}
      visible={props.dialog}
      style={{ width: "50vw" }}
      onHide={handleOnHide}
    >
      {handleBody()}
      {message}

      {handleButton()}
    </Dialog>
  )
}

import { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

//Components
import DeveloperToolsModal from "./DeveloperToolsModal"
import { getEnvironmentCode } from "./developerToolsHelpers"
import {
  disableAllUrlOverrides,
  getActiveURLOverrides,
  hasActiveUrlOverrides,
} from "./ServiceUrls/serviceUrlHelpers"

//Context
import { UserContext } from "../../context/userContext"

//Helpers
import { LogError } from "../../helpers/logger"

//Styles
import devToolStyles from "../../styles/developerTools/DevTools.module.css"

//GraphQl
import { useLazyQuery } from "@apollo/client"
import { VALIDATE_KEY } from "./queries"

export default function DeveloperTools() {
  const userCtx = useContext(UserContext)
  const localUnlockKey = "DevToolKey"

  const [searchParams] = useSearchParams()

  const [toolsUnlocked, setToolsUnlocked] = useState(false)
  const [showTools, setShowTools] = useState(false)
  const [hasActiveTools, setHasActiveTools] = useState(false)

  const [validateDevToolsKey] = useLazyQuery(VALIDATE_KEY, {
    fetchPolicy: "cache-and-network",
    variables: {
      key: "",
    },
  })

  useEffect(() => {
    if (!toolsUnlocked) {
      const currentStatus = localStorage.getItem(localUnlockKey) === "true"
      if (currentStatus) {
        const activeUrls = getActiveURLOverrides()
        if (activeUrls && activeUrls.length > 0) {
          userCtx.setNotificationInformation({
            message: (
              <>
                Active service URL overrides:
                <ul>
                  {activeUrls?.map((override) => {
                    return <li>{`-   ${override}`}</li>
                  })}
                </ul>
              </>
            ),
            sticky: true, //Show this notification until the user closes it
          })
        }
      }
      setToolsUnlocked(currentStatus)
    }

    checkForActiveTools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!toolsUnlocked) {
      const paramValue = searchParams.get("devtools")
      if (paramValue) {
        validateDevToolsKey({ variables: { key: paramValue } })
          .then((result) => {
            if (result.data?.validateDevTools?.isValid) {
              localStorage.setItem(localUnlockKey, true)
              setToolsUnlocked(true)
            } else {
              userCtx.setNotificationError("Unable to validate DevTools key")
            }
          })
          .catch((error) => {
            LogError("DevTools key validation error", error)
            userCtx.setNotificationError("Unable to validate DevTools key")
            localStorage.removeItem(localUnlockKey)
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  //Checks with each tools' helper function to see if there are active tools
  function checkForActiveTools() {
    setHasActiveTools(hasActiveUrlOverrides())
  }

  return (
    <>
      <DeveloperToolsModal
        showTools={showTools}
        onHide={() => {
          setShowTools(false)
          checkForActiveTools()
        }}
        onDisableTools={() => {
          localStorage.removeItem(localUnlockKey)
          setToolsUnlocked(false)
          disableAllUrlOverrides()
          setShowTools(false)
        }}
        onCheckTools={() => {
          checkForActiveTools()
        }}
      />
      {toolsUnlocked ? (
        <li
          className="top-icon"
          onClick={() => {
            setShowTools(true)
          }}
        >
          <div className={devToolStyles.devToolsTopIcon}>
            <span className="material-icons top-icon-span">code</span>
            {getEnvironmentCode()}
            {hasActiveTools ? (
              <div className={devToolStyles.hasActiveTool} />
            ) : null}
          </div>

          <label className="top-menu-label">Developer tools</label>
        </li>
      ) : null}
    </>
  )
}

import { useContext, useEffect } from "react"
import { Field, useFormikContext } from "formik"
import { DateTime } from "luxon"
import _ from "lodash"

import { FormContext } from "../../../context/formContext"
import { Calendar } from "primereact/calendar"

import styles from "../../../styles/Form.module.css"
import fieldVisibility from "../../../helpers/fieldVisibility"

export default function TypeSessionDate({ field, block, index }) {
  const form = useContext(FormContext)
  const formik = useFormikContext()
  let isDisabled = form ? form.blocksSaved[block] : false
  isDisabled = field.read_only ? true : isDisabled

  const checkIfRequired = () => {
    try {
      return JSON.parse(field.field_validation.required[0])
    } catch (error) {
      return false
    }
  }
  const isRequired = checkIfRequired()

  const handleMinDate = () => {
    switch (field.field_name) {
      default:
        return null
    }
  }
  const handleMaxDate = () => {
    switch (field.field_name) {
      default:
        return new Date()
    }
  }

  useEffect(() => {
    const fieldSessionDate = formik?.getFieldProps(field.field_name)
    if (!_.isDate(fieldSessionDate.value)) {
      const initialValue = DateTime.now()
      const now = initialValue.toUTC().toJSDate()
      const end = initialValue.plus({ minutes: 15 }).toUTC().toJSDate()

      formik.setFieldValue(field.field_name, now)
      formik.setFieldValue("session_start_time", now)
      formik.setFieldValue("session_end_time", end)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = ({ e, form }) => {
    const fieldStartTime = formik?.getFieldProps("session_start_time")
    const fieldEndTime = formik?.getFieldProps("session_end_time")

    const sessionDate = DateTime.fromJSDate(e.value).toObject()
    const startTime = DateTime.fromJSDate(fieldStartTime.value).toObject()
    const endTime = DateTime.fromJSDate(fieldEndTime.value).toObject()

    const x = DateTime.fromFormat(
      `${sessionDate.year}-${sessionDate.month}-${sessionDate.day} ${startTime?.hour || "00"
      }:${startTime?.minute || "00"}`,
      "y-M-d h:m"
    ).toJSDate()
    form.setFieldValue("session_start_time", x)
    form.setFieldValue(field.field_name, x)

    const y = DateTime.fromFormat(
      `${sessionDate.year}-${sessionDate.month}-${sessionDate.day} ${endTime?.hour || "00"
      }:${endTime?.minute || "00"}`,
      "y-M-d h:m"
    ).toJSDate()
    form.setFieldValue("session_end_time", y)
  }

  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, meta, form }) => (
          <>
            <Calendar
              name={formikField.name}
              value={formikField.value}
              onChange={(e) => handleChange({ e, form })}
              disabled={isDisabled}
              onBlur={formikField.onBlur}
              maxDate={handleMaxDate()}
              minDate={handleMinDate()}
              monthNavigator
              yearNavigator
              yearRange="1970:2100"
            />
            {meta.touched && meta.error && (
              <div className={styles.errFieldMsg}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  )
}

export default function ParticipantRSI() {
  return (
    <div className="box-container">
      <div className="box-rsi">
        <span className="rsi-number">N/A</span>
        <span className="rsi-label">Last RSI</span>
      </div>
      <div className="box-rsi">
        <span className="rsi-number">N/A</span>
        <span className="rsi-label">Last RSI</span>
      </div>
    </div>
  )
}

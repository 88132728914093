import { useField } from "formik"
import { InputText } from "primereact/inputtext"
import { InputMask } from "primereact/inputmask"
import { Skeleton } from "primereact/skeleton"
import { Password } from "primereact/password"

//Style
import styles from "../../styles/Form-Inputs.module.css"

export default function FormikTextInput({
  name: fieldName,
  label,
  placeholderText,
  required,
  isInitialLoad,
  mask,
  password,
  disabled,
  helpText,
  onChangeCallback,
}) {
  const [field, meta, helpers] = useField(fieldName)

  let placeholder = placeholderText
  if (required && !placeholder) {
    placeholder = fieldName
  }

  const inputfield = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else if (!mask && !password) {
      return (
        <InputText
          placeholder={placeholder}
          {...field}
          required={required}
          disabled={disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (!!onChangeCallback) {
              onChangeCallback(e.target.value)
            }
            helpers.setValue(e.target.value)
          }}
        />
      )
    } else if (password) {
      return (
        <Password
          placeholder={placeholder}
          {...field}
          required={required}
          disabled={disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          feedback={false}
          style={{ width: "100%" }}
        />
      )
    } else {
      return (
        <InputMask
          mask={mask}
          placeholder={mask}
          {...field}
          required={required}
          disabled={disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (!!onChangeCallback) {
              onChangeCallback(e.target.value)
            }
            helpers.setValue(e.target.value)
          }}
        />
      )
    }
  }

  const labelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={styles.field}>
      {labelField()}
      <div className="col-12 md:col-10">
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {inputfield()}

        {meta.touched && meta.error ? (
          <label id={`${fieldName}-error`} className={styles.errFieldMsg}>
            {meta.error}
          </label>
        ) : null}
      </div>
    </div>
  )
}

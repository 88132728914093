import { gql } from "@apollo/client"

export const GET_SAVED_BLOCKS = gql`
  query RecoveryPlanPage($participant_id: ID!) {
    blockSaved(
      participant_id: $participant_id
      block_name: "session__recovery_plan"
    ) {
      data
    }
  }
`

export const GET_SAVED_BLOCKS_BY_ORGANIZATION_ID = gql`
  query RecoveryPlanPage(
    $participant_id: ID!
    $organization_id: ID!
    $block_name: ID!
  ) {
    blockSavedbyOrganizationID(
      participant_id: $participant_id
      organization_id: $organization_id
      block_name: $block_name
    ) {
      data
    }
  }
`

export const GET_BLOCKS = gql`
  query ParticipantBlock($participant_id: ID!, $blocks: [String]!) {
    getRichBlocksByParticipantId(
      participant_id: $participant_id
      blocks: $blocks
    ) {
      data
    }
  }
`

export const GET_FULL_BLOCKS = gql`
  query ParticipantBlock($participant_id: ID!, $idTemplate: ID!) {
    getAllRichBlocksByParticipantId(
      participant_id: $participant_id
      idTemplate: $idTemplate
      groupByIdStamp: true
    ) {
      data
    }
  }
`
export const GET_FULL_BLOCKS_ORGANIZATION = gql`
  query ParticipantBlock($participant_id: ID!, $idTemplate: ID!) {
    getAllRichBlocksByParticipantIdGroupedOrganization(
      participant_id: $participant_id
      idTemplate: $idTemplate
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const GET_USER = gql`
  query user($staff_id: ID!, $organization_id: ID!) {
    user(staff_id: $staff_id, organization_id: $organization_id) {
      staff_name_first
      staff_name_last
    }
  }
`

export const ENGAGEMENTS_PARTICIPANT_GROUP_BY_CAPITAL_TEMPLATE = gql`
  query RecoveryCapital($participant_id: ID) {
    recoveryCapitalParticipant: getSpecificBlocksByParticipantID(
      participant_id: $participant_id
      blocks: ["session__recovery_plan"]
    ) {
      data
    }
    templateNames: templates(type: "system") {
      template_name
      template_title
    }
  }
`

import { useContext, useRef } from "react"
import { useParams, useNavigate, generatePath } from "react-router-dom"
import _ from "lodash"

// Context
import { FormContext } from "../../context/formContext"

// Prime
import { Toast } from "primereact/toast"

import {
  BsFillRecordFill as IcoBullet,
  BsCheckLg as IcoCheck,
} from "react-icons/bs"
import styles from "../../styles/Nav-Form.module.css"

export default function FormNav({ blocks }) {
  const toast = useRef(null)
  const navigate = useNavigate()
  const form = useContext(FormContext)

  const { idTemplate, formPage } = useParams()

  const currentBlock = _.find(blocks, { index: Number(formPage) })

  const showWarn = () => {
    toast.current.show({
      severity: "warn",
      detail: "Current Block must be Saved.",
      life: 3000,
    })
  }

  const listOfBlocks = _.map(blocks, (block) => {
    const { idBlock, block_title, index } = block
    const isActive = Number(formPage) === Number(index)

    const isValid = form.blocksSaved[block.block_name]
    const isEdit = form.blocksEdited[block.block_name]

    const handlePageJump = () => {
      const nextPage = () => {
        if (form.editMode) {
          return generatePath(`/form/${idTemplate}/:formPage/${form.idStamp}`, {
            formPage: index,
          })
        }
        return generatePath(`/form/${idTemplate}/:formPage/`, {
          formPage: index,
        })
      }
      return navigate(nextPage(), { replace: true })
    }

    const handleOnClick = () => {
      if (form.editMode) {
        const isCurrentBlockSaved = form.blocksSaved[currentBlock.block_name]
        return isCurrentBlockSaved ? handlePageJump() : showWarn()
      }
      return handlePageJump()
    }

    const handleIcoStyle = () => {
      if (isEdit) return styles.edit
      if (isValid) return styles.valid
    }

    const isRequired = _.includes(form.blocksRequired, block.block_name)

    return (
      <li key={idBlock} className={isActive ? styles.active : null}>
        <i className={handleIcoStyle()}>
          {isValid ? <IcoCheck /> : <IcoBullet />}
        </i>
        <button onClick={handleOnClick}>
          {block_title}
          {isRequired && "*"}
        </button>
      </li>
    )
  })
  return (
    <ul className={styles.nav}>
      {listOfBlocks}
      <Toast ref={toast} position="bottom-left" />
    </ul>
  )
}

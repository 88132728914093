import { getURLOverrideValue } from "../components/DeveloperTools/ServiceUrls/serviceUrlHelpers"
import { LogError } from "../helpers/logger"
import GLOBALS_ENVIRONMENT from "./globalsEnvironment"

//Retrieve the URL for the requested key and check if it has been overridden
const getUrlValue = (envKey, values) => {
  try {
    const overrideValue = getURLOverrideValue(envKey)

    if (overrideValue.overrideUrl) {
      return overrideValue.overrideUrl
    } else {
      //Note: Use values, as environment values are
      //      for some reason not used in participant client
      return values[envKey]
    }
  } catch (error) {
    LogError("Unable to retrieve microservice URL value", error)
    return undefined
  }
}

//Decide which environment key should be used to retrieve service URLs
const getUrlForEnvironment = (
  devKey,
  stageKey,
  qaProdKey,
  prodKey,
  values,
  defaultUrlsOnly
) => {
  let urlKey = undefined

  if (GLOBALS_ENVIRONMENT.isDevelopment) {
    urlKey = devKey
  }
  if (GLOBALS_ENVIRONMENT.isStaging) {
    urlKey = stageKey
  }
  if (GLOBALS_ENVIRONMENT.isQAProduction) {
    urlKey = qaProdKey
  }
  if (GLOBALS_ENVIRONMENT.isProduction) {
    urlKey = prodKey
  }

  if (defaultUrlsOnly) {
    return values[urlKey || devKey]
  } else {
    return getUrlValue(urlKey || devKey, values)
  }
}

function notificationsUrl(defaultUrlOnly) {
  const REACT_APP_NOTIFICATIONS_API_DEV =
    "https://notifications-dev.microservice.myrecoverylink.com"
  const REACT_APP_NOTIFICATIONS_API_STAGE =
    "https://notifications-dev.microservice.myrecoverylink.com"
  const REACT_APP_NOTIFICATIONS_API_QAPROD = ""
  const REACT_APP_NOTIFICATIONS_API_PROD =
    "https://notifications.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_NOTIFICATIONS_API_DEV",
    "REACT_APP_NOTIFICATIONS_API_STAGE",
    "REACT_APP_NOTIFICATIONS_API_QAPROD",
    "REACT_APP_NOTIFICATIONS_API_PROD",
    {
      REACT_APP_NOTIFICATIONS_API_DEV,
      REACT_APP_NOTIFICATIONS_API_STAGE,
      REACT_APP_NOTIFICATIONS_API_QAPROD,
      REACT_APP_NOTIFICATIONS_API_PROD,
    },
    defaultUrlOnly
  )
}

function tasksUrl(defaultUrlOnly) {
  const REACT_APP_TASKS_API_DEV =
    "https://tasks-dev.microservice.myrecoverylink.com"
  const REACT_APP_TASKS_API_STAGE =
    "https://tasks-dev.microservice.myrecoverylink.com"
  const REACT_APP_TASKS_API_QAPROD = ""
  const REACT_APP_TASKS_API_PROD =
    "https://tasks.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_TASKS_API_DEV",
    "REACT_APP_TASKS_API_STAGE",
    "REACT_APP_TASKS_API_QAPROD",
    "REACT_APP_TASKS_API_PROD",
    {
      REACT_APP_TASKS_API_DEV,
      REACT_APP_TASKS_API_STAGE,
      REACT_APP_TASKS_API_QAPROD,
      REACT_APP_TASKS_API_PROD,
    },
    defaultUrlOnly
  )
}

function referralsUrl(defaultUrlOnly) {
  const REACT_APP_REFERRALS_API_DEV =
    "https://referrals-dev.microservice.myrecoverylink.com"
  const REACT_APP_REFERRALS_API_STAGE =
    "https://referrals-dev.microservice.myrecoverylink.com"
  const REACT_APP_REFERRALS_API_QAPROD = ""
  const REACT_APP_REFERRALS_API_PROD =
    "https://referrals.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_REFERRALS_API_DEV",
    "REACT_APP_REFERRALS_API_STAGE",
    "REACT_APP_REFERRALS_API_QAPROD",
    "REACT_APP_REFERRALS_API_PROD",
    {
      REACT_APP_REFERRALS_API_DEV,
      REACT_APP_REFERRALS_API_STAGE,
      REACT_APP_REFERRALS_API_QAPROD,
      REACT_APP_REFERRALS_API_PROD,
    },
    defaultUrlOnly
  )
}

function gpraUrl(defaultUrlOnly) {
  const REACT_APP_GPRA_API_DEV =
    "https://gpra-dev.microservice.myrecoverylink.com"
  const REACT_APP_GPRA_API_STAGE =
    "https://gpra-dev.microservice.myrecoverylink.com"
  const REACT_APP_GPRA_API_QAPROD = ""
  const REACT_APP_GPRA_API_PROD = "https://gpra.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_GPRA_API_DEV",
    "REACT_APP_GPRA_API_STAGE",
    "REACT_APP_GPRA_API_QAPROD",
    "REACT_APP_GPRA_API_PROD",
    {
      REACT_APP_GPRA_API_DEV,
      REACT_APP_GPRA_API_STAGE,
      REACT_APP_GPRA_API_QAPROD,
      REACT_APP_GPRA_API_PROD,
    },
    defaultUrlOnly
  )
}

function telerecoveryApiUrl(defaultUrlOnly) {
  const REACT_APP_TWILIO_MICROSERVICE_API_DEV =
    "https://trs-dev.microservice.myrecoverylink.com"
  const REACT_APP_TWILIO_MICROSERVICE_API_STAGE =
    "https://trs-dev.microservice.myrecoverylink.com"
  const REACT_APP_TWILIO_MICROSERVICE_API_QAPROD = ""
  const REACT_APP_TWILIO_MICROSERVICE_API_PROD =
    "https://trs-prod.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_TWILIO_MICROSERVICE_API_DEV",
    "REACT_APP_TWILIO_MICROSERVICE_API_STAGE",
    "REACT_APP_TWILIO_MICROSERVICE_API_QAPROD",
    "REACT_APP_TWILIO_MICROSERVICE_API_PROD",
    {
      REACT_APP_TWILIO_MICROSERVICE_API_DEV,
      REACT_APP_TWILIO_MICROSERVICE_API_STAGE,
      REACT_APP_TWILIO_MICROSERVICE_API_QAPROD,
      REACT_APP_TWILIO_MICROSERVICE_API_PROD,
    },
    defaultUrlOnly
  )
}

function appointmentsApiUrl(defaultUrlOnly) {
  const REACT_APP_APPOINTMENTS_API_DEV =
    "https://appt-dev.microservice.myrecoverylink.com"
  const REACT_APP_APPOINTMENTS_API_STAGE =
    "https://appt-dev.microservice.myrecoverylink.com"
  const REACT_APP_APPOINTMENTS_API_QAPROD = ""
  const REACT_APP_APPOINTMENTS_API_PROD =
    "https://appt-prod.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_APPOINTMENTS_API_DEV",
    "REACT_APP_APPOINTMENTS_API_STAGE",
    "REACT_APP_APPOINTMENTS_API_QAPROD",
    "REACT_APP_APPOINTMENTS_API_PROD",
    {
      REACT_APP_APPOINTMENTS_API_DEV,
      REACT_APP_APPOINTMENTS_API_STAGE,
      REACT_APP_APPOINTMENTS_API_QAPROD,
      REACT_APP_APPOINTMENTS_API_PROD,
    },
    defaultUrlOnly
  )
}

function documentSigningApiUrl(defaultUrlOnly) {
  const REACT_APP_DOCUMENT_SIGNING_API_DEV =
    "https://docsign-dev.myrecoverylink.com"
  const REACT_APP_DOCUMENT_SIGNING_API_STAGE =
    "https://docsign-dev.myrecoverylink.com"
  const REACT_APP_DOCUMENT_SIGNING_API_QAPROD = ""
  const REACT_APP_DOCUMENT_SIGNING_API_PROD =
    "https://docsign-prod.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_DOCUMENT_SIGNING_API_DEV",
    "REACT_APP_DOCUMENT_SIGNING_API_STAGE",
    "REACT_APP_DOCUMENT_SIGNING_API_QAPROD",
    "REACT_APP_DOCUMENT_SIGNING_API_PROD",
    {
      REACT_APP_DOCUMENT_SIGNING_API_DEV,
      REACT_APP_DOCUMENT_SIGNING_API_STAGE,
      REACT_APP_DOCUMENT_SIGNING_API_QAPROD,
      REACT_APP_DOCUMENT_SIGNING_API_PROD,
    },
    defaultUrlOnly
  )
}

function assessmentsApiUrl(defaultUrlOnly) {
  const REACT_APP_ASSESSMENTS_API_QAPROD = ""
  const REACT_APP_ASSESSMENTS_API_PROD =
    "https://assessments.microservice.myrecoverylink.com"
  const REACT_APP_ASSESSMENTS_API_DEV =
    "https://assessments-dev.microservice.myrecoverylink.com"
  const REACT_APP_ASSESSMENTS_API_STAGE =
    "https://assessments-dev.microservice.myrecoverylink.com"

  return getUrlForEnvironment(
    "REACT_APP_ASSESSMENTS_API_DEV",
    "REACT_APP_ASSESSMENTS_API_STAGE",
    "REACT_APP_ASSESSMENTS_API_QAPROD",
    "REACT_APP_ASSESSMENTS_API_PROD",
    {
      REACT_APP_ASSESSMENTS_API_DEV,
      REACT_APP_ASSESSMENTS_API_STAGE,
      REACT_APP_ASSESSMENTS_API_QAPROD,
      REACT_APP_ASSESSMENTS_API_PROD,
    },
    defaultUrlOnly
  )
}

export {
  assessmentsApiUrl,
  notificationsUrl,
  tasksUrl,
  referralsUrl,
  telerecoveryApiUrl,
  appointmentsApiUrl,
  documentSigningApiUrl,
}

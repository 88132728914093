import React from "react"
import { useLocation } from "react-router"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

import styles from "../../styles/Page-with-Sidebar.module.css"
import Requests from "./Requests/Index"

export default function Documents({ overrideDefaultFilter }) {
  const location = useLocation()

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      <div className="main-content">
        <h1>Documents</h1>
        <Requests defaultFilterStatus={location.state?.overrideDefaultFilter} />
      </div>
    </div>
  )
}

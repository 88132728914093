import { useField } from "formik"
import { Skeleton } from "primereact/skeleton"

//Style
import styles from "../../styles/Form-Inputs.module.css"
import { InputTextarea } from "primereact/inputtextarea"

export default function RefTextAreaInput(props) {
  const [field, meta] = useField(props.name)

  let placeholder = props.placeholderText
  if (props.required && !placeholder) {
    placeholder = props.name
  }

  const inputField = () => {
    if (props.isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <InputTextarea
          placeholder={placeholder}
          {...field}
          required={props.required}
          disabled={props.disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          style={{ height: "100%" }}
        />
      )
    }
  }

  const labelField = () => {
    if (props.isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {props.label}{" "}
          {props.required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={styles.field} style={{ height: "100%" }}>
      {labelField()}
      <div style={{ height: "100%" }}>
        {inputField()}
        {meta.touched && meta.error ? (
          <label id={`${props.name}-error`} className={styles.errFieldMsg}>
            {meta.error}
          </label>
        ) : null}
      </div>
    </div>
  )
}

//Config
import GLOBALS_ENVIRONMENT from "../../config/globalsEnvironment"

export const getEnvironmentCode = () => {
  if (GLOBALS_ENVIRONMENT.isDevelopment) {
    return "DEV"
  } else if (GLOBALS_ENVIRONMENT.isProduction) {
    return "PROD"
  } else if (GLOBALS_ENVIRONMENT.isQAProduction) {
    return "QAPROD"
  } else if (GLOBALS_ENVIRONMENT.isStaging) {
    return "STAGE"
  } else if (GLOBALS_ENVIRONMENT.isLocal) {
    return "DEV"
  } else {
    return "???"
  }
}

import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"

// Context
import { UserContext } from "../../context/userContext"

// Component
import FormProfile from "./Form/FormProfile"
import { LoaderMedium } from "../../components/Loaders"
import ROUTES from "../../components/Routes/routesContants"

import { FirebaseContext } from "../../firebase"

// Queries
import {
  EDIT_PROFILE,
  GET_PARTICIPANT,
  EDIT_PROFILE_PARTICIPANT,
} from "./queries"

export default function EditProfile() {
  const user = useContext(UserContext)
  const [uploadValue, setUploadValue] = useState(0)

  const [filename, setFilename] = useState(null)
  const [filepath, setFilepath] = useState(null)
  const [mediaURL, setMediaURL] = useState(null)

  const firebase = useContext(FirebaseContext)

  const navigate = useNavigate()

  const mutationVariables = {
    staff_id: user.participant_id,
  }

  const { data, loading } = useQuery(GET_PARTICIPANT, {
    variables: {
      participant_id: user.participant_id,
      organization_id: user.organization_id,
    },
    fetchPolicy: "no-cache",
  })

  const [editUser] = useMutation(EDIT_PROFILE_PARTICIPANT, {
    variables: mutationVariables,
    refetchQueries: [
      {
        query: GET_PARTICIPANT,
        variables: {
          participant_id: user.participant_id,
          organization_id: user.organization_id,
        },
      },
    ],
    onCompleted: () => navigate(ROUTES.PROFILE_EDIT),
  })

  const handleSubmit = ({ variables }) => {
    // inicio test subir archivo
    const {
      getStorage,
      ref,
      uploadBytesResumable,
      getDownloadURL,
      deleteObject,
    } = firebase.storage

    const storage = getStorage()
    const bucketPath = `public/profilePhotos/${user.organization_id}/`
    const file = variables.file
    const path = `${bucketPath}/${file.name}` + makeid(10)
    const storageRef = ref(storage, path)
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          const update = {
            photoURL: url,
          }

          firebase.auth.currentUser.updateProfile(update)
        })
      }
    )

    // fin test subir archivo

    const mutationVariables = {
      staff_id: "0",
      participant_id: user.participant_id,
      participant_name_first: variables.participant_name_first,
      participant_name_last: variables.participant_name_last,
      participant_email_account: variables.participant_email_account,
    }

    editUser({ variables: mutationVariables })
  }

  function makeid(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  if (loading) return <LoaderMedium />

  return (
    <div>
      <h1>Account Settings</h1>

      <div className="layout-full">
        <FormProfile fields={data.participant} onSubmitHandler={handleSubmit} />
      </div>
    </div>
  )
}

import { useState, useContext, useEffect } from "react"
import { useQuery } from "@apollo/client"
import _ from "lodash"

import ParticipantProfileBoxSmall from "../../../components/ParticipantProfileBoxSmall"
// Context
import { UserContext } from "../../../context/userContext"

// Prime
import { TabView, TabPanel } from "primereact/tabview"

// Comps
import ParticipantSummaryInfo from "../../../components/sidebars/ParticipantSummaryInfo"

import { LoaderMedium } from "../../../components/Loaders"
import Form from "./Form"

import { MdStickyNote2 as IcoBlocks } from "react-icons/md"

// Queries
import {
  PARTICIPANT_ACCOUNT_INFORMATION_BY_PARTICIPANT,
} from "./queries"

import styles from "../../../styles/Participant-Profile.module.css"

export default function ParticipantProfile() {
  const user = useContext(UserContext)

  const [blocks, setBlocks] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const participant_id = user.participant_id

  useEffect(() => {
    const urlString = window.location.href.toLowerCase() || null
    const url = new URL(urlString)
    if (url.searchParams.get("tab")) {
      const newIndex = Number(url.searchParams.get("tab"))
      setActiveIndex(newIndex)
    }
  }, [])
  const { loading, refetch } = useQuery(
    PARTICIPANT_ACCOUNT_INFORMATION_BY_PARTICIPANT,
    {
      variables: {
        participant_id,
        organization_id: user.organization_id,
      },
      fetchPolicy: "network-only",
      skip: !user,
      onCompleted: ({ participantProfile }) => {
        const sortBlocks = _.sortBy(participantProfile.blocks, ["index"])
        const renameBlocks = _.map(sortBlocks, (block) => {
          return {
            ...block,
            block_title: `${block.block_title}`.replace("Participant ", ""),
          }
        })
        setBlocks(renameBlocks)
      },
    }
  )

  if (loading) return <LoaderMedium />

  return (
    <div>
      <div className="layout-with-sidebar">
        <ParticipantSummaryInfo />

        <div className="main-content profile-content">
          <div className="history"></div>

          <ParticipantProfileBoxSmall />

          <div className={styles.tabs}>
            <h3>
              <IcoBlocks />
              Personal Information
            </h3>
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              {_.map(blocks, (block) => {
                return (
                  <TabPanel
                    key={block.block_name}
                    header={block.block_title}
                    id={block.block_name}
                  >
                    <Form
                      key={block.block_name}
                      blockName={block.block_name}
                      totalFields={block.total_fields}
                      triggerParticipantQuery={refetch}
                    />
                  </TabPanel>
                )
              })}
            </TabView>
          </div>
        </div>
      </div>
    </div>
  )
}

//Formik
import { useField } from "formik"

//PrimeReact
import { Skeleton } from "primereact/skeleton"
import { InputTextarea } from "primereact/inputtextarea"

//Style
import styles from "../../styles/Form-Inputs.module.css"

export default function FormikTextAreaInput({
  name,
  label,
  placeholderText,
  required,
  isInitialLoad,
  rows,
  disabled,
  helpText,
}) {
  const [field, meta] = useField(name)

  let placeholder = placeholderText
  if (required && !placeholder) {
    placeholder = name
  }

  const inputfield = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <InputTextarea
          placeholder={placeholder}
          {...field}
          required={required}
          disabled={disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          style={{ width: "100%", height: "100%" }}
          rows={rows}
        />
      )
    }
  }

  const labelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={styles.field}>
      {labelField()}
      <div className="col-12 md:col-10">
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {inputfield()}
        {meta.touched && meta.error ? (
          <label id={`${name}-error`} className={styles.errFieldMsg}>
            {meta.error}
          </label>
        ) : null}
      </div>
    </div>
  )
}

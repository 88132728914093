import { DateTime } from "luxon"

import { LogError } from "../../helpers/logger"

import { getAllMinifiedAppointments } from "../Appointments/AppointmentsApi"
import { getDateKey } from "../Appointments/utils"

//Styles
import style from "../../styles/dailyPlanner/DailyPlannerAppointments.module.css"
import { Dialog } from "primereact/dialog"

export async function getAppointments(user, onSetAppointmentsToday) {
  //Get tasks assigned to me that are due today
  let todayLocal = DateTime.fromISO(DateTime.now().toISODate())



  let endOfToday = DateTime.local(
    todayLocal.year,
    todayLocal.month,
    todayLocal.day,
    23,
    59,
    59,
    999
  )

  return getAllMinifiedAppointments(
    user.tokenAppointments,
    null, 
    todayLocal.toISO(),
    endOfToday.toISO(),
    user.participant_alias //Note: Should also accept user.participant_id
  )
    .then(async (result) => {
      if (result.successful) {
        onSetAppointmentsToday(result.data)
      } else {
        LogError(`Unable to retrieve appointments: ${result.error}`)
      }
    })
    .catch((error) => {
      LogError("An unexpected error occurred while retrieving tasks", error)
      user.setNotificationError(error.Message)
    })
}

function getTime(date, dateFormat) {

  if(date.length > 19){
    dateFormat = `${dateFormat} ZZ`
  }

  //const dateText = date.substring(0, dateFormat.length + 1)
  const dateText = date

  const time = DateTime.fromFormat(dateText, dateFormat).toFormat("hh:mm a")

  return time
}

export function GetAppointmentInfo({ appointment, onHideDialog }) {
  if (!appointment) {
    return null
  }

  return (
    <Dialog
      header="Appointment Detail"
      visible={appointment !== undefined}
      onHide={() => {
        onHideDialog()
      }}
    >
      <div>
        <div className={style.gridInfoHeader}>Appointment: </div>
        <div>
          <span className={style.gridDescription}>Service: </span>
          {appointment?.engagementType}
        </div>
        <div>
          <span className={style.gridDescription}>Channel: </span>
          {appointment?.engagementChannel}
        </div>
        <div>
          <span className={style.gridDescription}>From: </span>
          {getTime(
            appointment?.startDateTime,
            !!appointment ? getDateKey(appointment?.startDateTime, true) : ""
          )}
        </div>
        <div>
          <span className={style.gridDescription}>To: </span>
          {getTime(
            appointment?.endDateTime,
            !!appointment ? getDateKey(appointment?.endDateTime, true) : ""
          )}
        </div>

        <div className={style.gridInfoHeader2}>Staff:</div>
        <div>
          <span className={style.gridDescription}>Name: </span>
          {appointment?.staff}
        </div>
{/*         <div>
          <span className={style.gridDescription}>E-mail: </span>
          {appointment?.participantEmail}
        </div>
        <div>
          <span className={style.gridDescription}>Phone: </span>
          {appointment?.participantPhone}
        </div> */}
        <div>
          <button
            className={`btn ${style.gridInfoButton}`}
            onClick={() => {
              onHideDialog()
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export function GetAppointmentsPanel(appointment, onShowAppointment) {
  const dateFormat = getDateKey(appointment.startDateTime, true)

  return (
    <div className={style.taskGridContainer}>
      <div className={style.gridItemType}>
        <span className={style.gridDescription}>Staff name: </span>
        {appointment.staff}
      </div>
      <div className={style.gridItemFrom}>
        <span className={style.gridDescription}>From: </span>
        {getTime(appointment.startDateTime, dateFormat)}
      </div>
      <div className={style.gridItemTo}>
        <span className={style.gridDescription}>To: </span>
        {getTime(appointment.endDateTime, dateFormat)}
      </div>

      <div className={style.gridItemParticipant}>
        <span className={style.gridDescription}>Service: </span>
        {appointment.engagementType}
      </div>
      <div className={style.gridItemInfo}>
        <button
          className={`btn ${style.gridInfoBtn}`}
          onClick={() => {
            onShowAppointment(appointment)
          }}
        >
          <span className="material-icons ">{"info"}</span>
        </button>
      </div>
    </div>
  )
}

export function headerButtonAction(user) {
  user.setToggleAppointments({ toggle: true, page: "default" })
}

import _ from "lodash"
import { useContext } from "react"
import { Field, FastField } from "formik"

import { FormContext } from "../../context/formContext"
import { RadioButton } from "primereact/radiobutton"

import styles from "../../styles/Form.module.css"

import fieldVisibility from "../../helpers/fieldVisibility"

function RegularFieldOutput(props) {
  const { field, block, index } = props
  const isRequired = JSON.parse(field.field_validation.required[0])
  const form = useContext(FormContext)
  const isDisabled = form ? form.blocksSaved[block] : false

  let visibility = fieldVisibility(field.field_visibility)

  if (field.field_name === "participant_email_confirm" && _.isEmpty(form)) {
    visibility = "none"
  }

  return (
    <div className={styles.field} style={{ order: index, display: visibility }}>
      <label className={styles.label} style={{ marginBottom: 10 }}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      {_.map(field.field_options, (option) => {
        return (
          <div
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
          >
            <Field name={field.field_name}>
              {({ field: formikField, meta }) => {
                return (
                  <>
                    <RadioButton
                      disabled={isDisabled}
                      inputId={`${field.field_name}_${option.value}`}
                      name={formikField.name}
                      value={option.value}
                      onChange={formikField.onChange}
                      checked={formikField.value === option.value}
                    />
                    <label
                      htmlFor={`${field.field_name}_${option.value}`}
                      style={{ marginLeft: 5 }}
                    >
                      {option.label}
                    </label>
                  </>
                )
              }}
            </Field>
          </div>
        )
      })}
    </div>
  )
}

function MultiFieldOutput(props) {
  const { field, index, mfFieldName, mfIndex } = props
  const fieldSlug = `${mfFieldName}.${mfIndex}.${field.field_name}`
  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>{field.field_label}</label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      {_.map(field.field_options, (option) => {
        return (
          <div
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
          >
            <FastField name={fieldSlug}>
              {({ field: fastField, form: formik }) => {
                return (
                  <>
                    <RadioButton
                      inputId={`${field.field_name}_${option.value}`}
                      name={fastField.name}
                      value={option.value}
                      onChange={fastField.onChange}
                      checked={fastField.value === option.value}
                    />
                    <label
                      htmlFor={`${field.field_name}_${option.value}`}
                      style={{ marginLeft: 5 }}
                    >
                      {option.label}
                    </label>
                  </>
                )
              }}
            </FastField>
          </div>
        )
      })}
    </div>
  )
}

function NestedMultiFieldOutput(props) {
  const {
    field,
    index,
    mfFieldName,
    mfIndex,
    nestedMfFielName,
    nestedMfIndex,
  } = props
  const fieldSlug = `${mfFieldName}.${mfIndex}.${nestedMfFielName}.${nestedMfIndex}.${field.field_name}`
  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>{field.field_label}</label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      {_.map(field.field_options, (option) => {
        return (
          <div
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
          >
            <FastField name={fieldSlug}>
              {({ field: fastField, form: formik }) => {
                return (
                  <>
                    <RadioButton
                      inputId={`${nestedMfFielName}_${field.field_name}_${option.value}`}
                      name={fastField.name}
                      value={option.value}
                      onChange={fastField.onChange}
                      checked={fastField.value === option.value}
                    />
                    <label
                      htmlFor={`${field.field_name}_${option.value}`}
                      style={{ marginLeft: 5 }}
                    >
                      {option.label}
                    </label>
                  </>
                )
              }}
            </FastField>
          </div>
        )
      })}
    </div>
  )
}

export default function TypeRadio(props) {
  switch (props.type) {
    case "regular-field":
      const regFieldProps = {
        field: props.field,
        block: props.block,
        index: props.index,
      }
      return <RegularFieldOutput {...regFieldProps} />
    case "multi-field":
      const multiFieldProps = {
        field: props.field,
        index: props.index,
        mfFieldName: props.mfFieldName,
        mfIndex: props.mfIndex,
      }
      return <MultiFieldOutput {...multiFieldProps} />
    case "nested-multi-field":
      const nestedMultiFieldProps = {
        field: props.field,
        index: props.index,
        mfFieldName: props.mfFieldName,
        mfIndex: props.mfIndex,
        nestedMfFielName: props.nestedMfFielName,
        nestedMfIndex: props.nestedMfIndex,
      }
      return <NestedMultiFieldOutput {...nestedMultiFieldProps} />

    default:
      return null
  }
}

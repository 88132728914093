import { useCallback, useContext, useEffect, useState } from "react"
import { ProgressSpinner } from "primereact/progressspinner"
import { useLazyQuery } from "@apollo/client"
import { GET_USER_BY_ALIAS } from "./taskQueries"

// Context
import { UserContext } from "../../context/userContext"

export default function AppointmentStaffColumnBody({
  rowData,
  staffList,
  onAddStaff,
}) {
  const userCtx = useContext(UserContext)

  const [staffProfile, setStaffProfile] = useState(undefined)

  const [getUser] = useLazyQuery(GET_USER_BY_ALIAS, {
    variables: {
      uid: "-6",
      organization_id: userCtx.organization_id,
      staff_alias: rowData?.PeerId,
      organization_alias: userCtx.organization_alias,
    },
  })

  const getStaffProfile = useCallback(async () => {
    const profile = staffList.filter(
      (staff) => staff.staff_alias === rowData.PeerId
    )

    if (profile.length > 0) {
      setStaffProfile(profile[0])
    } else {
      const getProfile = await getUser({
        variables: {
          uid: "-6",
          organization_id: userCtx.organization_id,
          staff_alias: rowData?.peerId,
          organization_alias: userCtx.organization_alias,
        },
      })
      if (getProfile?.data) {
        const newStaffProfile = {
          ...getProfile.data.userByAlias,
          displayName: `${getProfile.data.userByAlias.staff_name_first} ${getProfile.data.userByAlias.staff_name_last}`,
        }

        setStaffProfile(newStaffProfile)
        onAddStaff(newStaffProfile)
      } else {
        onAddStaff({ staff_alias: rowData?.peerId, displayName: "" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffList])

  useEffect(() => {
    getStaffProfile()
  }, [getStaffProfile])

  return (
    <>
      {staffProfile ? (
        staffProfile.displayName
      ) : (
        <ProgressSpinner
          style={{ width: "30px", height: "30px" }}
          strokeWidth="4"
          animationDuration=".2s"
        />
      )}
    </>
  )
}

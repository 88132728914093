import React from "react"
import trcss from "../../styles/Telerecovery.module.css"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

export default function ExternalLinkPopup({ link, showPopup, setShowPopup }) {

    const footerContent = (
        <div className={trcss.buttons}>
            <Button
                className={trcss.cancel_button}
                label="Abort"
                onClick={() => setShowPopup(false)}
            />
            <Button
                className={trcss.button}
                label="Proceed"
                onClick={() => {
                    setShowPopup(false)
                    window.open(link, '_blank')
                }}
                autoFocus
            />
        </div>
    )
    return <Dialog
        header="Leaving RecoveryLink"
        visible={showPopup}
        style={{ width: '50vw' }}
        className="custom_dialog"
        footer={footerContent}
        modal
        closable={false}
        onHide={() => { setShowPopup(false) }}
    >
        <p>By proceeding, you acknowledge that you are leaving the RecoveryLink platform and navigating to a third-party application.</p>
    </Dialog>
}

import * as Yup from "yup"
import _ from "lodash"


export default function validation_conditional_text(field, acc) {
  const { field_name, field_validation } = field

  const conditions = JSON.parse(field.field_conditional) 
  const fieldConditional =  _.keys(conditions)
  const conditionFieldName = fieldConditional[0]

  const checkBool = () => {
    if (!field_validation.required) return false
    return field_validation.required[0] === "true" ? true : false
  }

  const isRequired = checkBool()
  const isRequiredTxt = field_validation.required[1] || "Required field."

  const min = (field_validation.min && Number(field_validation.min[0])) || null
  const minTxt =
    (field_validation.min && field_validation.min[1]) ||
    `Text must have at least ${min} characters.`

  const max = (field_validation.max && Number(field_validation.max[0])) || null
  const maxTxt =
    (field_validation.max && field_validation.max[1]) ||
    `Text must be less then ${max} characters long.`

  if (!isRequired) return acc

  if (min && max) {
    return {
        [field_name]: Yup.string()
        .nullable()
        .when(conditionFieldName, {
          is: (value) => {
            if(_.isArray(value))  {
              return _.find(value, function (v) {
                if (_.includes(conditions[conditionFieldName], v)) {
                    return true
                }
            });  
            } else {
              return _.includes(conditions[conditionFieldName], value) 
            }
          }, 
          then: Yup.string()
          .required(isRequiredTxt)
          .min(min, minTxt)
          .max(max, maxTxt),
        }),
      ...acc,
      }
  } else if (min) {
    return {
        [field_name]: Yup.string()
        .nullable()
        .when(conditionFieldName, {
          is: (value) => {
            if(_.isArray(value))  {
              return _.find(value, function (v) {
                if (_.includes(conditions[conditionFieldName], v)) {
                    return true
                }
            });  
            } else {
              return _.includes(conditions[conditionFieldName], value) 
            }
          }, 
          then: Yup.string().required(isRequiredTxt).min(min, minTxt),
        }),
      ...acc,
      }
  } else if (max) {
    return {
        [field_name]: Yup.string()
        .nullable()
        .when(conditionFieldName, {
          is: (value) => {
            if(_.isArray(value))  {
              return _.find(value, function (v) {
                if (_.includes(conditions[conditionFieldName], v)) {
                    return true
                }
            });  
            } else {
              return _.includes(conditions[conditionFieldName], value) 
            }
          }, 
          then: Yup.string().required(isRequiredTxt).max(max, maxTxt),
        }),
      ...acc,
      }
  } else {
    return {
      
        [field_name]: Yup.string()
        .nullable()
        .when(conditionFieldName, {
          is: (value) => {
            if(_.isArray(value))  {
              return _.find(value, function (v) {
                if (_.includes(conditions[conditionFieldName], v)) {
                    return true
                }
            });  
            } else {
              return _.includes(conditions[conditionFieldName], value) 
            }
          }, 
          then: Yup.string().required(isRequiredTxt),
        }),
      ...acc,
      }
  }
}

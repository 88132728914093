import React,{ useState }  from "react"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import styles from "../../styles/Page-with-Sidebar.module.css"

export default function HarmReductionActivityLog() {

  const dummyAssesment = [
    {
      date: "2022-06-03	",
      length: "2:10	",
      activity_name: "Agnes B Sanford",
      phone: "913-205-5434	",
      type: "Outgoing",
      actions: <button className="btn">Edit</button>,
    }
  ]

  const [lineStylesData] = useState({
    labels: ["20", "30", "40", "50", "60"],
    datasets: [
      {
        label: "Current",
        data: [28, 30, 40, 45, 59],
        fill: true,
        borderDash: [5, 5],
        tension: 0.4,
        borderColor: "rgba(180,99,200,0.2)",
        backgroundColor: "rgba(162,132,170,0.2)",
      },
      {
        label: "Intake",
        data: [30, 35, 40, 45, 56],
        fill: true,
        borderColor: "rgba(162,132,170,0.2)",
        tension: 0.4,
        backgroundColor: "rgba(180,99,200,0.5)",
      },
    ],
  })


  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    }

    return {
      basicOptions,
    }
  }

  const { basicOptions } = getLightTheme()


  return (
    <div  className="layout-with-sidebar">
      
        <ParticipantSummaryInfo />
     
      <div className="main-content">
        <h1>Harm Reduction Activity Log</h1>

        <div>
            
            <DataTable value={dummyAssesment} responsiveLayout="scroll">
              <Column field="date" header="Date" />
              <Column field="length" header="Length" />
              <Column field="activity_name" header="Activity Name" />
              <Column field="phone" header="Phone" />
              <Column field="type" header="Type" />
              <Column field="actions" header="Actions" />
            </DataTable>
          </div>
      </div>
    </div>
  )
}

import { useState, useContext } from "react"
import { Field } from "formik"
import { useLazyQuery, gql } from "@apollo/client"
import { FormContext } from "../../context/formContext"
import { UserContext } from "../../context/userContext"
import PhoneInput from "react-phone-number-input/input"
import 'react-phone-number-input/style.css'
import styles from "../../styles/Form.module.css"

const PHONE_NUMBER_VERIFIER = gql`
  query phoneNumberVerifier(
    $participant_phone_primary: String!
    $organization_id: String!
    $participant_id: String!
  ) {
    phoneNumberVerifier(
      participant_phone_primary: $participant_phone_primary
      organization_id: $organization_id
      participant_id: $participant_id
    ) {
      data
    }
  }
`

const isValidPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return false
  const cleanedNumber = phoneNumber.replace(/\D/g, "")
  return phoneNumber.startsWith("+1") && cleanedNumber.length === 11
}

const validatePhoneNumber = async (
  value,
  checkPhone,
  typeQuery,
  userCtx,
  staffId
) => {
  let error
  if (!isValidPhoneNumber(value)) {
    error = "Please enter a valid US phone number (maximum 11 digits)"
  } else {
    try {
      if (typeQuery !== "") {
        let checkPhoneVars = {
          participant_phone_primary: value,
          organization_id: userCtx.session.user.organization_id,
          participant_id: userCtx.participant_id,
        }

        let user = userCtx.participant_id

        if (user !== undefined) {
          const result = await checkPhone({
            variables: checkPhoneVars,
          })

          if (
            result.data &&
            JSON.parse(result.data["phoneNumberVerifier"].data)
          ) {
            error = `${value} is already in use by an existing participant member. Please provide a unique phone number.`
          }
        }
      }
    } catch (error) {
      console.error("GraphQL query error:", error)
      error = "An error occurred while validating the phone number."
    }
  }
  return error
}

export default function TypeTel({ field, block, index }) {
  const [errorMsg, setErrorMsg] = useState(null)
  const [isValid, setIsValid] = useState(false)
  const userCtx = useContext(UserContext)
  const form = useContext(FormContext)
  const isDisabled = form ? form.blocksSaved[block] : false

  const [checkPhone, { loading }] = useLazyQuery(PHONE_NUMBER_VERIFIER, {
    onError: (error) => {
      console.error("GraphQL query error:", error)
      setErrorMsg(error.message)
      setIsValid(false)
    },
  })

  const handleBlur = async (formikField, form) => {
    form.setFieldTouched(formikField.name)
    console.log(formikField.name)
    const error = await validatePhoneNumber(
      formikField.value,
      checkPhone,
      formikField.name==="participant_phone_primary" ? "participant" : "",
      userCtx,
      null
    )
    if (error) {
      setErrorMsg(error)
      setIsValid(false)
    } else {
      setErrorMsg(null)
      setIsValid(true)
    }
  }

  const isRequired = JSON.parse(field.field_validation.required[0])

  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field
        name={field.field_name}
        validate={(value) =>
          isRequired
            ? validatePhoneNumber(value, checkPhone, field.field_name==="participant_phone_primary" ? "participant" : "", userCtx)
            : undefined
        }
      >
        {({ field: formikField, meta, form }) => (
          <>
            <PhoneInput
              defaultCountry="US"
              placeholder="Enter phone number"
              disabled={isDisabled || loading}
              value={formikField.value}
              onChange={(value) => {
                if (value && !value.startsWith("+1")) {
                  value = "+1" + value
                }
                form.setFieldValue(formikField.name, value)
                setErrorMsg(null)
                setIsValid(false)
              }}
              onBlur={() => handleBlur(formikField, form)}
              className="p-inputtext p-component"
              international={false} // Esto elimina la bandera
            />
            {meta.touched && errorMsg && (
              <div className={styles.errFieldMsg}>{errorMsg}</div>
            )}
            {!meta.error && isValid && (
              <div className={styles.validFieldMsg}>
                {formikField.value} is a valid US phone number
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  )
}

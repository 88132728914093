import { gql } from "@apollo/client"

export const GET_PARTICIPANT_ORGANIZATIONS = gql`
  query GetOrganizations($participant_id: ID!) {
    organizationsByPid(participant_id: $participant_id) {
      organization_id
      organization_alias
      organization_name
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($participant_id: ID!, $organization_id: ID!) {
    staffsByPid(
      participant_id: $participant_id
      organization_id: $organization_id
    ) {
      staff_id
      staff_alias
      staff_name_first
      staff_name_last
    }
  }
`

export const GET_USERS_PARTICIPANTS = gql`
  query GetUsers($staff_id: ID!, $organization_id: ID!) {
    users(staff_id: $staff_id, organization_id: $organization_id) {
      staff_id
      staff_name_first
      staff_name_last
      staff_email_address
      staff_role_name
    }

    allParticipants: participantAdvancedSearch(
      staff_id: $staff_id
      organization_id: $organization_id
      search_type: "all_participants"
    ) {
      participant_id
      participant_name_first
      participant_name_last
      participant_email_account
      participant_membership_status
    }

    myParticipants: participantAdvancedSearch(
      staff_id: $staff_id
      organization_id: $organization_id
      search_type: "my_participants"
    ) {
      participant_id
      participant_membership_status
    }
  }
`

export const GET_USERS_FROM_LIST = gql`
  query Users($list: [ID!]) {
    users: usersFromList(list: $list) {
      staff_id
      staff_alias
      staff_name_first
      staff_name_last
      staff_email_address
      staff_user_status
    }
  }
`

import * as Yup from "yup"
import _ from "lodash"


export default function validation_conditional_checkbox(field, acc) {
  const { field_name, field_validation } = field

  const conditions = JSON.parse(field.field_conditional)
  const fieldConditional =  _.keys(conditions)
  const conditionFieldName = fieldConditional[0]

  const checkBool = () => {
    if (!field_validation.required) return false
    return field_validation.required[0] === "true" ? true : false
  }

  const isRequired = checkBool()
  const isRequiredTxt =
    (field_validation.required && field_validation.required[1]) ||
    "Required field."

  if (!isRequired) return acc

  return {
    [field_name]: Yup.array()
    .nullable()
    .when(conditionFieldName, {
        is: (value) => {
          if(_.isArray(value))  {
            return _.find(value, function (v) {
              if (_.includes(conditions[conditionFieldName], v)) {
                  return true
              }
          });  
          } else {
            return _.includes(conditions[conditionFieldName], value) 
          }
        }, 
        then:  Yup.array().min(1, isRequiredTxt).required(),
      }),
    ...acc,
  }
}

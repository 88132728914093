import React from "react"
import { Skeleton } from "primereact/skeleton"
import _ from "lodash"

import styles from "../../styles/Form.module.css"

export default function FormLoader({ numberOfFields }) {
  const handleSkeleton = () => {
    let skeletonList = []
    _.times(numberOfFields, (i) => {
      skeletonList.push(
        <div className={styles.field} key={i}>
          <Skeleton
            width="10rem"
            height="1rem"
            className={styles.skeletonLabel}
          />
          <Skeleton width="15rem" height="3rem" />
        </div>
      )
    })
    return skeletonList
  }

  return (
    <form className={styles.block}>
      <Skeleton width="20rem" height="2rem" className={styles.skeletonTitle} />
      {handleSkeleton()}
    </form>
  )
}

import * as Yup from "yup"

export default function validation_text(field, acc) {
  const { field_name, field_validation } = field
  const checkBool = () => {
    if (!field_validation.required) return false
    return field_validation.required[0] === "true" ? true : false
  }

  const isRequired = checkBool()
  const isRequiredTxt = field_validation.required[1] || "Required field."

  const min = (field_validation.min && Number(field_validation.min[0])) || null
  const minTxt =
    (field_validation.min && field_validation.min[1]) ||
    `Text must have at least ${min} characters.`

  const max = (field_validation.max && Number(field_validation.max[0])) || null
  const maxTxt =
    (field_validation.max && field_validation.max[1]) ||
    `Text must be less then ${max} characters long.`

  if (!isRequired) return acc

  if (min && max) {
    return {
      [field_name]: Yup.string()
        .required(isRequiredTxt)
        .min(min, minTxt)
        .max(max, maxTxt),
      ...acc,
    }
  } else if (min) {
    return {
      [field_name]: Yup.string().required(isRequiredTxt).min(min, minTxt),
      ...acc,
    }
  } else if (max) {
    return {
      [field_name]: Yup.string().required(isRequiredTxt).max(max, maxTxt),
      ...acc,
    }
  } else {
    return {
      [field_name]: Yup.string().required(isRequiredTxt),
      ...acc,
    }
  }
}

// import
import * as ReadyOnlyType from "../../components/FieldTypes/TypeReadOnly"

export default function FieldValuePerType({ fieldValuePerTypeArg }) {
  const { blockValues, fieldName, fieldType, fieldOptions, description } =
    fieldValuePerTypeArg

  switch (fieldType) {
    case "checkbox":
      return <ReadyOnlyType.TypeCheckbox value={blockValues[fieldName]} />
    case "date":
      return (
        <ReadyOnlyType.TypeDate
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "dropdown":
      return (
        <ReadyOnlyType.TypeDropdown
          value={blockValues[fieldName]}
          options={fieldOptions}
          description={description}
        />
      )
    case "multiselect":
      return <ReadyOnlyType.TypeMultiSelect value={blockValues[fieldName]} />
    case "dropdown_us_states":
      return <ReadyOnlyType.TypeState value={blockValues[fieldName]} />
    case "email":
      return <ReadyOnlyType.TypeEmail value={blockValues[fieldName]} />
    case "monthpicker":
      return <ReadyOnlyType.TypeMonthPicker value={blockValues[fieldName]} />
    case "multi_field":
      return <ReadyOnlyType.TypeMultiField value={blockValues[fieldName]} />
    case "text":
      return <ReadyOnlyType.TypeText value={blockValues[fieldName]} />
    case "textarea":
      return <ReadyOnlyType.TypeTextarea value={blockValues[fieldName]} />
    case "textarea_rich":
      return <ReadyOnlyType.TypeTextareaRich value={blockValues[fieldName]} />
    case "time":
      return (
        <ReadyOnlyType.TypeTime
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "number":
      return (
        <ReadyOnlyType.TypeNumber
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "password":
      return {}
    case "radio":
      return (
        <ReadyOnlyType.TypeRadio
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "phone":
      return <ReadyOnlyType.TypePhone value={blockValues[fieldName]} />
    case "url":
      return <ReadyOnlyType.TypeURL value={blockValues[fieldName]} />
    case "zipcode":
      return <ReadyOnlyType.TypeZipCode value={blockValues[fieldName]} />
    case "dropdowngrouped":
      return (
        <ReadyOnlyType.TypeDropDownGrouped value={blockValues[fieldName]} />
      )
    case "conditional_text":
      return (
        <ReadyOnlyType.TypeConditionalText
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "conditional_checkbox":
      return (
        <ReadyOnlyType.TypeConditionalCheckbox value={blockValues[fieldName]} />
      )
    case "conditional_dropdown":
      return (
        <ReadyOnlyType.TypeConditionalDropdown value={blockValues[fieldName]} />
      )
    case "conditional_textarea_rich":
      return (
        <ReadyOnlyType.TypeConditionalTextAreaRich
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "conditional_number":
      return (
        <ReadyOnlyType.TypeConditionalNumber
          value={blockValues[fieldName]}
          description={description}
        />
      )
    case "file":
      return <ReadyOnlyType.TypeFile value={blockValues[fieldName]} />
    default:
      return null
  }
}

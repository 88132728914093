
import { telerecoveryApiUrl } from "../../config/microservicesUrls"
import { axiosGet, axiosPost } from "../../helpers/microserviceAPIs";

const api_url = telerecoveryApiUrl();

//Chat

export async function trApiSendChatMessageToStaff(jwt, smsBody) {
  return await axiosPost(jwt, `${api_url}/Chat/ParticipantToPeerMessage`, smsBody)
}

export async function trApiGetChatHistory(jwt, convSid) {
  return await axiosGet(jwt, `${api_url}/Chat/ChatHistory?convSid=${convSid}`)
}

export async function trApiGetChatConversationSid(jwt, peerId, caseId, orgId) {
  return await axiosGet(jwt, `${api_url}/Chat/GetConversations?peerId=${peerId}&caseId=${caseId}&orgId=${orgId}`)
}

export async function trApiChatConvSetRead(jwt, data) {
  return await axiosPost(jwt, `${api_url}/Chat/SetRead`, data)
}
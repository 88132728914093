import { useContext } from "react"
import { DateTime } from "luxon"

// Context
import { UserContext } from "../../context/userContext"

//Referral components
import { referralsParticipantStaffId } from "../../config/microservicesSystemUsers"
import { refApiRefEntityNotePut } from "./ReferralsAPI"
import { getNotePayload } from "./sharedFunctions"

//PrimeReact
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"

//Formik
import { Formik, Form, useField } from "formik"
import * as Yup from "yup"
import FormikDropDown from "../../helpers/formikInputs/FormikDropDown"
import FormikTextAreaInput from "../../helpers/formikInputs/FormikTextAreaInput"

//GraphQL
import { useMutation } from "@apollo/client"
import { UPDATE_REFERRAL_Block } from "./query"
import { LogError } from "../../helpers/logger"

export default function ReferralStatusPopup({
  showForm,
  onHideForm,
  onStatusChanged,
  referralOrganizationId,
  referralIdStamp,
  referralStatus,
  referralParticipantsNotes,
  referralEntityId,
}) {
  const user = useContext(UserContext)
  const [, , statusFieldHelper] = useField("referral.values.referral_status")

  const referralStatuses = [
    "Active referral",
    "Completed referral",
    "Incomplete referral",
  ]

  const [updateReferral] = useMutation(UPDATE_REFERRAL_Block, {
    variables: {
      referral_status: "",
      referral_participants_notes: "",
      user_type: "",
      idStamp: "",
    },
  })

  const validationSchema = Yup.object().shape({
    status: Yup.string()
      .required("Please specify a status to update")
      .notOneOf(
        [referralStatus],
        "The status is unchanged, please select a new status"
      ),
    note: Yup.string().required("Please enter a note for the status update"),
  })

  const handleSubmit = async (values) => {
    try {
      const todayLocal = DateTime.now()
      const todayUtc = todayLocal.toUTC().toISO()
      const serviceUserId = referralsParticipantStaffId()
      const referral_update = await updateReferral({
        variables: {
          staff_id: serviceUserId,
          organization_id: referralOrganizationId,
          referral_status: values.status,
          referral_participants_notes: referralParticipantsNotes,
          user_type: "participant",
          idStamp: referralIdStamp,
        },
      })

      if (!!referral_update.data.updateBlockReferral) {
        //Add status update note to Referral service
        const payload = {
          userId: user.participant_id,
          note: values.note,
          referralId: referralIdStamp,
          referralEntityId: referralEntityId,
          notePayload: getNotePayload(user),
          ReferralStatusOld: referralStatus,
          ReferralStatusNew: values.status,
        }
        const referralNoteResult = await refApiRefEntityNotePut(
          user.token,
          values.id,
          payload
        )

        if (!referralNoteResult) {
          LogError("Unable to submit status update note", referral_update)
          user.setNotificationError("Unable to submit status update note")
        }

        statusFieldHelper.setValue(values.status)
        onStatusChanged(values.status)
      } else {
        LogError("Unable to submit status update", referral_update)
        user.setNotificationError("Unable to submit status update")
        return false
      }
    } catch (error) {
      LogError("Unable to submit status update", error)
      user.setNotificationError("Unable to submit status update")
      return false
    }
  }

  return (
    <Dialog
      header={"Referral status update"}
      visible={showForm}
      onHide={() => {
        return onHideForm()
      }}
      style={{ width: "30vw", height: "465px" }}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          status: referralStatus,
          note: "",
        }}
        enableReinitialize
        onSubmit={async (values) => {
          return await handleSubmit(values)
        }}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty, values, errors }) => (
          <Form onSubmit={handleSubmit}>
            <FormikDropDown
              name="status"
              label="Referral status"
              helpText={"Please select a new status"}
              placeholderText="Partner status"
              options={referralStatuses}
              required={true}
            />
            <div className="p-fluid" style={{ height: "200px" }}>
              <FormikTextAreaInput
                label={"Status change note"}
                helpText={"Please add a note for this status update. "}
                name="note"
                placeholderText="Note"
                required={true}
                rows={4}
              />
            </div>
            <div style={{ float: "right" }}>
              <Button
                className="btn"
                label="Submit"
                type="submit"
                disabled={isSubmitting || !dirty}
              />
              <Button
                className="btn"
                label="Cancel"
                style={{ marginLeft: "10px" }}
                type="button"
                onClick={() => {
                  onHideForm()
                }}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

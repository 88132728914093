import { useContext } from "react"
import _ from "lodash"

// Context
import { FormContext } from "../../context/formContext"

import styles from "../../styles/Form-Btn-Submit.module.css"

export default function FormSubmitBtn() {
  const form = useContext(FormContext)
  const handleEnableSubmit = () => {
    if (form.editMode) {
      return _.every(form.blocksSaved)
    }

    const requiredBlockSavedList = _.map(
      form.blocksRequired,
      (block) => form.blocksSaved[block]
    )
    return _.every(requiredBlockSavedList)
  }

  const enableSubmit = handleEnableSubmit()

  return (
    <button
      className={styles.submit}
      disabled={!enableSubmit}
      onClick={() => form.handleSubmit()}
    >
      Ready to Submit
    </button>
  )
}

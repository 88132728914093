//styles
import dpStyles from "../../styles/dailyPlanner/DailyPlanner.module.css"

//Primereact
import { ProgressSpinner } from "primereact/progressspinner"
import { DataScroller } from "primereact/datascroller"
import { Tooltip } from "primereact/tooltip"

export function GetCounterPanel(
  name,
  backgroundColor,
  value,
  onActionClick,
  actionToolTip
) {
  return (
    <div className={dpStyles.counterPanel}>
      <Tooltip target={".tt-counter"} />
      <div
        className={dpStyles.counterPanelIcon}
        style={{ background: backgroundColor }}
      >
        <span className="material-icons">{"edit_document"}</span>
      </div>
      <div className={dpStyles.counterPanelName}>{name}</div>
      <div className={dpStyles.counterPanelValue}>
        {!!value || value === 0 ? (
          value
        ) : (
          <ProgressSpinner
            style={{ width: "50px", height: "50px", right: "20px" }}
          />
        )}
      </div>
      <div>
        {!!onActionClick ? (
          <button
            className={`btn ${dpStyles.counterButton} tt-counter`}
            onClick={() => {
              onActionClick()
            }}
            data-pr-tooltip={!!actionToolTip ? actionToolTip : name}
          >
            <span className="material-icons">{"chevron_right"}</span>
          </button>
        ) : null}
      </div>
    </div>
  )
}

export function GetSummaryPanel(
  name,
  backgroundColor,
  value,
  isLoading,
  templateFunction,
  emptyMessage,
  buttonFunction,
  buttonToolTip
) {
  return (
    <div className={dpStyles.summaryPanel}>
      <Tooltip target={".tt-summary"} />
      <div className={dpStyles.summaryDetail}>
        {!!value && !isLoading ? (
          <DataScroller
            value={value}
            itemTemplate={templateFunction}
            rows={5}
            inline
            scrollHeight="195px"
            emptyMessage={emptyMessage}
          />
        ) : (
          <ProgressSpinner
            style={{
              width: "100px",
              height: "100px",
              left: "35%",
              top: "25%",
            }}
          />
        )}
      </div>
      <div
        className={dpStyles.summaryHeader}
        style={{ background: backgroundColor }}
      >
        {name}
        {!!buttonFunction ? (
          <button
            className={`btn ${dpStyles.summaryHeaderButton} tt-summary`}
            onClick={() => {
              buttonFunction()
            }}
            data-pr-tooltip={!!buttonToolTip ? buttonToolTip : name}
          >
            <span className="material-icons">{"chevron_right"}</span>
          </button>
        ) : null}
      </div>
    </div>
  )
}

export function GetDetailPanel(
  name,
  backgroundColor,
  value,
  isLoading,
  templateFunction,
  emptyMessage,
  buttonFunction,
  buttonToolTip
) {
  return (
    <div className={dpStyles.todayPanel}>
      <div className={dpStyles.todayDetail}>
        {!isLoading ? (
          <DataScroller
            value={value}
            itemTemplate={templateFunction}
            rows={5}
            inline
            scrollHeight="375px"
            emptyMessage={emptyMessage}
          />
        ) : (
          <ProgressSpinner
            style={{
              width: "150px",
              height: "150px",
              left: "35%",
              top: "30%",
            }}
          />
        )}
      </div>
      <div
        className={dpStyles.todayHeader}
        style={{ background: backgroundColor }}
      >
        {name}
        {!!buttonFunction ? (
          <button
            className={`btn ${dpStyles.detailHeaderButton}`}
            onClick={() => {
              buttonFunction()
            }}
            data-pr-tooltip={!!buttonToolTip ? buttonToolTip : name}
          >
            <span className="material-icons">{"chevron_right"}</span>
          </button>
        ) : null}
      </div>
    </div>
  )
}

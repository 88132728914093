import { useEffect, useState } from "react"

//Styles
import docSignStyles from "../../../styles/documentSigning/DocSign.filters.module.css"
import docSignReqStyles from "../../../styles/documentSigning/DocSign.requests.filter.module.css"

//primeReact
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"
import { Tooltip } from "primereact/tooltip"

const RequestsFilters = ({
  filters,
  sortOptions,
  onChangeFilters,
  onChangeSortField,
  organizations,
  isLoading,
}) => {
  const sortDirections = [
    { value: "Descending", icon: "arrow_downward" },
    { value: "Ascending", icon: "arrow_upward" },
  ]

  const [dateFilter, setDateFilter] = useState(null)
  const [searchPhrase, setSearchPhrase] = useState("")
  const [statusFilter, setStatusFilter] = useState("all")
  const [organizationFilter, setOrganizationFilter] = useState()
  const [sortField, setSortField] = useState(sortOptions[0])
  const [sortDirection, setSortDirection] = useState(sortDirections[0])

  const statusOptions = [
    { name: "All", id: "all" },
    { name: "Awaiting Signature", id: "awaiting_signature" },
    { name: "Signed", id: "signed" },
    { name: "Declined", id: "declined" },
  ]

  useEffect(() => {
    if (filters.sort !== sortField) {
      setSortField(filters.sort)
    }

    if (filters.sortDirection !== sortDirection.value) {
      if (sortDirection.value === sortDirections[0].value) {
        setSortDirection(sortDirections[1])
      } else {
        setSortDirection(sortDirections[0])
      }
    }

    //Note: Disable exhaustive deps due to only wanting to fire this event when filters change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (filters.status !== statusFilter) {
      setStatusFilter(filters.status)
    }

    if (filters.organizations !== organizationFilter) {
      setOrganizationFilter(filters.organizations)
    }

    if (filters.dateCreated !== dateFilter) {
      setDateFilter(filters.dateCreated)
    }

    if (filters.search !== searchPhrase) {
      setSearchPhrase(!filters.search ? "" : filters.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <div className={docSignReqStyles.filterGridContainer}>
      <Tooltip target={".tt-ds-requests-filter"} />
      <div className={docSignReqStyles.filterItemDate}>
        <div className={docSignReqStyles.label}>Filter by Date Requested: </div>
        <div className={docSignReqStyles.dateInput}>
          <Calendar
            className={docSignReqStyles.calendar}
            value={dateFilter}
            onChange={(e) => setDateFilter(e.value)}
            selectionMode="range"
            readOnlyInput
            placeholder="Click to select dates"
            disabled={isLoading}
          />
        </div>
      </div>
      <div className={docSignReqStyles.filterItemSearch}>
        <div className={docSignReqStyles.label}>Search:</div>
        <div>
          <InputText
            value={searchPhrase}
            onChange={(e) => {
              setSearchPhrase(e.target.value)
            }}
            className={docSignReqStyles.searchText}
            placeholder="Type to search"
            disabled={isLoading}
          />
        </div>
        <div className={docSignReqStyles.filterHelpText}>
          Search on: Title, Document(s) or Metadata
        </div>
      </div>
      <div className={docSignReqStyles.filterItemSort}>
        <div className={docSignReqStyles.label}>Sort documents:</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Dropdown
            value={sortField}
            options={sortOptions}
            optionLabel="display"
            onChange={(e) => {
              const newSort = {
                sort: e.value,
                sortDirection: sortDirection.value,
              }
              setSortField(e.value)
              onChangeSortField(newSort)
            }}
            style={{ width: "100%", margin: "0px" }}
          />
          <button
            className={`btn ${docSignStyles.sortDirection} tt-ds-requests-filter`}
            onClick={() => {
              const newDirection =
                sortDirection.value === sortDirections[0].value
                  ? sortDirections[1]
                  : sortDirections[0]
              const newSort = {
                sort: sortField,
                sortDirection: newDirection.value,
              }

              setSortDirection(newDirection)
              onChangeSortField(newSort)
            }}
            data-pr-tooltip={`${sortDirection.value}, click to change to ${
              sortDirection.value === sortDirections[0].value
                ? sortDirections[1].value
                : sortDirections[0].value
            }`}
            data-pr-position="bottom"
          >
            <span className="material-icons ">{sortDirection.icon}</span>
          </button>
        </div>
      </div>

      <div className={docSignReqStyles.filterItemStatus}>
        <div className={docSignReqStyles.label}>Filter by Status:</div>
        <div>
          <Dropdown
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.value)
            }}
            className={docSignReqStyles.status}
            options={statusOptions}
            optionLabel="name"
            optionValue="id"
            disabled={isLoading}
          />
        </div>
      </div>
      <div className={docSignReqStyles.filterItemOrganizations}>
        <div className={docSignReqStyles.label}>Filter by Organization:</div>
        <div>
          <MultiSelect
            value={organizationFilter}
            placeholder="All Organizations"
            onChange={(e) => {
              setOrganizationFilter(e.value)
            }}
            className={docSignReqStyles.status}
            options={organizations}
            optionLabel="organization_name"
            filter
            disabled={isLoading}
            maxSelectedLabels={3}
          />
        </div>
      </div>

      <div className={docSignReqStyles.filterItemButtons}>
        <button
          className={`btn`}
          type="button"
          onClick={() => {
            onChangeFilters({
              dateCreated: dateFilter,
              search: searchPhrase,
              organizations: organizationFilter,
              status: statusFilter,
              sort: sortField,
              sortDirection: sortDirection,
            })
          }}
          disabled={isLoading}
        >
          Apply
        </button>
        <button
          className={`btn ${docSignStyles.filterButton}`}
          type="button"
          onClick={() => {
            setSearchPhrase("")
            setDateFilter(null)
            setSortField(sortOptions[2])
            setSortDirection(sortDirections[0])
            onChangeFilters({
              dateCreated: [],
              search: "",
              organizations: organizationFilter,
              status: "all",
              sort: sortOptions[3],
              sortDirection: sortDirections[0],
            })
            setStatusFilter("")
          }}
          disabled={isLoading}
        >
          Reset
        </button>
      </div>
    </div>
  )
}
export default RequestsFilters

import React from "react"
import { LoaderMedium } from "../../components/Loaders"

export default function FormSubmitting() {
  return (
    <div>
      <h1>Submitting, please wait...</h1>
      <p>Don't refresh the browser</p>
      <LoaderMedium />
    </div>
  )
}

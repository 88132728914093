import { useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"

// Context
import { UserContext } from "../../context/userContext"

//Appointments modules
import styles from "../../styles/appointments/AppointmentsCron.module.css"

//PrimeReact
import { Dropdown } from "primereact/dropdown"
import { RadioButton } from "primereact/radiobutton"
import { Checkbox } from "primereact/checkbox"
import { LogError } from "../../helpers/logger"

export default function AppointmentsFormCron({
  cronValues,
  startTime,
  startTimeTimezoneCode,
  onChangeCronValues,
}) {
  const userCtx = useContext(UserContext)

  const [selectedPeriod, setSelectedPeriod] = useState("Daily")
  const [isLoading, setIsLoading] = useState(true)

  //Daily options
  const [dailyType, setDailyType] = useState("nDays")
  const [dailyNDays, setDailyNDays] = useState(1)

  //Day of week selections
  const emptyWeekDayOptions = {
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false,
  }

  const weekDays = [
    { name: "Monday", value: "MON" },
    { name: "Tuesday", value: "TUE" },
    { name: "Wednesday", value: "WED" },
    { name: "Thursday", value: "THU" },
    { name: "Friday", value: "FRI" },
    { name: "Saturday", value: "SAT" },
    { name: "Sunday", value: "SUN" },
  ]

  const [weekDaySelection, setWeekDaySelection] = useState(emptyWeekDayOptions)

  //Monthly options
  const [monthlyType, setMonthlyType] = useState("nDay_nMonth")
  const [monthlyNDays, setMonthlyNDays] = useState(1)
  const [monthlyNMonths, setMonthlyNMonths] = useState(1)
  const [monthlyNthDay, setMonthlyNthDay] = useState(1)
  const [monthlyWeekDay, setMonthlyWeekDay] = useState("MON")

  useEffect(() => {
    //If this is not a generated cronValues, setup the form.
    if (cronValues && !cronValues.generated) {
      try {
        const cronDescription = cronValues.cronDescription.split(" | ")

        if (cronDescription.length > 1) {
          const cronPeriod = cronDescription[1]
          const cronCode = cronValues.cronValue.split(" ")
          setSelectedPeriod(cronPeriod)

          switch (cronPeriod) {
            case "Daily":
              if (cronCode[4] === "MON-FRI") {
                setDailyType("weekDays")
              } else {
                setDailyType("nDays")
                if (cronCode[2] === "*") {
                  setDailyNDays(1)
                } else {
                  const dailyDays = cronCode[2].replace("*/", "")
                  setDailyNDays(+dailyDays)
                }
              }
              break
            case "Weekly":
            case "Biweekly":
              if (cronCode[4] !== "*") {
                const days = cronCode[4].split(",")
                const newWeekDays = { ...emptyWeekDayOptions }
                days.forEach((day) => {
                  newWeekDays[day] = true
                })

                setWeekDaySelection(newWeekDays)
              }

              break
            case "Monthly":
              if (cronCode[4].includes("#")) {
                setMonthlyType("weekDays")

                const monthWeekDay = cronCode[4].split("#")
                setMonthlyNthDay(+monthWeekDay[1])
                setMonthlyWeekDay(monthWeekDay[0])
              } else {
                setMonthlyType("nDay_nMonth")
                setMonthlyNDays(+cronCode[2])
              }

              if (cronCode[3] === "*") {
                setMonthlyNMonths(1)
              } else {
                const monthlyDays = cronCode[3].replace("1/", "")
                setMonthlyNMonths(+monthlyDays)
              }

              break
            default:
              LogError(`Unknown cron period: ${cronPeriod}`)
              break
          }
        }
      } catch (error) {
        LogError("Unable to decypher CRON", error)
        userCtx.setNotificationError(
          `Unable to decypher schedule: ${cronValues?.cronDescription}`
        )
      }
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronValues])

  useEffect(() => {
    if (!isLoading) {
      let startTimeValue = undefined

      if (startTime) {
        startTimeValue = DateTime.fromJSDate(startTime)
      }

      if (startTimeValue && startTimeTimezoneCode) {
        startTimeValue = startTimeValue.setZone(startTimeTimezoneCode)
      }

      let dow = "?"
      let month = "*"
      let dom = "*"
      let time = startTimeValue?.toFormat("HH:mm")?.split(":")
      let min = time ? time[1] : ""
      let hour = time ? time[0] : ""

      switch (selectedPeriod) {
        case "Daily":
          switch (dailyType) {
            case "nDays":
              if (dailyNDays > 1) dom = "*/" + dailyNDays
              break
            case "weekDays":
              dom = "?"
              dow = "MON-FRI"
              break
            default:
              LogError(`Unknown daily period found: ${dailyType}`)
              break
          }
          break
        case "Weekly": {
          //Set default dayOfWeek and dayOfMonth settings
          dow = "*"
          dom = "?"

          let selectedDayOfWeek = []

          //Retrieve a list of selected week days
          Object.keys(weekDaySelection).forEach((key) => {
            if (weekDaySelection[key]) {
              selectedDayOfWeek.push(key)
            }
          })

          if (selectedDayOfWeek.length < 7 && selectedDayOfWeek.length > 0) {
            dow = selectedDayOfWeek.join(",")
          }

          break
        }
        case "Biweekly": {
          //Set default dayOfWeek and dayOfMonth settings
          dow = "*"
          dom = "?"

          let selectedDayOfBiWeek = []

          //Retrieve a list of selected week days
          Object.keys(weekDaySelection).forEach((key) => {
            if (weekDaySelection[key]) {
              selectedDayOfBiWeek.push(key)
            }
          })

          if (
            selectedDayOfBiWeek.length < 7 &&
            selectedDayOfBiWeek.length > 0
          ) {
            dow = selectedDayOfBiWeek.join(",") + " [BI]"
          }

          break
        }
        case "Monthly": {
          let nmonth
          if (monthlyType === "nDay_nMonth") {
            //month = "*" -- This is the default and expected value of month
            nmonth = monthlyNMonths
            dom = monthlyNDays
            //dow = "?" --This is the default and expected value of dow
          } else {
            dow = monthlyWeekDay + "#" + monthlyNthDay
            nmonth = monthlyNMonths
            dom = "?"
          }
          if (nmonth > 1) month = "1/" + nmonth
          break
        }
        default:
          break
      }

      const cronValue = [min, hour, dom, month, dow].join(" ").replace("?", "*")

      onChangeCronValues({
        cronValue: cronValue,
        cronDescription: undefined,
        period: selectedPeriod,
        generated: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTime,
    selectedPeriod,
    dailyType,
    dailyNDays,
    weekDaySelection,
    monthlyType,
    monthlyNDays,
    monthlyNMonths,
    monthlyNthDay,
    monthlyWeekDay,
  ])

  const getNumberRange = (startValue, endValue) => {
    return [...Array(endValue - startValue + 1)].map((_, i) => i + 1)
  }

  const MonthlyOptions = () => {
    const nthDayOptions = [
      { name: "First", value: 1 },
      { name: "Second", value: 2 },
      { name: "Third", value: 3 },
      { name: "Forth", value: 4 },
    ]

    const checkMonthlyType = (type) => {
      if (monthlyType !== type) {
        setMonthlyType(type)
      }
    }

    return (
      <>
        <div>Monthly options: </div>
        <div className={styles.cronOption}>
          <RadioButton
            value={"nDay_nMonth"}
            onChange={(e) => {
              setMonthlyType(e.value)
            }}
            checked={monthlyType === "nDay_nMonth"}
            className={styles.cronOptionRadioButton}
          />
          <span className={styles.cronOptionPortion}>Day </span>
          <Dropdown
            options={getNumberRange(1, 31)}
            value={monthlyNDays}
            onChange={(e) => {
              setMonthlyNDays(e.value)
              checkMonthlyType("nDay_nMonth")
            }}
            className={`${styles.cronOptionPortion} ${styles.numberOptionDropDown}`}
          />

          <span className={styles.cronOptionPortion}> of every </span>
          <Dropdown
            options={getNumberRange(1, 12)}
            value={monthlyNMonths}
            onChange={(e) => {
              setMonthlyNMonths(e.value)
              checkMonthlyType("nDay_nMonth")
            }}
            className={`${styles.cronOptionPortion} ${styles.numberOptionDropDown}`}
          />
          <span className={styles.cronOptionPortion}> month(s)</span>
        </div>
        <div className={styles.cronOption}>
          <RadioButton
            value={"weekDays"}
            onChange={(e) => {
              setMonthlyType(e.value)
            }}
            checked={monthlyType === "weekDays"}
            className={styles.cronOptionRadioButton}
          />
          <span className={styles.cronOptionPortion}>The </span>
          <Dropdown
            options={nthDayOptions}
            optionLabel="name"
            value={monthlyNthDay}
            onChange={(e) => {
              setMonthlyNthDay(e.value)
              checkMonthlyType("weekDays")
            }}
            className={`${styles.cronOptionPortion} ${styles.nDayOfWeekOptionDropDown}`}
          />
          <Dropdown
            options={weekDays}
            optionLabel="name"
            value={monthlyWeekDay}
            onChange={(e) => {
              setMonthlyWeekDay(e.value)
              checkMonthlyType("weekDays")
            }}
            className={`${styles.cronOptionPortion} ${styles.dayOfWeekOptionDropDown}`}
          />
          <span className={styles.cronOptionPortion}> of every </span>{" "}
          <Dropdown
            options={getNumberRange(1, 12)}
            value={monthlyNMonths}
            onChange={(e) => {
              setMonthlyNMonths(e.value)
              checkMonthlyType("weekDays")
            }}
            className={`${styles.cronOptionPortion} ${styles.numberOptionDropDown}`}
          />
          <span className={styles.cronOptionPortion}> month(s)</span>
        </div>
      </>
    )
  }

  const WeeklyOptions = () => {
    return (
      <div>
        <div>Weekly options: </div>
        <div className={styles.weeklyContainer}>
          {weekDays.map((day, index) => (
            <div
              className={`${styles.cronOption} ${styles.weeklyOption}`}
              key={index}
            >
              <Checkbox
                checked={weekDaySelection[day.value]}
                onChange={(e) => {
                  const newWeekDays = { ...weekDaySelection }
                  newWeekDays[day.value] = e.checked
                  setWeekDaySelection(newWeekDays)
                }}
                className={styles.cronOptionPortion}
              />
              <span className={styles.cronOptionPortion}>{day.name}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const DailyOptions = () => {
    return (
      <>
        <div>Daily options: </div>
        <div className={styles.cronOption}>
          <RadioButton
            value={"nDays"}
            onChange={(e) => {
              setDailyType(e.value)
            }}
            checked={dailyType === "nDays"}
            className={styles.cronOptionRadioButton}
          />
          <span className={styles.cronOptionPortion}>Every </span>
          <Dropdown
            options={getNumberRange(1, 7)}
            value={dailyNDays}
            onChange={(e) => {
              setDailyNDays(e.value)
              if (dailyType !== "nDays") {
                setDailyType("nDays")
              }
            }}
            className={styles.cronOptionPortion}
          />
          <span> day(s)</span>
        </div>
        <div className={styles.cronOption}>
          <RadioButton
            value={"weekDays"}
            onChange={(e) => {
              setDailyType(e.value)
            }}
            checked={dailyType === "weekDays"}
            className={styles.cronOptionRadioButton}
          />
          <span>Every week day</span>
        </div>
      </>
    )
  }

  return (
    <div>
      <div>Select period: </div>
      <div>
        <Dropdown
          options={["Daily", "Weekly", "Biweekly", "Monthly"]}
          value={selectedPeriod}
          onChange={(e) => {
            setSelectedPeriod(e.value)
          }}
          className={styles.periodTypeDropDown}
        />
        {selectedPeriod === "Daily" ? <DailyOptions /> : null}
        {selectedPeriod === "Weekly" || selectedPeriod === "Biweekly" ? (
          <WeeklyOptions />
        ) : null}
        {selectedPeriod === "Monthly" ? <MonthlyOptions /> : null}
      </div>
    </div>
  )
}

import { gql } from "@apollo/client"

export const GET_PARTICIPANT = gql`
  query ParticipantProfile($participant_id: ID!, $organization_id: ID!) {
    participant(
      participant_id: $participant_id
      organization_id: $organization_id
    ) {
      participant_id
      participant_alias
      participant_name_first
      participant_name_last
      participant_name_preferred
      participant_phone_primary
      participant_email_account
      participant_city_primary
      participant_state_primary
      participant_zip_code_primary
      organization_id
      organization_alias
      program_list
      participant_membership_status
    }
  }
`
export const GET_SERVICES = gql`
  query getTemplatesByOrganization(
    $organization_id: ID!
    $template_type: String
  ) {
    getTemplatesByOrganization(
      organization_id: $organization_id
      template_type: $template_type
    ) {
      template_title
      template_name
      template_collection
      template_type
      template_availability
    }
  }
`

export const GET_USER_BY_ALIAS = gql`
  query GetUser(
    $uid: ID!
    $organization_id: ID!
    $staff_alias: ID!
    $organization_alias: ID!
  ) {
    userByAlias(
      staff_id: $uid
      organization_id: $organization_id
      staff_alias: $staff_alias
      organization_alias: $organization_alias
    ) {
      staff_id
      staff_name_first
      staff_name_last
      organization_id
      organization_alias
    }
  }
`

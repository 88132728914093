import { docSigningApiSignatureRequestsListPost } from "../../pages/Documents/documentsApi"

export async function getDocuments(user, organizations, onSetDocumentsCounter) {
  let organizationIds = [
    ...organizations.map((organization) => organization.organization_id),
    ...organizations
      .filter((organization) => organization.organization_alias > 0)
      .map((organization) => organization.organization_alias),
  ]

  const listingBody = {
    groupIds: organizationIds,
    pageNum: 1,
    pageSIze: 20,
    statusFilter: "awaiting_signature",
    recipientIds: [`${user.participant_id}`],
  }

  if (user.participant_alias > 0) {
    listingBody.recipientIds.push(user.participant_alias)
  }

  const result = await docSigningApiSignatureRequestsListPost(
    user.tokenDocuments,
    listingBody
  )

  onSetDocumentsCounter(result.numResults)
}

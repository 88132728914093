import { useEffect } from "react"
import { useFormikContext } from "formik"

import { useContext } from "react"
import { UserContext } from "../../../context/userContext"

import styles from "../../../styles/Form.module.css"

export default function ParticipantUserChanged({ field, index }) {
  const formik = useFormikContext()
  const user = useContext(UserContext)

  useEffect(() => {
    return formik.setFieldValue(field.field_name, "not-set")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.hidden} style={{ order: index }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

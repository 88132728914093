import React, { useContext } from "react"
import moment from "moment"
import trcss from "../../../styles/Telerecovery.module.css"
import { Tooltip } from "primereact/tooltip"
import { UserContext } from "../../../context/userContext"
import { staffNameInitials, transformMessage } from "../Utils"

export default function ChatMessage({ messages, fromName, setJoinLink, setShowExternalLinkPopup }) {
  const userCtx = useContext(UserContext)

  const buildParticipantMessage = () => {
    return (
      <div style={{ marginBottom: "15px" }}>
        <div className={trcss.messageData}
          style={{
            justifyContent: "flex-end",
            marginBottom: "5px"
          }}
        >
          <span className={trcss.messageDataTime}>
            {moment(messages[0].dateUpdated).fromNow()}
          </span>
        </div>
        <div style={{ float: "right" }} className={trcss.chatMessageBody}>
          {
            messages.map(message => showMsgBody(message, trcss.myMessage))
          }
        </div>
      </div>
    )
  }

  const buildStaffMessage = () => {
    return (
      <div style={{ marginBottom: "15px" }}>
        <div className={trcss.messageData}>
          <span className={trcss.userInitials}>{staffNameInitials(fromName)}</span>
          <div style={{ alignSelf: "center" }}>
            <span>{fromName.full_name}</span>
            <span className={trcss.messageDataTime}>{moment(messages[0].dateUpdated).fromNow()}</span>
          </div>
        </div>
        <div style={{ marginLeft: "42px", float: "left" }} className={trcss.chatMessageBody}>
          {
            messages.map(message => showMsgBody(message, trcss.staffMessage))
          }
        </div>
      </div>
    )
  }

  const transformChatMessage = (message) => {
    //if mge contains an external_link need to show popup message
    if (message.body.includes("<external_link")) {
      const [text, link] = message.body.split(/<external_link>|<\/external_link>/)
      setJoinLink(link)
      return (
        <div>
          {text}
          <p className={trcss.link} onClick={() => setShowExternalLinkPopup(true)}>
            {link}
          </p>
        </div>
      )
    }
    //if mge contains a twilio or gcp bucket link means it's an image and should show it as image
    return transformMessage(message)
  }

  const showMsgBody = (message, classN) => {
    const dateClass = moment(message.dateUpdated).format(
      "MMMMDoYYYYhmmss"
    )
    return (
      <div>
        <div
          className={classN + " " + dateClass}
        >
          {transformChatMessage(message)}
        </div>
        <Tooltip target={"." + dateClass} className="custom-tooltip">
          <span>
            {moment(message.dateUpdated).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <>
      {messages[0].fromId === userCtx.participant_id
        ? buildParticipantMessage() : buildStaffMessage()}
    </>
  )
} 
import { gql } from "@apollo/client"

export const GET_SAVED_BLOCKS = gql`
  query RecoveryPlanPage($participant_id: ID!) {
    blockSaved(
      participant_id: $participant_id
      block_name: "session__recovery_plan"
    ) {
      data
    }
  }
`

export const GET_BLOCKS = gql`
  query ParticipantBlock($participant_id: ID!, $blocks: [String]!) {
    getRichBlocksByParticipantId(
      participant_id: $participant_id
      blocks: $blocks
    ) {
      data
    }
  }
`

export const GET_FULL_BLOCKS = gql`
  query ParticipantBlock($participant_id: ID!, $idTemplate: ID!) {
    getAllRichBlocksByParticipantId(
      participant_id: $participant_id
      idTemplate: $idTemplate
      groupByIdStamp: true
    ) {
      data
    }
  }
`
export const GET_FULL_BLOCKS_CAPITAL = gql`
  query EngagementsParticipant($participant_id: ID!, $blocks: [String]) {
    engagementsParticipant: getBlocksByParticipantId(
      participant_id: $participant_id
      blocks: $blocks
    ) {
      data
    }
  }
`

export const GET_BLOCK_ASSESSMENT_RECOVERY_CAPITAL = gql`
  query RecoveryCapital($staff_id: String!, $participant_membership_id: ID) {
    recoveryCapital: getSpecificBlocksByMembership(
      participant_membership_id: $participant_membership_id
      blocks: ["session__assessment_recovery_capital"]
    ) {
      data
    }
    templateNames: templates(type: "system", staff_id: $staff_id) {
      template_name
      template_title
    }
  }
`

export const ENGAGEMENTS_PARTICIPANT_GROUP_BY_TEMPLATE = gql`
  query EngagementsParticipant(
    $participant_id: ID!
    $organization_id: ID!
    $idTemplates: [ID]
    $idBlocks: [ID]
    $participant_membership_id: ID
  ) {
    engagementsParticipant: getAllParticipantBlocksGroupByIdTemplate(
      participant_id: $participant_id
      organization_id: $organization_id
      idTemplates: $idTemplates
      idBlocks: $idBlocks
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const ENGAGEMENTS_PARTICIPANT_GROUP_BY_CAPITAL_TEMPLATE = gql`
  query RecoveryCapital($participant_id: ID) {
    recoveryCapitalParticipant: getSpecificBlocksByParticipantID(
      participant_id: $participant_id
      blocks: ["session", "session__assessment_recovery_capital"]
    ) {
      data
    }
    templateNames: templates(type: "system") {
      template_name
      template_title
    }
  }
`

//PrimeReact
import { Checkbox } from "primereact/checkbox"
import { InputText } from "primereact/inputtext"

//styling
import myStyles from "../../../styles/assessments/AssessmentForm.module.css"

export default function AssessmentFormOutcomeEntry({
  outcome,
  selectedOutcomes,
  onUpdateOutcome,
  disabled,
  readOnlyView,
}) {
  const selectedOutcome = selectedOutcomes?.filter(
    (selectedOutcome) => selectedOutcome.suggestedOutcomeId === outcome.id
  )

  let isChecked = false
  let isOther = !outcome.id
  let otherText = ""

  if (selectedOutcome.length > 0) {
    isChecked = selectedOutcome[0].selected
    otherText = selectedOutcome[0].otherFinalOutcome
  }

  if (readOnlyView) {
    let textValue = outcome.text

    if (isOther) {
      textValue = otherText || "Other outcome selected, no text found"
    }

    return (
      <div
        className={`${myStyles.ReadOnlyTextContainer} ${myStyles.readOnlyOutcome}`}
      >
        {textValue}
      </div>
    )
  } else {
    return (
      <div className={myStyles.outcomeOptionLayout}>
        <div className={myStyles.outcomeOptionLayoutCheckbox}>
          <Checkbox
            checked={isChecked}
            onChange={(e) => onUpdateOutcome(outcome.id, e.checked, otherText)}
            disabled={disabled}
          />
        </div>
        <div className={myStyles.outcomeOptionLayoutTitle}>
          {isOther ? (
            <InputText
              value={otherText}
              placeholder={"Other: specify other outcome"}
              onChange={(e) => {
                onUpdateOutcome(outcome.id, isChecked, e.target.value)
              }}
              disabled={!isChecked || disabled || readOnlyView}
              className={`${myStyles.outcomeInput} ${
                !outcome.id && isChecked && !otherText ? "p-invalid block" : ""
              }`}
            />
          ) : (
            outcome.text
          )}
        </div>
      </div>
    )
  }
}

import { gql } from "@apollo/client"

export const GET_PARTICIPANT = gql`
  query ParticipantProfile($participant_id: ID!) {
    participant: participantSelfView(participant_id: $participant_id) {
      participant_id
      participant_alias
      participant_name_first
      participant_name_last
      participant_name_preferred
      participant_phone_primary
      participant_email_account
      participant_city_primary
      participant_state_primary
      participant_zip_code_primary
      participant_external_id
      organization_id
      program_list
      participant_membership_status
      participant_profile_image
    }
    participantProfile(participant_id: $participant_id, withConsent: true) {
      blocks {
        block_title
        block_name
        total_fields
        index
      }
    }
  }
`

export const PARTICIPANT_BLOCK = gql`
  query ParticipantBlock($participant_id: ID!, $idBlock: ID!) {
    block(idBlock: $idBlock) {
      fields {
        idField
          field_type
          field_description
          field_conditional
          field_label
          field_default_value
          field_name
          field_visibility
          read_only
          field_options {
            ...fieldOptions
          }
          field_validation {
            ...fieldValidation
          }
          multi_fields {
            ...multiField
            multi_fields {
              ...multiField
            }
          }
      }
    }
    blockSaved(participant_id: $participant_id, block_name: $idBlock) {
      data
    }
  }

  fragment fieldOptions on TypeFieldOptions {
    label
    value
  }

  fragment fieldValidation on TypeFieldValidation {
    required
    min
    max
    maxlength
    file_extension
    file_size
    file_multi_files
    file_firestore_storage
    file_cloud_storage
    file_is_shared
    file_is_renameable
    __typename
  }

  fragment multiField on TypeMultiField {
    field_name
    field_type
    field_label
    field_options {
      ...fieldOptions
    }
    field_validation {
      ...fieldValidation
    }
  }
`

export const PARTICIPANT_ACCOUNT_INFORMATION_BY_PARTICIPANT = gql`
  query EngagedServices($participant_id: ID!) {
    participantProfile: getSpecificBlockByParticipant(
      participant_id: $participant_id
      idBlock: "participant"
    ) {
      blocks {
        block_title
        block_name
        total_fields
        index
      }
    }
  }
`

import { useEffect, useContext } from "react"
import _ from "lodash"

import { Field, useFormikContext } from "formik"

import { Checkbox } from "primereact/checkbox"
import { FormContext } from "../../../context/formContext"

import styles from "../../../styles/Form.module.css"
import fieldVisibility from "../../../helpers/fieldVisibility"

export default function PasswordUpdate({ field, block, index }) {
  const isRequired = JSON.parse(field.field_validation.required[0])
  const formik = useFormikContext()
  const form = useContext(FormContext)

  let isDisabled = form ? form.blocksSaved[block] : false
  isDisabled = field.read_only ? true : isDisabled

  let visibility = fieldVisibility(field.field_visibility)
  useEffect(() => {
    formik.setFieldValue(field.field_name, "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //if (loading) return null
  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: visibility,
      }}
    >
      <label className={styles.label} style={{ marginBottom: 10 }}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      {formik.touched[field.field_name] && formik.errors[field.field_name] && (
        <div className={styles.errFieldMsg}>
          {formik.errors[field.field_name]}
        </div>
      )}
      {_.map(field.field_options, (option) => {
        return (
          <div
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
            onBlur={() => formik.setFieldTouched(field.field_name)}
          >
            <Field name={field.field_name}>
              {({ field: formikField }) => {
                return (
                  <>
                    <Checkbox
                      disabled={isDisabled}
                      inputId={`${field.field_name}_${option.value}`}
                      name={formikField.name}
                      id={field.field_name}
                      value={option.value}
                      onChange={formikField.onChange}
                      checked={_.includes(formikField.value, option.value)}
                    />
                    <label
                      htmlFor={`${field.field_name}_${option.value}`}
                      style={{ marginLeft: 5 }}
                    >
                      {option.label}
                    </label>
                  </>
                )
              }}
            </Field>
          </div>
        )
      })}
    </div>
  )
}

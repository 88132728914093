import _ from "lodash"
import { useEffect } from "react"

import { useFormikContext } from "formik"

import styles from "../../../styles/Form.module.css"

export default function AssessmentType({ field, block, index }) {
  const formik = useFormikContext()
  useEffect(() => {
    let assessmentType = ""
    if (block === "session__assessment_recovery_capital") {
      assessmentType = "self_assessment"
    }

    return formik.setFieldValue(field.field_name, assessmentType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])
  return (
    <div className={styles.hidden} style={{ order: index }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

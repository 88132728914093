import _ from "lodash"
import * as Types from "../../../components/FieldTypes"

export default function FieldBuilder({ fields, block }) {
  const handleFields = () => {
    return _.map(fields, (field, index) => {
      const newProps = {
        type: "regular-field",
        block,
        field,
        index,
        typeForm: "block",
      }

      const isConditional = _.startsWith(field.field_type, "conditional")

      if (field.field_name === "participant_account_password") {
        field.field_conditional =
          '{"participant__password_update": ["Select to update password"]}'
      }

      if (isConditional) {
        if (field.field_name !== "participant_email_account") {
          return <Types.TypeCondiontal {...newProps} key={field.field_name} />
        }
      }

      if (field.field_name === "participant_email_account") {
        field.field_type = "participant_email_account"
      }

      if (field.field_name === "consent_data_store") {
        return (
          <Types.TypeConsentDropdown {...newProps} key={field.field_name} />
        )
      }
      switch (field.field_type) {
        case "hidden":
          return <Types.TypeHidden {...newProps} key={field.field_name} />
        case "sys":
          return <Types.TypeSys {...newProps} key={field.field_name} />
        case "checkbox":
          return <Types.TypeCheckbox {...newProps} key={field.field_name} />
        case "date":
          return <Types.TypeDate {...newProps} key={field.field_name} />
        case "dropdown":
          return <Types.TypeDropdown {...newProps} key={field.field_name} />
        case "dropdown_us_states":
          return (
            <Types.TypeDropdownUsState {...newProps} key={field.field_name} />
          )
        case "email":
          return <Types.TypeEmail {...newProps} key={field.field_name} />
        case "monthpicker":
          return <Types.TypeMonthpicker {...newProps} key={field.field_name} />
        // case "multi_field":
        //   return <Types.TypeMultiField {...newProps} key={field.field_name} />
        case "text":
          return <Types.TypeText {...newProps} key={field.field_name} />
        case "textarea":
          return <Types.TypeTextarea {...newProps} key={field.field_name} />
        case "time":
          return <Types.TypeTime {...newProps} key={field.field_name} />
        case "number":
          return <Types.TypeNumber {...newProps} key={field.field_name} />
        case "password":
          return <Types.TypePassword {...newProps} key={field.field_name} />
        case "radio":
          return <Types.TypeRadio {...newProps} key={field.field_name} />
        case "phone":
          return <Types.TypeTel {...newProps} key={field.field_name} />
        case "url":
          return <Types.TypeUrl {...newProps} key={field.field_name} />
        case "zipcode":
          return <Types.TypeZipcode {...newProps} key={field.field_name} />
        case "file":
          return <Types.TypeFile {...newProps} key={field.field_name} />
        case "file_image":
          return <Types.TypeFileImage {...newProps} key={field.field_name} />
        case "participant_email_account":
          return (
            <Types.TypeParticipantEmail {...newProps} key={field.field_name} />
          )
        default:
          return null
      }
    })
  }
  return handleFields()
}

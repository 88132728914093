import { DateTime } from "luxon"

export function getLocalDateTimeFromIso(utcDateString, format) {
  if (utcDateString === "") {
    return ""
  }

  if (!format) {
    format = "MM/dd/yyyy HH:mm"
  }

  return DateTime.fromISO(utcDateString, { zone: "utc" })
    .toLocal()
    .toFormat(format)
}

export function getNotePayload(user) {
  return `{"displayName": "${user.participant_name_first} ${user.participant_name_last}", "email": "${user.participant_email_account}"}`
}

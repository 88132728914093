import { useContext } from "react"

//styling
import assessStyles from "../../../styles/assessments/Assessment.module.css"

//Assessment components
import AssessmentFormQuestionInput from "./AssessmentFormQuestionInput"

// Context
import { UserContext } from "../../../context/userContext"
import {
  validateMultiValueType,
  validateSingeValueType,
} from "./helpers/conditionalQuestionValidator"
import { LogError } from "../../../helpers/logger"

export default function AssessmentFormQuestion({
  question,
  answers,
  readOnly,
  displayAnswers,
  displayAnswerValues,
  displayScore,
}) {
  const userCtx = useContext(UserContext)

  const values = answers?.filter((answer) => answer.questionId === question.id)
  const answerIndex = values.length > 0 ? answers.indexOf(values[0]) : -1

  let displaySetting = true
  let requiredSetting = question.required

  if (question.conditionalSource) {
    let sourceAnswer = answers.find(
      (answer) => answer.questionId === question.conditionalSource
    )

    if (sourceAnswer) {
      let conditionResult = undefined

      if (sourceAnswer.type.toLowerCase() === "radio") {
        conditionResult = validateMultiValueType(
          question,
          sourceAnswer.multiValues
        )
      } else {
        conditionResult = validateSingeValueType(question, sourceAnswer.value)
      }

      if (!conditionResult) {
        LogError(
          "Unable to validate conditional setup, ignoring conditional setup",
          question
        )
        userCtx.setNotificationError(
          "Unable to validate conditional setup, ignoring conditional setup"
        )
        requiredSetting = true
      } else {
        requiredSetting = conditionResult.required
        displaySetting = conditionResult.display
      }
    } else {
      LogError(
        "Missing source question detected, ignoring conditional setup",
        question
      )
      userCtx.setNotificationError(
        "Missing source question detected, ignoring conditional setup"
      )
      requiredSetting = true
    }
  }

  const calculateScore = () => {
    let score = 0
    let showScore = false
    const myAnswers = answers.filter(
      (answer) => answer.questionId === question.id
    )

    myAnswers.forEach((answer) => {
      const answerType = answer.type.toLowerCase()

      if (!["textinput", "date"].includes(answerType)) {
        if (["dropdown", "radio"].includes(answerType)) {
          let answerOption = answer.multiValues.filter(
            (option) => option.optionId === answer.value
          )
          if (answerOption.length > 0) {
            score = score + (answerOption[0].scoreValue || 0)
          }
        } else if (answerType === "number" && answer.scoreFromAnswer) {
          score = score + (+answer.value || 0)
        } else if (answerType === "checkbox") {
          let answerOption = answer.multiValues.filter((option) => option.value)
          if (answerOption.length > 0) {
            score = score + (answerOption[0].scoreValue || 0)
          }
        }
        showScore = true
      }
    })

    return showScore ? (
      <>
        <span className={assessStyles.scoreLabel}>Score:</span>{" "}
        <span className={assessStyles.scoreValue}>{score}</span>
      </>
    ) : null
  }

  if (!displaySetting) {
    return null
  }

  return (
    <div
      className={
        displayScore
          ? assessStyles.questionLayoutWithScore
          : assessStyles.questionLayout
      }
    >
      <div className={assessStyles.questionLayoutName}>
        {question.text}
        {requiredSetting ? (
          <span className={assessStyles.required}>{" *"}</span>
        ) : null}
      </div>
      {displayScore ? (
        <div className={assessStyles.questionLayoutScore}>
          {calculateScore()}
        </div>
      ) : null}
      {displayAnswers !== false ? (
        <div
          className={`${assessStyles.questionLayoutBody} ${
            question.optionAlignment === "horizontal"
              ? assessStyles.horizontalQuestion
              : ""
          }`}
        >
          <AssessmentFormQuestionInput
            answerIndex={answerIndex}
            answers={answers}
            question={question}
            readOnly={readOnly}
            displayAnswerValues={displayAnswerValues}
          />
        </div>
      ) : null}
    </div>
  )
}

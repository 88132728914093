import { useContext, useState } from "react"

//Formik
import { Form, Formik, useField } from "formik"
import * as Yup from "yup"

// Context
import { UserContext } from "../../context/userContext"

//PrimeReact
import { Panel } from "primereact/panel"
import { Rating } from "primereact/rating"
import { Button } from "primereact/button"

//Styles
import styles from "../../styles/Form-Inputs.module.css"
import refStyles from "../../styles/Referrals.module.css"

//Referral components
import RefTextAreaInput from "./RefTextAreaInput"
import { RefApiRefRatingPost } from "./ReferralsAPI"
import { LogError } from "../../helpers/logger"

export default function ReferralRating({ rating, isLoading }) {
  const user = useContext(UserContext)

  const [thisRating, setThisRating] = useState(rating)

  if (!!thisRating && !thisRating.note) {
    thisRating.note = ""
  }

  const validationSchema = Yup.object().shape({
    score: Yup.number()
      .min(1, "Please select a satisfaction rating")
      .required("Please select a satisfaction rating"),
    note: Yup.string().required("Please enter a review note"),
  })

  function GetRating({ name, disabled }) {
    const [field, meta] = useField(name)

    let ratingDescription = ""

    switch (field.value) {
      case 1:
        ratingDescription = "Very dissatisfied"
        break
      case 2:
        ratingDescription = "Dissatisfied"
        break
      case 3:
        ratingDescription = "Neither satisfied nor dissatisfied"
        break
      case 4:
        ratingDescription = "Satisfied"
        break
      case 5:
        ratingDescription = "Very satisfied"
        break
      default:
        ratingDescription = ""
    }

    return (
      <div
        className={styles.field}
        style={{ height: "100%", marginTop: "10px" }}
      >
        <label>{`Satisfaction rating:  ${ratingDescription}`}</label>
        <div style={{ height: "100%", marginTop: "10px" }}>
          <Rating
            {...field}
            cancel={false}
            className={meta.touched && meta.error ? "p-invalid block" : "block"}
            disabled={disabled}
          ></Rating>
          {meta.touched && meta.error ? (
            <label id={`${name}-error`} className={styles.errFieldMsg}>
              {meta.error}
            </label>
          ) : null}
        </div>
      </div>
    )
  }

  if (!thisRating) {
    return null
  } else {
    return (
      <Panel
        header="Participant feedback"
        style={{ marginBottom: "10px", marginTop: "10px" }}
        className="p-fluid"
      >
        <Formik
          initialValues={thisRating}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values) => {
            try {
              const result = await RefApiRefRatingPost(
                user.token,
                rating.referralId,
                values
              )

              setThisRating(result)
            } catch (error) {
              LogError(`Unable to submit the referral rating: `, error)
              user.setNotificationError("Unable to submit the referral rating")
            }
          }}
        >
          {({ isValid, isSubmitting, dirty, values, errors }) => (
            <Form autoComplete="off">
              <GetRating name={"score"} disabled={values.id !== 0} />
              <div style={{ width: "100%" }}>
                <RefTextAreaInput
                  name="note"
                  label="Note"
                  style={{ width: "100%" }}
                  disabled={values.id !== 0}
                />
              </div>
              {values.id === 0 ? (
                <Button
                  label="Submit"
                  icon="pi pi-check"
                  type="submit"
                  className={refStyles.button}
                  loading={isLoading}
                  style={{ width: "115px" }}
                />
              ) : null}
            </Form>
          )}
        </Formik>
      </Panel>
    )
  }
}

import { DateTime } from "luxon"

import jQuery from 'jquery'

(function ($) {
  "use strict"
  var cronInputs = $.fn.cronInputs = {
    'en': {
      periodCreate: '<div class="cron-select-period"><label></label><select class="cron-period-select" id="cron-period-select-create"></select></div>',
      periodEdit: '<div class="cron-select-period"><label></label><select class="cron-period-select" id="cron-period-select-edit"></select></div>',
      startCreateTime: `<div class="cron-input cron-start-time">Start time  <input class="timepicker form-control" type="text" id="create-cron-time" name="best_time" maxlength="80" size="30" required></div>`,
      endCreateTime: `<div class="cron-input cron-end-time">End time  <input class="timepicker form-control" type="text" id="create-cron-time" name="best_time" maxlength="80" size="30" required></div>`,

      startEditTime: `<div class="cron-input cron-start-time">Start time  <input class="timepicker form-control" type="text" id="edit-cron-time" name="best_time" maxlength="80" size="30" required></div>`,

      container: '<div class="cron-input"></div>',
      daily: {
        tag: 'cron-daily',
        inputs: ['<p style="margin-top:10px;"><input type="radio" name="dailyType" value="every"> <span > Every </span> <select class="cron-daily-select"></select><span > day(s)</span></p>',
          '<p style="margin-top:10px;"><input type="radio" name="dailyType" value="clock"> <span > Every week day </span> </p>']
      },
      weekly: {
        tag: 'cron-weekly',
        inputs: ['<p style="margin-top:10px;"><input type="checkbox" name="dayOfWeekMon"> Monday  <input type="checkbox" name="dayOfWeekTue"> Tuesday  ' +
          '<input type="checkbox" name="dayOfWeekWed"> Wednesday  <input type="checkbox" name="dayOfWeekThu"> Thursday</p>',
        '<p style="margin-top:10px;"><input type="checkbox" name="dayOfWeekFri"> Friday  <input type="checkbox" name="dayOfWeekSat"> Saturday  ' +
        '<input type="checkbox" name="dayOfWeekSun"> Sunday</p>']
      },
      monthly: {
        tag: 'cron-monthly',
        inputs: ['<p style="margin-top:10px;"><input type="radio" name="monthlyType" value="byDay"> <span > Day  </span> <select class="cron-monthly-day"></select> of every <select class="cron-monthly-month"></select> month(s)</p>',
          '<p style="margin-top:10px;"><input type="radio" name="monthlyType" value="byWeek"> <span > The </span>  <select class="cron-monthly-nth-day"></select> ' +
          '<select class="cron-monthly-day-of-week"></select> of every <select class="cron-monthly-month-by-week"></select> month(s)</p>']
      },
    }
  }

  var optsText = $.fn.optsText = {
    'en': {
      periodOpts: ["Daily", "Weekly", "Biweekly", "Monthly"],
      monthOpts: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      nthWeekOpts: ["First", "Second", "Third", "Forth"],
      dayOfWeekOpts: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    }
  }

  function getTimezoneName() {
    const today = new Date()
    const short = today.toLocaleDateString(undefined)
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' })

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short)
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length)

      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '')

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full
    }
  }

  // Convert an array of values to options to append to select input
  function arrayToOptions(opts, values) {
    var inputOpts = ''
    for (var i = 0; i < opts.length; i++) {
      var value = opts[i]
      if (values != null) value = values[i]
      inputOpts += "<option value='" + value + "'>" + opts[i] + "</option>\n"

    }
    return inputOpts
  }

  // Convert an integer range to options to append to select input
  function rangeToOptions(start, end, isClock) {
    var inputOpts = '', label
    for (var i = start; i <= end; i++) {
      if (isClock && i < 10) label = "0" + i
      else label = i
      inputOpts += "<option value='" + i + "'>" + label + "</option>\n"
    }
    return inputOpts
  }

  // Add input elements to UI as defined in cronInputs
  function addInputElements($baseEl, inputObj, language, onFinish) {
    $(cronInputs[language].container).addClass(inputObj.tag).appendTo($baseEl)
    $baseEl.children("." + inputObj.tag).append(inputObj.inputs)
    if (typeof onFinish === "function") onFinish()
  }

  var eventHandlers = {
    periodSelect: function () {
      var period = ($(this).val())
      var $selector = $(this).parent()
      $selector.siblings('div.cron-input').hide()
      $selector.siblings().find("select option").removeAttr("selected")
      $selector.siblings().find("select option:first").attr("selected", "selected")
      $selector.siblings('div.cron-start-time').show()
      $selector.siblings('div.cron-start-time').children("select.cron-clock-hour").val('12')
      switch (period) {
        case 'Daily':
          var $dailyEl = $selector.siblings('div.cron-daily')
          $dailyEl.show()
            .find("input[name=dailyType][value=every]").prop('checked', true)
          break
        case 'Weekly':
          $selector.siblings('div.cron-weekly')
            .show()
            .find("input[type=checkbox]").prop('checked', false)
          break
        case 'Biweekly':
          $selector.siblings('div.cron-weekly')
            .show()
            .find("input[type=checkbox]").prop('checked', false)
          break
        case 'Monthly':
          var $monthlyEl = $selector.siblings('div.cron-monthly')
          $monthlyEl.show()
            .find("input[name=monthlyType][value=byDay]").prop('checked', true)
          break
      }
    }
  }

  // Public functions
  $.cronBuilder = function (el, options) {
    var base = this

    // Access to jQuery and DOM versions of element
    base.$el = $(el)
    base.el = el

    // Reverse reference to the DOM object
    base.$el.data('cronBuilder', base)

    // Initialization
    base.init = function () {
      base.options = $.extend({}, $.cronBuilder.defaultOptions, options)

      var periodOpts = arrayToOptions(optsText[base.options.language].periodOpts, ["Daily", "Weekly", "Biweekly", "Monthly"])
      var dayOpts = rangeToOptions(1, 7)
      var secondClockOpts = rangeToOptions(0, 59, true)
      var minuteClockOpts = rangeToOptions(0, 59, true)
      var hourClockOpts = rangeToOptions(0, 23, true)
      var dayInMonthOpts = rangeToOptions(1, 31)
      var monthOpts = arrayToOptions(optsText[base.options.language].monthOpts,
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
      var monthNumOpts = rangeToOptions(1, 12)
      var nthWeekOpts = arrayToOptions(optsText[base.options.language].nthWeekOpts, [1, 2, 3, 4])
      var dayOfWeekOpts = arrayToOptions(optsText[base.options.language].dayOfWeekOpts, ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"])

      if (options.edit)
        base.$el.append(cronInputs[base.options.language].periodEdit)
      else
        base.$el.append(cronInputs[base.options.language].periodCreate)


      base.$el.find("div.cron-select-period label").text(base.options.selectorLabel)
      base.$el.find("select.cron-period-select")
        .append(periodOpts)
        .bind("change", eventHandlers.periodSelect)


      addInputElements(base.$el, cronInputs[base.options.language].daily, base.options.language, function () {
        base.$el.find("select.cron-daily-select").append(dayOpts)
      })

      addInputElements(base.$el, cronInputs[base.options.language].weekly, base.options.language)

      addInputElements(base.$el, cronInputs[base.options.language].monthly, base.options.language, function () {
        base.$el.find("select.cron-monthly-day").append(dayInMonthOpts)
        base.$el.find("select.cron-monthly-month").append(monthNumOpts)
        base.$el.find("select.cron-monthly-nth-day").append(nthWeekOpts)
        base.$el.find("select.cron-monthly-day-of-week").append(dayOfWeekOpts)
        base.$el.find("select.cron-monthly-month-by-week").append(monthNumOpts)
      })


      if (options.availabilityCron) {
        base.$el.append(cronInputs[base.options.language].startCreateTime)
        base.$el.append(cronInputs[base.options.language].endCreateTime)
      }
      else if (options.create) {
        ;//base.$el.append(cronInputs[base.options.language].startCreateTime);
      } else {
        base.$el.append(cronInputs[base.options.language].startEditTime)

      }


      if (typeof base.options.onChange === "function") {
        base.$el.find("select, input").change(function () {
          base.options.onChange(base.getExpression())
        })
      }

      base.$el.find("select#cron-period-select-create")
        .triggerHandler("change")
      base.$el.find("select#cron-period-select-edit")
        .triggerHandler("change")

    }

    base.clearCache = function () {
      base.$el.find("select#cron-period-select-create")
        .triggerHandler("change")
      base.$el.find("select#cron-period-select-edit")
        .triggerHandler("change")
    }


    base.getExpression = function (type, time) {
      //var b = c.data("block");
      var sec = 0 // ignoring seconds by default
      var year = "*" // every year by default
      var dow = "?"
      var month = "*", dom = "*"
      var time = DateTime.fromJSDate(time).toFormat("HH:mm").split(":")
      var min = time[1]
      var hour = time[0]
      var period = base.$el.find("select.cron-period-select").val()
      switch (period) {

        case 'Daily':
          var $selector = base.$el.find("div.cron-daily")
          if ($selector.find("input[name=dailyType][value=every]").is(":checked")) {
            var ndom = $selector.find("select.cron-daily-select").val()
            if (ndom > 1) dom = "*/" + ndom
          } else {
            dom = "?"
            dow = "MON-FRI"
          }
          break

        case 'Weekly':
          var $selector = base.$el.find("div.cron-weekly")
          var ndow = []
          if ($selector.find("input[name=dayOfWeekMon]").is(":checked"))
            ndow.push("MON")
          if ($selector.find("input[name=dayOfWeekTue]").is(":checked"))
            ndow.push("TUE")
          if ($selector.find("input[name=dayOfWeekWed]").is(":checked"))
            ndow.push("WED")
          if ($selector.find("input[name=dayOfWeekThu]").is(":checked"))
            ndow.push("THU")
          if ($selector.find("input[name=dayOfWeekFri]").is(":checked"))
            ndow.push("FRI")
          if ($selector.find("input[name=dayOfWeekSat]").is(":checked"))
            ndow.push("SAT")
          if ($selector.find("input[name=dayOfWeekSun]").is(":checked"))
            ndow.push("SUN")
          dow = "*"
          dom = "?"
          if (ndow.length < 7 && ndow.length > 0) dow = ndow.join(",")
          break
        case 'Biweekly':
          var $selector = base.$el.find("div.cron-weekly")
          var ndow = []
          if ($selector.find("input[name=dayOfWeekMon]").is(":checked"))
            ndow.push("MON")
          if ($selector.find("input[name=dayOfWeekTue]").is(":checked"))
            ndow.push("TUE")
          if ($selector.find("input[name=dayOfWeekWed]").is(":checked"))
            ndow.push("WED")
          if ($selector.find("input[name=dayOfWeekThu]").is(":checked"))
            ndow.push("THU")
          if ($selector.find("input[name=dayOfWeekFri]").is(":checked"))
            ndow.push("FRI")
          if ($selector.find("input[name=dayOfWeekSat]").is(":checked"))
            ndow.push("SAT")
          if ($selector.find("input[name=dayOfWeekSun]").is(":checked"))
            ndow.push("SUN")
          dow = "*"
          dom = "?"
          if (ndow.length < 7 && ndow.length > 0) dow = ndow.join(",") + " [BI]"
          break

        case 'Monthly':
          var $selector = base.$el.find("div.cron-monthly")
          var nmonth
          if ($selector.find("input[name=monthlyType][value=byDay]").is(":checked")) {
            month = "*"
            nmonth = $selector.find("select.cron-monthly-month").val()
            dom = $selector.find("select.cron-monthly-day").val()
            dow = "?"
          } else {
            dow = $selector.find("select.cron-monthly-day-of-week").val()
              + "#" + $selector.find("select.cron-monthly-nth-day").val()
            nmonth = $selector.find("select.cron-monthly-month-by-week").val()
            dom = "?"
          }
          if (nmonth > 1) month = "1/" + nmonth
          break

        default:
          break
      }
      return [min, hour, dom, month, dow].join(" ")
    }

    if (el.children.length == 0)
      base.init()
  }

  // Plugin default options
  $.cronBuilder.defaultOptions = {
    selectorLabel: "Select period: ",
    language: "en"
  }

  // Plugin definition
  $.fn.cronBuilder = function (options) {
    return this.each(function () {
      (new $.cronBuilder(this, options))
    })
  }

}(jQuery))

const GLOBALS_ENVIRONMENT = {
  environmentName: process.env.REACT_APP_STAGE,
  isLocal: process.env.REACT_APP_STAGE === "localhost",
  isDevelopment: process.env.REACT_APP_STAGE === "development",
  isStaging: process.env.REACT_APP_STAGE === "staging",
  isProduction: process.env.REACT_APP_STAGE === "production",
  isQAProduction: process.env.REACT_APP_STAGE === "qaproduction",
}

module.exports = GLOBALS_ENVIRONMENT

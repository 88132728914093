import { useContext, useState, useEffect } from "react"
import { Field } from "formik"
import _ from "lodash"
import { useLazyQuery, gql } from "@apollo/client"

import { useFormikContext } from "formik"

import { FormContext } from "../../context/formContext"
import { UserContext } from "../../context/userContext"
import { InputText as Input } from "primereact/inputtext"

import styles from "../../styles/Form.module.css"

import fieldVisibility from "../../helpers/fieldVisibility"

export default function TypeParticipantEmail({
  field,
  block,
  index,
  typeForm,
}) {
  const isRequired = JSON.parse(field.field_validation.required[0])
  const formik = useFormikContext()
  const form = useContext(FormContext)
  const user = useContext(UserContext)

  let isDisabled = form ? form.blocksSaved[block] : false
  isDisabled = field.read_only ? true : isDisabled

  const [isUnique, setIsUnique] = useState(null)
  const [candidate, setCandidate] = useState("")

  let CHECK_EMAIL = gql`
    query EmailChecker($email: String!, $participant_id: ID!, $organization_id: ID!) {
      participantEmailAddressVerifier(
        email: $email,
        participant_id: $participant_id,
        organization_id: $organization_id
      ) {
        data
      }
    }
  `

  const [checkEmail, { loading }] = useLazyQuery(CHECK_EMAIL, {
    fetchPolicy: "no-cache",
    onCompleted: ({ participantEmailAddressVerifier: { data } }) => {
      if (_.isEmpty(data)) {
        return setIsUnique(true)
      } else {
        if (typeForm === "template") {
          formik.setFieldValue(field.field_name, "")
          return setIsUnique(false)
        } else {
          if (user.current_participant_id === data) {
            return setIsUnique(true)
          } else {
            formik.setFieldValue(field.field_name, "")
            return setIsUnique(false)
          }
        }
      }
    },
  })
  useEffect(() => {
    return () => {
      //unmount reset
      setIsUnique(null)
      setCandidate("")
      formik.setFieldTouched("participant_email_account", false)
    }
  }, [])
  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, meta, form: formik }) => (
          <span className="p-input-icon-right">
            {loading && <i className="pi pi-spin pi-spinner" />}
            {isUnique && !loading && <i className="pi pi-check" />}
            <Input
              name={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              disabled={isDisabled || loading}
              onBlur={() => {
                formik.setFieldTouched(formikField.name)
                setCandidate(formikField.value)
                return checkEmail({
                  variables: {
                    email: formikField.value,
                    participant_id: user.participant_id || "",
                    organization_id: user.organization_id,
                  },
                })
              }}
              autoComplete="off"
            />
            {meta.touched && !isUnique && !loading && (
              <div className={styles.errFieldMsg}>
                {candidate} is already in use. Please provide an alternative
                email or select "No" for valid email address to continue.
              </div>
            )}
            {meta.touched && meta.error && !loading && (
              <div className={styles.errFieldMsg}>{meta.error}</div>
            )}
          </span>
        )}
      </Field>
      {loading && (
        <div>
          <small>Validating Email...</small>
        </div>
      )}
    </div>
  )
}

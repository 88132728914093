import { useEffect, useState, useContext, useCallback } from "react"

//Notifications modules
import {
  noteApiListMutedPost,
  noteApiMuteNotificationsPOST,
} from "../notificationsApi"

//PrimeReact
import { Checkbox } from "primereact/checkbox"
import { ProgressSpinner } from "primereact/progressspinner"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Button } from "primereact/button"
import { Tooltip } from "primereact/tooltip"
import { InputText } from "primereact/inputtext"
import { FilterMatchMode } from "primereact/api"

// Context
import { UserContext } from "../../../context/userContext"

//Formik
import { Formik, Form } from "formik"
import * as Yup from "yup"

//Helpers
import { LogError } from "../../../helpers/logger"

//Styles
import myStyles from "../../../styles/NotificationsForm.module.css"

export default function NotificationSettingsTab({
  onSetMutedNotifications,
  isDisabledParticipant,
}) {
  const userCtx = useContext(UserContext)
  const group_id = "All"

  const [loading, setLoading] = useState(true)
  const [mutedNotifications, setMutedNotifications] = useState([])
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const validationSchema = Yup.object().shape({})

  const getMutedNotifications = useCallback(async () => {
    setLoading(true)

    try {
      const res = await noteApiListMutedPost(
        userCtx.tokenNotification,
        group_id,
        userCtx.participant_id
      )

      if (res) {
        if (res.mutedEventTypes) {
          res.mutedEventTypes = res.mutedEventTypes.map((eventType) => {
            return {
              ...eventType,
              channelEmail: !eventType.channelEmail,
              channelSignalR: !eventType.channelSignalR,
              channelSMS: !eventType.channelSMS,
              sound: !eventType.sound,
            }
          })
        }

        setMutedNotifications(res)
      } else {
        setMutedNotifications([])
      }
    } catch (error) {
      LogError("Unable to list muted notifications", error)
      userCtx.setNotificationError(
        "Unable to list muted notifications. See console for details."
      )
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.tokenNotification, userCtx.staff_id])

  useEffect(() => {
    getMutedNotifications()
  }, [getMutedNotifications])

  async function applyMute(values) {
    setLoading(true)
    try {
      const mutedEvents = values.mutedEventTypes
        .filter(
          (eventType) =>
            !eventType.channelEmail ||
            !eventType.channelSMS ||
            !eventType.channelSignalR ||
            !eventType.sound
        )
        .map((eventType) => {
          return {
            id: eventType.eventTypeId,
            ...eventType,
            channelEmail: !eventType.channelEmail,
            channelSMS: !eventType.channelSMS,
            channelSignalR: !eventType.channelSignalR,
            sound: !eventType.sound,
          }
        })

      await noteApiMuteNotificationsPOST(
        userCtx.tokenNotification,
        group_id,
        userCtx.participant_id,
        mutedEvents
      )
      userCtx.setNotificationInformation("Mute settings applied successfully")

      onSetMutedNotifications(values)

      await getMutedNotifications()

      setLoading(false)
      return true
    } catch (error) {
      LogError("Unable to list event types", error)
      userCtx.setNotificationError(
        "Unable to list event types. See console for details"
      )
      setLoading(false)
      return false
    }
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <ProgressSpinner />
      </div>
    )
  }

  const RenderCheckBoxField = (rowData, values, fieldName, onSetValues) => {
    const index = values?.mutedEventTypes?.indexOf(rowData)

    return (
      <Checkbox
        checked={rowData[fieldName]}
        onChange={(e) => {
          onSetValues(`mutedEventTypes[${index}].${fieldName}`, e.checked)
        }}
        disabled={isDisabledParticipant}
      />
    )
  }

  const RenderCheckboxHeader = (label, fieldName, values, onSetFieldValue) => {
    const uncheckedItems = values?.mutedEventTypes?.filter(
      (eventType) => !eventType[fieldName]
    )

    //Set all values of the column to the checked value.
    const setAllValues = (checked) => {
      let newValues = [...values.mutedEventTypes]
      newValues.forEach((eventType) => {
        if (eventType[fieldName] !== checked) {
          eventType[fieldName] = checked
        }
      })
      onSetFieldValue("mutedEventTypes", newValues)
    }

    return (
      <>
        {values?.mutedEventTypes ? (
          <>
            {!isDisabledParticipant ? (
              <Checkbox
                checked={uncheckedItems?.length === 0}
                onChange={(e) => {
                  setAllValues(e.checked)
                }}
              />
            ) : null}{" "}
          </>
        ) : null}

        {label}
      </>
    )
  }

  const RenderHeader = () => {
    return (
      <div className={myStyles.noteSettingsTableHeader}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={filters.global.value}
            onChange={(e) => {
              const value = e.target.value
              let _filters = { ...filters }

              _filters["global"].value = value

              setFilters(_filters)
            }}
          />{" "}
        </span>
      </div>
    )
  }

  return (
    <div>
      <Tooltip target=".submit-disabled-tt" />
      <Formik
        initialValues={mutedNotifications}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          applyMute(values)
        }}
      >
        {({ handleSubmit, dirty, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className={myStyles.noteSettingsLayout}>
              <div className={myStyles.noteSettingsLayoutBody}>
                <DataTable
                  value={values?.mutedEventTypes || []}
                  sortField="friendlyName"
                  sortOrder={1}
                  disabled={isDisabledParticipant}
                  className={myStyles.noteSettingsTable}
                  scrollable
                  scrollHeight="540px"
                  filters={filters}
                  header={RenderHeader}
                  globalFilterFields={["friendlyName"]}
                >
                  <Column header={"Notification type"} field={"friendlyName"} />
                  <Column
                    header={() =>
                      RenderCheckboxHeader(
                        "E-mail",
                        "channelEmail",
                        values,
                        setFieldValue
                      )
                    }
                    body={(rowData) =>
                      RenderCheckBoxField(
                        rowData,
                        values,
                        "channelEmail",
                        setFieldValue
                      )
                    }
                    className={myStyles.noteSettingsCheckedColumn}
                  />
                  <Column
                    header={() =>
                      RenderCheckboxHeader(
                        "SMS",
                        "channelSMS",
                        values,
                        setFieldValue
                      )
                    }
                    body={(rowData) =>
                      RenderCheckBoxField(
                        rowData,
                        values,
                        "channelSMS",
                        setFieldValue
                      )
                    }
                    className={myStyles.noteSettingsCheckedColumn}
                  />
                  <Column
                    header={() =>
                      RenderCheckboxHeader(
                        "Online",
                        "channelSignalR",
                        values,
                        setFieldValue
                      )
                    }
                    body={(rowData) =>
                      RenderCheckBoxField(
                        rowData,
                        values,
                        "channelSignalR",
                        setFieldValue
                      )
                    }
                    className={myStyles.noteSettingsCheckedColumn}
                  />
                  <Column
                    header={() =>
                      RenderCheckboxHeader(
                        "Sound",
                        "sound",
                        values,
                        setFieldValue
                      )
                    }
                    body={(rowData) =>
                      RenderCheckBoxField(
                        rowData,
                        values,
                        "sound",
                        setFieldValue
                      )
                    }
                    className={myStyles.noteSettingsCheckedColumn}
                  />
                </DataTable>
              </div>
              <div className={myStyles.noteSettingsLayoutButton}>
                <Button
                  className="btn"
                  label="Save"
                  type="submit"
                  disabled={isDisabledParticipant}
                  style={{ float: "right" }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

import { useContext } from "react";
import { Field, FastField } from "formik";

import { FormContext } from "../../context/formContext";

import { Editor as InputTextareaRich } from "primereact/editor";

import styles from "../../styles/Form.module.css";

import fieldVisibility from "../../helpers/fieldVisibility";

const renderHeader = () => {
  return (
    <div className="ql-toolbar">
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
      <span className="ql-formats">
        <button
          className="ql-list"
          aria-label="Ordered List"
          value="ordered"
        ></button>
        <button
          className="ql-list"
          aria-label="Unordered List"
          value="bullet"
        ></button>
      </span>
    </div>
  );
};

const header = renderHeader();

function RegularFieldOutput(props) {
  const { field, block, index } = props;
  const isRequired = JSON.parse(field.field_validation.required[0])
  const form = useContext(FormContext);
  const isDisabled = form ? form.blocksSaved[block] : false;
  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, form, meta }) => {
          return (
            <>
              <InputTextareaRich
                name={formikField.name}
                value={formikField.value}
                onTextChange={({ htmlValue }) =>
                  form.setFieldValue(formikField.name, htmlValue)
                }
                headerTemplate={header}
                disabled={isDisabled}
                style={{ height: "280px" }}
                onBlur={formikField.onBlur}
              />
              {meta.touched && meta.error && (
                <div className={styles.errFieldMsg}>{meta.error}</div>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
}

function MultiFieldOutput(props) {
  const { field, index, mfFieldName, mfIndex } = props;
  const fieldSlug = `${mfFieldName}.${mfIndex}.${field.field_name}`;
  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>{field.field_label}</label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <FastField name={fieldSlug}>
        {({ field: fastField, form }) => {
          return (
            <InputTextareaRich
              name={fastField.name}
              value={fastField.value}
              onTextChange={({ htmlValue }) =>
                form.setFieldValue(fastField.name, htmlValue)
              }
              headerTemplate={header}
              style={{ height: "280px" }}
              onBlur={fastField.onBlur}
            />
          );
        }}
      </FastField>
    </div>
  );
}

export default function TypeTextareaRich(props) {
  switch (props.type) {
    case "regular-field":
      const regFieldProps = {
        field: props.field,
        block: props.block,
        index: props.index,
      };
      return <RegularFieldOutput {...regFieldProps} />;
    case "multi-field":
      const multiFieldProps = {
        field: props.field,
        index: props.index,
        mfFieldName: props.mfFieldName,
        mfIndex: props.mfIndex,
      };
      return <MultiFieldOutput {...multiFieldProps} />;
    default:
      return null;
  }
}

import { axiosPost, axiosPut, axiosGet } from "../../helpers/microserviceAPIs"

import { assessmentsApiUrl } from "../../config/microservicesUrls"

export async function assessmentsApiParticipantOverviewPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(jwt, `${api_url}/Participant/Overview`, payload)
  if (res === null) {
    throw new Error(`Unable to retrieve participant assessments overview`)
  }
  return res
}

export async function assessmentsApiParticipantOverviewSummaryPost(
  jwt,
  payload
) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(
    jwt,
    `${api_url}/Participant/Overview/summary`,
    payload
  )
  if (res === null) {
    throw new Error(
      `Unable to retrieve participant assessments overview summary`
    )
  }
  return res
}

export async function assessmentsApiCompleteListPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(
    jwt,
    `${api_url}/Assessment/completed/list`,
    payload
  )
  if (res === null) {
    throw new Error(`Unable to submit a completed assessment listing request`)
  }
  return res
}

export async function assessmentsApiQuestionsGet(jwt, assessmentId) {
  const api_url = assessmentsApiUrl()
  const res = await axiosGet(
    jwt,
    `${api_url}/Assessment/${assessmentId}/Questions`
  )
  if (res === null) {
    throw new Error(`Unable to retrieve assessment questions`)
  }
  return res
}

export async function assessmentsApiCompletedGet(jwt, assessmentId) {
  const api_url = assessmentsApiUrl()
  const res = await axiosGet(
    jwt,
    `${api_url}/Assessment/completed?id=${assessmentId}`
  )
  if (res === null) {
    throw new Error(`Unable to retrieve completed assessment`)
  }
  return res
}

//Logs a completed assessment
export async function assessmentsApiCompletePost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(jwt, `${api_url}/Assessment/complete`, payload)
  if (res === null) {
    throw new Error(`Unable to submit a completed assessment`)
  }
  return res
}

//Update a completed assessment
export async function assessmentsApiUpdateCompletedPost(
  jwt,
  completedAssessmentID,
  payload
) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(
    jwt,
    `${api_url}/Assessment/complete/${completedAssessmentID}`,
    payload
  )
  if (res === null) {
    throw new Error(`Unable to update a completed assessment`)
  }
  return res
}

//Logs a completed assessment and creates an ad_hoc_assessment SOAR session.
export async function assessmentsApiCompleteWithSessionPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(
    jwt,
    `${api_url}/Assessment/complete/soar`,
    payload
  )
  if (res === null) {
    throw new Error(`Unable to submit a completed assessment`)
  }
  return res
}

export async function assessmentsApiSubmitSoarSessionPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(
    jwt,
    `${api_url}/Assessment/complete/soar/session`,
    payload
  )
  if (res === null) {
    throw new Error(`Unable to submit a completed assessment`)
  }
  return res
}

export async function assessmentsApiConfirmCompletePost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(
    jwt,
    `${api_url}/Assessment/confirmCompleted`,
    payload
  )
  if (res === null) {
    throw new Error(`Unable to submit a completed assessment`)
  }
  return res
}

export async function assessmentsApiScheduleListPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(jwt, `${api_url}/Scheduling/list`, payload)
  if (res === null) {
    throw new Error(`Unable to list assessment schedules`)
  }
  return res
}

export async function assessmentsApiScheduleListTasksPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(jwt, `${api_url}/Scheduling/list/tasks`, payload)
  if (res === null) {
    throw new Error(`Unable to list assessment scheduled tasks`)
  }
  return res
}

export async function assessmentsApiListMiniPost(jwt, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPost(jwt, `${api_url}/Assessment/list/mini`, payload)
  if (res === null) {
    throw new Error(`Unable to get mini list of assessments`)
  }
  return res
}

export async function assessmentsApiScheduleGet(jwt, id) {
  const api_url = assessmentsApiUrl()
  const res = await axiosGet(jwt, `${api_url}/Scheduling?id=${id}`)
  if (res === null) {
    throw new Error(`Unable to retrieve schedule profile`)
  }
  return res
}

export async function assessmentsApiScheduleTaskGet(jwt, id) {
  const api_url = assessmentsApiUrl()
  const res = await axiosGet(jwt, `${api_url}/Scheduling/task/${id}`)
  if (res === null) {
    throw new Error(`Unable to retrieve schedule task profile`)
  }
  return res
}

export async function assessmentsApiScheduleTaskCompletePut(jwt, id, payload) {
  const api_url = assessmentsApiUrl()
  const res = await axiosPut(
    jwt,
    `${api_url}/Scheduling/task/${id}/complete/soar`,
    payload
  )
  if (res === null) {
    throw new Error(`Unable to complete scheduled task`)
  }
  return res
}

export async function assessmentsApiOrganizationGet(jwt, organizationId) {
  const api_url = assessmentsApiUrl()
  const res = await axiosGet(jwt, `${api_url}/organization/${organizationId}`)
  if (res === null) {
    throw new Error(`Unable to retrieve organization profile`)
  }
  return res
}

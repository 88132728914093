import { gql } from "@apollo/client"

export const GET_NOTIFICATION_USERS = gql`
  query Users($staff_id: ID!, $organization_id: ID!) {
    staff: staffsByOrganization(
      staff_id: $staff_id
      organization_id: $organization_id
    ) {
      staff_id
      staff_alias
      staff_name_first
      staff_name_last
    }
  }
`

export const GET_STAFF = gql`
  query GetUser($staff_id: ID) {
    user: usersFromList(list: [$staff_id]) {
      staff_name_first
      staff_name_last
    }
  }
`

import * as Yup from "yup"
import _ from "lodash"

// Validators
import validation_default from "./validation_default"
import validation_date from "./validation_date"
import validation_text from "./validation_text"
import validation_number from "./validation_number"
import validation_email from "./validation_email"
import validation_checkbox from "./validation_checkbox"
//import validation_participant_password from "./validation_participant_password"
//import validation_participant_email from "./validation_participant_email"
import validation_session_end_time from "./validation_session_end_time"
//import validation_form_end_time from "./validation_form_end_time"

export default function validationSchema(blocks, editMode=false) {
  const yupObj = _.reduce(
    blocks,
    (acc, block) => {
      const handleValidationPerField = _.reduce(
        block.fields,
        (acc, field) => {
          const fieldValidation = field.field_validation || null
          const { field_type, field_name } = field

          const skipList = [
            "hidden",
            "sys",
            "service",
            "conditional_text",
            "conditional_password",
          ]
          const skipValidation = _.includes(skipList, field_type)

          //const isParticipantPassword = field_name === "participant_account_password"
          //const isParticipantEmail = field_name === "participant_email_account"
          const isSessionEndTime = field_name === "session_end_time"
          //const isFormEndTime = field_name === "form_end_time"
          
          //if (isParticipantPassword) return validation_participant_password(field, acc)
         // if (isParticipantEmail) return validation_participant_email(field, acc)
          if (isSessionEndTime) return validation_session_end_time(field, acc)
          //if (isFormEndTime) return validation_form_end_time(field, acc)

          if (skipValidation || !fieldValidation) return acc

          switch (field_type) {
            case "date":
              return validation_date(field, acc)
            case "text":
              return validation_text(field, acc)
            case "number":
              return validation_number(field, acc)
            case "email":
              return validation_email(field, acc)
            case "checkbox":
              return validation_checkbox(field, acc)
            default:
              return validation_default(field, acc)
          }
        },
        {}
      )
      return handleValidationPerField
    },
    {}
  )

  return Yup.object().shape(yupObj)
}

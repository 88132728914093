//Formik
import FormikNumberInput from "../../../helpers/formikInputs/FormikNumberInput"
import FormikCheckboxInput from "../../../helpers/formikInputs/FormikCheckboxInput"
import FormikDropDown from "../../../helpers/formikInputs/FormikDropDown"
import FormikTextInput from "../../../helpers/formikInputs/FormikTextInput"
import FormikCalendar from "../../../helpers/formikInputs/FormikCalendar"
import FormikRadioButtonInput from "../../../helpers/formikInputs/FormikRadioButtonInput"
import { useField } from "formik"

//Styles
import styles from "../../../styles/Form-Inputs.module.css"

export default function AssessmentFormQuestionInput({
  question,
  answers,
  answerIndex,
  readOnly,
  displayAnswerValues,
}) {
  let valueFieldName = displayAnswerValues === false ? "NoValue" : "value"
  const singleValueName = `answers[${answerIndex}].${valueFieldName}`

  const [, meta] = useField(singleValueName)

  if (answerIndex >= 0) {
    switch (question.type.toLowerCase()) {
      case "textinput":
        return <FormikTextInput name={singleValueName} disabled={readOnly} />
      case "number":
        return (
          <FormikNumberInput
            name={singleValueName}
            minFractionDigits={0}
            disabled={readOnly}
          />
        )
      case "date":
        return <FormikCalendar name={singleValueName} disabled={readOnly} />
      case "checkbox":
        return (
          <div className="radioGroup">
            {answers[answerIndex].multiValues.map((value, index) => {
              return (
                <FormikCheckboxInput
                  checkboxLabel={value.text}
                  name={`answers[${answerIndex}].multiValues[${index}].${valueFieldName}`}
                  disabled={readOnly}
                />
              )
            })}
            {meta.touched && meta.error ? (
              <div className="errorLabel">
                <label
                  id={`${singleValueName}-error`}
                  className={styles.errFieldMsg}
                >
                  {meta.error}
                </label>
              </div>
            ) : null}
          </div>
        )
      case "dropdown":
        return (
          <FormikDropDown
            name={singleValueName}
            options={answers[answerIndex].multiValues}
            optionLabel={"text"}
            optionValue={"optionId"}
            disabled={readOnly}
          />
        )
      case "radio":
        return (
          <FormikRadioButtonInput
            name={singleValueName}
            options={answers[answerIndex].multiValues}
            optionLabel={"text"}
            optionValue={"optionId"}
            disabled={readOnly}
          />
        )
      default:
        return <>Unknown question input type</>
    }
  } else {
    return <>Answer not found</>
  }
}

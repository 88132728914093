//Helpers
import { LogError } from "../../../helpers/logger"
import { getEnvironmentCode } from "../developerToolsHelpers"

export const MicroServices = [
  {
    name: "Appointments",
    envVariable: "REACT_APP_APPOINTMENTS_API_",
  },
  {
    name: "Assessments",
    envVariable: "REACT_APP_ASSESSMENTS_API_",
  },
  {
    name: "Document Signing",
    envVariable: "REACT_APP_DOCUMENT_SIGNING_API_",
  },
  {
    name: "Notifications",
    envVariable: "REACT_APP_NOTIFICATIONS_API_",
  },
  {
    name: "Referrals",
    envVariable: "REACT_APP_REFERRALS_API_",
  },
  { name: "Tasks", envVariable: "REACT_APP_TASKS_API_" },
  {
    name: "Telerecovery",
    envVariable: "REACT_APP_TWILIO_MICROSERVICE_API_",
  },
]

export const getURLOverrideValue = (key) => {
  const defaultOverrideValue = {
    overrideUrl: undefined,
  }

  try {
    const storageValue = localStorage.getItem(key)

    if (storageValue) {
      let overrideValue = JSON.parse(storageValue)

      return overrideValue
    } else {
      return defaultOverrideValue
    }
  } catch (error) {
    LogError("Unable to retrieve override URL value from localStorage", error)
    return defaultOverrideValue
  }
}

export const setURLOverrideValue = (key, value) => {
  if (value === "") {
    localStorage.removeItem(key)
  } else {
    const saveValue = JSON.stringify({ overrideUrl: value })

    localStorage.setItem(key, saveValue)
  }
}

export const hasActiveUrlOverrides = () => {
  const environmentCode = getEnvironmentCode()
  let hasActive = false

  MicroServices.forEach((service) => {
    if (!hasActive) {
      const override = getURLOverrideValue(
        `${service.envVariable}${environmentCode}`
      )
      if (override?.overrideUrl) {
        hasActive = true
      }
    }
  })

  return hasActive
}

export const getActiveURLOverrides = () => {
  const environmentCode = getEnvironmentCode()
  let overrides = []

  MicroServices.forEach((service) => {
    const override = getURLOverrideValue(
      `${service.envVariable}${environmentCode}`
    )
    if (override?.overrideUrl) {
      overrides = [...overrides, service.name]
    }
  })

  return overrides
}

export const disableAllUrlOverrides = () => {
  const environmentCode = getEnvironmentCode()
  let hasActive = false

  MicroServices.forEach((service) => {
    if (!hasActive) {
      const key = `${service.envVariable}${environmentCode}`

      const override = getURLOverrideValue(key)
      if (override?.overrideUrl) {
        setURLOverrideValue(key, "")
      }
    }
  })
}

import { useEffect, useState } from "react"

//PrimeReact
import { Calendar } from "primereact/calendar"
import { MultiSelect } from "primereact/multiselect"

//Appointments modules.
import { groupFilterTemplate } from "./utils"

export default function AppointmentFilters({
  hideDateSelection,
  dateRange,
  onChangeDateRange,
  filterValues,
  onChangeFilterValues,
  services,
  disable,
}) {
  const [groupedFilters, setGroupFilters] = useState(groupFilterTemplate)

  useEffect(() => {
    let modifiedGroup = groupedFilters

    modifiedGroup[0].items = services.map((x) => {
      return { label: x, value: x }
    })
    setGroupFilters(modifiedGroup)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services])

  return (
    <>
      <div hidden={hideDateSelection} style={{ width: "22%" }}>
        <Calendar
          id="range"
          style={{ width: "100%" }}
          value={dateRange}
          onChange={(e) => {
            onChangeDateRange(e.value)
          }}
          selectionMode="range"
          readOnlyInput
          placeholder="Date Range"
          disabled={disable}
        />
      </div>
      <MultiSelect
        style={{ width: "30%" }}
        display="chip"
        value={filterValues}
        options={groupedFilters}
        onChange={(e) => {
          onChangeFilterValues(e.value)
        }}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        placeholder="Filters"
        disabled={disable}
      />
    </>
  )
}

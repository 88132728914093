import React from "react"
import { useContext, useState, useEffect } from "react"
import { useQuery, useLazyQuery } from "@apollo/client"
import _ from "lodash"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { DateTime } from "luxon"
import { UserContext } from "../../context/userContext"
import { useParams, useNavigate, Link } from "react-router-dom"

// Comps
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
//import ParticipantProfileBoxSmall from "../../components/ParticipantProfileBoxSmall"

// Loader
import { LoaderMedium } from "../../components/Loaders"

// Queries
import {
  GET_SAVED_BLOCKS,
  GET_USER,
  GET_SAVED_BLOCKS_BY_ORGANIZATION_ID,
} from "./queries"

import SupervisorName from "./SupervisorName"

// Styles
import styles from "../../styles/Page-RecoveryPlan.module.css"

export default function ViewRecoveryPlan() {
  const [blockSaved, setBlockSaved] = useState({
    strengths_interests: "",
    hopes_dreams: "",
  })
  const user = useContext(UserContext)
  const participant_id = user.participant_id
  const [nameStaff, setNameStaff] = useState(null)
  const [nameStaffUpdated, setNameStaffUpdated] = useState(null)
  const { organization_id, staff_id } = useParams()
  const block_name = "session__recovery_plan"
  const navigate = useNavigate()

  const { loading } = useQuery(GET_SAVED_BLOCKS_BY_ORGANIZATION_ID, {
    fetchPolicy: "no-cache",
    variables: {
      participant_id,
      organization_id,
      block_name: block_name,
    },
    onCompleted: (data) => {
      const dataParse = data.blockSavedbyOrganizationID
        ? JSON.parse(data.blockSavedbyOrganizationID.data)
        : {}
      setBlockSaved(dataParse)

      getStaffUpdate({
        variables: {
          staff_id: staff_id,
          organization_id: organization_id,
        },
      })
    },
  })

  const [getStaffCreated] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",

    variables: {
      staff_id: blockSaved?.staff_id,
      organization_id: user.organization_id,
    },
    onCompleted: (data) => {
      setNameStaff(data.user.staff_name_first + " " + data.user.staff_name_last)
    },
  })

  const [getStaffUpdate] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    /* variables: {
      staff_id: blockSaved?.values?.staff_id,
      organization_id: user.organization_id,
    },*/
    onCompleted: (data) => {
      setNameStaffUpdated(
        data.user.staff_name_first + " " + data.user.staff_name_last
      )
    },
  })

  useEffect(() => {
    getStaffCreated()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockSaved, user.participant_id])

  const activeGoalsTemplate = ({ goal }) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: goal,
        }}
      />
    )
  }
  if (loading) return <LoaderMedium />

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <div>
          <div>
            <br></br>
            <h2 className={styles.title}>Recovery Plan</h2>
            <ul className={styles.cta}>
              <li>
                <Link className="btn-sm edit-button" to="#">
                  Print Recovery Plan
                </Link>
              </li>
              <li>
                <Link
                  className="btn-sm edit-button"
                  onClick={() => navigate(-1)}
                  to="#"
                >
                  Close View
                </Link>
              </li>
            </ul>
            <div className="box-summary no-title">
              <ul>
                <li>
                  <strong>Date Plan Created:</strong>{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: isNaN(
                        DateTime.fromISO(blockSaved?.values?.date_created).year
                      )
                        ? " "
                        : DateTime.fromISO(blockSaved?.values.date_created)
                            .month +
                          "-" +
                          DateTime.fromISO(blockSaved?.values.date_created)
                            .day +
                          "-" +
                          DateTime.fromISO(blockSaved?.values.date_created)
                            .year,
                    }}
                  />
                </li>

                <li>
                  <strong>Created by:</strong>{" "}
                  {!_.isNull(blockSaved) ? nameStaff : " "}
                </li>

                <li>
                  <strong>Date Last Updated:</strong>{" "}
                  {blockSaved?.values?.date_changed === "not set" ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: isNaN(
                          DateTime.fromISO(blockSaved?.values?.date_changed)
                            .year
                        )
                          ? " "
                          : DateTime.fromISO(blockSaved?.values?.date_changed)
                              .month +
                            "-" +
                            DateTime.fromISO(blockSaved?.values?.date_changed)
                              .day +
                            "-" +
                            DateTime.fromISO(blockSaved?.values?.date_changed)
                              .year,
                      }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: isNaN(
                          DateTime.fromISO(blockSaved?.values?.session_date)
                            .year
                        )
                          ? " "
                          : DateTime.fromISO(blockSaved?.values?.session_date)
                              .month +
                            "-" +
                            DateTime.fromISO(blockSaved?.values?.session_date)
                              .day +
                            "-" +
                            DateTime.fromISO(blockSaved?.values?.session_date)
                              .year,
                      }}
                    />
                  )}
                </li>

                <li>
                  <strong>Last Updated By:</strong>{" "}
                  {!_.isNull(blockSaved) ? nameStaffUpdated : " "}
                </li>
              </ul>
            </div>

            <div className={styles.summaryContent}>
              <div className={styles.colLeft}>
                <h4>Strenght & Interesets</h4>
              </div>
              <div className={styles.colRight}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blockSaved?.values?.strengths_interests,
                  }}
                />
              </div>
              <div className={styles.colLeft}>
                <h4>Hopes & Dream</h4>
              </div>
              <div className={styles.colRight}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blockSaved?.values?.hopes_dreams,
                  }}
                />
              </div>
              <div className={styles.colLeft}>
                <h4>Change one thing?</h4>
              </div>
              <div className={styles.colRight}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blockSaved?.values?.change_thing,
                  }}
                />
              </div>
              <div className={styles.colLeft}>
                <h4>Complementary Plan</h4>
              </div>
              <div className={styles.colRight}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blockSaved?.values?.plan_complementary,
                  }}
                />
              </div>
            </div>
            <h2>Supportive People</h2>
            <DataTable
              value={blockSaved?.values?.supportive_people || []}
              responsiveLayout="scroll"
            >
              <Column field="name" header="Name" sortable />
              <Column field="relationship" header="Relationship" sortable />
              <Column field="contact" header="Contact" sortable />
            </DataTable>
            <h2>Recovery Plan Goals</h2>
            <DataTable
              value={blockSaved?.values?.plan_goals}
              responsiveLayout="scroll"
            >
              <Column
                field="goal"
                header="Active Goals"
                body={activeGoalsTemplate}
                sortable
              />
              <Column field="domain" header="Recovery Domain" sortable />
              <Column field="status" header="Status" sortable />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

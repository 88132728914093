import * as Yup from "yup"
import _ from "lodash"

export default function validation_conditional_dropdown(field, acc) {
  const { field_name, field_validation } = field

 const conditions = JSON.parse(field.field_conditional)
 const fieldConditional =  _.keys(conditions)
 const conditionFieldName = fieldConditional[0]

  const checkBool = () => {
    if (!field_validation.required) return false
    return field_validation.required[0] === "true" ? true : false
  }

  const isRequired = checkBool()
  const isRequiredTxt =
    (field_validation.required && field_validation.required[1]) ||
    "Required field."

  const isForce = field_validation.force

  if (!isRequired) return acc

  if (isForce) {
    const [match, errTxt] = field_validation.force

    return {
      [field_name]: Yup.string()
      .nullable()
      .when(conditionFieldName, {
          is: (value) => {
            if(_.isArray(value))  {
              return _.find(value, function (v) {
                if (_.includes(conditions[conditionFieldName], v)) {
                    return true
                }
            });  
            } else {
              return _.includes(conditions[conditionFieldName], value) 
            }
          }, 
          then: Yup.string().required().matches(match, errTxt),
        }),
      ...acc,
    }
  }


  return {
    [field_name]: Yup.string()
    .nullable()
    .when(conditionFieldName, {
        is: (value) => {
          if(_.isArray(value))  {
            return _.find(value, function (v) {
              if (_.includes(conditions[conditionFieldName], v)) {
                  return true
              }
          });  
          } else {
            return _.includes(conditions[conditionFieldName], value) 
          }
        }, 
        then: Yup.string().required(isRequiredTxt),
      }),
    ...acc,
  }
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styles from "../../styles/NotificationsToast.module.css"
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip"

//Sounds
import useSound from "use-sound"
import generalNotificationSound from "../../assets/sounds/GeneralNotificationChime.mp3"
import trCall from "../../assets/sounds/notification_telerecovery_call.mp3"

function NotificationsToastContent(props) {
  const icon = props.isError ? "error" : "info"

  //Sounds
  const [playGeneralSound, { stop: stopGeneralSound }] = useSound(generalNotificationSound)
  const [playTrCall, { stop: stopTrCall, sound }] = useSound(trCall, { volume: 0.25 })

  useEffect(() => {
    if(props.notification?.eventType && !props.muteSound){
      switch(props.notification.eventType){
        case "incoming_call":
          if(sound){
            sound.loop(true) 
            playTrCall() 
            return () => stopTrCall()
          }
          break;
        default: 
          playGeneralSound()
          return () => stopGeneralSound()
      }  
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, playGeneralSound, playTrCall, sound])

  const handleCloseClick = (markAsRead) => {
    stopGeneralSound()
    stopTrCall()
    
    const msg = props.toast.current.state.messages.filter((x) => x.detail === props.toastId)

    if(msg.length > 0){
      props.toast.current.onClose(msg[0])
    }

    if(markAsRead && !props.systemNotification && !props.isDisabledParticipant) props.markNotificationRead(props.notificationId);


  }

  const actionButton = (label, actionUrl, padLeft) => {
    return(
      <div className={props.isDisabledParticipant ? "action-disabled-tt" : ""} data-pr-tooltip={
        props.isDisabledParticipant ? "Inactive participant" : ""
      }> 
        <Button 
          className={styles.actionButton + " p-button-outlined p-button-secondary"}  
          style={{marginLeft:padLeft}}
          label={label}
          onClick={() => {
            props.onHandleActionURL(
              actionUrl,
              props.notificationId,
              props.notification
            );
            handleCloseClick(false);
          }}
          disabled={props.isDisabledParticipant}
        />
      </div>
    )
  }

  const buildBody = () => {
    
    const primary = props.primaryActionLabel || "Primary action"
    const secondary = props.secondaryActionLabel || "Secondary action"

    return(
      <div>
        {props.title &&  
          <div className="mb-2">
            <b>{props.title}</b>
          </div>}
        {props.description}
        {(props.primaryActionUrl || props.secondaryActionUrl) && (
          <div style={{display:"flex"}}>
            <Tooltip target=".action-disabled-tt" />
            {props.primaryActionUrl && actionButton(primary, props.primaryActionUrl)}
            {props.secondaryActionUrl && actionButton(secondary, props.secondaryActionUrl, "10px")}              
        </div>
        )}
      </div>
    )
  }


  return (
    <div className={styles.gridContainer}>
      <div className={styles.gridItemIcon}>
        <span className="material-icons ">
          {icon}                      
        </span>
      </div>
      <div className={styles.gridItemMain}>
        {buildBody()}
      </div>
      <div className={styles.gridItemClose}>
          <span 
            className="material-icons  p-overlay-badge"
            style={{border:"none", background:"none", cursor:"pointer"}}
            onClick={() => handleCloseClick(false)}    >
              {"close"}          
        </span>
      
      </div>
      {!props.isInfo && !props.systemNotification && !props.isDisabledParticipant ? <div className={styles.gridItemCloseAndRead} >
        <span 
              className="material-icons  p-overlay-badge"
              style={{border:"none", background:"none", cursor:"pointer"}}
              onClick={() => handleCloseClick(true)}    >
                {"done_all"}            
          </span>  

      </div>
      : null}
    </div>
  );
}

NotificationsToastContent.propTypes = {
  toast: PropTypes.instanceOf(Object),
  systemNotification: PropTypes.bool,
  isError: PropTypes.bool, 
  toastId: PropTypes.number,
  notificationId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  primaryActionUrl: PropTypes.string,
  primaryActionLabel: PropTypes.string,
  secondaryActionUrl: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  onHandleActionURL: PropTypes.func,
  muteSound: PropTypes.bool
};

export default NotificationsToastContent;

import _ from "lodash"

export default function parseDatesInObj(obj) {
  const isDateRegex =
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/
  return _.reduce(
    obj,
    (acc, value, key) => {
      return {
        ...acc,
        [key]: isDateRegex.test(value) ? new Date(value) : value,
      }
    },
    {}
  )
}

import React from "react";
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo";

import styles from "../../styles/Page-with-Sidebar.module.css";

export default function Appointments() {
  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <h1>Calendar</h1>
      </div>
    </div>
  );
}

import { useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import _ from "lodash"
import axios from "axios"

// Context
import { UserContext } from "../../context/userContext"
import { FormContext } from "../../context/formContext"

// Helpers
import cloudFunctionsEndpoint from "../../config/cloudFunctionsEndpoint"

// Queries
import { GET_TEMPLATE } from "./queries"

// Comps
import FormNav from "./FormNav"
import FormContainer from "./FormContainer"
import FormSubmitBtn from "./FormSubmitBtn"
import FormSubmitting from "./FormSubmitting"
import { LoaderMedium } from "../../components/Loaders"

import styles from "../../styles/Form.module.css"

export default function FormBuilder() {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const { idTemplate, formPage, idStamp = null } = useParams()

  const [submitting, setSubmitting] = useState(false)
  const [err, setErr] = useState(false)

  const [blocks, setBlocks] = useState([])
  const [globalValues, setGlobalValues] = useState({})

  const [blocksRequired, setBlocksRequired] = useState([])
  const [blocksSaved, setBlocksSaved] = useState({})
  const [blocksEdited, setBlocksEdited] = useState({})

  const TYPE = "system"

  const EDIT_MODE = idStamp ? true : false

  const handleSubmit = async () => {
    const endpoint = cloudFunctionsEndpoint()
    try {
      setSubmitting(true)
      const postBody = {
        idTemplate,
        organization_alias: user.organization_alias,
        organization_id: user.organization_id,
        enterprise_id: user.enterprise_id,
        enterprise_alias: user.enterprise_alias,
        participant_alias: user.participant_alias || "not-set",
        participant_membership_id: user.participant_membership_id || "not-set",
        participant_id: user.participant_id,
        staff_alias: "1",
        staff_id: "0",
        values: globalValues,
      }

      /*Google Analytics tracking*/
      window.dataLayer.push({
        event: "template_finish",
        participant_id: user?.participant_id,
        template_name: data.template.template_title,
      })
      /*Google Analytics tracking*/

      if (EDIT_MODE) {
        postBody.idStamp = idStamp
        await axios.post(`${endpoint}/formEdit`, postBody)
      } else {
        await axios.post(`${endpoint}/formSubmit`, postBody)
      }
      return navigate("/")
    } catch (error) {
      /*Google Analytics tracking*/
      window.dataLayer.push({
        event: "exception",
        participant_id: user?.participant_id,
        template_name: data.template.template_title,
        error: error,
      })
      /*Google Analytics tracking*/
      setErr(true)
    }
    setSubmitting(false)
  }

  const handleSaveBlock = (blockId, values) => {
    setGlobalValues({
      ...globalValues,
      [blockId]: values,
    })
    setBlocksEdited({
      ...blocksEdited,
      [blockId]: false,
    })
    return setBlocksSaved({
      ...blocksSaved,
      [blockId]: true,
    })
  }

  const handleEditBlock = (blockId) => {
    setBlocksSaved({
      ...blocksSaved,
      [blockId]: false,
    })
    return setBlocksEdited({
      ...blocksEdited,
      [blockId]: true,
    })
  }

  const { loading, error, data } = useQuery(GET_TEMPLATE, {
    variables: {
      staff_id: 0,
      idTemplate,
      type: TYPE,
      participant_id: user.participant_id,
      organization_id: user.organization_id,
    },
    onCompleted: (data) => {
      /*Google Analytics tracking*/
      window.dataLayer.push({
        event: "template_begin",
        participant_id: user?.participant_id,
        template_name: data.template.template_title,
      })
      /*Google Analytics tracking*/

      const sortedBlocks = _.sortBy(data.template.blocks, ["index"])
      const initBlocksSaved = _.reduce(
        data.template.blocks,
        (acc, block) => {
          return {
            ...acc,
            [block.block_name]: false,
          }
        },
        {}
      )
      const initBlocksRequired = _.reduce(
        data.template.blocks,
        (acc, block) => {
          if (!block.system_required) return acc
          return [...acc, block.block_name]
        },
        []
      )
      setBlocksSaved(initBlocksSaved)
      setBlocksRequired(initBlocksRequired)
      setBlocks(sortedBlocks)
    },
  })

  if (loading) return <LoaderMedium />
  if (submitting) return <FormSubmitting />
  if (err) return <div>Err! please refresh....</div>

  const dataPropagation = data.template.values
    ? JSON.parse(data.template.values)
    : {}

  const formContextValue = {
    handleSaveBlock,
    handleEditBlock,
    handleSubmit,
    blocksRequired,
    blocksSaved,
    blocksEdited,
    idTemplate,
    type: TYPE,
    participant_id: user.participant_id,
    idStamp,
    editMode: EDIT_MODE,
    currentPage: Number(formPage),
    isLastBlock: data.template.total_blocks === Number(formPage),
    globalValues,
  }

  return (
    <FormContext.Provider value={formContextValue}>
      <h1>{data.template.template_title}</h1>
      <div>{data.template.template_description}</div>
      <div className={styles.formContainer}>
        <div className={styles.formNavSection}>
          <div className={styles.stickyWrapper}>
            <FormNav blocks={blocks} />
            <FormSubmitBtn />
          </div>
        </div>
        <FormContainer
          blocks={blocks}
          globalValues={globalValues}
          dataPropagation={dataPropagation}
        />
      </div>
    </FormContext.Provider>
  )
}

import { axiosPost, axiosPut } from "../../helpers/microserviceAPIs"
import { tasksUrl as api_url } from "../../config/microservicesUrls"

export async function taskApiGetAssignedTasksPOST(jwt, requestBody) {
  const url = api_url()
  return await axiosPost(jwt, `${url}/Tasks/Get/Assigned`, requestBody)
}

export async function taskApiGetAssignedTasksFromDatePOST(jwt, requestBody) {
  const url = api_url()
  return await axiosPost(
    jwt,
    `${url}/Tasks/Get/AssignedFromDate`,
    requestBody
  )
}

export async function taskApiMarkCompletePUT(jwt, taskId) {
  const url = api_url()
  const res = await axiosPut(jwt, `${url}/Tasks/MarkComplete/${taskId}`)

  if (res === null) {
    throw new Error(`Unable to mark task as complete (Id: ${taskId})`)
  }

  return true
}

import * as Yup from "yup"
import _ from "lodash"

// Validators
import validation_default from "./validation_default"
import validation_date from "./validation_date"
import validation_text from "./validation_text"
import validation_number from "./validation_number"
import validation_email from "./validation_email"
import validation_checkbox from "./validation_checkbox"

import validation_conditional_date from "./validation_conditional_date"
import validation_conditional_text from "./validation_conditional_text"
import validation_conditional_number from "./validation_conditional_number"
import validation_conditional_checkbox from "./validation_conditional_checkbox"
import validation_conditional_radio from "./validation_conditional_radio"
import validation_conditional_dropdown from "./validation_conditional_dropdown"

import validation_participant_email from "./validation_participant_email"
import validation_participant_password from "./validation_participant_password"

export default function validationSchema(block) {
  const yupObj = _.reduce(
    block.fields,
    (acc, field) => {
      const fieldValidation = field.field_validation || null
      const { field_type, field_name } = field

      const skipList = ["hidden", "sys", "service"]
      const skipValidation = _.includes(skipList, field_type)

      const typeSplit = _.split(field_name, "__")
      const fieldName = _.isEmpty(typeSplit[1]) ? field_name : typeSplit[1]

      switch (fieldName) {
        case "participant_email_account":
          return validation_participant_email(field, acc) 
        case "participant_account_password":
          return validation_participant_password(field, acc)                            
        default:
          break
      }

      if (skipValidation || !fieldValidation ) {
        return acc
      }

      switch (field_type) {
        case "date":
          return validation_date(field, acc)
        case "text":
          return validation_text(field, acc)
        case "number":
          return validation_number(field, acc)
        case "email":
          return validation_email(field, acc)
        case "checkbox":
          return validation_checkbox(field, acc)
        case "conditional_date":
          return validation_conditional_date(field, acc)
        case "conditional_text":
          return validation_conditional_text(field, acc)
        case "conditional_number":
          return validation_conditional_number(field, acc)
        case "conditional_checkbox":
          return validation_conditional_checkbox(field, acc)
        case "conditional_radio":  
          return validation_conditional_radio(field, acc)              
        case "conditional_dropdown":
        case "conditional_dropdown_us_states": 
          return validation_conditional_dropdown(field, acc)
        default:
          return validation_default(field, acc)
      }
    },
    {}
  )

  return Yup.object(yupObj)
}

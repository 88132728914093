import { useContext, useState } from "react"
import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import _ from "lodash"
import { Calendar } from "primereact/calendar"
import { UserContext } from "../../context/userContext"
// Comps
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

// Loader
import { LoaderMedium } from "../../components/Loaders"

// Queries
import { GET_FULL_BLOCKS_ORGANIZATION } from "./queries"

export default function RecoveryPlan() {
  const user = useContext(UserContext)
  const participant_id = user.participant_id
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useQuery(GET_FULL_BLOCKS_ORGANIZATION, {
    variables: {
      participant_id: participant_id,
      idTemplate: "recovery_plan_engagement",
      groupByIdStamp: true,
    },
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      if (data.getAllRichBlocksByParticipantIdGroupedOrganization === null) {
        return setLoading(false)
      }
      const dataFormatted = JSON.parse(
        data.getAllRichBlocksByParticipantIdGroupedOrganization.data
      )

      const handleData = () =>
        _.map(dataFormatted, (template) => {
          let newData
          const blocks = _.groupBy(template, "block_name")

          const session_recovery_plan = _.isEmpty(
            blocks?.session__recovery_plan
          )
            ? {}
            : blocks?.session__recovery_plan[0]
          const session = _.isEmpty(blocks?.session) ? {} : blocks?.session[0]
          const handleLocations = () => {
            const locationsList = _.map(
              session.values.session__location_ids,
              ({ label }) => label
            )
            return _.join(locationsList, ", ")
          }
          newData = {
            date: new Date(session_recovery_plan.values.date_created),
            date_modified:
              session.values.date_changed !== "not-set"
                ? new Date(session.values.date_changed)
                : new Date(session.values.date_created),
            organization: session.organization_name,
            staff: _.unescape(session_recovery_plan.values.staff_full_name),
            location: handleLocations(),
            view: (
              <Link
                to={`/participant/recovery-plan/view/${session.organization_id}/${session.staff_id}`}
                className="btn"
              >
                View
              </Link>
            ),
          }
          return newData
        })

      setData(_.compact(handleData()))

      setLoading(false)
    },
  })

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date)
  }

  const dateBodyTemplateModified = (rowData) => {
    return formatDate(rowData.date_modified)
  }

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  }

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    )
  }

  if (loading) return <LoaderMedium />

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <h1>Recovery Plan</h1>

        <div>
          <div>
            <br />
            <br />
            <DataTable
              value={data}
              responsiveLayout="scroll"
              sortField="organization"
              sortOrder={1}
            >
              <Column
                field="organization"
                header="Organization"
                filter={true}
                filterMatchMode="contains"
              />
              <Column
                field="location"
                header="Location"
                filter
                filterPlaceholder="Search by Location"
              />
              <Column
                field="date"
                sortable
                header="Date Plan Created"
                filterField="date"
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
                body={dateBodyTemplate}
              />
              <Column
                field="date_modified"
                sortable
                header="Date Plan Updated"
                filterField="date"
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
                body={dateBodyTemplateModified}
              />

              <Column field="staff" header="Updated By" />
              <Column field="view" header="View" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

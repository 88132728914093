import FieldBuilder from "./FieldBuilder"
import BlockActionBtns from "./BlockActionBtns"

import styles from "../../styles/Form.module.css"

export default function BlockBuilder({ block }) {
  return (
    <div key={block.block_name} className={styles.block}>
      <h2 className={styles.blockTitle}>{block.block_title}</h2>
      <FieldBuilder fields={block.fields} block={block.block_name} />
      <BlockActionBtns block={block.block_name} />
    </div>
  )
}

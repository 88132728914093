import * as Yup from "yup"
import _ from "lodash"

export default function validation_participant_password(field, acc) {
  const { field_name, field_validation } = field

   const isRequiredTxt =
    (field_validation.required && field_validation.required[1]) ||
     "Required field."

  return {
    [field_name]: Yup.string().when("participant__password_update", {
      is: (value) => {
        if(_.isArray(value))  {
          return _.find(value, function (v) {
            if (_.includes(value, 'Select to update password')) {
                return true
            }
        });  
        } else {
          return  value==='Select to update password'
        }
      }, 
      then: Yup.string().required(isRequiredTxt),
    }),
    [field_name]: Yup.string().when("participant__password_update", {
      is: (value) => {
        if(_.isArray(value))  {
          return _.find(value, function (v) {
            if (_.includes(value, 'Select to update password')) {
                return true
            }
        });  
        } else {
          return  value==='Select to update password'
        }
      }, 
      then: Yup.string().min(6, "Password must be at least 6 characters long.").required(isRequiredTxt),
    }),
    ...acc,
  }
}

import * as Yup from "yup"
import _ from "lodash"

export default function validation_participant_email(field, acc, validationType) {
  const { field_name, field_validation } = field

   const isRequiredTxt =
    (field_validation.required && field_validation.required[1]) ||
     "Required field."

  if(validationType==="template"){
    return {
      [field_name]: Yup.string().when("participant_email_confirm", {
        is: (value) => {
         
          return  value==='1'
        }, 
        then: Yup.string().email("Invalid E-mail format.").required(isRequiredTxt),
      }),
      ...acc,
    }
  } else {
    return {
      [field_name]: Yup.string().email("Invalid E-mail format.").required(isRequiredTxt),
      ...acc,
    }
  }  

}

import { useState, useEffect, useContext } from "react"
import _ from "lodash"

import { FormContext } from "../../context/formContext"

import * as Types from "../../components/FieldTypes"

import {
  MdExpandLess as IcoLess,
  MdExpandMore as IcoMore,
} from "react-icons/md"

import styles from "../../styles/Form.module.css"

export default function FieldBuilder({ fields, block }) {
  const form = useContext(FormContext)
  const [collapse, setCollapse] = useState(false)

  useEffect(() => {
    setCollapse(form.collapseAll)
  }, [form.collapseAll])

  const handleFields = () => {
    return _.map(fields, (field, index) => {
      const newProps = {
        type: "regular-field",
        block,
        field,
        index,
      }
      const isConditional = _.startsWith(field.field_type, "conditional")
      if (isConditional) {
        return <Types.TypeCondiontal {...newProps} key={field.field_name} />
      }

      switch (field.field_type) {
        case "hidden":
          return <Types.TypeHidden {...newProps} key={field.field_name} />
        case "sys":
          return <Types.TypeSys {...newProps} key={field.field_name} />
        case "checkbox":
          return <Types.TypeCheckbox {...newProps} key={field.field_name} />
        case "date":
          return <Types.TypeDate {...newProps} key={field.field_name} />
        case "dropdown":
          return <Types.TypeDropdown {...newProps} key={field.field_name} />
        case "dropdown_us_states":
          return (
            <Types.TypeDropdownUsState {...newProps} key={field.field_name} />
          )
        case "email":
          return <Types.TypeEmail {...newProps} key={field.field_name} />
        case "monthpicker":
          return <Types.TypeMonthpicker {...newProps} key={field.field_name} />
        case "multi_field":
          return <Types.TypeMultiField {...newProps} key={field.field_name} />
        case "text":
          return <Types.TypeText {...newProps} key={field.field_name} />
        case "textarea":
          return <Types.TypeTextarea {...newProps} key={field.field_name} />
        case "textarea_rich":
          return <Types.TypeTextareaRich {...newProps} key={field.field_name} />
        case "time":
          return <Types.TypeTime {...newProps} key={field.field_name} />
        case "number":
          return <Types.TypeNumber {...newProps} key={field.field_name} />
        case "password":
          return <Types.TypePassword {...newProps} key={field.field_name} />
        case "radio":
          return <Types.TypeRadio {...newProps} key={field.field_name} />
        case "phone":
          return <Types.TypeTel {...newProps} key={field.field_name} />
        case "url":
          return <Types.TypeUrl {...newProps} key={field.field_name} />
        case "zipcode":
          return <Types.TypeZipcode {...newProps} key={field.field_name} />
        default:
          return null
      }
    })
  }
  return (
    <div
      className={collapse ? styles.fieldsWrapperCollapse : styles.fieldsWrapper}
    >
      <span onClick={() => setCollapse(!collapse)} className={styles.toogle}>
        {collapse ? <IcoMore /> : <IcoLess />}
      </span>
      <div style={{ display: collapse ? "none" : "block" }}>
        {handleFields()}
      </div>
    </div>
  )
}

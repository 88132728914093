import { gql } from "@apollo/client"

export const ENGAGEMENTS = gql`
  query Engagements(
    $staff_id: ID
    $idTemplate: ID!
    $participant_membership_id: ID
  ) {
    engagements: blocksSavedByIdTemplate(
      staff_id: $staff_id
      idTemplate: $idTemplate
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const ENGAGEMENTS_PARTICIPANT = gql`
  query EngagementsParticipant(
    $participant_id: ID!
    $organization_id: ID!
    $idTemplate: ID!
    $participant_membership_id: ID
  ) {
    engagementsParticipant: blocksSavedByParticipantIdTemplate(
      participant_id: $participant_id
      organization_id: $organization_id
      idTemplate: $idTemplate
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const TEMPLATE_STATS = gql`
  query templateStats($participant_membership_id: ID, $staff_id: String) {
    stats: getSpecificBlocksStatsByMembership(
      participant_membership_id: $participant_membership_id
      blocks: ["session", "session__recovery_plan", "session__group_session"]
    ) {
      data
    }
    engagements: getSpecificBlocksByMembership(
      participant_membership_id: $participant_membership_id
      blocks: ["session", "session__recovery_plan", "session__group_session"]
    ) {
      data
    }
    templateNames: templates(type: "system", staff_id: $staff_id) {
      template_name
      template_title
    }
  }
`
export const ENGAGEMENTS_PARTICIPANT_GROUP_BY_TEMPLATE = gql`
  query EngagementsParticipant(
    $participant_id: ID!
    $idTemplates: [ID]
    $idBlocks: [ID]
    $participant_membership_id: ID
  ) {
    engagementsParticipant: getAllParticipantBlocksGroupByIdTemplatePortal(
      participant_id: $participant_id
      idTemplates: $idTemplates
      idBlocks: $idBlocks
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const GET_ENGAGED_SERVICES_BY_PARTICIPANT = gql`
  query EngagedServices($participant_id: ID!) {
    participantProfile: getSpecificBlockByParticipant(
      participant_id: $participant_id
      idBlock: "participant__engaged_services"
    ) {
      values
      blocks {
        block_title
        block_name
        total_fields
        index
      }
    }
  }
`

export const GET_ENGAGED_SERVICES_BY_PARTICIPANT_ID = gql`
  query EngagementsParticipant(
    $participant_id: ID!
    $idTemplates: [ID]
    $idBlocks: [ID]
  ) {
    engagementsParticipant: getAllParticipantEngagedServicesByParticipantId(
      participant_id: $participant_id
      idTemplates: $idTemplates
      idBlocks: $idBlocks
      groupByIdStamp: true
    ) {
      data
      __typename
    }
  }
`

export const GET_SERVICES_EPISODES_BY_PARTICIPANT_ID = gql`
  query EngagementsParticipant($participant_id: ID!) {
    getServiceEpisodesParticipantPortal(participant_id: $participant_id) {
      start_date
      end_date
      episode_number
      membership_id
      assigned_staff_ids
      organization_id
      location_id
      membership_status
      organization_name
      location_name
      staff_name
      staff_id
      organization_id
      enterprise_id
      episode_assigned_staff_ids
      staff_email_address
      staff_phone_number
    }
  }
`

export const GET_ORGANIZATION_USERS = gql`
  query Users($staff_id: ID!, $organization_id: ID!) {
    users(staff_id: $staff_id, organization_id: $organization_id) {
      staff_id
      staff_email_address
      enterprise_id
      organization_id
      staff_phone_number
      staff_role
    }
  }
`

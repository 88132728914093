import { useParams, useNavigate, generatePath } from "react-router-dom"
import { useContext } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"

// Context
import { FormContext } from "../../context/formContext"

import styles from "../../styles/Nav-Blocks.module.css"

export default function BlockActionBtns({ block }) {
  const formik = useFormikContext()
  const form = useContext(FormContext)
  const navigate = useNavigate()

  const isDirty = formik.dirty
  const isValid = formik.isValid

  const { idTemplate, formPage } = useParams()

  const handleNextPageJump = () => {
    form.handleSaveBlock(block, formik.values)
    const nextPage = () => {
      if (form.editMode) {
        return generatePath(`/form/${idTemplate}/:formPage/${form.idStamp}`, {
          formPage: Number(formPage) + 1,
        })
      }
      return generatePath(`/form/${idTemplate}/:formPage/`, {
        formPage: Number(formPage) + 1,
      })
    }
    return navigate(nextPage(), { replace: true })
  }

  const handleActionBtn = () => {
    const showEdit = form.blocksSaved[block]
    if (showEdit) {
      return (
        <button
          type="button"
          className={styles.edit}
          onClick={() => form.handleEditBlock(block)}
        >
          Edit
        </button>
      )
    }
    return (
      <button
        type="button"
        className={styles.save}
        disabled={!isValid}
        onClick={() => form.handleSaveBlock(block, formik.values)}
      >
        Save
      </button>
    )
  }

  const handleNextBlock = () => {
    if (!form.isLastBlock) {
      return (
        <button
          type="button"
          onClick={() => handleNextPageJump()}
          disabled={isDirty && !isValid}
          className={styles.next}
        >
          Next Block
        </button>
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      {handleActionBtn()}
      {handleNextBlock()}
    </div>
  )
}

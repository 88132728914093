import { useEffect } from "react"
import { useLocation } from "react-router-dom"

// Hooks
import useLogs from "../hooks/useLogs"

export default function PermitionDenied() {
  const location = useLocation()
  const [logsEnable, writeLog] = useLogs()

  useEffect(() => {
    const logger = async () => {
      if (logsEnable) {
        return await writeLog({
          type: "warning",
          component: location.state.path,
          message: `Path denied for User: ${location.state.staff_id}`,
          stack: "",
        })
      }
    }
    logger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <h1>Permission Denied</h1>
    </div>
  )
}

import { useContext, useEffect, useState } from "react"
import { Field, useFormikContext } from "formik"

import { FormContext } from "../../context/formContext"
import { Dropdown } from "primereact/dropdown"

import styles from "../../styles/Form.module.css"

import ConsentDialog from "./helpers/ConsentDialog"

export default function TypeConsentDropdown({ field, block, index }) {
  const form = useContext(FormContext)
  const isDisabled = form ? form.blocksSaved[block] : false
  const { values } = useFormikContext()

  const [dialog, setDialog] = useState(false)
  const [firstMount, setFirstMount] = useState(true)

  useEffect(() => {
    if (values.consent_data_store) {
      if (values.consent_data_store === "No" && !firstMount) {
        setDialog(true)
      }
    }
    setFirstMount(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.consent_data_store])

  const dialogProps = {
    dialog,
    setDialog,
  }
  return (
    <>
      <ConsentDialog {...dialogProps} />
      <div className={styles.field} style={{ order: index }}>
        <label className={styles.label}>{field.field_label}</label>
        {field.field_description && (
          <div className={styles.description}>{field.field_description}</div>
        )}
        <Field name={field.field_name}>
          {({ field: formikField, meta }) => (
            <>
              <Dropdown
                placeholder="Please select"
                id={field.field_name}
                name={formikField.name}
                value={formikField.value}
                onChange={formikField.onChange}
                disabled={isDisabled}
                options={field.field_options}
              />
              {meta.touched && meta.error && (
                <div className={styles.errFieldMsg}>{meta.error}</div>
              )}
            </>
          )}
        </Field>
      </div>
    </>
  )
}

//styling
import { Panel } from "primereact/panel"
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import AssessmentFormGroup from "./AssessmentFormGroup"

export default function AssessmentFormMatrix({
  matrix,
  answers,
  readOnly,
  onSetFieldValue,
  displayAnswers,
  displayAnswerValues,
  isCompletedDisplay,
  displayScore,
}) {
  return (
    <Panel
      header={matrix.name}
      toggleable
      className={assessStyles.matrixContainer}
    >
      {matrix.groups.map((group) => {
        return (
          <AssessmentFormGroup
            group={group}
            answers={answers}
            onSetFieldValue={onSetFieldValue}
            key={group.id}
            readOnly={readOnly}
            displayAnswers={displayAnswers}
            displayAnswerValues={displayAnswerValues}
            displayScore={displayScore}
          />
        )
      })}
    </Panel>
  )
}

import { useEffect, useState, useContext } from "react"
import { DateTime } from "luxon"

//Notifications modules
import {
  noteApiListSystemNotificationsPOST,
  noteApiMarkSystemAsReadPut,
  noteApiMarkAllSystemAsReadPut,
  noteApiMarkSystemAsUnreadPut,
  noteApiListEventTypesGet,
} from "../notificationsApi"
import NotificationsFilters from "./NotificationsFilters"

//styles
import noteStyle from "../../../styles/NotificationsForm.module.css"

// Context
import { UserContext } from "../../../context/userContext"

//Helpers
import { LogDebug } from "../../../helpers/logger"

//prime react
import { Accordion, AccordionTab } from "primereact/accordion"
import { ProgressSpinner } from "primereact/progressspinner"
import { Paginator } from "primereact/paginator"
import { confirmDialog } from "primereact/confirmdialog"
import { Tooltip } from "primereact/tooltip"

export default function NotificationsSystemTab(props) {
  const user = useContext(UserContext)
  const moment = require("moment")

  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState(null)
  const [eventTypes, setEventTypes] = useState(null)
  const [refresh, setRefresh] = useState(new Date().getTime() - 10000)

  // Filters //
  const defaultFilters = {
    search: "",
    eventType: "All",
    createDateStart: DateTime.fromISO(user.participant_create_date).toJSDate(),
    createDateEnd: "",
    readDateStart: "",
    readDateEnd: "",
    sort: "newest",
    includeRead: false,
    includeUnread: true,
  }

  const [filters, setFilters] = useState(defaultFilters)
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters)

  function combineReadAndUnreadFilters() {
    const { includeRead, includeUnread } = appliedFilters

    if (includeRead && includeUnread) return null
    else if (includeRead && !includeUnread) return true
    else return false
  }

  async function getNotifications(page, currentPageSize) {
    setLoading(true)
    try {
      const res = await noteApiListSystemNotificationsPOST(
        user.tokenNotification,
        user,
        combineReadAndUnreadFilters(),
        appliedFilters.eventType,
        appliedFilters.createDateStart,
        appliedFilters.createDateEnd,
        appliedFilters.readDateStart,
        appliedFilters.readDateEnd,
        appliedFilters.search,
        appliedFilters.sort,
        page || 1,
        currentPageSize || pageSize,
        props.organizations.map((x) => x.organization_id)
      )

      if (res) {
        setNotifications(res)
      }
      LogDebug("System Notifications", res)
    } catch (error) {
      console.error("Unable to list system notifications", error)
      props.onShowError(
        "Unable to list system notifications. See console for details."
      )
    }
    setLoading(false)
  }

  async function getEventTypes() {
    setLoading(true)
    try {
      const res = await noteApiListEventTypesGet(user.tokenNotification)

      if (res) {
        setEventTypes(res)
      }
      LogDebug("EventTypes", res)
    } catch (error) {
      console.error("Unable to list event types", error)
      props.onShowError("Unable to list event types. See console for details.")
    }
    setLoading(false)
  }

  useEffect(() => {
    if (notifications) {
      getNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (notifications !== null) {
      getNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters])

  useEffect(() => {
    if (!notifications) {
      getNotifications()
    } else if (!eventTypes) {
      getEventTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, eventTypes])

  async function markAsRead(notificationId) {
    setLoading(true)

    try {
      await noteApiMarkSystemAsReadPut(
        user.tokenNotification,
        notificationId,
        user.participant_id
      )
      props.onRefreshBellCounter(new Date().getTime())
      getNotifications()
      LogDebug(`Notification marked as read (ID: ${notificationId})`)
    } catch (error) {
      console.error(
        `Unable to mark notification as read (id: ${notificationId})`,
        error
      )
      props.onShowError(
        `Unable to mark notification as read (id: ${notificationId}). See console for details.`
      )
    }
    setLoading(false)
  }

  async function markAllAsRead() {
    if (!!notifications) {
      setLoading(true)

      await noteApiMarkAllSystemAsReadPut(user.tokenNotification, user)

      props.onRefreshBellCounter(new Date().getTime())
      setNotifications(null)
      getNotifications()

      setLoading(false)
    }
  }

  async function markAsUnread(notificationId) {
    setLoading(true)

    try {
      await noteApiMarkSystemAsUnreadPut(
        user.tokenNotification,
        notificationId,
        user.participant_id
      )
      props.onRefreshBellCounter(new Date().getTime())
      getNotifications()
      LogDebug(`Notification marked as unread (ID: ${notificationId})`)
    } catch (error) {
      console.error(
        `Unable to mark notification as unread (id: ${notificationId})`,
        error
      )
      props.onShowError(
        `Unable to mark notification as unread (id: ${notificationId}). See console for details.`
      )
    }
    setLoading(false)
  }

  const getOrganization = (organization_id) => {
    if (organization_id !== "0") {
      const org = props.organizations.filter(
        (x) => x.organization_id === organization_id
      )

      if (org.length > 0) {
        return org[0].organization_name
      } else {
        return "Unknown organization"
      }
    } else {
      return ""
    }
  }

  const itemHeader = (data) => {
    return (
      <div className={noteStyle.noteHeaderGridContainer}>
        <Tooltip target=".header-disabled-tt" />
        <div className={noteStyle.noteHeaderGridItemTitle}>
          {data.highPriority ? (
            <span
              className={`material-icons md-18 ${noteStyle.noteHighImportant}`}
            >
              priority_high
            </span>
          ) : null}
          {data.title}
        </div>
        <div className={noteStyle.noteHeaderGridItemCreation}>
          {moment.utc(data.createdAt).local().format("L LT")}
        </div>
        <div className={noteStyle.noteHeaderGridItemDescr}>
          {data.description}
        </div>
        <div className={noteStyle.noteHeaderGridItemOrg}>
          {getOrganization(data.groupId)}
        </div>
        <div
          className={`${noteStyle.noteHeaderGridItemRead} ${
            props.isDisabledParticipant ? "header-disabled-tt" : ""
          }`}
          data-pr-tooltip={
            props.isDisabledParticipant ? "Inactive participant" : ""
          }
        >
          <button
            type="button"
            className="btn"
            style={{
              width: 40,
              height: 33,
              float: "right",
              padding: "4px 9px",
            }}
            onClick={(e) => {
              e.stopPropagation()
              if (!data.read) {
                markAsRead(data.id)
              } else {
                markAsUnread(data.id)
              }
            }}
            disabled={props.isDisabledParticipant}
          >
            <span
              className={`material-icons  p-overlay-badge ${noteStyle.NoteMarkRead}`}
            >
              {data.read ? "remove_done" : "done_all"}
            </span>
          </button>
        </div>
      </div>
    )
  }

  const filterHeader = () => {
    return (
      <div className={noteStyle.noteFilterHeaderGridContainer}>
        <Tooltip target=".filterHeader-disabled-tt" />
        <div className={noteStyle.noteFilterHeader}>
          <label>Filters</label>
        </div>
        <div className={noteStyle.noteFilterButton}>
          <button
            type="button"
            className="btn"
            style={{
              float: "right",
              width: 40,
              height: 33,
              display: "flex",
              padding: "4px 9px",
            }}
            onClick={(e) => {
              e.stopPropagation()
              setRefresh(new Date().getTime())
            }}
          >
            <span
              className={`material-icons  p-overlay-badge ${noteStyle.NoteMarkRead}`}
            >
              {"autorenew"}
            </span>
          </button>
          <div
            className={
              props.isDisabledParticipant ? "filterHeader-disabled-tt" : ""
            }
            data-pr-tooltip={
              props.isDisabledParticipant ? "Inactive participant" : ""
            }
          >
            <button
              type="button"
              className="btn t-tip"
              data-pr-tooltip="Mark All Notifications As Read"
              style={{
                float: "right",
                width: 40,
                height: 33,
                display: "flex",
                padding: "4px 9px",
                marginRight: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation()
                confirmDialog({
                  message:
                    "Do you want to mark all unread notifications as read?",
                  header: "Mark all as read",
                  accept: () => markAllAsRead(),
                })
              }}
              disabled={props.isDisabledParticipant}
            >
              <span
                className={`material-icons  p-overlay-badge ${noteStyle.NoteMarkRead}`}
              >
                {"done_all"}
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const onPageChange = (event) => {
    if (pageSize !== event.rows) {
      setPageSize(event.rows)
    }

    getNotifications(event.page + 1, event.rows)
  }

  const itemTemplate = (data) => {
    return (
      <AccordionTab
        header={itemHeader(data)}
        headerStyle={{ width: "100%", marginTop: "10px" }}
        key={data.id}
      >
        <div className={noteStyle.noteBodyGridContainer}>
          <div className={noteStyle.noteBodyGridItemMod}>
            <span className={noteStyle.noteBodyLabel}>Last modified:</span>{" "}
            {moment.utc(data.modifiedAt).local().format("L LT")}
          </div>
        </div>
      </AccordionTab>
    )
  }

  return (
    <div>
      <div className={noteStyle.addLargeSpace}>
        <Accordion className={noteStyle.noteAccordion}>
          <AccordionTab header={filterHeader()}>
            <NotificationsFilters
              defaultFilters={defaultFilters}
              filters={filters}
              onSetFilters={setFilters}
              onApplyFilters={setAppliedFilters}
              eventTypes={eventTypes}
              organizations={props.organizations}
            />
          </AccordionTab>
        </Accordion>
      </div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          {notifications && notifications.items.length > 0 ? (
            <>
              <Accordion
                className={noteStyle.noteAccordion}
                activeIndex={-1}
                onTabChange={(e) => e.originalEvent.stopPropagation()}
                expandIcon=""
              >
                {notifications
                  ? notifications.items.map((x) => {
                      return itemTemplate(x)
                    })
                  : null}
              </Accordion>

              <Paginator
                first={notifications.pageSize * (notifications.pageNum - 1)}
                rows={notifications.pageSize}
                totalRecords={notifications.numTotal}
                onPageChange={onPageChange}
                rowsPerPageOptions={[5, 10, 20, 30]}
              />
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              No system notifications found
            </div>
          )}
        </div>
      )}
    </div>
  )
}

import React, { useState, useEffect, useContext } from "react"

import { Formik, Form } from "formik"
import _ from "lodash"
// Context
import { UserContext } from "../../context/userContext"

//GraphQL
import { useLazyQuery, useQuery } from "@apollo/client"
import { GET_USERS_FROM_LIST, GET_AUTHORIZED_USER } from "./query"

//Referal modules
import {
  RefApiRefEntityNotesListGet,
  refApiRefEntityNotePut,
} from "./ReferralsAPI"
import { getLocalDateTimeFromIso, getNotePayload } from "./sharedFunctions"

//Style
import styles from "../../styles/Form-Inputs.module.css"
import refStyles from "../../styles/Referrals.module.css"
import refNoteStyles from "../../styles/ReferralNotes.module.css"
import loaderStyles from "../../styles/Loader.module.css"

//PrimeReact
import { Card } from "primereact/card"
import { Dialog } from "primereact/dialog"
import RefTextAreaInput from "./RefTextAreaInput"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"

export default function ReferralNotes(props) {
  const user = useContext(UserContext)

  const referralId = props.referralId
  const referralEntityId = !!props.referralEntityId
    ? props.referralEntityId
    : undefined

  const referralCreatorUserId = props.referralCreatorStaffId || ""
  const participantId = props.referralParticipantId || ""
  const referralOrganization = props.referralOrganizationID || ""

  const [errorMessage, setErrorMessage] = useState("")
  const [notes, setNotes] = useState([])
  const [createNewNote, setCreateNewNote] = useState(false)
  const [listUsers, setListUsers] = useState([
    {
      id: user.participant_id,
      staff_id: undefined,
      participant_id: user.participant_id,
      alias: user.participant_alias,
      status: "",
      email: user.participant_email_account,
      name2: `${user.participant_name_first} ${user.participant_name_last}`,
      userRole: user.staff_role_name,
    },
  ])
  const [isLoading, setIsLoading] = useState(true)

  function logError(ex) {
    console.log(ex)

    if (ex.isAxiosError) {
      if (ex.response !== undefined) {
        setErrorMessage(
          "An error ocurred while retrieving the referral notes: " +
            ex.response.data
        )
        console.error(ex)
      } else {
        setErrorMessage("Unable to load partner status: " + ex.message)
      }
    } else {
      setErrorMessage(ex)
    }
  }

  const [getParticipant] = useLazyQuery(GET_AUTHORIZED_USER, {
    fetchPolicy: "cache-and-network",
  })

  const { refetch: checkUserCache } = useQuery(GET_USERS_FROM_LIST, {
    fetchPolicy: "cache-and-network",
    skip: true,
    onError: (e) => {
      console.log(e)
      setErrorMessage(
        "Unable to retrieve user information, please refer to the console for more information"
      )
      setIsLoading(false)
    },
    onCompleted: async (data) => {
      if (data.users !== null) {
        let newUsers = data.users
          .filter((x) => x !== null)
          .map((x) => {
            return {
              id: x.staff_id,
              staff_id: x.staff_id,
              participant_id: undefined,
              alias: x.staff_alias,
              status: x.staff_user_status,
              email: x.staff_email_address,
              name2: `${x.staff_name_first} ${x.staff_name_last}`,
              userRole: x.staff_role_name,
            }
          })

        let missingUsers = notes.filter(
          (note) => !data.users.some((user) => user?.userId === note.userId)
        )

        if (missingUsers.length > 0) {
          //If there are still missing users, look for them in the participant query
          missingUsers = [...new Set(missingUsers.map((usr) => usr.userId))]

          let participants = await Promise.all(
            missingUsers.map(async (usr) => {
              return await getParticipant({
                variables: { participant_id: usr },
              })
            })
          )

          participants = participants.filter((result) => !!result.data?.user)

          newUsers = [
            ...newUsers,
            ...participants.map((result) => {
              const participant = result.data.user
              return {
                id: participant.participant_id,
                staff_id: null,
                participant_id: participant.participant_id,
                alias: participant.participant_alias,
                status: "",
                email: participant.participant_email_account,
                name2: `${_.unescape(
                  participant.participant_name_first
                )} ${_.unescape(participant.participant_name_last)}`,
                userRole: participant.staff_role_name,
              }
            }),
          ]
        }

        setListUsers([...listUsers, ...newUsers])
      }
      setIsLoading(false)
    },
  })

  useEffect(() => {
    setIsLoading(true)
    RefApiRefEntityNotesListGet(user.token, referralId)
      .then((result) => {
        try {
          if (result.length > 0) {
            if (result[0].referralId === null) {
              setNotes([])
              setIsLoading(false)
            } else {
              setNotes(result)
              checkUserCache({ list: result.map((note) => note.userId) })
            }
          } else {
            setNotes(result)
            setIsLoading(false)
          }
        } catch (ex) {
          setNotes([])
          logError(exports)
        }
      })
      .catch((ex) => {
        setNotes([])
        logError(ex)
        setIsLoading(false)
      })
  }, [checkUserCache, referralId, user.token])

  function getUser(id) {
    if (listUsers) {
      const usr = listUsers?.filter((x) => x.id === id)

      if (usr.length > 0) {
        return usr[0]
      } else {
        const listUsr = listUsers?.filter((x) => x.staff_id === id)

        if (listUsr.length > 0) {
          return listUsr[0]
        } else {
          return null
        }
      }
    } else {
      return null
    }
  }

  function getNoteUserDisplay(note) {
    let userDisplay = undefined
    let profileEmail = undefined
    let profileName = undefined

    if (!!note.notePayload) {
      try {
        let payload = JSON.parse(note.notePayload)
        profileEmail = payload.email
        profileName = payload.displayName
      } catch {
        userDisplay = undefined
      }
    }

    if (!profileName) {
      let userProfile = getUser(note.userId)
      if (!!userProfile) {
        profileEmail = userProfile.email
        profileName = userProfile.name2
      }
    }

    userDisplay = !!profileName
      ? `${profileName} [${profileEmail}]`
      : "Unknown user" + (!!profileEmail ? ` [${profileEmail}]` : "")

    return userDisplay
  }

  function GetNote(note) {
    let entityStatus = undefined
    let referralStatus = undefined
    let bodyText = note.note

    const getStatusText = (oldStatus, newStatus) => {
      return `${!!oldStatus ? oldStatus : "<Undefined>"} => ${
        !!newStatus ? newStatus : "<Undefined>"
      }`
    }

    if (!!note.referralStatusOld || !!note.referralStatusNew) {
      referralStatus = getStatusText(
        note.referralStatusOld,
        note.referralStatusNew
      )
    }

    if (!!note.referralEntityStatusOld || !!note.referralEntityStatusNew) {
      entityStatus = getStatusText(
        note.referralEntityStatusOld,
        note.referralEntityStatusNew
      )
    } else if (
      !!bodyText &&
      bodyText.startsWith("[Status update: ") &&
      bodyText.includes("] : ")
    ) {
      bodyText = note.note.replace("[Status update: ", "")
      entityStatus = bodyText.substring(0, bodyText.indexOf("] : "))
      bodyText = bodyText.substring(bodyText.indexOf("] : ") + 4)
    }

    return (
      <div className={refNoteStyles.noteGridContainer} key={note.creationDate}>
        <div className={refNoteStyles.noteGridItemAuthor}>
          <span className={refNoteStyles.noteEntryLabel}>Author:</span>
          {getNoteUserDisplay(note)}
        </div>
        <div className={refNoteStyles.noteGridItemCreated}>
          <span className={refNoteStyles.noteEntryLabel}>Created: </span>
          {getLocalDateTimeFromIso(note.creationDate)}
        </div>
        {bodyText !== "" ? (
          <div className={refNoteStyles.noteGridItemNote}>
            <span className={refNoteStyles.noteEntryLabel}>Note Summary: </span>
            {bodyText}
          </div>
        ) : null}
        {!!referralStatus ? (
          <div className={refNoteStyles.noteGridItemRefStatus}>
            <span className={refNoteStyles.noteEntryLabel}>
              Referral status update:{" "}
            </span>
            {referralStatus}
          </div>
        ) : null}
        {!!entityStatus ? (
          <div className={refNoteStyles.noteGridItemEntityStatus}>
            <span className={refNoteStyles.noteEntryLabel}>
              Referral partner status update:{" "}
            </span>
            {entityStatus}
          </div>
        ) : null}
      </div>
    )
  }

  function handleSubmit(values) {
    refApiRefEntityNotePut(user.token, referralId, values)
      .then((result) => {
        let creationDate = new Date()
        values.creationDate = creationDate.toISOString()
        values.userId = user.participant_id
        notes.unshift(values)
        setCreateNewNote(false)
      })
      .catch((ex) => {
        logError(ex)
      })
  }

  if (isLoading) {
    return (
      <div className={loaderStyles.wrapperMed}>
        <ProgressSpinner />
      </div>
    )
  }

  return (
    <>
      <Dialog
        header={"New Referral note"}
        visible={createNewNote}
        onHide={() => {
          setCreateNewNote(false)
        }}
        style={{ width: "30vw", height: "350px" }}
      >
        <Formik
          initialValues={{
            userId: user.participant_id,
            note: "",
            referralId: referralId,
            referralEntityId: referralEntityId,
            notePayload: getNotePayload(user),
            referralCreatorUserId,
            participantId,
            organizationID: referralOrganization,
          }}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <div className="p-fluid" style={{ height: "200px" }}>
                <RefTextAreaInput
                  name="note"
                  placeholderText="Note"
                  style={{ bottom: "0" }}
                />
              </div>

              <div className={refStyles.footerNoBg}>
                <div
                  className={
                    refStyles.footerNoBg + " " + refStyles.buttonContainerFull
                  }
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    type="submit"
                    disabled={!dirty}
                    style={{ marginRight: "0.5rem", width: "115px" }}
                    className={refStyles.button}
                  />
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    type="button"
                    className={refStyles.button}
                    onClick={() => {
                      setCreateNewNote(false)
                    }}
                    style={{ width: "115px" }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
      <div>
        <Button
          label="New Note"
          type="Button"
          onClick={() => setCreateNewNote(true)}
          className={refStyles.button}
          style={{ float: "right", marginBottom: "10px" }}
        />
      </div>
      <div>
        {errorMessage !== "" ? (
          <label className={styles.errFieldMsg}>{errorMessage}</label>
        ) : null}
      </div>
      <div>
        {notes?.length > 0 ? (
          notes?.map((note) => GetNote(note))
        ) : (
          <Card>No notes available</Card>
        )}
      </div>
    </>
  )
}

import React, { useContext } from "react"
// Context
import { UserContext } from "../../context/userContext"

import styles from "../../styles/Page-with-Sidebar.module.css"


export default function DemoPage() {
  const user = useContext(UserContext)

  //print context variables
  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>

      </div>
      <div className={styles.content}>
        <h1>Demo Page</h1>
        <p>
          <strong>
            Check the file src/pages/DemoPage/index.js to get started on getting
            React's context variables and a GraphQL query example.
          </strong>
        </p>

        <h3>Context variables:</h3>
        <p>
          <strong>User ID</strong>: {user.participant_id}
        </p>
        <p>
          <strong>User</strong>: {user.staff_name_first} {user.name_last}
        </p>
        <p>
          <strong>User Status</strong>: {user.status}
        </p>
        <p>
          <strong>Role id</strong>: {user.staff_role}
        </p>
        <p>
          <strong>Role name</strong>: {user.staff_role_name}
        </p>
        <p>
          <strong>Enterprise ID</strong>: {user.enterprise_id}
        </p>
        <p>
          <strong>Organization ID</strong>: {user.organization_id}
        </p>
      </div>
    </div>
  )
}

import { useEffect, useState } from "react"

import { TabView, TabPanel } from "primereact/tabview"

import NotificationSettingsTab from "./NotificationSettingsTab"
import NotificationsTab from "./NotificationsTab"
import NotificationsSystemTab from "./NotificationsSystemTab"

export default function NotificationsForm(props) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (props.ActiveKey) {
      switch (props.ActiveKey) {
        case "":
        case "notifications":
          setSelectedIndex(0)
          break
        case "sys-notifications":
          setSelectedIndex(1)
          props.onResetActiveKey()
          break
        case "mute":
          setSelectedIndex(2)
          props.onResetActiveKey()
          break
        default:
          setSelectedIndex(0)
          break
      }
    }
  }, [props.ActiveKey])

  return (
    <TabView
      activeIndex={selectedIndex}
      onTabChange={(e) => setSelectedIndex(e.index)}
    >
      <TabPanel header="My notifications">
        <NotificationsTab
          organizations={props.organizations}
          onShowError={props.onShowError}
          onHandleActionURL={props.onHandleActionURL}
          onRefreshBellCounter={props.onRefreshBellCounter}
          isDisabledParticipant={props.isDisabledParticipant}
        />
      </TabPanel>
      <TabPanel header="System notifications">
        <NotificationsSystemTab
          organizations={props.organizations}
          onShowError={props.onShowError}
          onRefreshBellCounter={props.onRefreshBellCounter}
          isDisabledParticipant={props.isDisabledParticipant}
        />
      </TabPanel>
      <TabPanel header="Manage notifications">
        <NotificationSettingsTab
          onSetMutedNotifications={(x) => {
            props.onSetMutedNotifications(x)
          }}
          isDisabledParticipant={props.isDisabledParticipant}
        />
      </TabPanel>
    </TabView>
  )
}

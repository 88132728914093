import React, { useState, useContext } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { useQuery } from "@apollo/client"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import { Link } from "react-router-dom"
import { UserContext } from "../../context/userContext"
import _ from "lodash"
import { DateTime } from "luxon"
import { Dropdown } from "primereact/dropdown"

import { LoaderMedium } from "../../components/Loaders"

import { ENGAGEMENTS_PARTICIPANT_GROUP_BY_CAPITAL_TEMPLATE } from "./queries"

export default function RecoveryCapital() {
  const user = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [typesFilter, setTypesFilter] = useState(null)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [locationFilter, setLocationFilter] = useState(null)

  useQuery(ENGAGEMENTS_PARTICIPANT_GROUP_BY_CAPITAL_TEMPLATE, {
    variables: {
      participant_id: user.participant_id,
    },
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      if (
        data?.recoveryCapital?.data === null ||
        data?.templateNames === null
      ) {
        return setLoading(false)
      }
      const templateNames = data.templateNames
      const dataFormatted = data.recoveryCapitalParticipant
        ? JSON.parse(data?.recoveryCapitalParticipant?.data)
        : {}
      const sessionBlocks = _.filter(dataFormatted, ["block_name", "session"])
      const recoveryCapitalBlocks = _.filter(dataFormatted, [
        "block_name",
        "session__assessment_recovery_capital",
      ])

      const orderdedData = _.orderBy(
        recoveryCapitalBlocks,
        ["values.session_date"],
        ["desc"]
      )
      _.remove(orderdedData, ["participant_membership_id", "not-set"])
      const recoveryCapitalData = _.map(orderdedData, (row) => {
        const score = (answer) => {
          const total = answer["assessment_score"]
            ? answer["assessment_score"]
            : "-"
          return total
        }

        const templateName = _.find(templateNames, {
          template_name: row.idTemplate,
        })

        const handleLocations = () => {
          const sessionBlock = _.find(sessionBlocks, ["idStamp", row.idStamp])
          if (!_.isEmpty(sessionBlock)) {
            const locationId = sessionBlock.values.session__location_ids || []
            const locationsList = _.map(locationId, ({ label }) => label)
            return _.join(locationsList, ", ")
          }
          return ""
        }

        return {
          organization: row.organization_name,
          date: DateTime.fromISO(row.values.session_date).toISODate(),
          type: templateName.template_title,
          score: score(row.values),
          trend: true,
          staff: _.unescape(row.staff_full_name),
          locations: handleLocations(),
          idTemplate: row.idTemplate,
          view: (
            <Link
              to={`/form/view/${row.idTemplate}/${row.idStamp}`}
              className="btn"
            >
              View
            </Link>
          ),
        }
      })

      const allTypes = _.map(recoveryCapitalData, ({ type }) => type)
      setTypesFilter(_.uniq(allTypes).sort())

      const allLocations = _.map(
        recoveryCapitalData,
        ({ locations }) => locations
      )
      setLocationFilter(_.uniq(allLocations).sort())

      const allOrganizations = _.map(
        recoveryCapitalData,
        ({ organization }) => organization
      )
      setOrganizationFilter(_.uniq(allOrganizations).sort())

      setData(recoveryCapitalData)

      setLoading(false)
    },
  })

  const libraryRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={typesFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Type"
        className="p-column-filter"
        showClear
      />
    )
  }

  const locationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={locationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Location"
        className="p-column-filter"
        showClear
      />
    )
  }
  const organizationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={organizationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select an Organization"
        className="p-column-filter"
        showClear
      />
    )
  }

  if (loading) return <LoaderMedium />

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <h1>Recovery Capital</h1>

        <div>
          <div>
            <br />
            <br />
            <DataTable
              value={data}
              responsiveLayout="scroll"
              sortField="organization"
              sortOrder={1}
            >
              <Column
                field="organization"
                header="Organization"
                filter
                filterElement={organizationsFilter}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
              />
              <Column
                field="locations"
                header="Location"
                filter
                filterElement={locationsFilter}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
              />

              <Column
                field="type"
                header="Assesment Type"
                filter
                sortable
                filterElement={libraryRowFilterTemplate}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
              />
              <Column field="date" header="Assesment Date" />

              <Column field="score" header="Assesment Score" />

              <Column field="staff" header="Performing User" />

              <Column field="view" header="View" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"

export default function TypeConditionalTextAreaRich({ value, description }) {
  return (
    <div>
      <strong>{description} </strong>
      {value}{" "}
    </div>
  )
}

import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Formik, useField } from "formik"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

//graphQL
import { useLazyQuery, useQuery } from "@apollo/client"
import { REFERRAL_BY_IDSTAMP, REFERRAL_EXTRA_DETAIL } from "./query"

// Styles
import styles from "../../styles/Page-with-Sidebar.module.css"
import refStyles from "../../styles/Referrals.module.css"

//Referal modules
import { RefApiRefEntityStatusGet, RefApiRefRatingGet } from "./ReferralsAPI"
import RefTextInput from "./RefTextInput"
import { TabPanel, TabView } from "primereact/tabview"
import ReferralNotes from "./ReferralNotes"
import { getLocalDateTimeFromIso } from "./sharedFunctions"
import ReferralRating from "./ReferralRating"
import ReferralParticipantNote from "./ReferralParticipantNote"
import ReferralStatusPopup from "./ReferralStatusPopup"

// Prime React
import { Button } from "primereact/button"
import { Editor as InputTextareaRich } from "primereact/editor"

// Context
import { UserContext } from "../../context/userContext"

//Routes
import ROUTES from "../../components/Routes/routesContants"
import { LogError } from "../../helpers/logger"

export default function ReferralsDetail() {
  const { id } = useParams()
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState("")
  const [referral, setReferral] = useState()
  const [refStatus, setRefStatus] = useState()
  const [refRating, setRefRating] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingRefStatus, setIsLoadingRefStatus] = useState(true)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  //ReferralStatusUpdate
  const [showRefStatusUpdate, setShowRefStatusUpdate] = useState(false)

  const successfulReferralStatus = "Completed referral"
  const incompleteStatus = "Incomplete referral"

  const [getReferralColumns] = useLazyQuery(REFERRAL_EXTRA_DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      organization_id: "",
    },
  })

  useQuery(REFERRAL_BY_IDSTAMP, {
    variables: { idStamp: id },
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      try {
        if (result.referrals !== null) {
          const referralsParsed = JSON.parse(result.referrals.data)

          let referralDetail = undefined
          let referralSession = undefined
          if (referralsParsed.length > 0) {
            referralDetail = referralsParsed.find(
              (block) => block.block_name === "referral"
            )
            referralSession = referralsParsed.find(
              (block) => block.block_name === "session"
            )

            if (!!referralSession) {
              const sessionDate = DateTime.fromISO(
                referralSession.values.session_date
              )
                .toLocal()
                .toLocaleString()

              referralSession.values.session_date_local = sessionDate
            }
          }

          const referral_extra = await getReferralColumns({
            variables: {
              organization_id: referralDetail.organization_id,
            },
          })

          if (!referral_extra.data.referral_block) {
            throw new Error("Unable to retrieve referral columns")
          }

          const refEntity = { status: "loading..." }

          if (referralDetail !== undefined) {
            //Fix dates
            referralDetail.values.referral_appointment_date =
              getLocalDateTimeFromIso(
                referralDetail.values.referral_appointment_date,
                "MM/dd/yyyy"
              )
            referralDetail.values.referral_appointment_time =
              getLocalDateTimeFromIso(
                referralDetail.values.referral_appointment_time,
                "HH:mm"
              )

            setReferral({
              referral: referralDetail,
              referral_session: referralSession,
              refEntity: refEntity,
              referral_block: referral_extra.data.referral_block,
            })

            let refPromises = []

            refPromises.push(
              RefApiRefEntityStatusGet(user.token, id)
                .then((result) => {
                  setRefStatus(result)
                })
                .catch((ex) => {
                  console.log(ex)

                  if (ex.isAxiosError && ex.response !== undefined) {
                    setErrorMessage(ex.response.data)
                  } else {
                    setErrorMessage("Referral API: " + ex.message)
                  }
                })
            )

            refPromises.push(
              RefApiRefRatingGet(user.token, id)
                .then((result) => {
                  if (result.id === 0) {
                    result.participantId = referralDetail.participant_id
                    result.referralEntityId =
                      referralDetail.values.referral_entity_id
                    result.referralId = id

                    result.isSatisfactionRating =
                      referralDetail.values.referral_status ===
                      successfulReferralStatus
                  }

                  setRefRating(result)
                })
                .catch((ex) => {
                  LogError("Unable to retrieve Referral rating", ex)

                  if (ex.isAxiosError && ex.response !== undefined) {
                    setErrorMessage(ex.response.data)
                  } else {
                    setErrorMessage("Referral API: " + ex.message)
                  }
                })
            )

            //Wait for the Referral API calls to complete before continuing
            await Promise.all(refPromises).then((result) => {
              setIsInitialLoad(false)
              setIsLoading(false)
            })
          } else {
            setReferral(null)
            setIsInitialLoad(false)
            setIsLoading(false)
          }
        } else {
          setReferral(null)
          setIsInitialLoad(false)
          setIsLoading(false)
        }
      } catch (ex) {
        setErrorMessage(ex)
        setIsInitialLoad(false)
        setIsLoading(false)
      }
    },
    onError: (error) => {
      setErrorMessage(error.message)
      setIsInitialLoad(false)
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (
      referral !== undefined &&
      refStatus !== undefined &&
      isLoadingRefStatus
    ) {
      setIsLoadingRefStatus(false)
      if (refStatus.referralId !== null) {
        referral.refEntity.status = refStatus.referralEntityStatus
      } else {
        referral.refEntity.status = "Pending"
      }
    }
  }, [referral, refStatus, isLoadingRefStatus])

  function NotesInput() {
    const [field] = useField("referral.values.referral_notes")

    if (isLoading || isInitialLoad) {
      return null
    }

    return (
      <div className={styles.field} style={{ marginBottom: "10px" }}>
        <div>Referal notes</div>
        <div className="col-12 md:col-10">
          <InputTextareaRich
            name="referral.values.referral_notes"
            value={field.value}
            readOnly
            showHeader={false}
            className={"block"}
            style={{ height: "280px" }}
          />
        </div>
      </div>
    )
  }

  function getReferralDetail(values) {
    let excludeFields = [
      "referral_status",
      "referral_type",
      "referral_type_other",
      "referral_entity_name",
      "referral_entity_id",
      "referral_reason",
      "referral_participants_notes",
      "referral_notified_name",
      "referral_notified_email",
      "referral_notified_phone",
    ]

    const GetField = ({
      fieldPath,
      fieldName,
      fieldLabel,
      ignoreExclusion,
    }) => {
      if (
        !ignoreExclusion &&
        excludeFields.some((field) => field === fieldName)
      ) {
        return null
      }

      if (!!values) {
        const field = values.referral_block.fields.filter(
          (field) => field.idField === fieldName
        )

        if (field.length > 0 && field[0].field_type !== "hidden") {
          let label =
            field[0].field_label !== "" ? field[0].field_label : fieldLabel

          //Replace Referral entity (case insensitive) with Referral partner
          label = label.replace(/Referral entity/i, "Referral partner")

          return (
            <RefTextInput
              name={fieldPath}
              label={label}
              placeholderText=""
              isInitialLoad={isInitialLoad}
              isLoading={isLoading}
              disabled={true}
            />
          )
        } else {
          return null
        }
      } else {
        return (
          <RefTextInput
            name={fieldName}
            label={fieldLabel}
            placeholderText=""
            isInitialLoad={isInitialLoad}
            isLoading={isLoading}
            disabled={true}
          />
        )
      }
    }

    return (
      <TabPanel header="Referral detail" className="p-fluid">
        <RefTextInput
          name="refEntity.status"
          label="Referral partner status"
          placeholderText="Referral partner status"
          isInitialLoad={isInitialLoad}
          disabled={true}
        />

        <div
          style={{
            display: "flex",
            flex: "row",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <GetField
              fieldPath="referral.values.referral_status"
              fieldName="referral_status"
              fieldLabel="Referral partner status"
              ignoreExclusion={true}
            />
          </div>

          {!isInitialLoad ? (
            <div style={{ width: "160px", marginLeft: "10px" }}>
              <Button
                label="Update status"
                type="button"
                className={refStyles.button}
                style={{ top: "24px", marginRight: "20px" }}
                onClick={() => {
                  setShowRefStatusUpdate(true)
                }}
                loading={isLoading || isInitialLoad}
              />
            </div>
          ) : null}
        </div>

        <RefTextInput
          name="referral_session.values.session_date_local"
          label="Referral date"
          placeholderText=""
          isInitialLoad={isInitialLoad}
          disabled={true}
        />

        <GetField
          fieldPath="referral.values.referral_type"
          fieldName="referral_type"
          fieldLabel="Referral type"
          ignoreExclusion={true}
        />

        <GetField
          fieldPath="referral.values.referral_type_other"
          fieldName="referral_type_other"
          fieldLabel="Referral type other"
          ignoreExclusion={true}
        />
        <GetField
          fieldPath="referral.values.referral_reason"
          fieldName="referral_reason"
          fieldLabel="Referral reason"
          ignoreExclusion={true}
        />
        <GetField
          fieldPath="referral.values.referral_entity_name"
          fieldName="referral_entity_name"
          fieldLabel="Referral Partner"
          ignoreExclusion={true}
        />

        <NotesInput />
        {values?.referral_block.fields.map((field) => (
          <GetField
            fieldPath={`referral.values.${field.idField}`}
            fieldName={field.idField}
            fieldLabel={field.field_label}
            key={field.idField}
          />
        ))}
      </TabPanel>
    )
  }

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      <div className="main-content">
        {!!refRating &&
        successfulReferralStatus ===
          referral?.referral.values.referral_status ? (
          <ReferralRating rating={refRating} />
        ) : null}
        {referral?.referral.values.referral_status === incompleteStatus ? (
          <ReferralParticipantNote referral={referral} />
        ) : null}

        <h1>Referral detail</h1>
        <div>
          {errorMessage !== "" ? (
            <label className={styles.errFieldMsg}>{errorMessage}</label>
          ) : null}
        </div>
        {referral ? (
          <>
            <Formik
              initialValues={referral}
              enableReinitialize
              onSubmit={() => {}}
            >
              {({ values }) => (
                <>
                  <ReferralStatusPopup
                    showForm={showRefStatusUpdate}
                    onHideForm={() => {
                      setShowRefStatusUpdate(false)
                    }}
                    onStatusChanged={(newStatus) => {
                      setShowRefStatusUpdate(false)

                      let newRef = { ...referral }
                      newRef.referral.values.referral_status = newStatus
                      setReferral(newRef)
                    }}
                    referralIdStamp={referral?.referral.idStamp}
                    referralOrganizationId={referral?.referral.organization_id}
                    referralStatus={referral?.referral.values.referral_status}
                    referralEntityId={
                      referral?.referral.values.referral_entity_id
                    }
                    referralParticipantsNotes={
                      referral?.referral.values.referral_participants_notes
                    }
                  />
                  <TabView>
                    {getReferralDetail(values)}
                    <TabPanel header={"Notes"}>
                      {referral === undefined ? null : (
                        <ReferralNotes
                          referralId={id}
                          referralEntityId={
                            referral.referral.values.referral_entity_id
                          }
                          loadAllMessages={true}
                          referralCreatorStaffId={referral?.referral?.staff_id || ""}
                          referralParticipantId={referral?.referral?.participant_id || ""}
                          referralOrganizationID={referral?.referral?.organization_id || ""}
                        />
                      )}
                    </TabPanel>
                  </TabView>
                </>
              )}
            </Formik>
          </>
        ) : (
          <Formik>
            <TabView>{getReferralDetail(undefined)}</TabView>
          </Formik>
        )}
        <div style={{ bottom: "0px", zIndex: "2", width: "100%" }}>
          <div>
            <Button
              label="Back"
              icon="pi pi-multiply"
              type="button"
              onClick={() => {
                navigate(ROUTES.PARTICIPANT_REFERRALS)
              }}
              loading={isLoading || isInitialLoad}
              disabled={isLoading || isInitialLoad}
              style={{
                width: "115px",
                display: "flex",
                flexDirection: "row",
                background: "var(--branded-purple)",
                float: "right",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

import { Calendar } from "primereact/calendar"
import { useField } from "formik"

import { Skeleton } from "primereact/skeleton"

//Style
import styles from "../../styles/Form-Inputs.module.css"

export default function FormikCalendar({
  name,
  label,
  placeholderText,
  helpText,
  disabled,
  required,
  isInitialLoad,
  minDate,
  maxDate,
  onChangeCallBack,
  showTime,
  timeOnly,
  hourFormat,
}) {
  const [field, meta, helpers] = useField(name)

  const getCalendar = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <>
          <Calendar
            value={!!field.value ? new Date(field.value) : undefined}
            name={name}
            disabled={disabled}
            onChange={(e) => {
              helpers.setValue(e.value)
              if (!!onChangeCallBack) {
                onChangeCallBack(e.value)
              }
            }}
            className={meta.touched && meta.error ? "p-invalid" : undefined}
            style={{ width: "100%" }}
            minDate={minDate}
            maxDate={maxDate}
            showTime={showTime}
            hourFormat={hourFormat || "12"}
            placeholder={placeholderText}
            timeOnly={timeOnly}
          />
          {meta.touched && meta.error ? (
            <label id={`${name}-error`} className={styles.errFieldMsg}>
              {meta.error}
            </label>
          ) : null}
        </>
      )
    }
  }

  const labelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={styles.field}>
      <label>{labelField()}</label>
      <div>
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {getCalendar()}
      </div>
    </div>
  )
}

import { DateTime } from "luxon"
import { useEffect } from "react"
import { useFormikContext } from "formik"

import styles from "../../../styles/Form.module.css"

export default function SessionLength({ field, index }) {
  const formik = useFormikContext()

  const startTime = formik.values.session_start_time
  const endTime = formik.values.session_end_time

  useEffect(() => {
    if (startTime && endTime) {
      const end = DateTime.fromJSDate(endTime) || 0
      const start = DateTime.fromJSDate(startTime) || 0
      const diffInMinutes = end.diff(start, "minutes").toObject()
      return formik.setFieldValue(
        field.field_name,
        diffInMinutes.minutes?.toFixed(0)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime])

  return (
    <div className={styles.hidden} style={{ order: index }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

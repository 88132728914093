import _ from "lodash"
import { useContext } from "react"
import { Route, Routes, Navigate, useLocation } from "react-router-dom"

// Comps
import Layout from "../../layout"
import routesDef from "./routesDef"
import { UserContext } from "../../context/userContext"

function RequireAdmin({ leastPrivilege, children }) {
  const user = useContext(UserContext)
  const location = useLocation()
  const notAllow = user.staff_role !== leastPrivilege

  if (notAllow) {
    const state = {
      staff_id: user.participant_id,
      path: location.pathname,
    }
    return <Navigate to="/denied" state={state} replace />
  } 
  return children
}

export default function RoutesBuilder() {
  function handleRoutes() {
    const routeList = _.map(routesDef, (route, i) => {
      const { requireAdmin, leastPrivilege } = route
      const element = requireAdmin ? (
        <RequireAdmin leastPrivilege={leastPrivilege}>
          {route.element}
        </RequireAdmin>
      ) : (
        route.element
      )
      return <Route key={i} path={route.path} element={element} />
    })
    return <Routes>{routeList}</Routes>
  }

  return <Layout>{handleRoutes()}</Layout>
}

import { DateTime } from "luxon"
import ReactQuill from "react-quill"

//Tasks modules
import {
  taskApiGetAssignedTasksPOST,
  taskApiGetAssignedTasksFromDatePOST,
  taskApiMarkCompletePUT,
} from "../notifications/TasksApi"

//Styles
import plannerTaskStyle from "../../styles/dailyPlanner/DailyPlannerTasks.module.css"

//helpers
import { displayLocalDateTimeFromIso } from "../../helpers/displayDates"
import { LogDebug, LogError } from "../../helpers/logger"

//PrimeReact
import { Accordion, AccordionTab } from "primereact/accordion"

export function getTasks(
  user,
  onSetTaskCounter,
  onSetNewTasks,
  onSetTasksToday
) {
  //Get tasks assigned to me
  const todayLocal = DateTime.now
  const endOfToday = DateTime.local(
    todayLocal.year,
    todayLocal.month,
    todayLocal.day,
    23,
    59,
    59,
    999
  )
    .toUTC()
    .toISO()
  taskApiGetAssignedTasksPOST(user.tokenTask, {
    groupId: user.organization_id,
    userId: user.participant_id,
    pageNum: 1,
    pageSize: 100,
    status: "pending",
    dueOn: endOfToday,
  })
    .then((result) => {
      onSetTaskCounter(result.numTotal)
      onSetTasksToday(result.items)
    })
    .catch((error) => {
      LogError("An unexpected error occurred while retrieving tasks", error)
      user.setNotificationError(error.Message)
    })

  //Retrieve new tasks
  taskApiGetAssignedTasksFromDatePOST(user.tokenTask, {
    groupId: user.organization_id,
    userId: user.participant_id,
    pageNum: 1,
    pageSize: 100,
    status: "pending",
  })
    .then((result) => {
      onSetNewTasks(result.items)
    })
    .catch((error) => {
      LogError("An unexpected error occurred while retrieving new tasks", error)
      user.setNotificationError(error.Message)
    })
}

export function GetNewTaskPanel(task, user, setIsLoadingStatus, refreshTasks) {
  let thisTask = task

  const baseClassName = plannerTaskStyle.taskGridContainer
  const overdue =
    DateTime.fromISO(thisTask?.dueOn, {
      zone: "utc",
    }).toLocal() < DateTime.local()
      ? plannerTaskStyle.taskGridOverDue
      : ""

  return (
    <div className={`${baseClassName} ${overdue}`} id={thisTask.id}>
      <div className={plannerTaskStyle.taskGridItemTitle}>
        <span className={plannerTaskStyle.taskGridDescription}>Title: </span>{" "}
        {thisTask.title}
      </div>
      <div className={plannerTaskStyle.taskGridItemDue}>
        <span className={plannerTaskStyle.taskGridDescription}>Item due: </span>{" "}
        {displayLocalDateTimeFromIso(thisTask.dueOn)}
      </div>
    </div>
  )
}

export function GetTaskPanel(task, user, setIsLoadingStatus, refreshTasks) {
  let thisTask = task

  const baseClassName = plannerTaskStyle.taskGridContainer
  const overdue =
    DateTime.fromISO(thisTask?.dueOn, {
      zone: "utc",
    }).toLocal() < DateTime.local()
      ? plannerTaskStyle.taskGridOverDue
      : ""

  return (
    <div className={`${baseClassName} ${overdue}`} id={thisTask.id}>
      <div className={plannerTaskStyle.taskGridItemTitle}>
        <span className={plannerTaskStyle.taskGridDescription}>Title: </span>{" "}
        {thisTask.title}
      </div>
      <div className={plannerTaskStyle.taskGridItemDue}>
        <span className={plannerTaskStyle.taskGridDescription}>Item due: </span>{" "}
        {displayLocalDateTimeFromIso(thisTask.dueOn)}
      </div>
      <div className={plannerTaskStyle.taskGridItemDone}>
        <button
          type="button"
          className="btn"
          style={{
            float: "right",
            marginRight: 3,
            width: 40,
            height: 33,
            padding: "4px 9px",
          }}
          onClick={(e) => {
            markTaskAsDone(
              thisTask.id,
              user.tokenTask,
              setIsLoadingStatus,
              refreshTasks,
              user.setNotificationError
            )
          }}
        >
          <span className={`material-icons`}> {"done"} </span>
        </button>
      </div>
      {!!thisTask.description ? (
        <div className={plannerTaskStyle.taskGridItemNotes}>
          <Accordion style={{ width: "100%" }}>
            <AccordionTab
              header="Notes"
              headerStyle={{ padding: 0, width: "100%" }}
            >
              <ReactQuill
                theme="bubble"
                value={thisTask.description}
                readOnly={true}
                style={{ wordBreak: "break-all" }}
              />
            </AccordionTab>
          </Accordion>
        </div>
      ) : null}
      {getActionButton(
        thisTask.primaryActionLabel,
        thisTask.primaryActionUrl,
        thisTask.primaryActionPayload,
        plannerTaskStyle.taskGridItemAction1,
        thisTask,
        plannerTaskStyle.taskActionButton,
        user.setNotificationAction
      )}
      {getActionButton(
        thisTask.secondaryActionLabel,
        thisTask.secondaryActionUrl,
        thisTask.secondaryActionPayload,
        plannerTaskStyle.taskGridItemAction2,
        thisTask,
        plannerTaskStyle.taskAction2Button,
        user.setNotificationAction
      )}
    </div>
  )
}

function getActionButton(
  label,
  url,
  payload,
  gridClass,
  task,
  actionButtonStyle,
  setContextAction
) {
  return (
    <>
      {url ? (
        <div className={gridClass}>
          <button
            className={`btn ${actionButtonStyle}`}
            onClick={() => {
              handleAction(url, payload, task, setContextAction)
            }}
          >
            {label}
          </button>
        </div>
      ) : null}
    </>
  )
}

function handleAction(actionUrl, payload, task, setContextAction) {
  switch (actionUrl) {
    case "#assigned-tasks":
    case "#created-tasks-pending":
      setContextAction({ actionUrl: actionUrl })
      break
    default:
      window.location.href = actionUrl
  }
}

async function markTaskAsDone(
  taskId,
  taskToken,
  setIsLoadingStatus,
  refreshTasks,
  showError
) {
  setIsLoadingStatus(true)
  try {
    await taskApiMarkCompletePUT(taskToken, taskId)
    refreshTasks()
    LogDebug(`Task marked as complete (ID: ${taskId})`)
  } catch (error) {
    const errorMsg = `Unable to mark task as complete (id: ${taskId})`
    console.error(errorMsg, error)

    if (!!showError) {
      showError(`Unable to mark task as complete:  ${error.message}`)
    }
  }
  setIsLoadingStatus(false)
}

export function headerButtonAction(user) {
  handleAction(
    "#assigned-tasks",
    undefined,
    undefined,
    user.setNotificationAction
  )
}

import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import { FirebaseContext } from "../../firebase"
import _ from "lodash"

import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

// Hooks
import useLogs from "../../hooks/useLogs"

// assets
import logo from "../../assets/images/logo-white-text.png"

export default function Login({ history }) {
  const firebase = useContext(FirebaseContext)
  const [logsEnable, writeLog] = useLogs()

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [err, setErr] = useState(null)
  const [showLogin, setShowLogin] = useState(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const userGet = await firebase.signIn(user, password)
      if (logsEnable) {
        await writeLog({
          type: "info",
          component: "Auth",
          message: `User logged in: ${userGet.user.participant_id}`,
          stack: "",
        })
      }
    } catch (error) {
      setErr(error.message)
    }
  }

  const handleSubmitRecover = async (e) => {
    e.preventDefault()
    try {
      await firebase.sendPasswordResetEmail(user)
      if (logsEnable) {
        await writeLog({
          type: "info",
          component: "Auth",
          message: `User resets password: ${user}`,
          stack: "",
        })
      }
    } catch (error) {
      setErr(error.message)
    }
  }

  return (
    <div className="layout-login">
      <img src={logo} alt="RecoveryLink Logo" className="main-logo" />
      {showLogin && (
        <div className="login-container">
          <h2>Login</h2>
          {err && <div className="form-section">{err}</div>}
          <form onSubmit={handleSubmit} className="form-section">
            <div className="form-field">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                onChange={({ target: { value } }) => setUser(_.trim(value))}
              />
            </div>
            <div className="form-field">
              <label htmlFor="pass">Password</label>
              <InputText
                id="pass"
                type="password"
                onChange={({ target: { value } }) => setPassword(value)}
              />
            </div>
            <Button label="Login" type="submit" className="btn" />
          </form>
          <button
            className="forgot-password-link"
            onClick={() => setShowLogin(false)}
          >
            Forgot your password?
          </button>
        </div>
      )}
      {!showLogin && (
        <div className="login-container">
          <h2>Resent your password</h2>
          {err && <div className="form-section">{err}</div>}
          <form onSubmit={handleSubmitRecover} className="form-section">
            <div className="form-field">
              <label htmlFor="email">
                Enter your email to receive a link to reset your password
              </label>
              <InputText
                id="email"
                onChange={({ target: { value } }) => setUser(_.trim(value))}
              />
            </div>
            <Button label="SEND LINK" type="submit" className="btn" />
          </form>
          <button className="btn" onClick={() => setShowLogin(true)}>
            BACK TO LOGIN
          </button>
        </div>
      )}
      <small className="recovery-link">RecoveryLink</small>
    </div>
  )
}

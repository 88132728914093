import _ from "lodash"
import * as Types from "../../index"

import {
  MdRemoveCircleOutline as IcoRm,
  MdAddCircleOutline as IcoPlus,
} from "react-icons/md"

import styles from "../../../../styles/Form-NestedMB.module.css"

export default function NestedMFBuilder(props) {
  const {
    arrayHelpers,
    fields,
    mfDefinition,
    mfFieldName,
    mfIndex,
    nestedMfFielName,
    nestedMfIndex,
    mfLength,
  } = props

  const handleMFTypes = () =>
    _.map(fields, (field) => {
      const newProps = {
        type: "nested-multi-field",
        field,
        mfFieldName,
        nestedMfFielName,
        mfIndex,
        nestedMfIndex,
      }
      switch (field.field_type) {
        case "text":
          return <Types.TypeText {...newProps} key={field.field_name} />
        case "radio":
          return <Types.TypeRadio {...newProps} key={field.field_name} />
        default:
          return null
      }
    })
  return (
    <div className={styles.blockNested}>
      {handleMFTypes()}
      <div className={styles.actions}>
        <IcoPlus
          onClick={() => arrayHelpers.insert(nestedMfIndex, mfDefinition)}
        />
        {mfLength > 1 && (
          <IcoRm onClick={() => arrayHelpers.remove(nestedMfIndex)} />
        )}
      </div>
    </div>
  )
}

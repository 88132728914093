import { axiosPost, axiosGet } from "../../helpers/microserviceAPIs"
import { documentSigningApiUrl } from "../../config/microservicesUrls"

const api_url = documentSigningApiUrl()

export async function docSigningApiSignatureRequestsDownloadGet(
  jwt,
  signatureRequestId
) {
  return await axiosGet(
    jwt,
    `${api_url}/api/SignatureRequests/Download/${signatureRequestId}`
  )
}

export async function docSigningApiSignURLGet(jwt, signatureRequestId) {
  return await axiosGet(
    jwt,
    `${api_url}/api/SignatureRequests/Sign/${signatureRequestId}`
  )
}

export async function docSigningApiSignatureRequestsListPost(jwt, body) {
  return await axiosPost(jwt, `${api_url}/api/SignatureRequests/List`, body)
}

export async function docSigningApiHelloSignConfigGet(jwt) {
  return await axiosGet(jwt, `${api_url}/api/Configuration/HelloSign`)
}

export async function docSigningApiRequestGet(jwt, signatureRequestId) {
  return await axiosGet(
    jwt,
    `${api_url}/api/SignatureRequests/${signatureRequestId}`
  )
}

import { noteApiListNotificationsSinceLastQueryPOST } from "../notifications/notificationsApi"

import { LogError } from "../../helpers/logger"

import styles from "../../styles/dailyPlanner/DailyPlannerNotifications.module.css"
import {
  anyNotificationHasTags,
  notificationHasTags,
  replaceNotificationTags,
} from "../notifications/notificationsTags"

export function getNotifications(
  user,
  onSetNewNotifications,
  getNotificationUsers
) {
  noteApiListNotificationsSinceLastQueryPOST(user, 1, 100)
    .then(async (result) => {
      if (anyNotificationHasTags(result.items)) {
        const useSearchResult = await getNotificationUsers()
        let participantList = [
          {
            participant_id: user.participant_id,
            participant_first_name: user.participant_first_name,
            participant_last_name: user.participant_last_name,
          },
        ]
        let staffList = useSearchResult.data?.staff || []

        result.items = await Promise.all(
          result.items.map(async (notification) =>
            notificationHasTags(notification)
              ? await replaceNotificationTags(
                  notification,
                  participantList,
                  undefined,
                  undefined,
                  staffList,
                  undefined
                )
              : notification
          )
        )
      }

      onSetNewNotifications(result.items)
    })
    .catch((error) => {
      LogError("An unexpected error occurred while retrieving tasks", error)
      user.setNotificationError(error.Message)
    })
}

const getOrganization = (organizations, organization_id) => {
  if (organization_id !== "0") {
    const org = organizations.filter(
      (x) => x.organization_id === organization_id
    )

    if (org.length > 0) {
      return org[0].organization_name
    } else {
      return "Unknown organization"
    }
  } else {
    return ""
  }
}

export function GetNewNotificationsPanel(notification, organizations) {
  return (
    <div className={styles.defaultGridContainer}>
      <div className={styles.gridItemTitle}>
        <span className={styles.notificationHeader}>Title: </span>{" "}
        {notification.title}
      </div>
      <div className={styles.gridItemOrganization}>
        <span className={styles.notificationHeader}>Organization: </span>{" "}
        {getOrganization(organizations, notification.groupId)}
      </div>
      <div className={styles.gridItemDescription}>
        <span className={styles.notificationHeader}>Body: </span>{" "}
        {notification.description}
      </div>
    </div>
  )
}

export function notificationHeaderButtonAction(user) {
  user.setNotificationAction({ actionUrl: "#ShowNotifications" })
}

import { Link } from "react-router-dom"
import React, { useState, useContext, useEffect, useRef } from "react"

import { UserContext } from "../context/userContext"
import { FirebaseContext } from "../firebase"

import { gql, useQuery } from "@apollo/client"

// Navs
import NavHeader from "../nav/Nav-Header"
import NavSecondary from "../nav/Nav-Secondary"

// assets
import logo from "../assets/images/logo-white-text.png"
import Appointments from "../components/Appointments/Appointments"
import profileDummy from "../assets/images/circle-user-icon-blue-hd-png-download.png"
import _ from "lodash"

// styles
import styles from "../styles/Layout.module.css"
import Notifications from "../components/notifications/notifications"
import DailyPlanner from "../components/DailyPlanner/DailyPlanner"
import DeveloperTools from "../components/DeveloperTools/DeveloperTools"

import ProfilePhoto from "../components/ProfilePhoto"

export default function Header() {
  const user = useContext(UserContext)
  const [profileToogle, setProfileToogle] = useState(false)
  const firebase = useContext(FirebaseContext)

  const [organizationName, setOrganizationName] = useState(null)

  let profileMenuRef = useRef()

  const GET_ORGANIZATION = gql`
    query organization($staff_id: ID!, $organization_id: ID!) {
      organization: organization(
        staff_id: $staff_id
        organization_id: $organization_id
      ) {
        organization_name
      }
    }
  `

  useEffect(() => {
    // setProfileToggle
    let profileHandler = (e) => {
      if (!profileMenuRef.current.contains(e.target)) {
        setProfileToogle(false)
      }
    }
    document.addEventListener("mousedown", profileHandler)
    // clean event listener
    return () => {
      document.removeEventListener("mousedown", profileHandler)
    }
  })

  useQuery(GET_ORGANIZATION, {
    variables: {
      organization_id: user.organization_id,
      staff_id: "100",
    },
    fetchPolicy: "no-cache",
    //skip: !user.staff_id,
    onCompleted: async (data) => {
      if (data.organization === null) {
        // setData(null)
        // return setLoading(false)
      }
      setOrganizationName(data.organization.organization_name)
    },
  })

  return (
    <header>
      <div className="wrapper">
        <img src={logo} alt="s" className="logo" />
        <ul className="nav-top">
          <DeveloperTools />
          <DailyPlanner />
          <Appointments />
          <Notifications />
          <li className="top-icon">
            <a
              href="https://help.myrecoverylink.com/ "
              target="_blank"
              rel="noreferrer"
            >
              <span className="material-icons top-icon-span">help</span>
              <label className="top-menu-label">Support</label>
            </a>
          </li>
        </ul>
        <div className="profile-menu" ref={profileMenuRef}>
          <div
            className="profile-menu-trigger"
            id="profile-menu-trigger"
            onClick={() => setProfileToogle(!profileToogle)}
          >
            <ProfilePhoto classes="border-2 border-white rounded-full w-11 h-11 aaa" />
            <div className="summary">
              <span>
                {user.participant_name_first} {user.participant_name_last}
              </span>

              <span>{!_.isNull(organizationName) ? organizationName : ""}</span>
            </div>
          </div>

          <ul
            id="top-main-menu"
            className={
              profileToogle
                ? "top-menu-dropdown-profile active"
                : "top-menu-dropdown-profile"
            }
          >
            <li className="top-dropdown-option">
              <Link to="/participant/account">
                <span className="material-icons"> dashboard </span>
                <label>My Account </label>
              </Link>
            </li>

            <li className="top-dropdown-option">
              <Link to="/participant/profile">
                <span className="material-icons"> label </span>
                <label>My Profile</label>
              </Link>
            </li>

            <div className="divider dropdown"></div>
            <li
              className="top-dropdown-option logout"
              onClick={() => {
                firebase.signOut()
              }}
            >
              <span className="material-icons"> power_settings_new </span>
              <label>Logout</label>
            </li>
          </ul>

          <p></p>
        </div>
      </div>
    </header>
  )
}

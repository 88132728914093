import { useCallback, useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"

// Context
import { UserContext } from "../../../context/userContext"

//styling
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"

//PrimeReact
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Paginator } from "primereact/paginator"

//Participant components
import ParticipantAssessmentFilters from "./ParticipantAssessmentsFilters"
import { assessmentsApiScheduleListTasksPost } from "../assessmentsApi"

//Helpers
import { LogError } from "../../../helpers/logger"
import { LoaderMedium } from "../../../components/Loaders"
import { useLazyQuery } from "@apollo/client"
import { GET_USERS_FROM_LIST } from "../queries"
import isParticipantActive from "../../../helpers/isParticipantActive"

export default function ParticipantAssessmentsSchedules({
  triggerRefresh,
  onProcessScheduledTask,
}) {
  const userCtx = useContext(UserContext)
  const participantIsActive = isParticipantActive(
    userCtx.participant_membership_status
  )

  const [isLoading, setIsLoading] = useState(true)
  const [dataset, setDataset] = useState(undefined)
  const [filters, setFilters] = useState({
    sortField: "date",
    sortOrder: "a-z",
    pageSize: 10,
    first: 0,
    pageNum: 1,
  })
  const [additionalFilters, setAdditionalFilters] = useState({
    includeUpcoming: true,
  })
  const [showFilterDialog, setShowFilterDialog] = useState(false)
  const [staffNames, setStaffNames] = useState([])

  const [loadStaffProfiles] = useLazyQuery(GET_USERS_FROM_LIST, {
    fetchPolicy: "cache-and-network",
    skip: true,
    variables: {
      list: [],
    },
  })

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true)
      const useFilters = {
        ...filters,
        organizationID: userCtx.organization_id,
        participantId: userCtx.participant_id,
        searchPhrase: additionalFilters?.searchPhrase,
        from: additionalFilters?.completedFrom,
        to: additionalFilters?.completedTo,
        includeUpcoming: additionalFilters?.includeUpcoming,
        includeCompleted: additionalFilters?.includeCompleted,
      }

      const results = await assessmentsApiScheduleListTasksPost(
        userCtx.tokenAssessments,
        useFilters
      )

      if (results) {
        //Load staff details if there are assignments allocates to them
        let newStaffNames = staffNames

        let staff_ids = [
          ...new Set(results?.tasks?.map((task) => task.assignerId)),
        ]

        staff_ids = staff_ids.filter(
          (id) => !newStaffNames.find((staffName) => staffName.staff_id === id)
        )

        const staffResults = await loadStaffProfiles({
          variables: { list: staff_ids },
        })

        if (staffResults.data?.usersFromList) {
          newStaffNames = [
            ...newStaffNames,
            ...staffResults.data?.usersFromList,
          ]
          //cache the names
          setStaffNames(newStaffNames)
        } else {
          LogError("Unable to load users from list", staffResults)
        }

        results.tasks = results.tasks.map((task) => {
          let assignerName = "Unknown"

          const creator = newStaffNames?.find(
            (staff) => staff.staff_id === task.assignerId
          )

          if (creator) {
            assignerName = creator.staff_full_name
          }

          return { ...task, assignerName }
        })

        setDataset(results)
      } else {
        setDataset([])
      }

      setIsLoading(false)
    } catch (error) {
      LogError("Unable to list assignments", error)
      userCtx.setNotificationError("Unable to list assignments")

      setDataset(undefined)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerRefresh,
    additionalFilters,
    filters,
    userCtx.participantId,
    userCtx.organizationID,
  ])

  useEffect(() => {
    loadData()
  }, [loadData])

  const RenderActionBody = (rowData) => {
    const dueDate = DateTime.fromISO(rowData.date)
    const today = DateTime.now()

    let doDisable = false
    let icon = ""
    let tooltip = ""

    if (rowData.dateCompleted) {
      icon = "visibility"
      tooltip = "View assessments"
    } else if (!participantIsActive) {
      return <></>
    } else if (dueDate.startOf("day").ts <= today.startOf("day").ts) {
      icon = "task"
      tooltip = "Complete assessments"
      doDisable = !participantIsActive
    } else {
      icon = "task"
      tooltip =
        "This assessment will be available to complete on the due date. Please try again at that time."
      doDisable = true
    }

    return (
      <div className={assessStyles.actionButtonContainer}>
        <Button
          type="button"
          onClick={() => {
            onProcessScheduledTask(rowData.id)
          }}
          className={assessStyles.actionButtonPurple}
          tooltip={tooltip}
          tooltipOptions={{ position: "bottom", showOnDisabled: true }}
          disabled={doDisable}
        >
          <span className={`material-icons`}>{icon}</span>
        </Button>
      </div>
    )
  }

  const RenderDateTemplate = (rowData, fieldName, includeTime) => {
    let date = rowData[fieldName]

    if (!!date) {
      const dateFromIso = DateTime.fromISO(date, { zone: "utc" })

      date = dateFromIso
        .toLocal()
        .toLocaleString(includeTime ? DateTime.DATETIME_MED : undefined)
    }

    return <>{date}</>
  }

  //If there are no additional Filters the include* filters are off which is no longer default
  const filterCount =
    Object.keys(additionalFilters).length > 0
      ? Object.keys(additionalFilters).length +
        (additionalFilters?.includeUpcoming ? -1 : 0)
      : 1

  return (
    <>
      {showFilterDialog ? (
        <ParticipantAssessmentFilters
          filters={additionalFilters}
          isScheduled={true}
          onSetFilters={(newFilters) => {
            setAdditionalFilters(newFilters)
            setShowFilterDialog(false)
          }}
          onClose={() => {
            setShowFilterDialog(false)
          }}
        />
      ) : null}
      <div className={myStyles.completedLayout}>
        <div className={myStyles.completedLayoutHeader}></div>
        <div className={myStyles.completedLayoutFilters}>
          <Button
            className={`${myStyles.actionButton}`}
            onClick={() => {
              setShowFilterDialog(true)
            }}
          >
            <span className={`${myStyles.filterButtonText}`}>{`Filters${
              filterCount > 0 ? ` [${filterCount}]` : ""
            }`}</span>
            <span className={`material-icons`}>{"tune"}</span>
          </Button>
        </div>
        <div className={myStyles.completedLayoutSort}>
          <Dropdown
            className={myStyles.completedComponent}
            value={filters.sortField}
            options={[
              { label: "Sort by: Name", value: "name" },
              { label: "Sort by: Due date", value: "date" },
              { label: "Sort by: Completed date", value: "dateCompleted" },
            ]}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => {
              setFilters({ ...filters, sortField: e.value })
            }}
          />
        </div>
        <div className={myStyles.completedLayoutBody}>
          {isLoading ? (
            <LoaderMedium />
          ) : (
            <>
              <DataTable
                value={dataset?.tasks || []}
                emptyMessage={"No tasks available"}
                sortField={filters.sortField}
                sortOrder={filters.sortOrder === "a-z" ? 1 : -1}
                onSort={(sort) => {
                  const sortOrder = sort.sortOrder === 1 ? "a-z" : "z-a"
                  setFilters({
                    ...filters,
                    sortField: sort.sortField,
                    sortOrder: sortOrder,
                  })
                }}
                className={assessStyles.dataTable}
              >
                <Column header={"Name"} field={"name"} sortable></Column>
                <Column header={"Assigned by"} field={"assignerName"} />
                <Column
                  header={"Due date"}
                  field={"date"}
                  body={(rowData) => {
                    return RenderDateTemplate(rowData, "date", true)
                  }}
                  sortable
                ></Column>
                <Column
                  header={"Date completed"}
                  field={"dateCompleted"}
                  sortable
                  body={(rowData) => {
                    return RenderDateTemplate(rowData, "dateCompleted", true)
                  }}
                ></Column>
                <Column body={RenderActionBody} />
              </DataTable>
              <Paginator
                first={dataset ? (dataset?.pageNum - 1) * dataset?.pageSize : 0}
                rows={dataset?.pageSize || 0}
                totalRecords={dataset?.numTotal || 0}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={(setup) => {
                  setFilters({
                    ...filters,
                    pageSize: setup.rows,
                    first: setup.first,
                    pageNum: setup.page + 1,
                  })
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

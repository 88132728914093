import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

// GraphQL
import { useQuery } from "@apollo/client"
import { REFERRAL_LIST_PARTICIPANT } from "./query"

// Styles
import formStyles from "../../styles/Form-Inputs.module.css"
import refEntityStyles from "../../styles/ReferralEntityForm.module.css"

// Context
import { UserContext } from "../../context/userContext"

// Prime React
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Skeleton } from "primereact/skeleton"
import { Button } from "primereact/button"

//Routes
import ROUTES from "../../components/Routes/routesContants"

// Referral API calls
import { RefApiRefEntityStatusListPost } from "./ReferralsAPI"

export default function Referrals() {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [refData, setRefData] = useState([{}])
  const [globalFilterValue, setGlobalFilterValue] = useState("")
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    full_name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    type: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    referral_entity_name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    status: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  })

  const [refStatus, setRefStatus] = useState(undefined)
  const [isLoadingRefStatus, setIsLoadingRefStatus] = useState(true)

  const viewReferralLink = ROUTES.PARTICIPANT_REFERRALS_DETAIL
  const query = REFERRAL_LIST_PARTICIPANT
  const queryVars = { pid: user.participant_id || null }
  const [bigScreen, setbigScreen] = useState(
    window.matchMedia("(min-width: 960px)").matches
  )

  useEffect(() => {
    //Keep an eye on when the form gets too small for the datatable and let it stack.
    window
      .matchMedia("(min-width: 960px)")
      .addEventListener("change", (e) => setbigScreen(e.matches))
  }, [])

  useQuery(query, {
    variables: queryVars,
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      try {
        if (result !== undefined && result.referrals !== null) {
          const dataParsed = JSON.parse(result.referrals.data)

          if (dataParsed.length > 0) {
            const ids = dataParsed.map((x) => {
              return x.idStamp
            })
            const preppedData = dataParsed.map((x) => {
              const org_name = result.orgs.find(
                (org) => org.organization_id === x.organization_id
              )?.organization_name

              if (!org_name) {
                console.log(`Unknown organization: ${x.organization_id}`)
              }

              const session_date_local = !!x.session_date
                ? DateTime.fromISO(x.session_date).toLocal().toLocaleString()
                : ""

              return {
                ...x,
                refEntityStatus: "Loading...",
                organization_name: org_name || "",
                session_date_local,
              }
            })

            setRefData(preppedData)
            setIsLoadingRefStatus(true)

            RefApiRefEntityStatusListPost(user.token, ids)
              .then((result) => {
                setRefStatus(result)
              })
              .catch((ex) => {
                console.log(ex)

                if (ex.isAxiosError) {
                  if (ex.response !== undefined) {
                    setErrorMessage(
                      "An error ocurred while retrieving the partner status: " +
                        ex.response.data
                    )
                  } else {
                    setErrorMessage(
                      "Unable to load partner status: " + ex.message
                    )
                    setRefStatus(null)
                  }
                } else {
                  setErrorMessage(ex)
                }
              })
          } else {
            setRefData(null)
          }
        } else {
          setRefData(null)
        }
        setIsLoading(false)
      } catch (ex) {
        setErrorMessage(ex)
        setIsLoading(false)
      }
    },
    onError: (error) => {
      setErrorMessage(error.message)
      console.error(error)
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (
      refData != null &&
      refData.length > 0 &&
      refStatus !== undefined &&
      isLoadingRefStatus
    ) {
      setIsLoadingRefStatus(false)
      let preppedData = []
      if (refStatus === null) {
        preppedData = refData.map((x) => {
          return { ...x, refEntityStatus: "Unknown" }
        })
      } else if (refStatus.length > 0) {
        preppedData = refData.map((x) => {
          return {
            ...x,
            refEntityStatus: refStatus.some((y) => y.referralId === x.idStamp)
              ? refStatus.filter((y) => y.referralId === x.idStamp)[0]
                  .referralEntityStatus
              : "Pending",
          }
        })
      } else {
        preppedData = refData.map((x) => {
          return { ...x, refEntityStatus: "Pending" }
        })
      }
      setRefData(preppedData)
    }
  }, [refData, refStatus, isLoadingRefStatus])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters["global"].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div style={{ display: "inline-block", width: "100%" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    )
  }

  const actionBodyTemplate = (rowData) => {
    if (rowData.idStamp) {
      return (
        <div style={{ width: "100%" }}>
          <Button
            type="button"
            onClick={() => {
              navigate(
                `${viewReferralLink.substring(
                  0,
                  viewReferralLink.lastIndexOf("/")
                )}/${rowData.idStamp}`
              )
            }}
            className={refEntityStyles.button}
            style={{ float: "right" }}
            disabled={isLoading}
          >
            View
          </Button>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <h1>Referrals</h1>
        {errorMessage !== "" ? (
          <div style={{ marginBottom: "20px" }}>
            <label className={formStyles.errFieldMsg}>{errorMessage}</label>
          </div>
        ) : null}
        <DataTable
          header={renderHeader}
          filters={filters}
          globalFilterFields={[
            "organization_name",
            "template_title",
            "values.referral_type",
            "values.referral_entity_name",
            "values.referral_status",
            "refEntityStatus",
          ]}
          value={isLoading ? Array.from({ length: 5 }) : refData}
          rowClassName={bigScreen ? "" : refEntityStyles.stackedDataTable}
        >
          <Column
            field="organization_name"
            sortable
            header="Organization"
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            field="session_date_local"
            sortable
            header="Date"
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            field="template_title"
            sortable
            header="Source"
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            field="values.referral_type"
            sortable
            header="Type"
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            field="values.referral_entity_name"
            sortable
            header="Referral Partner"
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            field="values.referral_status"
            sortable
            header="Status"
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            field="refEntityStatus"
            sortable
            header="Entity Status"
            body={isLoading ? <Skeleton /> : null}
          />
          {isLoading || refData?.length === 0 ? null : (
            <Column
              header=""
              body={actionBodyTemplate}
              alignFrozen="right"
              frozen
              style={{ width: "100px" }}
              resizeable={false}
            />
          )}
        </DataTable>
      </div>
    </div>
  )
}

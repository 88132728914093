import axios from "axios"
import { referralsUrl } from "../../config/microservicesUrls"

const api_url = referralsUrl()

async function referralAxiosPut(jwt, endpoint, data) {
  const axiosObj = axios.create()

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  }

  const x = await axiosObj
    .put(`${api_url}/${endpoint}`, data, requestConfig)
    .then((responseBody) => {
      return responseBody.data
    })

  return x
}

async function ReferralAxiosPost(jwt, endpoint, data) {
  const axiosObj = axios.create()

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
  }

  const result = await axiosObj
    .post(`${api_url}/${endpoint}`, data, requestConfig)
    .then((responseBody) => {
      return responseBody.data
    })

  return result
}

async function ReferralAxiosGet(jwt, endpoint) {
  const axiosObj = axios.create()

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  }

  const result = await axiosObj
    .get(`${api_url}/${endpoint}`, requestConfig)
    .then((responseBody) => {
      return responseBody.data
    })

  return result
}

export async function RefApiRefEntityStatusListPost(jwt, referralIds) {
  return await ReferralAxiosPost(
    jwt,
    "Referrals/entityprogress/list",
    referralIds
  )
}

export async function RefApiRefEntityStatusGet(jwt, referralId) {
  return await ReferralAxiosGet(jwt, `Referrals/entityprogress/${referralId}`)
}

export async function RefApiRefRatingGet(jwt, referralId) {
  return await ReferralAxiosGet(jwt, `Referrals/${referralId}/rating`)
}

export async function RefApiRefRatingPost(jwt, referralId, ratingData) {
  return await ReferralAxiosPost(
    jwt,
    `Referrals/${referralId}/rating`,
    ratingData
  )
}

export async function RefApiRefEntityNotesListGet(jwt, referralId) {
  return await ReferralAxiosGet(jwt, `Referrals/${referralId}/notes`)
}

export async function refApiRefEntityNotePut(jwt, referralId, referralNoteObj) {
  return await referralAxiosPut(
    jwt,
    `Referrals/${referralId}/note`,
    referralNoteObj
  )
}

import React, { useState } from 'react'
import { Image } from 'primereact/image'
import { ProgressSpinner } from 'primereact/progressspinner'
import trcss from "../../styles/Telerecovery.module.css"
import placeholder from './resources/img_placeholder.svg'

export default function ImageWithLoader({ src, width, height, aKey }) {
    const [loading, setLoading] = useState(true)
    const [imgSrc, setImgSrc] = useState(src)

    const handleImageLoad = () => {
        setLoading(false)
    }

    const handleError = () => {
        setLoading(false)
        setImgSrc(placeholder)
    }

    return (
        <div key={aKey}>
            {loading && (
                <div className={trcss.loader_container}>
                    <ProgressSpinner
                        style={{ width: "30px", height: "30px" }}
                        strokeWidth="4"
                        animationDuration=".5s"
                    />
                </div>
            )}
            <img
                src={src}
                alt="Image"
                onLoad={handleImageLoad}
                onError={handleError}
                style={{ display: 'none' }}
            />
            <Image
                src={imgSrc}
                alt={imgSrc === src ? "media-image" : "Image not available"}
                width={imgSrc === src ? width : "80px"}
                height={height}
                preview={imgSrc === src}
                className={`${trcss.mms} ${loading ? trcss.hidden : ''}`}
            />
            {imgSrc !== src && <small className="p-error">Image not available</small>}
        </div>
    )
}
import { gql } from "@apollo/client"

export const ADVANCED_SEARCH = gql`
  query AdvancedSearch(
    $participant_alias: String
    $participant_name_first: String
    $participant_name_last: String
    $participant_name_preferred: String
    $participant_phone_primary: String
    $participant_email_account: String
    $participant_city_primary: String
    $participant_state_primary: String
    $participant_zip_code_primary: String
    $program_ids: [String]
    $participant_membership_status: [String]
  ) {
    search: participantAdvancedSearch(
      participant_alias: $participant_alias
      participant_name_first: $participant_name_first
      participant_name_last: $participant_name_last
      participant_name_preferred: $participant_name_preferred
      participant_phone_primary: $participant_phone_primary
      participant_email_account: $participant_email_account
      participant_city_primary: $participant_city_primary
      participant_state_primary: $participant_state_primary
      participant_zip_code_primary: $participant_zip_code_primary
      program_ids: $program_ids
      participant_membership_status: $participant_membership_status
    ) {
      participant_id
      participant_alias
      participant_membership_status
      participant_name_first
      participant_name_last
      participant_phone_primary
      participant_email_account
    }
  }
`

export const PROGRAM_ID_LIST = gql`
query GetProgramIdList($staff_id: ID!, $organization_id: ID!) {
  programs(staff_id: $staff_id, organization_id: $organization_id) {
      program_identity_id
      name
    }
  }
`

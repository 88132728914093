//styling
import myStyles from "../../../styles/assessments/AssessmentForm.module.css"

export default function AssessmentFormInstruction({ instruction }) {
  if (instruction) {
    return (
      <div className={myStyles.instructionContainer}>
        <p>{instruction}</p>
      </div>
    )
  } else {
    return <></>
  }
}

import { gql } from "@apollo/client"

export const GET_USER = gql`
  query GetUser($staff_id: ID!) {
    user(staff_id: $staff_id) {
      staff_id
      staff_user_status
      staff_name_first
      staff_name_last
      staff_email_address
      staff_role
      staff_description
      environment
      enterprise_id
      organization_id
      staff_country
      staff_zip_code
      staff_address
      staff_city
      staff_state
      staff_phone_telerecovery
      staff_credentials
      staff_hire_date
      staff_primary_supervisor
    }
  }
`
export const GET_PARTICIPANT = gql`
  query GetParticipant($participant_id: ID!, $organization_id: ID!) {
    participant(
      participant_id: $participant_id
      organization_id: $organization_id
    ) {
      participant_id
      participant_name_first
      participant_name_last
      participant_email_account
      participant_zip_code_primary
      participant_city_primary
      participant_state_primary
      participant_phone_primary
      participant_profile_image
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($staff_id: ID!, $organization_id: ID!) {
    users(staff_id: $staff_id, organization_id: $organization_id) {
      staff_id
      staff_name_first
      staff_name_last
      organization_id
      organization_name
      staff_user_status
      staff_role
      staff_description
      staff_primary_supervisor_name
    }
  }
`

export const EDIT_USER = gql`
  mutation EditUser(
    $staff_id: String!
    $staff_name_first: String
    $staff_name_last: String
    $staff_email_address: String
    $staff_password: String
    $staff_role: Int
    $organization_id: String
    $staff_country: String
    $staff_zip_code: String
    $staff_address: String
    $staff_city: String
    $staff_state: String
    $staff_phone_telerecovery: String
    $staff_credentials: String
    $staff_hire_date: String
    $staff_primary_supervisor: String
  ) {
    editUser(
      staff_id: $staff_id
      staff_name_first: $staff_name_first
      staff_name_last: $staff_name_last
      staff_email_address: $staff_email_address
      staff_password: $staff_password
      staff_role: $staff_role
      organization_id: $organization_id
      staff_country: $staff_country
      staff_zip_code: $staff_zip_code
      staff_address: $staff_address
      staff_city: $staff_city
      staff_state: $staff_state
      staff_phone_telerecovery: $staff_phone_telerecovery
      staff_credentials: $staff_credentials
      staff_hire_date: $staff_hire_date
      staff_primary_supervisor: $staff_primary_supervisor
    ) {
      staff_id
    }
  }
`

export const EDIT_PROFILE_PARTICIPANT = gql`
  mutation EditProfileParticipant(
    $staff_id: ID!
    $participant_id: ID!
    $participant_name_first: String
    $participant_name_last: String
    $participant_phone_primary: String
    $participant_zip_code_primary: String
    $participant_city_primary: String
    $participant_state_primary: String
    $participant_email_account: String
  ) {
    editContactInformation(
      staff_id: $staff_id
      participant_id: $participant_id
      participant_name_first: $participant_name_first
      participant_name_last: $participant_name_last
      participant_email_account: $participant_email_account
      participant_zip_code_primary: $participant_zip_code_primary
      participant_city_primary: $participant_city_primary
      participant_state_primary: $participant_state_primary
      participant_phone_primary: $participant_phone_primary
    ) {
      staff_role
    }
  }
`

export const EDIT_PROFILE = gql`
  mutation EditProfile(
    $staff_id: String!
    $staff_name_first: String
    $staff_name_last: String
    $staff_email_address: String
    $staff_password: String
    $staff_zip_code: String
    $staff_city: String
    $staff_state: String
  ) {
    editProfile(
      staff_id: $staff_id
      staff_name_first: $staff_name_first
      staff_name_last: $staff_name_last
      staff_email_address: $staff_email_address
      staff_password: $staff_password
      staff_zip_code: $staff_zip_code
      staff_city: $staff_city
      staff_state: $staff_state
    ) {
      staff_id
    }
  }
`

export const CHANGE_USER_STATUS = gql`
  mutation ChangeUserStatus(
    $staff_id: ID!
    $user_uid: String!
    $organization_id: String!
    $rol_id: String!
    $staff_user_status: String
  ) {
    changeUserStatus(
      staff_id: $staff_id
      user_uid: $user_uid
      organization_id: $organization_id
      rol_id: $rol_id
      staff_user_status: $staff_user_status
    ) {
      staff_id
    }
  }
`

export const ADD_USER = gql`
  mutation AddUser(
    $staff_name_first: String!
    $staff_name_last: String!
    $staff_email_address: String!
    $staff_password: String!
    $description: String
    $staff_role: Int!
    $organization_id: String!
    $staff_country: String
    $staff_zip_code: String
    $staff_address: String
    $staff_city: String
    $staff_state: String
    $staff_phone_telerecovery: String
    $staff_credentials: String
    $staff_hire_date: String
  ) {
    addUser(
      staff_name_first: $staff_name_first
      staff_name_last: $staff_name_last
      staff_email_address: $staff_email_address
      staff_password: $staff_password
      description: $description
      staff_role: $staff_role
      organization_id: $organization_id
      staff_country: $staff_country
      staff_zip_code: $staff_zip_code
      staff_address: $staff_address
      staff_city: $staff_city
      staff_state: $staff_state
      staff_phone_telerecovery: $staff_phone_telerecovery
      staff_credentials: $staff_credentials
      staff_hire_date: $staff_hire_date
    ) {
      staff_id
    }
  }
`

export const ADD_EXISTING_USER = gql`
  mutation AddExistingUser(
    $staff_id: String!
    $staff_members_id: String!
    $staff_role: Int!
    $staff_primary_supervisor: String
  ) {
    addExistingUser(
      staff_id: $staff_id
      staff_members_id: $staff_members_id
      staff_role: $staff_role
      staff_primary_supervisor: $staff_primary_supervisor
    ) {
      staff_id
    }
  }
`

export const LOAD_USER_FORM = gql`
  query LoadForm($staff_id: ID!, $organization_id: ID!) {
    roles: rolesByUid(
      staff_id: $staff_id
      organization_id: $organization_id
      group_type: 0
    ) {
      rol_id
      rol_name
    }
    supervisors: supervisorUsers(
      staff_id: $staff_id
      organization_id: $organization_id
    ) {
      staff_id
      staff_name_first
      staff_name_last
    }
  }
`

export const LOAD_PARTNER_FORM = gql`
  query LoadForm($staff_id: ID!, $organization_id: ID!) {
    roles: rolesByUid(
      staff_id: $staff_id
      organization_id: $organization_id
      group_type: 2
    ) {
      rol_id
      rol_name
    }
  }
`

export const LOAD_EXISTING_USER_FORM = gql`
  query LoadForm($staff_id: ID!, $organization_id: ID!) {
    roles: rolesByUid(
      staff_id: $staff_id
      organization_id: $organization_id
      group_type: 0
    ) {
      rol_id
      rol_name
    }
    staffMembers: staffUsers(
      staff_id: $staff_id
      organization_id: $organization_id
    ) {
      staff_id
      staff_name_first
      staff_name_last
    }
    supervisors: supervisorUsers(
      staff_id: $staff_id
      organization_id: $organization_id
    ) {
      staff_id
      staff_name_first
      staff_name_last
    }
  }
`

export const LOAD_ASSIGN_FORM = gql`
  query LoadForm($staff_id: ID!, $organization_id: ID!) {
    loadingAvailable: participants(
      staff_id: $staff_id
      organization_id: $organization_id
      condiction: false
    ) {
      participant_id
      participant_alias
      participant_name_first
      participant_name_last
      participant_membership_status
      participant_name_preferred
    }
    loadingSelected: participants(
      staff_id: $staff_id
      organization_id: $organization_id
      condiction: true
    ) {
      participant_id
      participant_alias
      participant_name_first
      participant_name_last
      participant_membership_status
      participant_name_preferred
    }
  }
`

export const ASSIGN_STAFF = gql`
  mutation AssingStaff($staff_id: String!, $idStaff: String!, $pids: [String]) {
    assignStaff(staff_id: $staff_id, idStaff: $idStaff, pids: $pids) {
      idStaff
    }
  }
`

import { useContext, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import ROUTES from "../Routes/routesContants"
import { Link, useNavigate } from "react-router-dom"
import { TieredMenu } from "primereact/tieredmenu"
// Context
import { UserContext } from "../../context/userContext"
import styles from "../../styles/Sidebar-Staff.module.css"
import logo from "../../assets/images/logo.png"

import {
  MdOutlineGroupAdd as IcoIntake,
  MdOutlinePostAdd as IcoForm,
  MdManageAccounts as IcoAccount,
  MdDateRange as IcoAppointment,
  MdMessage as IcoMsg,
  MdSnippetFolder as IcoDocs,
  MdAccessibilityNew as IcoParticipant,
  MdOutlineDocumentScanner as IcoReferral,
  MdMedication as IcoRecovery,
  MdOutlineEditNote as IcoForms,
  MdOutlineExitToApp as IcoRelease,
  MdHomeRepairService as IcoServices,
} from "react-icons/md"

export default function ParticipantSummaryInfo() {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const GET_PARTICIPANT_DATA = gql`
    query ParticipantSummary($participant_id: ID!) {
      participant: participantSelfView(participant_id: $participant_id) {
        participant_id
        participant_name_first
        participant_name_last
      }
    }
  `

  const { loading, data } = useQuery(GET_PARTICIPANT_DATA, {
    variables: {
      participant_id: user.participant_id,
      organization_id: user.organization_id,
    },
  })

  const handleItemTemplate = (item, options, link, icon) => {
    return (
      <Link className={`${options.className} item-with-icon`} to={link}>
        {icon}
        <span className={options.labelClassName}>{item.label}</span>
      </Link>
    )
  }

  const items = [
    {
      label: "Participant Profile",
      command: () => navigate(ROUTES.PARTICIPANT_PROFILE),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_PROFILE,
          <IcoParticipant />
        ),
    },
    {
      label: "Recovery Plan",
      command: () => navigate(ROUTES.PARTICIPANT_RECOVERY_PLAN),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_RECOVERY_PLAN,
          <IcoServices />
        ),
    },
    {
      label: "Assessments",
      command: () => navigate(ROUTES.PARTICIPANT_ASSESSMENTS),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_ASSESSMENTS,
          <IcoRecovery />
        ),
    },
    {
      label: "Telerecovery",
      command: () =>
        navigate(ROUTES.PARTICIPANT_TELERECOVERY_SERVICES + "/chat"),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_TELERECOVERY_SERVICES,
          <IcoAccount />
        ),
    },
    {
      label: "Engagements",
      command: () => navigate(ROUTES.PARTICIPANT_ENGAGEMENTS),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_ENGAGEMENTS,
          <IcoForm />
        ),
    },
    {
      label: "Referrals",
      command: () => navigate(ROUTES.PARTICIPANT_REFERRALS),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_REFERRALS,
          <IcoReferral />
        ),
    },
    {
      label: "Documents",
      command: () => navigate(ROUTES.PARTICIPANT_DOCUMENTS),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_DOCUMENTS,
          <IcoForms />
        ),
    },
    {
      label: "Custom Data Forms",
      command: () => navigate(ROUTES.PARTICIPANT_CUSTOM_FORMS),
      template: (item, options) =>
        handleItemTemplate(
          item,
          options,
          ROUTES.PARTICIPANT_CUSTOM_FORMS,
          <IcoForm />
        ),
    },
    {
      label: "Activity Logs",
      items: [
        {
          label: "General Activity Log",
          command: () => navigate(ROUTES.PARTICIPANT_GENERAL_ACTIVITY_LOG),
        },
        {
          label: "Harm Reduction Activity Log",
          command: () =>
            navigate(ROUTES.PARTICIPANT_HARM_REDUCTION_ACTIVITY_LOG),
        },
        {
          label: "Contact Log",
          command: () => navigate(ROUTES.PARTICIPANT_CONTACT_LOG),
        },
        {
          label: "Volunteer Activity Log",
          command: () => navigate(ROUTES.PARTICIPANT_VOLUNTEER_ACTIVITY_LOG),
        },
      ],
    },

    /* {
          label: "Discharge",
          command: () => navigate("/form/discharge_session/1"),
          template: (item, options) =>
            handleItemTemplate(
              item,
              options,
              ROUTES.PARTICIPANT_PROFILE,
              <IcoRelease />
            ),
        },
        {
          label: "Manage Services",
          command: () => navigate(ROUTES.PARTICIPANT_MANAGE_SERVICES),
          template: (item, options) =>
            handleItemTemplate(
              item,
              options,
              ROUTES.PARTICIPANT_PROFILE,
              <IcoServices />
            ),
        },*/
  ]

  const handleParticipantMenu = () => {
    return <TieredMenu model={items} className={styles.tieredMenu} />
  }

  if (loading) {
    return (
      <div>
        <LoaderMedium />
      </div>
    )
  }

  return (
    <div className="sidebar">
      {/* {handleParticipantMenu()}   */}

      <div className="navbar-participants">
        <ul className="first-level">
          <li>
            <Link to={ROUTES.PARTICIPANT_PROFILE}>
              <span className="material-icons"> accessibility </span>Participant
              Profile
            </Link>
          </li>
          <li>
            <Link to={ROUTES.PARTICIPANT_RECOVERY_PLAN}>
              <span className="material-icons"> event_note </span>Recovery Plan
            </Link>
          </li>
          <li>
            <Link to={ROUTES.PARTICIPANT_ASSESSMENTS}>
              <span className="material-icons"> medication_liquid </span>
              Assessments
            </Link>
          </li>
          <li>
            <Link to={ROUTES.PARTICIPANT_TELERECOVERY_SERVICES + "/chat"}>
              <span className="material-icons"> manage_accounts </span>
              Telerecovery
            </Link>
          </li>
          <li>
            <Link to={ROUTES.PARTICIPANT_ENGAGEMENTS}>
              <span className="material-icons"> task </span>Engagements
            </Link>
          </li>

          <li>
            <Link to={ROUTES.PARTICIPANT_REFERRALS}>
              <span className="material-icons"> contacts </span>Referrals
            </Link>
          </li>

          <li style={{ display: "none" }}>
            <Link to={ROUTES.PARTICIPANT_APPOINTMENTS}>
              <span className="material-icons"> event_available </span>
              Appointments
            </Link>
          </li>
          <li>
            <Link to={ROUTES.PARTICIPANT_DOCUMENTS}>
              {" "}
              <span className="material-icons"> edit_note </span>Documents
            </Link>
          </li>

          <li>
            <Link to={ROUTES.PARTICIPANT_CUSTOM_FORMS}>
              <span className="material-icons"> list_alt </span>
              Custom Data Forms
            </Link>
          </li>

          <li style={{ display: "none" }}>
            <a href="#">
              <span className="material-icons"> list_alt </span>
              Activity Logs{" "}
              <span className="material-icons right-arrow">chevron_right</span>
            </a>
            <ul className="second-level">
              <li>
                <Link to={ROUTES.PARTICIPANT_GENERAL_ACTIVITY_LOG}>
                  General Activity Log
                </Link>{" "}
              </li>
              <li>
                <Link to={ROUTES.PARTICIPANT_HARM_REDUCTION_ACTIVITY_LOG}>
                  Harm Reduction Activity Log
                </Link>{" "}
              </li>
              <li>
                <Link to={ROUTES.PARTICIPANT_CONTACT_LOG}>Contact Log</Link>{" "}
              </li>
              <li>
                <Link to={ROUTES.PARTICIPANT_VOLUNTEER_ACTIVITY_LOG}>
                  Volunteer Activity Log
                </Link>{" "}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}

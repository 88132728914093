import { DateTime } from "luxon"

export function displayLocalDateTimeFromIso(utcDateString, format) {
  if (!format) {
    format = { ...DateTime.DATETIME_SHORT, timeZoneName: "short" }
  }

  let utcDateValue = DateTime.fromISO(utcDateString, { zone: "utc" })

  try {
    if (!utcDateString || utcDateValue.year === 1) {
      return ""
    } else {
      return utcDateValue.toLocal().toLocaleString(format)
    }
  } catch (error) {
    console.log(utcDateString)
  }
}

import { useState, useContext } from "react";
import { gql, useQuery } from "@apollo/client";

import { Field } from "formik";

import { MultiSelect } from "primereact/multiselect";
import { FormContext } from "../../../context/formContext";
import { UserContext } from "../../../context/userContext";

import styles from "../../../styles/Form.module.css";

export default function ProgramIdentityId({ field, block, index }) {
  const [programList, setProgramList] = useState([]);

  const form = useContext(FormContext);
  const user = useContext(UserContext);
  const isDisabled = form ? form.blocksSaved[block] : false;

  const GET_PROGRAMS = gql`
    query getPrograms($staff_id: ID!, $organization_id: ID!) {
      programs(staff_id: $staff_id, organization_id: $organization_id) {
        label: program_id_name
        value: program_identity_id
      }
    }
  `;
  const { data, loading } = useQuery(GET_PROGRAMS, {
    fetchPolicy: "network-only",
    variables: {
      staff_id: "0",
      organization_id: user.organization_id,
    },
    skip: !user,
    onCompleted: ({ programs }) => {
      setProgramList(programs);
    },
  });

  return (
    <div
      className={styles.field}
      style={{
        order: index,
        // display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label}>{field.field_label}</label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, meta }) => (
          <>
            <MultiSelect
              placeholder="Please select"
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              disabled={isDisabled}
              options={programList}
            />
            {meta.touched && meta.error && (
              <div className={styles.errFieldMsg}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
}

import { gql } from "@apollo/client"

export const GET_TEMPLATE = gql`
  query GetForm(
    $staff_id: ID!
    $participant_id: ID!
    $idTemplate: ID!
    $type: ID!
    $organization_id: ID
  ) {
    template(
      staff_id: $staff_id
      participant_id: $participant_id
      idTemplate: $idTemplate
      type: $type
      organization_id: $organization_id
    ) {
      total_blocks
      template_description
      template_name
      template_title
      template_type
      values
      blocks {
        system_required
        idBlock
        block_name
        block_title
        total_fields
        index
      }
    }
  }
`
export const GET_TEMPLATE_PAGE = gql`
  query GetFormByPage(
    $staff_id: ID!
    $organization_id: ID
    $enterprise_id: ID
    $participant_id: ID!
    $idTemplate: ID!
    $type: ID!
    $page: Int
    $idStamp: ID
    $editMode: Boolean
  ) {
    template(
      staff_id: $staff_id
      organization_id: $organization_id
      enterprise_id: $enterprise_id
      participant_id: $participant_id
      idTemplate: $idTemplate
      type: $type
      page: $page
      idStamp: $idStamp
      editMode: $editMode
    ) {
      total_blocks
      template_description
      template_name
      template_title
      template_type
      blocks {
        idBlock
        block_name
        block_title
        index
        fields {
          idField
          field_type
          field_description
          field_conditional
          field_label
          field_default_value
          field_name
          field_visibility
          read_only
          field_options {
            ...fieldOptions
          }
          field_validation {
            ...fieldValidation
          }
          multi_fields {
            ...multiField
            multi_fields {
              ...multiField
            }
          }
        }
      }
    }
  }
  fragment fieldOptions on TypeFieldOptions {
    label
    value
  }
  fragment fieldValidation on TypeFieldValidation {
    required
    number_min
    number_max
    force
    maxlength
    file_extension
    file_size
    file_multi_files
    file_firestore_storage
    file_cloud_storage
    file_is_shared
    file_is_renameable
  }
  fragment multiField on TypeMultiField {
    field_name
    field_type
    field_label
    field_options {
      ...fieldOptions
    }
    field_validation {
      ...fieldValidation
    }
  }
`

import { useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Formik, Form as FormWrapper } from "formik";
import axios from "axios";

// Context
import { UserContext } from "../../../context/userContext";

// Queries
import { PARTICIPANT_BLOCK } from "./queries";
import { telerecoveryApiUrl } from "../../../config/microservicesUrls"

// Helpers
import validationSchema from "../../../helpers/validationSchema/";
import initialValues from "../../../helpers/initialValues";
import parseDatesInObj from "../../../helpers/parseDatesInObj";

// Comps
import FieldBuilder from "./FieldBuilder";
import { LoaderMedium } from "../../../components/Loaders";

// Helpers
import cloudFunctionsEndpoint from "../../../config/cloudFunctionsEndpoint";

export default function Form(props) {
  const [fetchData, setFetchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);

  const user = useContext(UserContext);
  const participant_id = user.participant_id;

  const { refetch } = useQuery(PARTICIPANT_BLOCK, {
    fetchPolicy: "network-only",
    variables: {
      participant_id,
      idBlock: props.blockName,
    },
    onError: () => setErr(true),
    onCompleted: (data) => {
      setFetchData(data);
      setLoading(false);
    },
  });

  if (err) return <div>Err...</div>;
  if (loading || !fetchData) return <LoaderMedium />;

  const dataStored = fetchData.blockSaved
    ? JSON.parse(fetchData.blockSaved.data)
    : {};

  const formValues = {
    ...initialValues(fetchData?.block),
    ...parseDatesInObj(dataStored?.values),
  };

  const handleSubmit = async (values, actions) => {
    const endpoint = cloudFunctionsEndpoint();
    setLoading(true);
    let newUserPhoneNumber = values?.participant_phone_primary
    try {
      const postBody = {
        idDoc: dataStored.idDoc,
        organization_alias: user.organization_alias,
        organization_id: user.organization_id,
        enterprise_id: user.enterprise_id,
        enterprise_alias: user.enterprise_alias,
        participant_alias: user.participant_alias,
        participant_membership_id: user.participant_membership_id,
        participant_id,
        staff_alias: 0,
        staff_id: 0,
        block_name: props.blockName,
        values
      };

      const response = await axios.post(`${endpoint}/blockSubmitParticipant`, postBody)
      if (response.status === 200) {
        await createHNR(newUserPhoneNumber)
        await user.setPayload(response.data)
      }
  

    } catch (error) {
      setLoading(false);
      setErr(true);
    }
  };

  async function createHNR(newPhone) {
    try {
      if (newPhone && newPhone !== user.participant_phone_primary) {
        await axios
          .post(
            `${telerecoveryApiUrl()}/Numbers/RemoveHnrSOAR?caseId=${participant_id}`
          )          
          .then((resp) => {
            console.log("/Numbers/CreateHnr")
            axios.post(`${telerecoveryApiUrl()}/Numbers/CreateHnr`, {
              phoneNumber: newPhone,
              caseUserId: participant_id,
            })
          })
      }
    } catch (error) {
      console.error("not able to create hnr")
    }
  }
  
  return (
    <Formik
      key={props.blockName}
      initialValues={formValues}
      validationSchema={validationSchema(fetchData.block)}
      onSubmit={async (values, actions) => {
        await handleSubmit(values, actions);
        props.triggerParticipantQuery();
        setFetchData(null);
        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);
      }}
    >
      <FormWrapper className="" key={props.blockName}>
        <FieldBuilder fields={fetchData.block.fields} />
        <button type="submit" className="btn">
          Save
        </button>
      </FormWrapper>
    </Formik>
  );
}

import { useContext } from "react"
import { Field, FastField } from "formik"

// Ctx
import { FormContext } from "../../context/formContext"

import { InputText as Input } from "primereact/inputtext"
import styles from "../../styles/Form.module.css"

function RegularFieldOutput(props) {
  const { field, block, index } = props
  const isRequired = JSON.parse(props.field.field_validation.required[0])

  const form = useContext(FormContext)
  const isDisabled = form ? form.blocksSaved[block] : false
  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, meta }) => (
          <>
            <Input
              name={formikField.name}
              id={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              disabled={isDisabled}
              onBlur={formikField.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.errFieldMsg}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  )
}

function MultiFieldOutput(props) {
  const { field, index, mfFieldName, mfIndex } = props
  const fieldSlug = `${mfFieldName}.${mfIndex}.${field.field_name}`
  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>{field.field_label}</label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <FastField name={fieldSlug}>
        {({ field: fastField }) => {
          return (
            <Input
              name={fastField.name}
              id={fastField.name}
              value={fastField.value}
              onChange={fastField.onChange}
              onBlur={fastField.onBlur}
            />
          )
        }}
      </FastField>
    </div>
  )
}

function NestedMultiFieldOutput(props) {
  const {
    field,
    index,
    mfFieldName,
    mfIndex,
    nestedMfFielName,
    nestedMfIndex,
  } = props
  const fieldSlug = `${mfFieldName}.${mfIndex}.${nestedMfFielName}.${nestedMfIndex}.${field.field_name}`
  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>{field.field_label}</label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <FastField name={fieldSlug}>
        {({ field: fastField }) => {
          return (
            <Input
              name={fastField.name}
              id={fastField.name}
              value={fastField.value}
              onChange={fastField.onChange}
              onBlur={fastField.onBlur}
            />
          )
        }}
      </FastField>
    </div>
  )
}

export default function TypeText(props) {
  switch (props.type) {
    case "regular-field":
      const regFieldProps = {
        field: props.field,
        block: props.block,
        index: props.index,
      }
      return <RegularFieldOutput {...regFieldProps} />
    case "multi-field":
      const multiFieldProps = {
        field: props.field,
        index: props.index,
        mfFieldName: props.mfFieldName,
        mfIndex: props.mfIndex,
      }
      return <MultiFieldOutput {...multiFieldProps} />
    case "nested-multi-field":
      const nestedMultiFieldProps = {
        field: props.field,
        index: props.index,
        mfFieldName: props.mfFieldName,
        mfIndex: props.mfIndex,
        nestedMfFielName: props.nestedMfFielName,
        nestedMfIndex: props.nestedMfIndex,
      }
      return <NestedMultiFieldOutput {...nestedMultiFieldProps} />
    default:
      return null
  }
}

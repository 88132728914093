import { gql } from "@apollo/client"

export const ENGAGEMENTS = gql`
  query Engagements(
    $staff_id: ID
    $idTemplate: ID!
    $participant_membership_id: ID
  ) {
    engagements: blocksSavedByIdTemplate(
      staff_id: $staff_id
      idTemplate: $idTemplate
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const ENGAGEMENTS_PARTICIPANT = gql`
  query EngagementsParticipant(
    $participant_id: ID!
    $organization_id: ID!
    $idTemplate: ID!
    $participant_membership_id: ID
  ) {
    engagementsParticipant: blocksSavedByParticipantIdTemplate(
      participant_id: $participant_id
      organization_id: $organization_id
      idTemplate: $idTemplate
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

export const TEMPLATE_STATS = gql`
  query templateStats($participant_membership_id: ID, $staff_id: String) {
    stats: getSpecificBlocksStatsByMembership(
      participant_membership_id: $participant_membership_id
      blocks: ["session", "session__recovery_plan", "session__group_session"]
    ) {
      data
    }
    engagements: getSpecificBlocksByMembership(
      participant_membership_id: $participant_membership_id
      blocks: ["session", "session__recovery_plan", "session__group_session"]
    ) {
      data
    }
    templateNames: templates(type: "system", staff_id: $staff_id) {
      template_name
      template_title
    }
  }
`
export const ENGAGEMENTS_PARTICIPANT_GROUP_BY_TEMPLATE = gql`
  query EngagementsParticipant(
    $participant_id: ID!
    $idTemplates: [ID]
    $idBlocks: [ID]
    $participant_membership_id: ID
  ) {
    engagementsParticipant: getAllParticipantBlocksGroupByIdTemplatePortal(
      participant_id: $participant_id
      idTemplates: $idTemplates
      idBlocks: $idBlocks
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`
export const CUSTOM_FORMS_BY_PARTICIPANT = gql`
  query CustomFormParticipant(
    $participant_id: ID!
    $idTemplates: [ID]!
    $participant_membership_id: ID
  ) {
    customForm: blocksSavedByParticipantIdTemplateAllOrgs(
      participant_id: $participant_id
      idTemplates: $idTemplates
      participant_membership_id: $participant_membership_id
      groupByIdStamp: true
    ) {
      data
    }
  }
`

import { useField } from "formik"
import { InputText } from "primereact/inputtext"
import { InputMask } from "primereact/inputmask"
import { Skeleton } from "primereact/skeleton"

//Style
import styles from "../../styles/Form-Inputs.module.css"
import { Password } from "primereact/password"

/* interface Props {
    required?: boolean
    name: string;
    label?: string;
    mask?: string;
    disabled?: boolean;
    placeholderText?: string;
}
 */
export default function RefTextInput(props) {
  const [field, meta] = useField(props.name)

  let placeholder = props.placeholderText
  if (props.required && !placeholder) {
    placeholder = props.name
  }

  const inputfield = () => {
    if (props.isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else if (!props.mask && !props.password) {
      return (
        <InputText
          placeholder={placeholder}
          {...field}
          required={props.required}
          disabled={props.disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
        />
      )
    } else if (props.password) {
      return (
        <Password
          placeholder={placeholder}
          {...field}
          required={props.required}
          disabled={props.disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          feedback={false}
        />
      )
    } else {
      return (
        <InputMask
          mask={props.mask}
          placeholder={props.mask}
          {...field}
          required={props.required}
          disabled={props.disabled}
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
        />
      )
    }
  }

  const labelField = () => {
    if (props.isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {props.label}{" "}
          {props.required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={styles.field}>
      {labelField()}
      <div className="col-12 md:col-10">
        {inputfield()}
        {meta.touched && meta.error ? (
          <label id={`${props.name}-error`} className={styles.errFieldMsg}>
            {meta.error}
          </label>
        ) : null}
      </div>
    </div>
  )
}

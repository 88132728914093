import { DateTime } from "luxon"

//Styles
import myStyles from "../../../styles/assessments/AssessmentBlock.module.css"
import AssessmentBlockTaskStaff from "./AssessmentBlockTaskStaff"

export default function AssessmentBlockTask({
  taskProfile,
  taskCompletedDate,
}) {
  if (!taskProfile) {
    return null
  }

  const dueDate = DateTime.fromISO(taskProfile?.dateDue)
  const completedDate = taskCompletedDate
    ? DateTime.fromISO(taskCompletedDate)
    : undefined

  return (
    <div className={myStyles.mainLayoutWithTask}>
      <div className={`${myStyles.mainLayoutWithTaskTitle}  ${myStyles.title}`}>
        Schedule Profile
      </div>
      <div className={myStyles.mainLayoutWithTaskName}>
        <div className={myStyles.taskTitle}>Name</div>
        <div className={myStyles.taskValue}>{taskProfile?.name}</div>
      </div>
      <div className={myStyles.mainLayoutWithTaskStaff}>
        <div className={myStyles.taskTitle}>Staff</div>
        <div className={myStyles.taskValue}>
          <AssessmentBlockTaskStaff taskProfile={taskProfile} />
        </div>
      </div>
      <div className={myStyles.mainLayoutWithDue}>
        <div className={myStyles.taskTitle}>Due Date</div>
        <div className={myStyles.taskValue}>
          {dueDate?.toLocaleString()}
          {!taskProfile?.taskIsDue ? " (Not due)" : ""}
        </div>
      </div>
      <div className={myStyles.mainLayoutWithTaskComplete}>
        <div className={myStyles.taskTitle}>Date Completed</div>
        <div className={myStyles.taskValue}>
          {completedDate?.toLocaleString() || "Not completed"}
        </div>
      </div>
    </div>
  )
}

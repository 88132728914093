import DateChanged from "./DateChanged"
import IntakeId from "./IntakeId"
import OrganizationId from "./OrganizationId"
import ParticipantId from "./ParticipantId"
import ParticipantUserChanged from "./ParticipantUserChanged"
import SessionId from "./SessionId"
import SessionLenght from "./SessionLenght"
import StaffId from "./StaffId"

export default function TypeHidden(props) {
  switch (props.field.field_name) {
    case "date_changed":
      return <DateChanged {...props} />
    case "intake_id":
      return <IntakeId {...props} />
    case "organization_id":
      return <OrganizationId {...props} />
    case "participant_id":
      return <ParticipantId {...props} />
    case "participant_user_changed":
      return <ParticipantUserChanged {...props} />
    case "session_id":
      return <SessionId {...props} />
    case "session_length":
      return <SessionLenght {...props} />
    case "staff_id":
      return <StaffId {...props} />
    default:
      return null
  }
}

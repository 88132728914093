import _ from "lodash"

export default function TypeNumber({ value, description }) {
  let newValue = ""
  if (!_.isEmpty(description)) {
    newValue = "<strong>" + description + "</strong><br>" + value
  } else {
    newValue = value
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: newValue,
      }}
    />
  )
}

import React from "react"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

export default function Notifications() {
  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <h1>Notifications</h1>
      </div>
    </div>
  )
}

import _ from "lodash"

export default function initialValues(data) {
  const multiFieldsToObj = (mf) => {
    const handleNested = (nestedMF) =>
      _.reduce(
        nestedMF,
        (acc, { field_name }) => {
          return {
            ...acc,
            [field_name]: "",
          }
        },
        {}
      )
    return _.reduce(
      mf,
      (acc, { field_name, field_type, multi_fields }) => {
        if (field_type === "multi_field_nested") {
          return {
            ...acc,
            [field_name]: [handleNested(multi_fields)],
          }
        }
        return {
          ...acc,
          [field_name]: "",
        }
      },
      {}
    )
  }
  const fieldsPerBlock = (block) => {
    return _.reduce(
      block.fields,
      (acc, field) => {
        if (field.field_type === "checkbox") {
          return {
            ...acc,
            [field.field_name]: [],
          }
        }
        if (field.field_type === "multi_field") {
          return {
            ...acc,
            [field.field_name]: [multiFieldsToObj(field.multi_fields)],
          }
        }
        return {
          ...acc,
          [field.field_name]: "",
        }
      },
      {}
    )
  }
  return _.reduce(
    data.template.blocks,
    (acc, block) => {
      const fields = fieldsPerBlock(block)
      return {
        ...acc,
        ...fields,
      }
    },
    {}
  )
}

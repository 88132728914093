import { useEffect, useState } from "react"

//styles
import noteStyle from "../../../styles/NotificationsForm.module.css"

//primeReact
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { Checkbox } from "primereact/checkbox"

//Formik
import { Formik, Form, useField, useFormikContext } from "formik"

export default function NotificationsFilters(props) {
  const [currentFilters, setCurrentFilters] = useState({
    createDateEnd: "",
    createDateStart: "",
    eventType: "All",
    includeRead: false,
    includeUnread: true,
    readDateEnd: "",
    readDateStart: "",
    search: "",
    sort: "newest",
  })
  const [organizationFilters, setOrganizationFilters] = useState([
    { organization_id: "All", organization_name: "All organizations" },
  ])

  useEffect(() => {
    setCurrentFilters(props.filters)
  }, [props.filters])

  useEffect(() => {
    const org = [
      { organization_id: "All", organization_name: "All organizations" },
      ...props.organizations,
    ]
    setOrganizationFilters(org)
  }, [props.organizations])

  function NoteTextBox({ name }) {
    const [field] = useField(name)

    return (
      <InputText
        placeholder="Search..."
        className={noteStyle.noteFilterTextboxWidth}
        {...field}
      />
    )
  }

  function NoteDropDown({ name, options, label, value, placeholder }) {
    const [field] = useField(name)

    return (
      <Dropdown
        className={noteStyle.noteFilterDropdownWidth}
        options={options}
        optionLabel={label}
        optionValue={value}
        placeholder={placeholder}
        {...field}
      />
    )
  }

  function NoteCalendar({ name, endOfDay }) {
    const [field, , helpers] = useField(name)
    const { values } = useFormikContext()

    return (
      <Calendar
        className={noteStyle.noteFilterCalender}
        name={name}
        placeholder="mm/dd/yyyy"
        onChange={(e) => {
          let val = e.value
          val.setHours(endOfDay ? 23 : 0)
          val.setMinutes(endOfDay ? 59 : 0)
          val.setSeconds(endOfDay ? 59 : 0)
          console.log(val)
          helpers.setValue(val)
          props.onSetFilters(values)
        }}
        value={field.value}
        onBlur={field.onBlur}
      />
    )
  }

  function NoteCheckBox({ name, label }) {
    const [field, , helpers] = useField(name)
    const { values } = useFormikContext()

    return (
      <>
        <Checkbox
          inputId={name}
          name={name}
          checked={field.value}
          onChange={(e) => {
            helpers.setValue(e.checked)
            props.onSetFilters(values)
          }}
          value={field.value}
          onBlur={field.onBlur}
        />
        <label htmlFor={name} style={{ marginLeft: "10px" }}>
          {label}
        </label>
      </>
    )
  }

  return (
    <div>
      <Formik
        initialValues={currentFilters}
        enableReinitialize
        onSubmit={(values) => {
          props.onSetFilters(values)
          props.onApplyFilters(values)
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div
              className={`${noteStyle.noteFilterGridContainer} ${noteStyle.addSpace}`}
            >
              <div className={noteStyle.noteFilterGridItemTitleSearch}>
                Event title or description:
              </div>
              <div className={noteStyle.noteFilterGridItemEventType}>
                Filter by event type:
              </div>
              <div className={noteStyle.noteFilterGridItemSort}>
                Sort order:
              </div>
              <div className={noteStyle.noteFilterGridItemOrg}>
                Filter by organization:
              </div>
              <div
                className={`${noteStyle.noteFilterGridItemSearchBox} ${noteStyle.addSpace}`}
              >
                <NoteTextBox name="search" />
              </div>
              <div className={noteStyle.noteFilterGridItemEventTypeDD}>
                <NoteDropDown
                  name="eventType"
                  options={props.eventTypes}
                  label="friendlyName"
                  value="name"
                  placeholder="Select an event type"
                />
              </div>
              <div
                className={`${noteStyle.noteFilterGridItemSortDD}  ${noteStyle.addSpace}`}
              >
                <NoteDropDown
                  name="sort"
                  options={[
                    { name: "Newest first (Default)", value: "newest" },
                    { name: "Oldest first", value: "oldest" },
                  ]}
                  label="name"
                  value="value"
                  placeholder="Select a sort direction"
                />
              </div>
              <div className={noteStyle.noteFilterGridItemOrgDD}>
                <NoteDropDown
                  name="organization"
                  options={organizationFilters}
                  label="organization_name"
                  value="organization_id"
                  placeholder="Select a sort direction"
                />
              </div>
              <div className={`${noteStyle.noteFilterGridItemCreated}`}>
                Filter by date created:
              </div>
              <div className={`${noteStyle.noteFilterGridItemCreatedFrom}`}>
                <div>From:</div>
                <NoteCalendar name="createDateStart" endOfDay={false} />
              </div>
              <div className={`${noteStyle.noteFilterGridItemCreatedTo} `}>
                <div>To:</div>
                <NoteCalendar name="createDateEnd" endOfDay={true} />
              </div>
              <div className={noteStyle.noteFilterGridItemRead}>
                Filter by date read:
              </div>
              <div className={`${noteStyle.noteFilterGridItemReadFrom}`}>
                <div>From:</div>
                <NoteCalendar name="readDateStart" endOfDay={false} />
              </div>
              <div className={`${noteStyle.noteFilterGridItemReadTo}`}>
                <div>To:</div>
                <NoteCalendar name="readDateEnd" endOfDay={true} />
              </div>
            </div>
            <div>
              <div className={`${noteStyle.addSpace}`}>
                <NoteCheckBox
                  name="includeUnread"
                  label="Include unread messages"
                />
              </div>
              <div className={`${noteStyle.addSpace}`}>
                <NoteCheckBox
                  name="includeRead"
                  label="Include read messages"
                />
              </div>
              <div>
                <button className="btn" type="submit">
                  Apply
                </button>
                <button
                  className="btn"
                  type="button"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setCurrentFilters(props.defaultFilters)
                    props.onApplyFilters(props.defaultFilters)
                    props.onSetFilters(props.defaultFilters)
                    resetForm()
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

import React, { useContext, useState } from "react"
import { UserContext } from "../../context/userContext"

import { useQuery } from "@apollo/client"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

import { Link } from "react-router-dom"
import { GET_ENGAGED_SERVICES_BY_PARTICIPANT_ID } from "./queries"
import { Button } from "primereact/button"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import styles from "../../styles/Page-with-Sidebar.module.css"
import _ from "lodash"
import { Dropdown } from "primereact/dropdown"
import { DateTime } from "luxon"

import LoaderFullPage from "../../components/Loaders"
import RequestDialog from "./components/RequestDialog"

export default function EngagedServices() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const user = useContext(UserContext)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [servicesFilter, setServiceFilter] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)
  const [locationFilter, setLocationFilter] = useState(null)

  const [services, setServices] = useState([])

  const ID_TEMPLATES = ["participant_profile"]
  const ID_BLOCKS = ["participant__engaged_services"]

  const [notificationType, setNotificationType] = useState("")
  const [staffID, setStaffID] = useState(null)
  const [assignedStaff, setAssignedStaff] = useState(null)
  const [dialog, setDialog] = useState(false)
  const [assignedOrganization, setAssignedOrganization] = useState(null)

  useQuery(GET_ENGAGED_SERVICES_BY_PARTICIPANT_ID, {
    variables: {
      participant_id: user.participant_id,
      idTemplates: ID_TEMPLATES,
      idBlocks: ID_BLOCKS,
    },
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      if (data.engagementsParticipant === null) {
        setData(null)
        return setLoading(false)
      }
      const dataFormatted = JSON.parse(data.engagementsParticipant.data)

      let servicesPeerRow = {}
      let servicesResourceReferralRow = {}
      let servicesProSocial = {}
      let servicesAdvocacy = {}
      let servicesEducational = {}
      let servicesEmployment = {}
      let servicesFamily = {}
      let servicesNaloxone = {}
      let servicesMutualAidMeetings = {}
      let servicesHarmReduction = {}
      let servicesReentry = {}
      let servicesOther = {}
      let servicesOtherType = {}
      let combined = {}
      let combinedArr = []

      const handleData = () =>
        _.map(dataFormatted, (template) => {
          if (template.assigned_staff_id === null) {
            template.assigned_staff_id = "-4"
            template.assigned_staff_name = "Unassigned Staff"
          }

          _.forOwn(template.values, function (value, key) {
            switch (key) {
              case "services_peer":
                servicesPeerRow = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Peer Services",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_peer_service")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_peer_service")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesPeerRow)
                combinedArr.push(servicesPeerRow)
                break
              case "services_resource_referral":
                servicesResourceReferralRow = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Resource referral",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType(
                            "discontinue_services_resource_referral"
                          )
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType(
                            "request_services_resource_referral"
                          )
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesResourceReferralRow)
                combinedArr.push(servicesResourceReferralRow)
                break
              case "services_prosocial":
                servicesProSocial = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Pro-social Activities",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_prosocial")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_prosocial")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesProSocial)
                combinedArr.push(servicesProSocial)
                break
              case "services_advocacy":
                servicesAdvocacy = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Advocacy activities",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_advocacy")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_advocacy")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesAdvocacy)
                combinedArr.push(servicesAdvocacy)
                break
              case "services_educational":
                servicesEducational = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Educational activities",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType(
                            "discontinue_services_educational"
                          )
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_educational")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesEducational)
                combinedArr.push(servicesEducational)
                break
              case "services_employment":
                servicesEmployment = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Employment activities",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_employment")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_employment")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesEmployment)
                combinedArr.push(servicesEmployment)
                break
              case "services_family":
                servicesFamily = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Family recovery support",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_family")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_family")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesFamily)
                combinedArr.push(servicesFamily)
                break
              case "services_naloxone":
                servicesNaloxone = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Naloxone",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_naloxone")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_naloxone")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesNaloxone)
                combinedArr.push(servicesNaloxone)
                break
              case "services_mutual_aid_meetings":
                servicesMutualAidMeetings = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Mutual aid meetings",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType(
                            "discontinue_services_mutual_aid_meetings"
                          )
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType(
                            "request_services_mutual_aid_meetings"
                          )
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesMutualAidMeetings)
                combinedArr.push(servicesMutualAidMeetings)
                break
              case "services_harm_reduction":
                servicesHarmReduction = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Harm reduction services",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType(
                            "discontinue_services_harm_reduction"
                          )
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_harm_reduction")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesHarmReduction)
                combinedArr.push(servicesHarmReduction)
                break
              case "services_reentry":
                servicesReentry = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Reentry support",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_reentry")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_reentry")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesReentry)
                combinedArr.push(servicesReentry)
                break
              case "services_other":
                servicesOther = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Other services",
                  status: value === "Yes" ? "Engaged" : "Not Engaged",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_other")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_other")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesOther)
                combinedArr.push(servicesOther)
                break
              case "services_other_type":
                servicesOtherType = {
                  service_updated: handleDate(template.date_modified._seconds),
                  service: "Other service",
                  status: !_.isEmpty(value) ? value : "--",
                  organization: template.organization_name,
                  location: template.location_name,
                  staff: template.assigned_staff_name,
                  view:
                    value === "Yes" ? (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("discontinue_services_other_type")
                        }}
                      >
                        Discontinue service
                      </Button>
                    ) : (
                      <Button
                        className="btn"
                        onClick={() => {
                          setAssignedStaff(template.assigned_staff_id)
                          setAssignedOrganization(template.organization_id)
                          setStaffID(template.staff_id)
                          setDialog(true)
                          setNotificationType("request_services_other_type")
                        }}
                      >
                        Request service
                      </Button>
                    ),
                }
                combined = Object.assign(combined, servicesOtherType)
                combinedArr.push(servicesOtherType)
                break

              default:
                break
            }
          })

          return combinedArr
        })
      handleData()
      setServices(combinedArr)

      setData(combinedArr)

      const allLocations = _.map(combinedArr, ({ location }) => location)
      setLocationFilter(_.uniq(allLocations).sort())

      const allOrganizations = _.map(
        combinedArr,
        ({ organization }) => organization
      )
      setOrganizationFilter(_.uniq(allOrganizations).sort())

      const allServices = _.map(combinedArr, ({ service }) => service)
      setServiceFilter(_.uniq(allServices).sort())

      const allStatus = _.map(combinedArr, ({ status }) => status)
      setStatusFilter(_.uniq(allStatus).sort())

      setLoading(false)
    },
  })

  const serviceFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={servicesFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Service"
        className="p-column-filter"
        showClear
      />
    )
  }

  const statusFilters = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statusFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    )
  }

  const organizationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={organizationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select an Organization"
        className="p-column-filter"
        showClear
      />
    )
  }
  const locationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={locationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Location"
        className="p-column-filter"
        showClear
      />
    )
  }

  const handleDate = (sec) => {
    const d = DateTime.fromSeconds(sec)
    if (d.invalid) return ""

    const newDate = d.toFormat("MM-dd-yyyy")

    return newDate
  }

  if (loading) return <LoaderFullPage />

  const dialogProps = {
    dialog,
    setDialog,
    setAssignedStaff,
    setAssignedOrganization,
    setStaffID,
    assignedStaff,
    notificationType,
    assignedOrganization,
    staffID,
  }

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      <RequestDialog {...dialogProps} />
      <div className="main-content">
        <div>
          <div>
            <h2 className={styles.title}>Engaged Services</h2>

            <DataTable
              value={_.compact(data)}
              responsiveLayout="scroll"
              sortField="organization"
              sortOrder={1}
              sortable
            >
              <Column
                field="service_updated"
                header="Service Updated"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />
              <Column
                field="service"
                header="Service"
                filterElement={serviceFilter}
                filter
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />

              <Column
                field="status"
                header="Service Status"
                filterElement={statusFilters}
                filter
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />

              <Column
                field="organization"
                header="Organization"
                filter
                showFilterMatchModes={false}
                filterElement={organizationsFilter}
                showFilterMenuOptions={false}
                sortable
              />
              <Column
                field="location"
                header="Location"
                filter
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                filterElement={locationsFilter}
                sortable
              />

              <Column
                field="staff"
                header="Assigned Staff"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />
              <Column field="view" header="Actions" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

import { useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"
import { save } from "save-file"
import HelloSign from "hellosign-embedded"

//PrimeReact
import { Paginator } from "primereact/paginator"
import { AccordionTab, Accordion } from "primereact/accordion"

//SOAR Components
import { LoaderMedium } from "../../../components/Loaders"
import { UserContext } from "../../../context/userContext"
import { LogDebug, LogError, LogWarning } from "../../../helpers/logger"

//documentSigning imports
import {
  docSigningApiSignatureRequestsDownloadGet,
  docSigningApiSignURLGet,
  docSigningApiSignatureRequestsListPost,
  docSigningApiHelloSignConfigGet,
  docSigningApiRequestGet,
} from "../documentsApi"
import RequestsViews from "./RequestsViews"
import RequestsFilterHeader from "./RequestsFilterHeader"
import RequestsFilters from "./RequestsFilters"

//graphQL
import { useLazyQuery, useQuery } from "@apollo/client"
import {
  GET_PARTICIPANT_ORGANIZATIONS,
  GET_USERS,
  GET_USERS_FROM_LIST,
} from "../queries"
import { useClientMethod, useHub } from "react-use-signalr"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { documentSigningApiUrl } from "../../../config/microservicesUrls"

export default function Requests({ defaultFilterStatus }) {
  const {
    tokenDocuments,
    setNotificationError,
    participant_id,
    participant_alias,
    organization_id,
    organization_alias,
    participant_name_first,
    participant_name_last,
  } = useContext(UserContext)

  const requestSortOptions = [
    { display: "Title", field: "title" },
    { display: "Requested By", field: "requestCreatorName" },
    { display: "Date Requested", field: "requestCreatedAt" },
    { display: "Date Completed", field: "completedDate" },
    { display: "Status", field: "status" },
  ]

  const [layoutOption, setLayoutOption] = useState("grid")
  const [loading, setLoading] = useState(true)
  const [appliedFilters, setAppliedFilters] = useState({
    dateCreated: [],
    search: "",
    organizations: [],
    status: !!defaultFilterStatus ? defaultFilterStatus : "all",
    sort: requestSortOptions[2],
    sortDirection: "Descending",
  })
  const [appliedQuickFilter, setAppliedQuickFilter] = useState("my_requests")
  const [documents, setDocuments] = useState(null)
  const [helloSignConfig, setHelloSignConfig] = useState(undefined)
  const [users, setUsers] = useState([
    {
      name: "Participants",
      items: [
        {
          signerId: participant_id,
          signerAlias: participant_alias,
          name: `${participant_name_first} ${participant_name_last}`,
        },
      ],
    },
  ])
  const [staffUsers, setStaffUsers] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [loadingOrganizations, setLoadingOrganizations] = useState(true)

  // SignalR
  const [connection, setConnection] = useState(null)
  const { hubConnectionState, error: hubError } = useHub(connection)
  useClientMethod(connection, "HelloSignEvent", (data) => {
    HandleHelloSignEvent(data)
  })
  const isTestMode = process.env.REACT_APP_STAGE === "development"

  const stripMetaKey = (value) => {
    try {
      return value.split("||")[0]
    } catch (error) {
      return value
    }
  }

  const assignedToMe = (request) => {
    let mySignature = undefined
    request.signatures.forEach((signature) => {
      const signerId = stripMetaKey(request.metadata[signature.signerRole])

      if (signerId === participant_id || signerId === participant_alias) {
        mySignature = signature
      }
    })

    return mySignature
  }

  const [get_users] = useLazyQuery(GET_USERS, {
    variables: { participant_id: participant_id, organization_id: undefined },
    fetchPolicy: "network-only",
  })

  const [get_users_in_list] = useLazyQuery(GET_USERS_FROM_LIST, {
    variables: {},
    fetchPolicy: "network-only",
  })

  useQuery(GET_PARTICIPANT_ORGANIZATIONS, {
    variables: { participant_id: participant_id },
    fetchPolicy: "network-only",
    onCompleted: async (results) => {
      if (!!results && results.organizationsByPid.length > 0) {
        setOrganizations(results.organizationsByPid)

        //Retrieve staff users per organization for display purposes
        let staff_userQueries = []
        results.organizationsByPid.forEach((org) => {
          staff_userQueries.push(
            get_users({
              variables: {
                participant_id: participant_id,
                organization_id: org.organization_id,
              },
            })
          )
        })

        const staff_results = await Promise.all(staff_userQueries)

        let staff_users = []
        staff_results.forEach((staff_Result) => {
          if (!!staff_Result.data) {
            staff_users.push(
              ...staff_Result.data.staffsByPid.map((user) => {
                return {
                  ...user,
                  signerId: `${user.staff_id}-STAFF`,
                  name: `${user.staff_name_first} ${user.staff_name_last}`,
                }
              })
            )
          }
        })

        setUsers([
          {
            name: "Participants",
            items: [
              {
                signerId: participant_id,
                signerAlias: participant_alias,
                name: `${participant_name_first} ${participant_name_last}`,
              },
            ],
          },
          { name: "Staff", items: [...staffUsers, ...staff_users] },
        ])

        setStaffUsers([...staffUsers, ...staff_users])

        setLoadingOrganizations(false)
      } else {
        setNotificationError("No organizations found")
        setOrganizations([])
        setLoadingOrganizations(false)
      }
    },
    onError: (error) => {
      setNotificationError("Unable to load organizations")
      setOrganizations([])
      setLoadingOrganizations(false)
      LogError("Unable to load organizations", error)
    },
  })

  const checkUserProfiles = async (requests) => {
    let userIds = []
    requests.forEach((request) => {
      request.signatures.forEach((signature) => {
        userIds.push(
          stripMetaKey(request.metadata[signature.signerRole]).replace(
            "-STAFF",
            ""
          )
        )
      })
    })

    userIds = userIds.filter((id) => {
      return (
        id !== participant_id && !staffUsers.some((staff) => staff.id === id)
      )
    })
    userIds = Array.from(new Set(userIds))

    let staff_users = await get_users_in_list({
      variables: { list: userIds },
    })

    staff_users = staff_users.data.users.filter((user) => {
      return !!user && !!user.staff_id
    })

    setStaffUsers([
      ...staffUsers,
      ...staff_users.map((user) => {
        return {
          ...user,
          signerId: `${user.staff_id}-STAFF`,
          signerAlias: `${user.staff_alias}-STAFF`,
          name: `${user.staff_name_first} ${user.staff_name_last}`,
        }
      }),
    ])
  }

  const getRequests = async (page, pageSize, filters) => {
    setLoading(true)
    try {
      const useFilters = filters || appliedFilters

      // Build request
      const requestBody = {
        groupIds: [
          ...organizations.map((x) => x.organization_id),
          ...organizations
            .filter((org) => org.organization_alias > 0)
            .map((org) => org.organization_alias),
        ],
        pageNum: page || 1,
        pageSIze: pageSize || 101,
      }

      if (appliedFilters.organizations?.length > 0) {
        requestBody.groupIds = [
          ...appliedFilters.organizations.map((org) => org.organization_id),
          ...appliedFilters.organizations
            .filter((org) => org.organization_alias > 0)
            .map((org) => org.organization_alias),
        ]
      }

      if (useFilters.dateCreated.length > 0) {
        let eopFilter = !!useFilters.dateCreated[1]
          ? useFilters.dateCreated[1]
          : useFilters.dateCreated[0]
        eopFilter.setHours(23)
        eopFilter.setMinutes(59)
        eopFilter.setSeconds(59)

        requestBody.createDateFilter = [
          DateTime.fromJSDate(useFilters.dateCreated[0]).toUTC().toISO(),
          DateTime.fromJSDate(eopFilter).toUTC().toISO(),
        ]
      }

      if (useFilters.search) {
        requestBody.searchFilter = useFilters.search
      }

      if (useFilters.status) {
        requestBody.statusFilter = useFilters.status
      }

      //Always filter on my participantId or alias
      requestBody.recipientIds = [participant_id]

      if (participant_alias > 0) {
        requestBody.recipientIds.push(participant_alias)
      }

      // Execute request
      let data = await docSigningApiSignatureRequestsListPost(
        tokenDocuments,
        requestBody
      )

      if (data.items?.length > 0) {
        await checkUserProfiles(data.items)
        const getStatus = (request) => {
          let status = "No status available"

          if (request.isComplete) {
            status = "Complete"
          } else if (request.hasError) {
            status = "Error"
          } else if (request.numSignersComplete < request.numSignersTotal) {
            status = `Awaiting signature (${request.numSignersComplete} of ${request.numSignersTotal} complete)`
          }

          return status
        }

        const getOrder = (request) => {
          if (!request.assignedToMe) {
            return "N/A"
          } else {
            return `${request.mySignatureRequest?.order || "0"} ${
              request.mySignatureRequest?.order === 1
                ? "person needs"
                : "people need"
            }  
              to sign before you.`
          }
        }

        data.items.forEach((request) => {
          request.mySignatureRequest = assignedToMe(request)
          request.assignedToMe = !!request.mySignatureRequest
          request.status = getStatus(request)
          request.canSignNow =
            request.assignedToMe &&
            request.numSignersComplete >= request.mySignatureRequest?.order &&
            request.mySignatureRequest.statusCode === "awaiting_signature"
          request.orderText = getOrder(request)
          request.requestCreatorId = stripMetaKey(request?.metadata?.Sender)
          request.requestCreatorName = stripMetaKey(
            request?.metadata?.SenderName
          )
          //created date value
          request.requestCreatedAt = new Date(
            stripMetaKey(request?.metadata?.CreatedAt)
          )
          //Created display string
          request.requestCreatedAtLocal =
            request.requestCreatedAt.toLocaleString()
          //Date that the request was completed
          request.completedDate =
            request.signatures[0]?.statusCode === "signed"
              ? new Date(request.signatures[0].signedAt)
              : undefined
          //Display value of when request was completed
          request.completedDateLocal =
            request.signatures[0]?.statusCode === "signed"
              ? new Date(request.signatures[0].signedAt).toLocaleString()
              : "Not Completed"
        })
      }

      //Sort data
      data = sortRequests(
        appliedFilters.sort,
        appliedFilters.sortDirection,
        data
      )

      // Set data
      setDocuments(data)
      setLoading(false)
      return data
    } catch (error) {
      LogError("Unable to list requests", error)
      setNotificationError(
        "Unable to list signature requests. See console for details."
      )
      setDocuments([])
      setLoading(false)
    }
  }

  function sortRequests(sortOption, sortDirection, requests) {
    let sortedRequests = JSON.parse(JSON.stringify(requests))
    sortedRequests.items.forEach((request) => {
      request.requestCreatedAt = new Date(request.requestCreatedAt)
      request.completedDate = !!request.completedDate
        ? new Date(request.completedDate)
        : undefined
    })

    const sortField = sortOption.field

    if (sortDirection === "Descending") {
      sortedRequests.items.sort((a, b) => {
        const result =
          a[sortField] > b[sortField] ? -1 : a[sortField] < b[sortField] ? 1 : 0
        return result
      })
    } else {
      sortedRequests.items.sort((a, b) => {
        const result =
          a[sortField] < b[sortField] ? -1 : a[sortField] > b[sortField] ? 1 : 0
        return result
      })
    }

    return sortedRequests
  }

  async function ValidateRequestGroup(signatureRequest) {
    try {
      if (signatureRequest) {
        // Extract GID
        const tGID = signatureRequest.metadata?.GroupId?.split("||")[0]

        // Compare GID
        if (tGID === organization_id) {
          return true
        }
      }
    } catch (error) {
      LogWarning(error)
    }

    return false
  }

  async function GetRequestDetails(signatureRequestId, request) {
    try {
      if (!request || !request.metadata) {
        return await docSigningApiRequestGet(tokenDocuments, signatureRequestId)
      } else {
        return request
      }
    } catch (error) {
      LogError("Unable to get signature request details.", error)
      setNotificationError(
        "Unable to get signature request details. See console for details."
      )
    }
  }

  // userKey = array of "Sender" and/or "Signer"
  //   ex.1: ["Sender"]
  //   ex.2: ["Sender", "Signer"]
  //   ex.3: ["Signer"]
  function ValidateRequestUser(signatureRequest, userKey) {
    try {
      if (signatureRequest) {
        for (const key of Object.keys(signatureRequest.metadata)) {
          if (
            signatureRequest.metadata[key].includes(userKey[0]) ||
            signatureRequest.metadata[key].includes(userKey[1])
          ) {
            // Extract ID
            const userId = signatureRequest.metadata[key]
              .split("||")[0]
              ?.replace("-STAFF", "")
            if (userId && userId === participant_id) {
              return true
            }
          }
        }
      }
    } catch (error) {
      console.warn(error)
    }

    return false
  }

  async function HandleHelloSignEvent(data) {
    const dataObj = JSON.parse(data)
    const request = dataObj?.signature_request

    if (isTestMode) LogDebug("HS Event Data", dataObj)

    // eslint-disable-next-line default-case
    switch (dataObj.event.event_type) {
      case "signature_request_invalid":
      case "file_error":
      case "unknown_error":
        if (
          ValidateRequestGroup(
            GetRequestDetails(
              dataObj.signature_request?.signature_request_id,
              request
            )
          ) &&
          ValidateRequestUser(request, ["Signer"])
        ) {
          setNotificationError(
            "Something went wong while processing a signature request. Please try again if this was your request. Refresh to update your list."
          )
          console.error("Signature-request send error", dataObj)
        }
        break
    }
  }

  function SetupSignalRConnection() {
    const api_url = documentSigningApiUrl()
    // Setup Connection
    const connection = new HubConnectionBuilder()
      .withUrl(`${api_url}/hubs/hellosign`)
      .configureLogging(isTestMode ? LogLevel.Debug : LogLevel.Warning)
      .build()

    connection?.onclose(async () => {
      connection?.start().catch((error) => {
        console.warn(error)
      })
    })

    setConnection(connection) // Save connection to state
  }

  useEffect(() => {
    if (!connection) {
      SetupSignalRConnection()
    }

    // Monitor for SignalR errors
    if (hubError) {
      LogWarning(
        "SignalR Warning: ",
        hubError,
        `\nConnection State: ${hubConnectionState}`
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, hubError])

  useEffect(() => {
    if (organizations.length > 0) {
      getRequests()
    }
    if (!helloSignConfig) {
      getHelloSignConfig()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, organization_id, defaultFilterStatus])

  const getHelloSignConfig = async () => {
    try {
      const config = await docSigningApiHelloSignConfigGet(tokenDocuments)
      setHelloSignConfig(config)
    } catch (error) {
      LogError(error)
      setNotificationError(
        "Unable to retrieve Hello Sign configuration. See console for details."
      )
    }
  }

  const downloadRequestDocument = async (signatureRequestId) => {
    setLoading(true)
    try {
      const res = await docSigningApiSignatureRequestsDownloadGet(
        tokenDocuments,
        signatureRequestId
      )
      save(res, `${signatureRequestId}.pdf`)
      setLoading(false)
    } catch (error) {
      setNotificationError(
        `Unable to download signature request documents. See console for details.`
      )
      LogError("Unable to download signature request documents", error)
      setLoading(false)
    }
  }

  function blockZooming() {
    const viewport = document.querySelector("meta[name=viewport]")
    if (viewport) {
      let content = viewport.getAttribute("content") || ""
      const newContent = content.split(/,\s?/)
      // Prevent browsers from automatically zooming into text fields
      if (!content.includes("maximum-scale=1")) {
        newContent.push("maximum-scale=1")
        content = newContent.join(", ")
      }
      if (content !== viewport.getAttribute("content")) {
        viewport.setAttribute("content", content)
      }
    }
  }

  const signDocument = async (signatureRequest) => {
    setLoading(true)
    try {
      const res = await docSigningApiSignURLGet(
        tokenDocuments,
        signatureRequest.mySignatureRequest.signatureId
      )

      if (res && res.signUrl) {
        const client = new HelloSign({
          clientId: helloSignConfig.clientId,
          skipDomainVerification: helloSignConfig.mode === "Test",
        })

        blockZooming() // work-around for mobile signing
        client.open(res.signUrl)
      }
      setLoading(false)
    } catch (error) {
      let message = "Unable to sign the request. See console for details."

      if (
        error.message
          ?.toLowerCase()
          .includes("this request has already been signed")
      ) {
        message =
          "Unable to sign the request. This request has already been signed. Please refresh your list"
      }

      if (
        error.message?.toLowerCase().includes("request cannot be signed yet")
      ) {
        message =
          "This request cannot be signed yet. There are other signers that need to sign before you."
      }

      setNotificationError(message)
      LogError(message, error)
      setLoading(false)
    }
  }

  return (
    <>
      <Accordion style={{ marginBottom: "10px" }} activeIndex={0}>
        <AccordionTab
          header={
            <RequestsFilterHeader
              layoutOption={layoutOption}
              appliedQuickFilter={appliedQuickFilter}
              setLayoutOptionCallBack={setLayoutOption}
              getRequestsCallBack={getRequests}
              currentSortOption={appliedFilters.sort}
              currentSortDirection={appliedFilters.sortDirection}
              onSetAppliedFilters={(filters, filterName) => {
                if (
                  filters.dateCreated !== appliedFilters.dateCreated ||
                  filters.search !== appliedFilters.search ||
                  filters.status !== appliedFilters.status ||
                  filters.organizations !== appliedFilters.organizations
                ) {
                  setAppliedFilters(filters)
                  setAppliedQuickFilter(filterName)
                  getRequests(undefined, undefined, filters)
                }
              }}
              disabled={loading || loadingOrganizations}
            />
          }
          disabled={loading || loadingOrganizations}
        >
          <RequestsFilters
            userOptions={users}
            organizations={organizations}
            filters={appliedFilters}
            sortOptions={requestSortOptions}
            onChangeFilters={(filters) => {
              if (
                filters.dateCreated !== appliedFilters.dateCreated ||
                filters.search !== appliedFilters.search ||
                filters.status !== appliedFilters.status ||
                filters.organizations !== appliedFilters.organizations
              ) {
                setAppliedFilters(filters)
                setAppliedQuickFilter("")
                getRequests(undefined, undefined, filters)
              }
            }}
            onChangeSortField={(option) => {
              const sortedRequests = sortRequests(
                option.sort,
                option.sortDirection,
                documents
              )
              setDocuments(sortedRequests)

              let updatedFilters = appliedFilters
              updatedFilters.sort = option.sort
              updatedFilters.sortDirection = option.sortDirection
              setAppliedFilters(updatedFilters)
            }}
            isLoading={loading || loadingOrganizations}
          />
        </AccordionTab>
      </Accordion>
      {loading || loadingOrganizations ? (
        <LoaderMedium />
      ) : (
        <>
          <RequestsViews
            documents={documents}
            layoutOption={layoutOption}
            users={users}
            appliedTableSorting={{
              sortField: appliedFilters.sort.field,
              sortOrder: appliedFilters.sortDirection === "Descending" ? -1 : 1,
            }}
            onDownloadRequest={downloadRequestDocument}
            onSignRequest={signDocument}
            onSortRequests={(fieldName, sortOrder) => {
              let sortOption = requestSortOptions[2]

              switch (fieldName) {
                case "title":
                  sortOption = requestSortOptions[0]
                  break
                case "requestCreatorName":
                  sortOption = requestSortOptions[1]
                  break
                case "completedDate":
                  sortOption = requestSortOptions[3]
                  break
                case "status":
                  sortOption = requestSortOptions[4]
                  break
                default:
                  break
              }

              const direction =
                sortOrder === -1 || sortOrder === -0
                  ? "Descending"
                  : "Ascending"

              setAppliedFilters({
                dateCreated: appliedFilters.dateCreated,
                search: appliedFilters.search,
                staff: appliedFilters.staff,
                participants: appliedFilters.participants,
                status: appliedFilters.status,
                sort: sortOption,
                sortDirection: direction,
              })

              setDocuments(sortRequests(sortOption, direction, documents))
            }}
          />
          <Paginator
            first={documents?.pageNum || 1}
            rows={documents?.pageSize || 10}
            totalRecords={documents?.numResults || 0}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={(event) => {
              getRequests(event.page + 1, event.rows)
            }}
          />
        </>
      )}
    </>
  )
}

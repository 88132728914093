import { DateTime } from "luxon"

export default function TypeDate({ value, description }) {
  return (
    <div>
      <strong>{description} </strong>
      {DateTime.fromISO(value).toISODate()}
    </div>
  )
}

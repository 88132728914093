import { useContext } from "react"
import { UserContext } from "../../../context/userContext"
import RequestViewCard from "./RequestsViewCard"
import DocumentViewTable from "./RequestsViewTable"

const RequestsViews = ({
  documents,
  layoutOption,
  appliedTableSorting,
  users,
  onCancelRequest,
  onDownloadRequest,
  onSignRequest,
  onSortRequests,
}) => {
  const user = useContext(UserContext)

  const stripMetaKey = (value) => {
    try {
      return value.split("||")[0]
    } catch (error) {
      return value
    }
  }

  if (documents?.items?.length > 0) {
    if (layoutOption === "grid") {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {documents.items.map((request, index) => {
            return (
              <RequestViewCard
                request={request}
                stripMetaKey={stripMetaKey}
                users={users}
                onCancelRequest={onCancelRequest}
                onDownloadRequest={onDownloadRequest}
                onSignRequest={onSignRequest}
                key={index}
              />
            )
          })}
        </div>
      )
    } else {
      return (
        <DocumentViewTable
          documents={documents}
          stripMetaKey={stripMetaKey}
          appliedSorting={appliedTableSorting}
          users={users}
          onCancelRequest={onCancelRequest}
          onDownloadRequest={onDownloadRequest}
          onSignRequest={onSignRequest}
          onSortRequests={onSortRequests}
        />
      )
    }
  } else {
    return <>No requests found</>
  }
}
export default RequestsViews

import { useEffect } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"
import * as Types from "../index"

import TypeDropdown from "../TypeDropdown"
import TypeRadio from "../TypeRadio"
import TypeText from "../TypeText"
import TypeTextarea from "../TypeTextarea"
import TypePassword from "../TypePassword"
import TypeDate from "../TypeDate"
import TypeEmail from "../TypeEmail"
import TypeNumber from "../TypeNumber"
import TypeParticipantEmail from "../TypeParticipantEmail"
import TypeTel from "../TypeTel"
import TypeCheckbox from "../TypeCheckbox"
import TypeTextareaRich from "../TypeTextareaRich"
//import TypeDropDownList from "../TypeDropDownList"
//import TypeMultiSelect from "../TypeMultiSelect"
import TypeSys from "../TypeSys"

// podria tener su refactor este ticket
export default function TypeConditional(props) {
  const { values, initialValues, setFieldValue } = useFormikContext()

  const typeSplit = _.split(props.field.field_type, "_")
  const type = typeSplit[1]

  const parseStringToObj = (string) => {
    try {
      return JSON.parse(string)
    } catch {
      return {}
    }
  }

  const { field_conditional, field_name, field_type } = props.field

  const conditionalFieldObj =
    !_.isEmpty(field_conditional) && parseStringToObj(field_conditional)

  const getConditionalFieldKey = () => {
    const keyList = Object.keys(conditionalFieldObj)
    return keyList[0]
  }

  const conditionalFieldKey = getConditionalFieldKey()
  const conditionalFieldValue = conditionalFieldObj[conditionalFieldKey]

  const displayField = _.reduce(
    conditionalFieldValue,
    (acc, option) => {
      if (
        conditionalFieldValue[0] === "*" &&
        values[conditionalFieldKey] !== ""
      ) {
        return true
      }
      return acc || _.includes(values[conditionalFieldKey], option)
    },
    false
  )

  useEffect(() => {
    const skipFieldReset = ["activity_end_date"]

    if (_.includes(skipFieldReset, field_name)) return

    const initialValue = initialValues[field_name] || null
    const currentValue = values[field_name] || null

    if (!displayField) {
      if (type === "number") {
        return setFieldValue(field_name, null)
      }
      return setFieldValue(field_name, "")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayField])

  if (!displayField) return null

  if (field_name === "participant_email_account") {
    return <TypeParticipantEmail {...props} />
  }
  if (field_type === "conditional_dropdown_us_states") {
    return <Types.TypeDropdownUsState {...props} key={field_name} />
  }
  switch (type) {
    case "text":
      return <TypeText {...props} />
    case "textarea":
      return <TypeTextarea {...props} />
    case "dropdown":
      return <TypeDropdown {...props} />
    /* case "multiselect":
      return <TypeMultiSelect {...props} />*/
    case "radio":
      return <TypeRadio {...props} />
    case "password":
      return <TypePassword {...props} />
    case "date":
      return <TypeDate {...props} />
    case "email":
      return <TypeEmail {...props} />
    case "number":
      return <TypeNumber {...props} />
    case "phone":
      return <TypeTel {...props} />
    case "checkbox":
      return <TypeCheckbox {...props} />
    case "textarea_rich":
      return <TypeTextareaRich {...props} />
    /* case "dropdownlist":
      return <TypeDropDownList {...props} />*/
    case "sys":
      return <TypeSys {...props} />
    default:
      return null
  }
}

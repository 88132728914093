import React, { useContext, useState } from "react"
import { UserContext } from "../../context/userContext"
import { useQuery } from "@apollo/client"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

import { GET_SERVICES_EPISODES_BY_PARTICIPANT_ID } from "./queries"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import styles from "../../styles/Page-with-Sidebar.module.css"
import _ from "lodash"
import { Dropdown } from "primereact/dropdown"
import RequestDialog from "./components/RequestDialog"

import LoaderFullPage from "../../components/Loaders"

export default function ServiceEpisodes() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const user = useContext(UserContext)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [servicesFilter, setServicesFilter] = useState(null)
  const [locationFilter, setLocationFilter] = useState(null)
  const [dialog, setDialog] = useState(false)
  const [assignedOrganization, setAssignedOrganization] = useState(null)
  const [assignedEnterprise, setaAssignedEnterprise] = useState(null)
  const [assignedStaff, setAssignedStaff] = useState(null)
  const [staffEmailAddress, setStaffEmailAddress] = useState(null)
  const [staffPhoneNumber, setStaffPhoneNumber] = useState(null)
  const [staffID, setStaffID] = useState(null)
  const [requestStaff, setRequestStaff] = useState(null)
  const [notificationType, setNotificationType] = useState("")

  useQuery(GET_SERVICES_EPISODES_BY_PARTICIPANT_ID, {
    variables: {
      participant_id: user.participant_id,
    },
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      if (data.getServiceEpisodesParticipantPortal === null) {
        setData(null)
        return setLoading(false)
      }

      const episodes = data?.getServiceEpisodesParticipantPortal || []

      const handleData = () =>
        _.map(episodes, (episode) => {
          let actionButton

          const isActiveEpisode = episode.end_date === null
          isActiveEpisode
            ? (actionButton = (
                <SplitButton
                  label="Request Discharge"
                  onClick={() => {
                    setAssignedStaff(episode.episode_assigned_staff_ids)
                    setaAssignedEnterprise(episode.enterprise_id)
                    setAssignedOrganization(episode.organization_id)
                    setDialog(true)
                    setNotificationType("discharge")
                    setStaffEmailAddress(episode.staff_email_address)
                    setStaffPhoneNumber(episode.staff_phone_number)
                    setStaffID(episode.staff_id)
                  }}
                  model={[
                    {
                      label: "Request New Staff",
                      command: () => {
                        setAssignedStaff(episode.episode_assigned_staff_ids)
                        setaAssignedEnterprise(episode.enterprise_id)
                        setAssignedOrganization(episode.organization_id)
                        setDialog(true)
                        setNotificationType("new_staff")
                        setStaffEmailAddress(episode.staff_email_address)
                        setStaffPhoneNumber(episode.staff_phone_number)
                        setStaffID(episode.staff_id)
                      },
                    },
                  ]}
                />
              ))
            : (actionButton = "")

          return {
            start_date: episode?.start_date,
            end_date: episode?.end_date,
            status: isActiveEpisode ? "Open" : "Closed",
            organization: episode?.organization_name,
            location: episode?.location_name,
            staff: episode?.staff_name,
            actions: actionButton,
          }
        })
      setData(handleData())

      const allLocations = _.map(handleData(), ({ location }) => location)
      setLocationFilter(_.uniq(_.compact(allLocations)).sort())

      const allOrganizations = _.map(
        handleData(),
        ({ organization }) => organization
      )
      setOrganizationFilter(_.uniq(allOrganizations).sort())

      const allServices = _.map(handleData(), ({ status }) => status)
      setServicesFilter(_.uniq(allServices).sort())

      setLoading(false)
    },
  })

  const organizationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={organizationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select an Organization"
        className="p-column-filter"
        showClear
      />
    )
  }
  const locationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={locationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Location"
        className="p-column-filter"
        showClear
      />
    )
  }

  const serviceFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={servicesFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Service"
        className="p-column-filter"
        showClear
      />
    )
  }

  if (loading) return <LoaderFullPage />

  const dialogProps = {
    dialog,
    setDialog,
    setAssignedStaff,
    setaAssignedEnterprise,
    setAssignedOrganization,
    setStaffEmailAddress,
    setStaffPhoneNumber,
    setStaffID,
    assignedStaff,
    notificationType,
    assignedOrganization,
    assignedEnterprise,
    staffEmailAddress,
    staffPhoneNumber,
    staffID,
  }

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      <RequestDialog {...dialogProps} />
      <div className="main-content">
        <div>
          <div>
            <h2 className={styles.title}>Service Episodes </h2>

            <DataTable
              value={_.compact(data)}
              responsiveLayout="scroll"
              sortField="organization"
              sortOrder={1}
            >
              <Column
                field="start_date"
                header="Service Start Date"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />
              <Column
                field="end_date"
                header="Service End Date"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />
              <Column
                field="status"
                header="Service Status"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                filterElement={serviceFilter}
                filter
                sortable
              />
              <Column
                field="organization"
                header="Organization"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                filter
                filterElement={organizationsFilter}
                sortable
              />
              <Column
                field="location"
                header="Location"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                filter
                filterElement={locationsFilter}
                sortable
              />
              {/*<Column
                field="staff"
                header="Assigned Staff"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                sortable
              />*/}
              <Column field="actions" header="Actions" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

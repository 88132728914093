import React from "react"

export default function TypeTextarea({ value }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  )
}

import _ from "lodash"

export default function TypeMultiField({ value }) {
  let data = _.map(value, (field) => {
    return (
      Object.keys(field).map((key, index) => {
        const keyLabel = _.startCase(_.toLower(key)).replaceAll("_", " ") + ": "
        const keyValue = field[key]
        if (typeof keyValue === "object") {
          const newData = _.map(keyValue, (newValue) => {
            let newDataExtra = Object.keys(newValue).map((newKey, newIndex) => {
              if (_.includes(newKey, "_id")) {
                return null
              }
              const newkeyValue = newValue[newKey]

              return (
                _.startCase(_.toLower(newKey)).replaceAll("_", " ") +
                ": " +
                newkeyValue
              )
            })
            return "<strong>" + keyLabel + "</strong>" + newDataExtra + "<br>"
          })

          return newData + "<br>"
        } else {
          return "<strong>" + keyLabel + "</strong>" + keyValue + "<br>"
        }
      }) + "<br>"
    )
  })
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: _.sortBy(data, ["index"])
          .toString()
          .replaceAll(",", "")
          .replaceAll("<p>", "")
          .replaceAll("</p>", ""),
      }}
    />
  )
}

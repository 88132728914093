import { useState, useContext, useEffect } from "react"
import { LoaderMedium } from "./Loaders"
import _ from "lodash"

// Context
import { UserContext } from "../context/userContext"
import { FirebaseContext } from "../firebase"

import profileDummy from "../assets/images/default-profile-avatar.jpg"

export default function ProfilePhoto({ participant_profile_image }) {
  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)
  const [loading, setLoading] = useState(false)
  const [profileImageUrl, setProfileImageUrl] = useState("")

  useEffect(() => {
    setLoading(true)
    const profileImage =
      participant_profile_image !== undefined
        ? participant_profile_image
        : user.participant_profile_image

    const getProfileImageUrl = async () => {
      const { getStorage, ref, getDownloadURL } = firebase.storage
      const storage = getStorage()

      if (!_.isEmpty(profileImage)) {
        const logoRef = ref(storage, profileImage)
        return await getDownloadURL(logoRef)
      }

      return null
    }

    const fetchLogo = async () => {
      const imageUrl = await getProfileImageUrl()
      const profileImageUrl = _.isEmpty(imageUrl) ? profileDummy : imageUrl
      setProfileImageUrl(profileImageUrl)
      setLoading(false)
    }

    fetchLogo()
  }, [
    firebase.storage,
    user.participant_profile_image,
    participant_profile_image,
  ])

  //console.log(user.organization_alias)

  if (loading) return <LoaderMedium />

  return (
    <img
      src={profileImageUrl}
      alt=""
      style={{
        maxWidth: "43px",
      }}
    />
  )
}

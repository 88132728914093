import _ from "lodash"

import ProgramIdentityIds from "./ProgramIdentityIds"
import LocationIds from "./LocationIds"
import AssessmentScore from "./AssessmentScore"
import SessionDate from "./SessionDate"
import SessionStartTime from "./SessionStartTime"
import AssessmentType from "./AssessmentType"
import PasswordUpdate from "./PasswordUpdate"

export default function TypeSys(props) {
  const typeSplit = _.split(props.field.field_name, "__")
  const field_name = _.isEmpty(typeSplit[1])
    ? props.field.field_name
    : typeSplit[1]

  switch (field_name) {
    case "program_identity_ids":
      return <ProgramIdentityIds {...props} />
    case "location_ids":
      return <LocationIds {...props} />
    case "session_date":
      return <SessionDate {...props} />
    case "session_start_time":
      return <SessionStartTime {...props} />
    case "assessment_score":
      return <AssessmentScore {...props} />
    case "assessment_type":
      return <AssessmentType {...props} />
    case "password_update":
      return <PasswordUpdate {...props} />
    default:
      return null
  }
}

import { useContext } from "react"

//Helpers
import isParticipantActive from "../../../helpers/isParticipantActive"

//PrimeReact
import { Tooltip } from "primereact/tooltip"

// Context
import { UserContext } from "../../../context/userContext"

export default function InactiveParticipantToolTip({ targetName, children }) {
  const userCtx = useContext(UserContext)
  const tooltipClass = `inactiveParticipant-tt-${targetName || "default"}`

  let tooltipText = null

  if (!isParticipantActive(userCtx.participant_membership_status)) {
    tooltipText = (
      <div>
        Your profile is inactive <br />
        Please contact a peer to start a new service episode
      </div>
    )
  }

  return (
    <span>
      <Tooltip target={`.${tooltipClass}`}>{tooltipText}</Tooltip>
      <div className={tooltipClass} data-pr-position="bottom">
        {children}
      </div>
    </span>
  )
}

import { gql } from "@apollo/client"

export const GET_PARTICIPANT_ORGNIZATIONS = gql`
  query Organizations($pid: ID!) {
    organizations: organizationsByPid(participant_id: $pid) 
    {
        organization_id
        organization_name
        organization_alias
    } 
  }
`
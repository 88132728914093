import _ from "lodash"
import { useMutation, gql } from "@apollo/client"
import { useParams } from "react-router-dom"

import { useRef, useState, useContext, useEffect } from "react"
import { FirebaseContext } from "../../firebase"

// PR
import { FileUpload } from "primereact/fileupload"
import { Toast } from "primereact/toast"
import { ProgressBar } from "primereact/progressbar"
import { Button } from "primereact/button"

//comps
import { LoaderSmall } from "../../components/Loaders"
import { confirmPopup } from "primereact/confirmpopup"

//context
import { useFormikContext } from "formik"
import { FormContext } from "../../context/formContext"
import { UserContext } from "../../context/userContext"

import styles from "../../styles/Form.module.css"
import fieldVisibility from "../../helpers/fieldVisibility"

export default function TypeFile({ field, block, index }) {
  const [progress, setProgress] = useState(0)
  const [fileUrl, setFileUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [timestampPrefix] = useState(Date.now())

  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)
  const form = useContext(FormContext)

  const {
    getStorage,
    ref,
    uploadBytesResumable,
    deleteObject,
    getDownloadURL,
  } = firebase.storage

  const { idStamp } = useParams()

  const storage = getStorage()
  const toast = useRef(null)

  const isEditMode = form.editMode

  const formik = useFormikContext()
  const FIELD_NAME = field.field_name

  const handleUserType = () => {
    switch (field.field_name) {
      case "document_participant_file":
        return {
          userPath: "participant",
          id: user.current_participant_id,
        }
      case "document_staff_file":
        return {
          userPath: "staff",
          id: user.staff_id,
        }
      default:
        return {
          userPath: "not-set",
          id: "not-set",
        }
    }
  }

  const userType = handleUserType()
  const FIELD_PATH = `documents/${user.enterprise_id}/${user.organization_id}/${userType.userPath}/${userType.id}`

  const REMOVE_FILE_QUERY = gql`
    mutation RemoveFileFromBlock($idStamp: String!) {
      removeFileRef(idStamp: $idStamp) {
        status
      }
    }
  `

  const [removeFileRefFromBlock] = useMutation(REMOVE_FILE_QUERY, {
    variables: {
      idStamp,
    },
  })

  useEffect(() => {
    const currentField = formik.values[FIELD_NAME] || null
    const getDownloadURLFromStorage = async () => {
      const storage = getStorage()
      const fileRef = ref(storage, currentField)
      try {
        const downloadURL = await getDownloadURL(fileRef)
        setFileUrl(downloadURL)
      } catch (error) {
        setFileUrl(null)
      }
      setLoading(false)
    }
    if (isEditMode && currentField) {
      return getDownloadURLFromStorage()
    } else {
      return setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkIfRequired = () => {
    try {
      return JSON.parse(field.field_validation.required[0])
    } catch (error) {
      return false
    }
  }

  const isRequired = checkIfRequired()

  const removeHandler = ({ file }) => {
    let currentFilename

    if (!form.editMode) {
      currentFilename = `${timestampPrefix}_${file.name}`
    } else {
      currentFilename = file.name
    }

    const fullFilePath = `${FIELD_PATH}/${currentFilename}`
    const fileRef = ref(storage, fullFilePath)
    deleteObject(fileRef)
      .then(async () => {
        formik.setFieldValue(FIELD_NAME, "")
        setFileUrl(null)
        await removeFileRefFromBlock()
        toast.current.show({
          severity: "info",
          summary: "Success",
          detail: "File Deleted and Refence removed from Block.",
        })
      })
      .catch((error) => {
        formik.setFieldValue(FIELD_NAME, "")
        console.error(error) // please handle ERR!!
      })
  }

  const uploadComponent = () => {
    const customUploadHander = (e) => {
      const [file] = e.files
      const uniqueFilename = `${timestampPrefix}_${file.name}`
      const path = `${FIELD_PATH}/${uniqueFilename}`
      const storageRef = ref(storage, path)
      const metadata = {
        contentType: "application/pdf",
      }
      const uploadTask = uploadBytesResumable(storageRef, file, metadata)
      uploadTask.on(
        "state_changed",
        (snapshot) =>
          setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        (error) => {
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break
            case "storage/canceled":
              // User canceled the upload
              break
            default:
              console.error(error)
          }
        },
        () => {
          const { fullPath } = uploadTask.snapshot.metadata
          toast.current.show({
            severity: "info",
            summary: "Success",
            detail: "File Uploaded",
          })
          return formik.setFieldValue(FIELD_NAME, fullPath)
        }
      )
    }
    return (
      <>
        <FileUpload
          name={FIELD_NAME}
          customUpload
          uploadHandler={customUploadHander}
          onRemove={removeHandler}
          onClear={() => formik.setFieldValue(FIELD_NAME, "")}
          accept="application/pdf"
          maxFileSize={10000000} // 10Mb
          multiple
        />
        <ProgressBar value={progress} style={{ margin: "10px 0" }} />
        {formik.touched[FIELD_NAME] && formik.errors[FIELD_NAME] && (
          <div className={styles.errFieldMsg}>{formik.errors[FIELD_NAME]}</div>
        )}
      </>
    )
  }

  const editComponent = () => {
    const getFilename = () => {
      const urlSplit = _.split(formik.values[FIELD_NAME], "/")
      return urlSplit[urlSplit.length - 1]
    }
    if (loading) return <LoaderSmall />
    if (!fileUrl) return uploadComponent()
    const filename = getFilename()

    const handleDownloadFile = () => {
      const xhr = new XMLHttpRequest()
      xhr.responseType = "blob"
      xhr.onload = () => {
        const blob = xhr.response
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
      }
      xhr.open("GET", fileUrl)
      xhr.send()
    }

    const accept = () =>
      removeHandler({
        file: {
          name: filename,
        },
      })
    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "Rejected",
        detail: "You have rejected",
        life: 3000,
      })
    }
    const confirm = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Do you want to delete this file?.",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
      })
    }
    return (
      <div>
        <div style={{ marginBottom: 5 }}>
          <Button
            className="btn-sm"
            onClick={() => handleDownloadFile()}
            style={{ marginRight: 10 }}
          >
            Download
          </Button>
          <Button
            onClick={confirm}
            icon="pi pi-times"
            label="Delete"
            className="p-button-danger p-button-outlined p-button-sm"
          />
        </div>
        <small style={{ display: "block" }}>
          Deleting the file removes it immediately and requires a new file
          upload.
        </small>
      </div>
    )
  }

  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
      onBlur={() => formik.setFieldTouched(FIELD_NAME)}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Toast ref={toast} />
      {form.editMode ? editComponent() : uploadComponent()}
    </div>
  )
}

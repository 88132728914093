import { useContext, useEffect } from "react"

// Context
import { UserContext } from "../../context/userContext"

//Formik
import { useFormikContext } from "formik"

export default function FormikErrorNotification({ message }) {
  const userCtx = useContext(UserContext)
  const { isValid, isValidating, isSubmitting } = useFormikContext()

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      userCtx.setNotificationError(
        message ||
          "Unable to submit changes due to validation errors, please correct and try again."
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isValid, isValidating])

  return null
}

import { useContext } from "react"
import _ from "lodash"
import { useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import ParticipantRSI from "../../components/ParticipantRSI"
// Queries
import { GET_PARTICIPANT } from "../../pages/Participant/ParticipantProfile/queries"
import ProfilePhoto from "../ProfilePhoto"
import LoaderMedium from "../../components/Loaders"

// Context
import { UserContext } from "../../context/userContext"

export default function ParticipantProfileBoxSmall() {
  const user = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [blocks, setBlocks] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [card, setCard] = useState({})

  useEffect(() => {
    const urlString = window.location.href.toLowerCase() || null
    const url = new URL(urlString)
    if (url.searchParams.get("tab")) {
      const newIndex = Number(url.searchParams.get("tab"))
      setActiveIndex(newIndex)
    }
  }, [])

  const { error, data, refetch } = useQuery(GET_PARTICIPANT, {
    variables: {
      participant_id: user.participant_id,
    },
    onCompleted: ({ participant, participantProfile }) => {
      setCard({
        participant_external_id: participant.participant_external_id,
        participant_name_preferred: participant.participant_name_preferred,
        participant_phone_primary: participant.participant_phone_primary,
        participant_city_primary: participant.participant_city_primary,
        participant_state_primary: participant.participant_state_primary,
        participant_zip_code_primary: participant.participant_zip_code_primary,
        participant_profile_image: participant.participant_profile_image,
      })
      const sortBlocks = _.sortBy(participantProfile.blocks, ["block_title"])

      const renameBlocks = _.map(sortBlocks, (block) => {
        return {
          ...block,
          block_title: `${block.block_title}`.replace("Participant ", ""),
        }
      })
      setBlocks(renameBlocks)
      setLoading(false)
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, user, card])

  if (loading) return <LoaderMedium />

  return (
    <div className="profile-section nav">
      <ParticipantRSI />

      <div className="participant-information">
        <div>
          <span>Participant ID:</span> {user.participant_alias}
        </div>
        <div>
          <span>External ID:</span> {card.participant_external_id}
        </div>
        <div>
          <span>Address: </span> {card.participant_city_primary},{" "}
          {card.participant_state_primary} {card.participant_zip_code_primary}
        </div>
        <div>
          <span>Email: </span> {data.participant.participant_email_account}
        </div>

        <div>
          <span>Phone: </span>
          {card.participant_phone_primary}
        </div>

        <div>
          <span>Program ID(s): </span>{" "}
        </div>
        <div>
          <span>Last Engagement: </span>{" "}
        </div>
        <div>
          <span>Last Activity: </span>{" "}
        </div>
      </div>
      <div className="profile-participant">
        <h2 className="text-center">
          {" "}
          {data.participant.participant_name_first}{" "}
          {data.participant.participant_name_last}{" "}
        </h2>

        <ProfilePhoto classes="border-2 border-white" />
      </div>
    </div>
  )
}

import _ from "lodash"
import { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../../../firebase"

import { LoaderSmall } from "../../../components/Loaders"

export default function TypeFile({ value }) {
  const [loading, setLoading] = useState(true)
  const [downloadUrl, setDownloadURL] = useState(null)
  const firebase = useContext(FirebaseContext)

  const { getStorage, ref, getDownloadURL } = firebase.storage

  useEffect(() => {
    const getDownloadURLFromStorage = async (path) => {
      const storage = getStorage()
      const fileRef = ref(storage, path)
      const downloadURL = await getDownloadURL(fileRef)
      setDownloadURL(downloadURL)
    }
    !_.isEmpty(value) && getDownloadURLFromStorage(value)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (_.isEmpty(value)) return <div>No file</div>

  if (loading) return <LoaderSmall />

  return (
    <div>
      <a
        className="file-upload"
        target="_blank"
        rel="noreferrer"
        href={`${downloadUrl}`}
      >
        {value}
      </a>
    </div>
  )
}

import { gql } from "@apollo/client"

export const GET_ORGANIZATIONS_BY_ENTERPRISE = gql`
  query organizations($staff_id: ID!, $enterprise_id: ID!) {
    organizationsByEnterpriseUid(
      staff_id: $staff_id
      enterprise_id: $enterprise_id
    ) {
      organization_id
      organization_name
      organization_status
    }
  }
`

export const GET_USERS_FROM_LIST = gql`
  query get_users_from_list($list: [ID]) {
    usersFromList(list: $list) {
      staff_id
      staff_full_name
      organization_id
      organization_name
    }
  }
`

export const GET_STAFF_LIST = gql`
  query get_users($staff_id: ID!, $organization_id: ID!) {
    users(staff_id: $staff_id, organization_id: $organization_id) {
      staff_id
      staff_name_first
      staff_name_last
      staff_email_address
      staff_phone_number
      organization_id
    }
  }
`

export const GET_STAFF = gql`
  query get_user($staff_id: ID!, $organization_id: ID!) {
    user(staff_id: $staff_id, organization_id: $organization_id) {
      staff_name_first
      staff_name_last
      staff_full_name
    }
  }
`

export const GET_PARTICIPANT = gql`
  query get_participant($participant_id: ID!, $organization_id: ID!) {
    participant(
      participant_id: $participant_id
      organization_id: $organization_id
    ) {
      participant_name_first
      participant_name_last
      participant_create_date
    }
  }
`

export const GET_LATEST_SERVICE_EPISODE = gql`
  query get_maxServiceEpisode(
    $staff_id: ID!
    $participant_id: ID!
    $organization_id: ID!
    $participant_membership_id: ID!
  ) {
    serviceEpisode: getMaxServiceEpisode(
      staff_id: $staff_id
      organization_id: $organization_id
      participant_membership_id: $participant_membership_id
      participant_id: $participant_id
    ) {
      episode_intake_date
      episode_discharge_date
      service_episodes_id
    }
  }
`

export const GET_LOCATIONS = gql`
  query get_locations($staff_id: ID!, $organization_id: ID!) {
    locations: locationsByOrganization(
      staff_id: $staff_id
      organization_id: $organization_id
    ) {
      location_id
      location_name
      location_status
    }
  }
`

export const GET_TEMPLATES = gql`
  query getTemplates($staff_id: String, $organization_id: ID!) {
    templates(staff_id: $staff_id, organization_id: $organization_id) {
      idTemplate
      template_title
    }
  }
`

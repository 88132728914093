import docSignStyles from "../../../styles/documentSigning/DocSign.filters.module.css"
import LayoutButton from "../Common/LayoutButton"
import FilterButton from "../Common/FilterButton"

const RequestsFilterHeader = ({
  layoutOption,
  appliedQuickFilter,
  setLayoutOptionCallBack,
  getRequestsCallBack,
  onSetAppliedFilters,
  disabled,
  currentSortOption,
  currentSortDirection,
}) => {
  return (
    <div className={docSignStyles.filterHeaderGridContainer}>
      <div className={docSignStyles.filterHeaderGridTitle}>
        <label>Filters & Sorting</label>
      </div>
      <div className={docSignStyles.filterHeaderGridButtons}>
        <div className={docSignStyles.filterButtonContainer}>
          <LayoutButton
            iconName="menu"
            toolTip="Display templates as a table"
            value="table"
            layoutOption={layoutOption}
            setLayoutOption={setLayoutOptionCallBack}
            disabled={disabled}
          />
          <LayoutButton
            iconName="apps"
            toolTip="Display templates as a grid"
            value="grid"
            layoutOption={layoutOption}
            setLayoutOption={setLayoutOptionCallBack}
            disabled={disabled}
          />
        </div>

        <div className={docSignStyles.filterButtonContainer}>
          <FilterButton
            iconName="refresh"
            toolTip="Refresh page"
            onClickCallBack={() => {
              getRequestsCallBack()
            }}
            disabled={disabled}
          />
        </div>
        <div className={docSignStyles.filterButtonContainer}>
          <FilterButton
            iconName="folder_copy"
            toolTip="Show requests assigned to me"
            onClickCallBack={() => {
              onSetAppliedFilters(
                {
                  dateCreated: [],
                  search: "",
                  status: "all",
                  organizations: [],
                  sort: currentSortOption,
                  sortDirection: currentSortDirection,
                },
                "my_requests"
              )
            }}
            disabled={disabled}
            doHighlight={appliedQuickFilter === "my_requests"}
          />
          <FilterButton
            iconName="edit_note"
            toolTip="Show requests that are awaiting my Signature"
            onClickCallBack={() => {
              onSetAppliedFilters(
                {
                  dateCreated: [],
                  search: "",
                  status: "awaiting_signature",
                  organizations: [],
                  sort: currentSortOption,
                  sortDirection: currentSortDirection,
                },
                "awaiting_my_signature"
              )
            }}
            disabled={disabled}
            doHighlight={appliedQuickFilter === "awaiting_my_signature"}
          />
        </div>
      </div>
    </div>
  )
}
export default RequestsFilterHeader

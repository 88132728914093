import * as Yup from "yup"

export default function validation_session_end_time(field, acc) {
  const { field_name } = field

     Yup.addMethod(Yup.date, "positiveSessionLegth", function (errorMessage) {
      return this.test(`positive-session-length`, errorMessage, function (value) {
        const { path, createError } = this
        return (
          createError({ path, message: errorMessage })
        )
      })
    })

  return {
    
    [field_name]: Yup.date().when("session_length", {
      is: (value) => {
        return  value <= 0
      }, 
      then: Yup.date().positiveSessionLegth('End time must be later than the start time.')
      .required("Required"),
    }),
    ...acc,
  }
}


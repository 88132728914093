import { useContext } from "react"
import { Field } from "formik"

import { FormContext } from "../../context/formContext"
import { Dropdown } from "primereact/dropdown"

import styles from "../../styles/Form.module.css"

export default function TypeDropdown({ field, block, index }) {
  const isRequired = JSON.parse(field.field_validation.required[0])
  const form = useContext(FormContext)
  const isDisabled = form ? form.blocksSaved[block] : false

  return (
    <div className={styles.field} style={{ order: index }}>
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, meta }) => (
          <>
            <Dropdown
              placeholder="Please select"
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              disabled={isDisabled}
              options={field.field_options}
            />
            {meta.touched && meta.error && (
              <div className={styles.errFieldMsg}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  )
}

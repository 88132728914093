import React from "react"
import _ from "lodash"

export default function TypeDropdown({ value, options, description }) {
  return (
    <div>
      {" "}
      <strong>{description} </strong>
      {_.find(options, { value })?.label}{" "}
    </div>
  )
}

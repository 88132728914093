import { DateTime } from "luxon"
import { parseCronExpression } from "cron-schedule"
import { getDate, getDateKey, getDateOverrideZone } from "./utils"
import { LogError } from "../../helpers/logger"

//Calculate the first day of a week using Sunday as the first day
function getFirstDayInWeek(date) {
  //Find the Monday for the current week (Keep the appointment time):
  let currentDate = date
    .startOf("week")
    .set({ hours: date.hour, minutes: date.minute })
  //Go back to Sunday which we consider the first day:
  while (currentDate.weekdayLong !== "Sunday") {
    currentDate = currentDate.minus({ days: 1 })
  }

  return currentDate
}

function calculateNDaysDates(
  controllerStartDate,
  controllerEndDate,
  everyNDaysCounter,
  filterStartDate,
  filterEndDate
) {
  let dates = []
  let localTz = DateTime.local().zoneName

  //consumers currently expect the time to reflect the appointment TZ time values.
  //So, override the TZ to show local and keep the times as is for later reversion.
  let currentDate = controllerStartDate.setZone(localTz, {
    keepLocalTime: true,
  })
  dates.push(currentDate.toJSDate())

  while (currentDate <= controllerEndDate && currentDate <= filterEndDate) {
    currentDate = currentDate.plus({ days: everyNDaysCounter })

    //If the current date is within the filter startDate and (filter endDate or controller end date) save the date
    if (
      currentDate >= filterStartDate &&
      currentDate <= controllerEndDate &&
      currentDate <= filterEndDate
    ) {
      dates.push(currentDate.toJSDate())
    }
  }

  return dates
}

export function getClosestDuration(startDate, endDate) {
  let a = startDate
  let b = endDate

  let durationOptions = [15, 30, 45, 60, 90, 120, 180, 240, 300, 360, 420, 480]
  let duration = b.diff(a, "minutes").minutes

  let closest = durationOptions.reduce(function (prev, curr) {
    return Math.abs(curr - duration) < Math.abs(prev - duration) ? curr : prev
  })

  return closest
}

//Calculate the first appointment startDate of a recurring appointment controller
export function getFirstRecurringDate(
  cronString,
  startDate,
  controllerStartDateTime
) {
  if (cronString !== "") {
    const isBiWeekly = cronString.includes("[BI]")
    let cron = cronString.replace(" [BI]", "")
    let cronComponents = cron.split(" ")

    let firstDate = startDate
    if (isBiWeekly) {
      let parsed = parseCronExpression(cron)
      firstDate = parsed.getNextDates(5, startDate)[0]

      //If this is not a valid weeklyDate from the controller start date,
      //Get the next weekly date.
      if (
        !checkValidWeeklyDateFromStart(
          true,
          controllerStartDateTime,
          DateTime.fromJSDate(firstDate)
        )
      ) {
        firstDate = getFirstRecurringDate(
          cronString,
          firstDate,
          controllerStartDateTime
        )
      }
    } else if (cron.includes("#")) {
      //If this is a "Every n months" controller, do this
      firstDate = fixCron(cron, startDate)[0]
    } else if (cronComponents.length > 2 && cronComponents[2].includes("*/")) {
      //If this is an "every n days" controller, start at the start date
      firstDate = startDate
    } else {
      //For normal recurring controllers, parse the cron normally and get the first date.
      let parsed = parseCronExpression(cron)
      firstDate = parsed.getNextDates(5, startDate)[0]
    }

    return firstDate
  } else {
    LogError(`getFirstRecurringDate: Empty cronString received`)
    return undefined
  }
}

export function checkValidWeeklyDateFromStart(
  isBiWeekly,
  dateTimeControllerStart,
  dateTimeAppointment
) {
  //If this is a biweekly appointment, isBiWeekly will be TRUE
  if (!isBiWeekly) {
    return true
  }

  //If the appointment date is before the start date, this is invalid.
  if (dateTimeAppointment < dateTimeControllerStart) {
    return false
  }

  //Get the start of the week for the dates
  const startDateStartOfWeek = getFirstDayInWeek(dateTimeControllerStart)
  const appointmentDateStartOfWeek = getFirstDayInWeek(dateTimeAppointment)

  //Calculate the weeks between the controller start date and appointment date.
  const dateDifference = appointmentDateStartOfWeek
    .diff(startDateStartOfWeek, "weeks")
    .toObject()
  console.log(dateDifference)

  //If the week calculator is less than one, it is in the same week.
  if (dateDifference.weeks < 1) {
    return true
  } else if (Math.floor(dateDifference.weeks) % 2 === 0) {
    return true
  } else {
    return false
  }
}

export function fixCron(cronExp, startDate, n = 30) {
  let cronExpression = cronExp.split("#")[0]
  let nth = +cronExp.split("#")[1]

  let test = parseCronExpression(cronExpression)
  let tests = test.getNextDates(n, startDate)

  let futureDates = {}

  tests.forEach((element) => {
    let makeKey = DateTime.fromJSDate(element).month
    if (!futureDates[makeKey]) {
      futureDates[makeKey] = []
    }

    futureDates[makeKey].push(element)
  })

  for (let key in futureDates) {
    if (futureDates.hasOwnProperty(key)) {
      let index = nth - 1
      if (futureDates[key].length >= nth)
        futureDates[key] = futureDates[key][index]
      else {
        if (nth === 1 && futureDates[key][0].getDate() <= 7) {
          futureDates[key] = futureDates[key][0]
        } else if (nth === 2 && futureDates[key][0].getDate() <= 14) {
          futureDates[key] = futureDates[key][0]
        } else if (nth === 3 && futureDates[key][0].getDate() <= 21) {
          futureDates[key] = futureDates[key][0]
        } else if (nth === 4 && futureDates[key][0].getDate() <= 28) {
          futureDates[key] = futureDates[key][0]
        } else {
          delete futureDates[key]
        }
      }
    }
  }

  let result = Object.values(futureDates).sort(function (a, b) {
    return a.getTime() - b.getTime()
  })

  return result
}

function calculateFutureRecurringAppointmentDates(
  controller,
  participantAlias,
  upcomingAppointments,
  filterStartDate,
  filterEndDate
) {
  //Get the cron expression and parse it for later use
  let cronExpression = controller.cronExpression || controller.cron //Minified CRON extracts have cron instead of cronExpression
  const parsedCron = parseCronExpression(
    cronExpression.includes("#") ? cronExpression.split("#")[0] : cronExpression
  )

  //Get the recurring controller start and end dates and times
  //Note: Keep this in the expected timezone until future dates have been calculated.
  const controllerStartDate = getDateOverrideZone(
    controller.startDate,
    undefined,
    controller.timezone
  ).set({ hour: parsedCron.hours, minute: parsedCron.minutes })

  const controllerEndDate = getDateOverrideZone(
    controller.endDate,
    undefined,
    controller.timezone
  )
    .set({ hour: parsedCron.hours, minute: parsedCron.minutes })
    .plus({ minutes: controller.duration || 15 })

  //Decide on from when to create appointments. choose the smallest period between filter and controller dates.
  let periodStart = controllerStartDate

  if (filterStartDate && filterStartDate > controllerStartDate) {
    periodStart = filterStartDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  }

  let periodEnd = controllerEndDate

  if (filterEndDate && filterEndDate < controllerEndDate) {
    periodEnd = filterEndDate.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    })
  }

  const controllerDays = periodEnd.diff(periodStart, "days").toObject()

  //Calculate future appointment dates
  let appointmentDates = []
  let cronComponents = cronExpression.split(" ")
  if (cronExpression.includes("#")) {
    appointmentDates = fixCron(
      cronExpression,
      periodStart.toJSDate(),
      controllerDays.days
    )
  } else if (cronComponents.length > 2 && cronComponents[2].includes("*/")) {
    appointmentDates = calculateNDaysDates(
      controllerStartDate,
      controllerEndDate,
      +cronComponents[2].replace("*/", ""),
      periodStart,
      periodEnd
    )
  } else {
    appointmentDates = parsedCron.getNextDates(
      controllerDays.days,
      periodStart.toJSDate()
    )
  }

  //convert JSDates to Luxon dates
  appointmentDates = appointmentDates.map((date) => {
    let newDate = DateTime.fromJSDate(date).setZone(controller.timezone, {
      keepLocalTime: true,
    })

    return newDate.toLocal()
  })

  //Filter dates that are before the start date or after the end date of the controller
  appointmentDates = appointmentDates.filter((date) => {
    return date >= periodStart && date <= periodEnd
  })

  //Remove dates that are not valid if this is a BiWeekly controller
  //Note: The function will always return true if the controller is not BiWeekly
  appointmentDates = appointmentDates.filter((date) => {
    return checkValidWeeklyDateFromStart(
      controller.isBiWeeklyCycleEven !== null,
      controllerStartDate,
      date
    )
  })

  //remove dates that overlap existing upcoming appointments if supplied
  if (upcomingAppointments) {
    appointmentDates = appointmentDates.filter((appointmentDate) => {
      return !upcomingAppointments.find((upcomingAppointment) => {
        const dateKey = getDateKey(upcomingAppointment.startDateTime)

        const upcomingAppointmentStartDate =
          dateKey !== "yyyy-MM-dd hh:mm a ZZ" && dateKey !== ""
            ? getDateOverrideZone(
                upcomingAppointment.startDateTime,
                undefined,
                upcomingAppointment.timezoneName || upcomingAppointment.timezone //Minified recurring controllers have timezone instead of timezoneName
              )
            : getDate(upcomingAppointment.startDateTime)

        return (
          upcomingAppointmentStartDate.toUnixInteger() ===
            appointmentDate.toUnixInteger() &&
          //If a participantAlias is provided, also filter on that
          (!participantAlias ||
            +participantAlias === +upcomingAppointment.caseUser?.caseId)
        )
      })
    })
  }

  //confirm that the date has not been cancelled on the controller
  if (controller.cancelledDates) {
    let cancelledDates = controller.cancelledDates
    if (typeof cancelledDates === "string") {
      cancelledDates = cancelledDates.split(",")
    }

    //Filter appointmentDates to only include dates that are not in the cancelledDates listing.
    appointmentDates = appointmentDates.filter(
      (date) =>
        !cancelledDates.find(
          (cancelledDate) =>
            date.valueOf() >= +cancelledDate &&
            date.valueOf() < +cancelledDate + 86400000
        )
    )
  }

  let processedAppointmentDates = []
  appointmentDates.forEach((appointmentDate) => {
    //Set the endDate as the startDateTime + the controller duration, default to 15 minutes if no duration is available.
    let appointmentEndDate = appointmentDate.plus({
      minutes: controller.duration || 15,
    })

    processedAppointmentDates.push({
      start: appointmentDate.setZone(controller.timezone),
      end: appointmentEndDate.setZone(controller.timezone),
    })
  })

  return processedAppointmentDates
}

export function generateFutureRecurringEventsForControllers(
  recurringControllers,
  upcomingAppointments,
  filterStartDate,
  filterEndDate
) {
  const controllerAppointments = []

  recurringControllers.forEach((controller) => {
    const futureAppointments = calculateFutureRecurringAppointmentDates(
      controller,
      undefined,
      upcomingAppointments,
      filterStartDate
        ? DateTime.fromJSDate(filterStartDate).startOf("day")
        : undefined,
      filterEndDate
        ? DateTime.fromJSDate(filterEndDate).endOf("day")
        : undefined
    )

    futureAppointments.forEach((appointment) => {
      if (
        appointment.start >= DateTime.now() ||
        appointment.end > DateTime.now()
      ) {
        controllerAppointments.push({
          ...controller,
          startDateTime: appointment.start.toISO(),
          endDateTime: appointment.end.toISO(),
        })
      }
    })
  })

  return controllerAppointments
}

import { Form, Formik } from "formik"
import { DateTime } from "luxon"

// Context
import { UserContext } from "../../context/userContext"

//PrimeReact
import { Panel } from "primereact/panel"
import { Button } from "primereact/button"

//Styles
import refStyles from "../../styles/Referrals.module.css"

//Referral components
import RefTextAreaInput from "./RefTextAreaInput"
import { referralsParticipantStaffId } from "../../config/microservicesSystemUsers"

//graphQL
import { useMutation } from "@apollo/client"
import { UPDATE_REFERRAL_Block } from "./query"
import { LogError } from "../../helpers/logger"
import { useContext, useState } from "react"

export default function ReferralParticipantNote({ referral, isLoading }) {
  const { setNotificationError } = useContext(UserContext)

  const [thisNote] = useState(referral)
  const [thisNoteSet, setThisNoteSet] = useState(
    !!referral?.referral.values.referral_participants_notes
  )

  const [updateReferral] = useMutation(UPDATE_REFERRAL_Block, {
    variables: {
      referral_status: "",
      $referral_participants_notes: "",
      $date_changed: "",
      $idStamp: "",
    },
  })

  const submitNote = async (values) => {
    try {
      const todayLocal = DateTime.now()
      const todayUtc = todayLocal.toUTC().toISO()
      const serviceUserId = referralsParticipantStaffId()
      const referral_update = await updateReferral({
        variables: {
          staff_id: serviceUserId,
          organization_id: values.referral.organization_id,
          referral_status: values.referral.values.referral_status,
          referral_participants_notes:
            values.referral.values.referral_participants_notes,
          date_changed: todayUtc,
          idStamp: values.referral.idStamp,
        },
      })

      if (!!referral_update) {
        setThisNoteSet(true)
      } else {
        LogError("Unable to submit incomplete referral note", referral_update)
        setNotificationError("Unable to submit incomplete referral note")
      }
    } catch (error) {
      LogError("Unable to submit incomplete referral note", error)
      setNotificationError("Unable to submit incomplete referral note")
    }
  }

  return (
    <Panel
      header="Participant feedback"
      style={{ marginBottom: "10px", marginTop: "10px" }}
      className="p-fluid"
    >
      <Formik initialValues={thisNote} enableReinitialize onSubmit={submitNote}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div style={{ width: "100%" }}>
              <RefTextAreaInput
                name="referral.values.referral_participants_notes"
                label="Why did you not complete this referral?"
                style={{ width: "100%" }}
                disabled={thisNoteSet}
              />
            </div>
            {!thisNoteSet ? (
              <Button
                label="Submit"
                icon="pi pi-check"
                type="button"
                className={refStyles.button}
                onClick={() => {
                  handleSubmit()
                }}
                loading={isLoading}
                style={{ width: "115px" }}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </Panel>
  )
}

import * as Yup from "yup"
import _ from "lodash"

export default function validation_conditional_number(field, acc) {
  const { field_name, field_validation } = field

  const conditions = JSON.parse(field.field_conditional)
  const fieldConditional = _.keys(conditions)
  const conditionFieldName = fieldConditional[0]

  const isRequired = field_validation.required?.[0] === "true"
  const isRequiredTxt = field_validation.required?.[1] || "Required field."

  const numberMin = Number(field_validation.number_min) || null
  const numberMax = Number(field_validation.number_max) || null

  const positive = field_validation.positive?.[0] || false
  const positiveTxt =
    field_validation.positive?.[1] || "Must be a Positive number."

  const integer = field_validation.integer?.[0] ?? true
  const integerTxt = field_validation.integer?.[1] || "Must be an Integer."

  let numberSchema = isRequired
    ? Yup.number().required(isRequiredTxt)
    : Yup.number().nullable()

  if (positive) {
    numberSchema = numberSchema.positive(positiveTxt)
  }

  if (integer) {
    numberSchema = numberSchema.integer(integerTxt)
  }

  if (numberMin !== null) {
    numberSchema = numberSchema.min(
      numberMin,
      `Must be greater than or equal to ${numberMin}.`
    )
  }

  if (numberMax !== null) {
    numberSchema = numberSchema.max(
      numberMax,
      `Must be less than or equal to ${numberMax}.`
    )
  }

  return {
    [field_name]: Yup.number()
      .nullable()
      .when(conditionFieldName, {
        is: (value) => {
          if (_.isArray(value)) {
            return _.find(value, function (v) {
              if (_.includes(conditions[conditionFieldName], v)) {
                return true
              }
            })
          } else {
            return _.includes(conditions[conditionFieldName], value)
          }
        },
        then: numberSchema,
      }),
    ...acc,
  }
}

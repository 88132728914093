import { useEffect, useState, useContext } from "react"
import { DateTime } from "luxon"

//Notifications modules
import {
  noteApiListNotificationsPOST,
  noteApiMarkAsReadPut,
  noteApiMarkAsUnreadPut,
  noteApiMarkAllAsReadPut,
  noteApiListEventTypesGet,
} from "../notificationsApi"
import NotificationsFilters from "./NotificationsFilters"

//styles
import noteStyle from "../../../styles/NotificationsForm.module.css"

// Context
import { UserContext } from "../../../context/userContext"

//prime react
import { Accordion, AccordionTab } from "primereact/accordion"
import { ProgressSpinner } from "primereact/progressspinner"
import { Paginator } from "primereact/paginator"
import { confirmDialog } from "primereact/confirmdialog"
import { Tooltip } from "primereact/tooltip"

//Helpers
import { LogDebug } from "../../../helpers/logger"
import {
  anyNotificationHasTags,
  notificationHasParticipantTag,
  notificationHasTags,
  replaceNotificationTags,
} from "../notificationsTags"
import { useLazyQuery } from "@apollo/client"
import { GET_NOTIFICATION_USERS } from "../notificationsQueries"

export default function NotificationsTab(props) {
  const user = useContext(UserContext)
  const moment = require("moment")
  const notificationServiceStaffId = "-9" //Service staff account: RecoveryLInk Microservices

  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState(null)
  const [eventTypes, setEventTypes] = useState(null)
  const [refresh, setRefresh] = useState(null)
  const [lastRefresh, setLastRefresh] = useState(null)
  const currentPage = 1

  // Filters //
  const defaultFilters = {
    search: "",
    eventType: "All",
    organization: "All",
    createDateStart: DateTime.fromISO(user.participant_create_date).toJSDate(),
    createDateEnd: "",
    readDateStart: "",
    readDateEnd: "",
    sort: "newest",
    includeRead: false,
    includeUnread: true,
  }

  const [filters, setFilters] = useState(defaultFilters)
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters)

  const [getNotificationUsers] = useLazyQuery(GET_NOTIFICATION_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: notificationServiceStaffId, //Service staff account: RecoveryLInk Microservices
      organization_id: user.organization_id,
    },
  })

  async function GetNotifications(page, currentPageSize) {
    // Limit refreshes

    if (lastRefresh !== null && new Date().getTime() - lastRefresh < 1000)
      return
    setLastRefresh(new Date().getTime())

    setLoading(true)

    try {
      const res = await noteApiListNotificationsPOST(
        user.tokenNotification,
        user.participant_id,
        user.participant_alias,
        appliedFilters.organization,
        "",
        CombineReadAndUnreadFilters(),
        appliedFilters.eventType,
        appliedFilters.createDateStart,
        appliedFilters.createDateEnd,
        appliedFilters.readDateStart,
        appliedFilters.readDateEnd,
        appliedFilters.search,
        appliedFilters.sort,
        page || currentPage,
        currentPageSize || pageSize
      )

      if (res) {
        if (anyNotificationHasTags(res.items)) {
          const result = await getNotificationUsers()
          let participantList = [
            {
              participant_id: user.participant_id,
              participant_first_name: user.participant_first_name,
              participant_last_name: user.participant_last_name,
            },
          ]

          let staffList = result.data?.staff || []

          res.items = await Promise.all(
            res.items.map(async (notification) => {
              if (notificationHasTags(notification)) {
                return await replaceNotificationTags(
                  notification,
                  participantList,
                  undefined,
                  undefined,
                  staffList,
                  undefined
                )
              } else {
                return notification
              }
            })
          )
        }

        setNotifications(res)
        props.onRefreshBellCounter(new Date().getTime())
      }
      LogDebug("Notifications", res)
    } catch (error) {
      console.error("Unable to list notifications", error)
      props.onShowError(
        "Unable to list notifications. See console for details."
      )
    }
    setLoading(false)
  }

  async function markAllAsRead() {
    if (!!notifications) {
      setLoading(true)

      await noteApiMarkAllAsReadPut(user.tokenNotification, user)

      GetNotifications()

      setLoading(false)
    }
  }

  async function MarkAsRead(notificationId) {
    setLoading(true)

    try {
      await noteApiMarkAsReadPut(user.tokenNotification, notificationId)
      GetNotifications()
      LogDebug(`Notification marked as read (ID: ${notificationId})`)
    } catch (error) {
      console.error(
        `Unable to mark notification as read (id: ${notificationId})`,
        error
      )
      props.onShowError(
        `Unable to mark notification as read (id: ${notificationId}). See console for details.`
      )
    }
    setLoading(false)
  }

  async function markAsUnread(notificationId) {
    setLoading(true)

    try {
      await noteApiMarkAsUnreadPut(user.tokenNotification, notificationId)
      GetNotifications()
      LogDebug(`Notification marked as unread (ID: ${notificationId})`)
    } catch (error) {
      console.error(
        `Unable to mark notification as unread (id: ${notificationId})`,
        error
      )
      props.onShowError(
        `Unable to mark notification as unread (id: ${notificationId}). See console for details.`
      )
    }
    setLoading(false)
  }

  async function GetEventTypes() {
    setLoading(true)
    try {
      const res = await noteApiListEventTypesGet(user.tokenNotification)

      if (res) {
        setEventTypes(res)
      }
      LogDebug("EventTypes", res)
    } catch (error) {
      console.error("Unable to list event types", error)
      props.onShowError("Unable to list event types. See console for details.")
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!notifications) {
      GetNotifications()
    } else if (!eventTypes) {
      GetEventTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, eventTypes])

  useEffect(() => {
    if (notifications) {
      GetNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (notifications !== null) {
      GetNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters])

  useEffect(() => {
    LogDebug("Filters", filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  function CombineReadAndUnreadFilters() {
    const { includeRead, includeUnread } = appliedFilters

    if (includeRead && includeUnread) return null
    else if (includeRead && !includeUnread) return true
    else return false
  }

  const getOrganization = (organization_id) => {
    if (organization_id !== "0") {
      let org = props.organizations.filter(
        (x) => x.organization_id === organization_id
      )

      if (org.length > 0) {
        return org[0].organization_name
      } else {
        org = props.organizations.filter(
          (x) => x.organization_alias === organization_id
        )
        if (org.length > 0) {
          return org[0].organization_name
        } else {
          return "Unknown organization"
        }
      }
    } else {
      return ""
    }
  }

  const itemHeader = (data) => {
    return (
      <div className={noteStyle.noteHeaderGridContainer}>
        <Tooltip target=".header-disabled-tt" />
        <div className={noteStyle.noteHeaderGridItemTitle}>
          {data.highPriority ? (
            <span
              className={`material-icons md-18 ${noteStyle.noteHighImportant}`}
            >
              priority_high
            </span>
          ) : null}
          {data.title}
        </div>
        <div className={noteStyle.noteHeaderGridItemCreation}>
          {moment.utc(data.createdAt).local().format("L LT")}
        </div>
        <div className={noteStyle.noteHeaderGridItemDescr}>
          {data.description}
        </div>
        <div className={noteStyle.noteHeaderGridItemOrg}>
          {getOrganization(data.groupId)}
        </div>

        <div
          className={`${noteStyle.noteHeaderGridItemRead} ${
            props.isDisabledParticipant ? "header-disabled-tt" : ""
          }`}
          data-pr-tooltip={
            props.isDisabledParticipant ? "Inactive participant" : ""
          }
        >
          <button
            type="button"
            className="btn"
            style={{
              width: 40,
              height: 33,
              float: "right",
              padding: "4px 9px",
            }}
            onClick={(e) => {
              e.stopPropagation()
              if (!data.read) {
                MarkAsRead(data.id)
              } else {
                markAsUnread(data.id)
              }
            }}
            disabled={props.isDisabledParticipant}
          >
            <span
              className={`material-icons  p-overlay-badge ${noteStyle.NoteMarkRead}`}
            >
              {data.read ? "remove_done" : "done_all"}
            </span>
          </button>
        </div>
      </div>
    )
  }

  const actionButton = (label, url, id, gridClass, notification) => {
    return (
      <>
        {url ? (
          <div
            className={`${gridClass} ${
              props.isDisabledParticipant ? "action-disabled-tt" : ""
            }`}
            data-pr-tooltip={
              props.isDisabledParticipant ? "Inactive participant" : ""
            }
            data-pr-position="bottom"
          >
            <button
              className={`btn ${noteStyle.noteBodyActionButton}`}
              onClick={() => {
                props.onHandleActionURL(url, id, notification)
              }}
              disabled={props.isDisabledParticipant}
            >
              {label}
            </button>
          </div>
        ) : null}
      </>
    )
  }

  const itemTemplate = (data) => {
    return (
      <AccordionTab
        header={itemHeader(data)}
        headerStyle={{ width: "100%", marginTop: "10px" }}
        key={data.id}
      >
        <div className={noteStyle.noteBodyGridContainer}>
          <div className={noteStyle.noteBodyGridItemMod}>
            <span className={noteStyle.noteBodyLabel}>Last modified:</span>{" "}
            {moment.utc(data.modifiedAt).local().format("L LT")}
          </div>
          <div className={noteStyle.noteBodyGridItemRead}>
            <label className={noteStyle.noteBodyLabel}>Read on: </label>
            {data.read
              ? moment.utc(data.modifiedAt).local().format("L LT")
              : "Unread"}
          </div>
          <Tooltip target=".action-disabled-tt" />
          {actionButton(
            data.primaryActionLabel,
            data.primaryActionUrl,
            data.id,
            noteStyle.noteBodyGridItemAct1,
            data
          )}
          {actionButton(
            data.secondaryActionLabel,
            data.secondaryActionUrl,
            data.id,
            noteStyle.noteBodyGridItemAct2,
            data
          )}
        </div>
      </AccordionTab>
    )
  }

  const filterHeader = () => {
    return (
      <div className={noteStyle.noteFilterHeaderGridContainer}>
        <Tooltip target=".filterheader-disabled-tt" />
        <div className={noteStyle.noteFilterHeader}>
          <label>Filters</label>
        </div>
        <div className={noteStyle.noteFilterButton}>
          <button
            type="button"
            className="btn"
            style={{
              float: "right",
              width: 40,
              height: 33,
              display: "flex",
              padding: "4px 9px",
              marginRight: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation()
              setRefresh(new Date().getTime())
            }}
          >
            <span
              className={`material-icons  p-overlay-badge ${noteStyle.NoteMarkRead}`}
            >
              {"autorenew"}
            </span>
          </button>
          <div
            className={
              props.isDisabledParticipant ? "filterheader-disabled-tt" : ""
            }
            data-pr-tooltip="Inactive participant"
          >
            <button
              type="button"
              className="btn"
              style={{
                float: "right",
                width: 40,
                height: 33,
                display: "flex",
                padding: "4px 9px",
                marginRight: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation()
                confirmDialog({
                  message:
                    "Do you want to mark all unread notifications as read?",
                  header: "Mark all as read",
                  accept: () => markAllAsRead(),
                })
              }}
              disabled={props.isDisabledParticipant}
            >
              <span
                className={`material-icons  p-overlay-badge ${noteStyle.NoteMarkRead}`}
              >
                {"done_all"}
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const onPageChange = (event) => {
    if (pageSize !== event.rows) {
      setPageSize(event.rows)
    }

    GetNotifications(event.page + 1, event.rows)
    console.log(notifications)
  }

  return (
    <div>
      <div className={noteStyle.addLargeSpace}>
        <Accordion>
          <AccordionTab header={filterHeader()} headerStyle={{ width: "100%" }}>
            <NotificationsFilters
              defaultFilters={defaultFilters}
              filters={filters}
              organizations={props.organizations}
              onSetFilters={setFilters}
              onApplyFilters={setAppliedFilters}
              eventTypes={eventTypes}
              isDisabledParticipant={props.isDisabledParticipant}
            />
          </AccordionTab>
        </Accordion>
      </div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          {notifications && notifications.items.length > 0 ? (
            <>
              <Accordion className={noteStyle.noteAccordion}>
                {notifications
                  ? notifications.items.map((x) => {
                      return itemTemplate(x)
                    })
                  : null}
              </Accordion>
              {notifications ? (
                <Paginator
                  first={notifications.pageSize * (notifications.pageNum - 1)}
                  rows={notifications.pageSize}
                  totalRecords={notifications.numTotal}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[5, 10, 20, 30]}
                />
              ) : null}
            </>
          ) : (
            <div style={{ textAlign: "center" }}>No notifications found</div>
          )}
        </div>
      )}
    </div>
  )
}

import { Dialog } from "primereact/dialog"
import { useState } from "react"
import DailyPlannerPopup from "./DailyPlannerPopup"

export default function DailyPlanner() {
  const [showDialog, setShowDialog] = useState(false)

  function handleButtonClick() {
    setShowDialog(true)
  }

  return (
    <>
      <Dialog
        header={"Daily planner"}
        visible={showDialog}
        onHide={() => {
          setShowDialog(false)
        }}
        style={{ width: "1300px", height: "1100px" }}
        contentStyle={{ padding: 0 }}
      >
        <DailyPlannerPopup
          onClosePopup={() => {
            setShowDialog(false)
          }}
        />
      </Dialog>
      <li
        className="top-icon"
        onClick={() => {
          handleButtonClick()
        }}
      >
        <span className="material-icons top-icon-span">{"today"}</span>
        <label className="top-menu-label">Daily Planner</label>
      </li>
    </>
  )
}

//DevTool components
import DevServiceUrls from "./ServiceUrls/DevToolServiceUrls"

//PrimeReact
import { Dialog } from "primereact/dialog"

//SOAR components
import GLOBALS_ENVIRONMENT from "../../config/globalsEnvironment"

//Styles
import myStyles from "../../styles/developerTools/DevTools.module.css"
import { useState } from "react"
import DevMasquerade from "./DevMasquerade"
import DevRoleSwitcher from "./DevRoleSwitcher"
import { Button } from "primereact/button"

export default function DeveloperToolsModal({
  showTools,
  onHide,
  onDisableTools,
  onCheckTools,
}) {
  const [expandCode, setExpandCode] = useState("")

  const Header = () => {
    if (expandCode !== "") {
      return (
        <div
          className={myStyles.devToolsModalHeader}
          onClick={() => {
            setExpandCode("")
            onCheckTools()
          }}
        >
          <span
            className={`material-icons ${myStyles.devToolsModalHeaderIcon}`}
          >
            arrow_back_ios
          </span>
          Back
        </div>
      )
    } else {
      return (
        <Button
          onClick={() => {
            onDisableTools()
          }}
          className={myStyles.devToolsModalHeaderDisable}
        >
          <span
            className={`material-icons ${myStyles.devToolsModalHeaderIcon}`}
          >
            code_off
          </span>{" "}
          Disable developer tools
        </Button>
      )
    }
  }

  return (
    <Dialog
      visible={showTools}
      header={Header}
      onHide={() => {
        onHide()
        setExpandCode("")
      }}
      className={myStyles.devToolsModal}
    >
      <div className={myStyles.mainBodyLayout}>
        <div className={myStyles.mainBodyLayoutTitle}>Developer tools</div>
        <div className={myStyles.mainBodyLayoutBlank}></div>
        <div className={myStyles.mainBodyLayoutEnv}>
          Environment
          <span className={myStyles.envName}>
            {GLOBALS_ENVIRONMENT.environmentName}
          </span>
        </div>
        <div className={myStyles.mainBodyLayoutBody}>
          <DevServiceUrls
            expandedCode={expandCode}
            onSetExpandedCode={setExpandCode}
            onUpdated={onCheckTools}
          />
          <DevMasquerade
            expandedCode={expandCode}
            onSetExpandedCode={setExpandCode}
          />
          <DevRoleSwitcher
            expandedCode={expandCode}
            onSetExpandedCode={setExpandCode}
          />
        </div>
      </div>
    </Dialog>
  )
}

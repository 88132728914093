import _ from "lodash"
import { useFormikContext, Field } from "formik"

import { useContext } from "react"
import { FormContext } from "../../context/formContext"
import { Checkbox } from "primereact/checkbox"

import styles from "../../styles/Form.module.css"

import fieldVisibility from "../../helpers/fieldVisibility"

function RegularFieldOutput({ field, block, index }) {
  const formik = useFormikContext()
  const form = useContext(FormContext)
  const isRequired = JSON.parse(field.field_validation.required[0])

  let isDisabled = form ? form.blocksSaved[block] : false
  isDisabled = field.read_only ? true : isDisabled

  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label} style={{ marginBottom: 10 }}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      {formik.touched[field.field_name] && formik.errors[field.field_name] && (
        <div className={styles.errFieldMsg}>
          {formik.errors[field.field_name]}
        </div>
      )}
      {_.map(field.field_options, (option) => {
        return (
          <div
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
            onBlur={() => formik.setFieldTouched(field.field_name)}
          >
            <Field name={field.field_name}>
              {({ field: formikField }) => {
                return (
                  <>
                    <Checkbox
                      disabled={isDisabled}
                      inputId={`${field.field_name}_${option.value}`}
                      name={formikField.name}
                      id={field.field_name}
                      value={option.value}
                      onChange={formikField.onChange}
                      checked={_.includes(formikField.value, option.value)}
                    />
                    <label
                      htmlFor={`${field.field_name}_${option.value}`}
                      style={{ marginLeft: 5 }}
                    >
                      {option.label}
                    </label>
                  </>
                )
              }}
            </Field>
          </div>
        )
      })}
    </div>
  )
}

function MultiFieldOutput(props) {
  const { field, isRequired, index, mfFieldName, mfIndex } = props
  const fieldSlug = `${mfFieldName}.${mfIndex}.${field.field_name}`
  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}

      {_.map(field.field_options, (option, i) => {
        return (
          <div
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
          >
            <Field name={fieldSlug}>
              {({ field: formikField, form: formik, meta }) => {
                const fieldId = `${fieldSlug}_${option.value}`
                return (
                  <>
                    <Checkbox
                      type="checkbox"
                      inputId={fieldId}
                      name={formikField.name}
                      value={option.value}
                      onChange={(e) => {
                        const currentValues = formikField.value
                        let newArray = []
                        if (_.includes(currentValues, e.target.value)) {
                          newArray = _.pull(currentValues, e.target.value)
                        } else {
                          newArray = [...currentValues, e.target.value]
                        }
                        formik.setFieldValue(formikField.name, newArray, false)
                      }}
                      checked={_.includes(
                        formik.values[mfFieldName][mfIndex][field.field_name],
                        option.value
                      )}
                    />
                    <label htmlFor={fieldId} style={{ marginLeft: 5 }}>
                      {option.label}
                    </label>
                  </>
                )
              }}
            </Field>
          </div>
        )
      })}
    </div>
  )
}

export default function TypeCheckbox(props) {
  const checkIfRequired = () => {
    try {
      return JSON.parse(props.field.field_validation.required[0])
    } catch (error) {
      return false
    }
  }
  const isRequired = checkIfRequired()
  switch (props.type) {
    case "regular-field":
      const regFieldProps = {
        field: props.field,
        isRequired,
        block: props.block,
        index: props.index,
      }
      return <RegularFieldOutput {...regFieldProps} />
    case "multi-field":
      const multiFieldProps = {
        field: props.field,
        isRequired,
        index: props.index,
        mfFieldName: props.mfFieldName,
        mfIndex: props.mfIndex,
      }
      return <MultiFieldOutput {...multiFieldProps} />
    default:
      return null
  }
}

import React from "react"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import { useQuery } from "@apollo/client"



export default function MyAccount() {
  return (
    <div className="layout-with-sidebar">
     
      <ParticipantSummaryInfo />
      
      <div className="main-content">
        <h1>My Account</h1>
      </div>
    </div>
  )
}

import config from "../config/firebase"

import app from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import * as storage from "firebase/storage"

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.firestore()
    this.storage = storage
  }
  signIn = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)
  signOut = () => {
    sessionStorage.clear()
    return this.auth.signOut()
  }
  sendPasswordResetEmail= (email) => {
    this.auth.sendPasswordResetEmail(email)
  }
}

export default Firebase

import React,{ useState } from "react"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import styles from "../../styles/Page-with-Sidebar.module.css"

export default function REALDForm() {

  
  const dummyAssesment = [
    {
      date: "07/30/2022",
      lenght: "120 minutes",
      channel: "In-person",
      referal_made: "true",
      recovery_capital: "51",
      actions: <button className="btn">View</button>,
    },
    {
      date: "06/30/2020",
      lenght: "90 minutes",
      channel: "Telephone",
      recovery_capital: "54",
      referal_made: "true",
      actions: <button className="btn">View</button>,
    },
    {
      date: "05/30/2020",
      lenght: "60 minutes",
      channel: "Video",
      referal_made: "false",
      recovery_capital: "50",
      actions: <button className="btn">View</button>,
    },
    {
      date: "04/30/2020",
      lenght: "120 minutes",
      channel: "Video",
      referal_made: "true",
      recovery_capital: "49",
      actions: <button className="btn">View</button>,
    },
  ]

  const [lineStylesData] = useState({
    labels: ["20", "30", "40", "50", "60"],
    datasets: [
      {
        label: "Current",
        data: [28, 30, 40, 45, 59],
        fill: true,
        borderDash: [5, 5],
        tension: 0.4,
        borderColor: "rgba(180,99,200,0.2)",
        backgroundColor: "rgba(162,132,170,0.2)",
      },
      {
        label: "Intake",
        data: [30, 35, 40, 45, 56],
        fill: true,
        borderColor: "rgba(162,132,170,0.2)",
        tension: 0.4,
        backgroundColor: "rgba(180,99,200,0.5)",
      },
    ],
  })


  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    }

    return {
      basicOptions,
    }
  }

  const { basicOptions } = getLightTheme()



  return (
    <div  className="layout-with-sidebar">
     
        <ParticipantSummaryInfo />
      
      <div className="main-content">
        <h1>REAL-D Form</h1>

        <div>
            
            <DataTable value={dummyAssesment} responsiveLayout="scroll">
              <Column field="date" header="Date" />
              <Column field="lenght" header="Lenght" />
              <Column field="channel" header="Channel" />
              <Column field="referal_made" header="Referal Made" />
              <Column field="recovery_capital" header="Recovery Capital" />
              <Column field="actions" header="Actions" />
            </DataTable>
          </div>
          
      </div>
    </div>
  )
}

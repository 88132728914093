import React, { useContext, useState } from "react"
import { UserContext } from "../../context/userContext"
import { useQuery } from "@apollo/client"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import { Link } from "react-router-dom"
import { CUSTOM_FORMS_BY_PARTICIPANT } from "./queries" // Update this import to your actual query
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"

import styles from "../../styles/Page-with-Sidebar.module.css"
import _ from "lodash"
import LoaderFullPage from "../../components/Loaders"

export default function CustomsFormsIndex() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [locationFilter, setLocationFilter] = useState(null)
  const [formsFilter, setFormsFilter] = useState(null)
  const user = useContext(UserContext)
  const [staffFilter, setStaffFilter] = useState(null)
  const ID_TEMPLATES = [
    "assessment_8_dimensions",
    "hep_ab_form",
    "hep_c_form",
    "housing_assistance_grant_award",
    "form_insurance_status",
    "lyft_assessment",
    "real_d_form",
  ]

  useQuery(CUSTOM_FORMS_BY_PARTICIPANT, {
    variables: {
      participant_id: user.participant_id,
      idTemplates: ID_TEMPLATES,
      participant_membership_id: user.participant_membership_id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.customForm === null) {
        setData(null)
        setLoading(false)
        return
      }
      const dataFormatted = JSON.parse(data.customForm.data)
      const handleData = () => {
        const validTemplates = _.filter(dataFormatted, (template) => {
          const blocks = _.groupBy(template, "block_name")
          return !_.isEmpty(blocks.form)
        })

        return _.map(validTemplates, (template) => {
          const blocks = _.groupBy(template, "block_name")
          const session = blocks.form[0]
          const staffID = session.staff_id

          const handleLocations = () => {
            const locationsList = _.map(
              session?.values?.form__location_ids,
              ({ label }) => label
            )
            return _.join(locationsList, ", ")
          }

          return {
            organization: session?.organization_name,
            date: new Date(session?.values?.form_date),
            locations: handleLocations(),
            form: _.startCase(session?.idTemplate?.replace(/_/g, " ")),

            formDuration: `${session?.values?.form_length} minutes`,
            facilitating_staff: _.unescape(session?.staff_full_name),
            view: (
              <Link
                to={`/form/view/${session?.idTemplate}/${session?.idStamp}`}
                state={{ staffId: staffID }}
                className="btn"
              >
                View
              </Link>
            ),
          }
        })
      }
      setData(handleData())
      setLoading(false)
      const allLocations = _.map(handleData(), ({ locations }) => locations)
      const allOrganizations = _.map(
        handleData(),
        ({ organization }) => organization
      )
      const allForms = _.map(handleData(), ({ form }) => form)
      const allStaffs = _.map(
        handleData(),
        ({ facilitating_staff }) => facilitating_staff
      )
      setFormsFilter(_.uniq(allForms).sort())
      setOrganizationFilter(_.uniq(allOrganizations).sort())
      setLocationFilter(_.uniq(allLocations).sort())
      setStaffFilter(_.uniq(allStaffs).sort())
    },
  })

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    )
  }
  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date)
  }

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  }
  const organizationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={organizationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select an Organization"
        className="p-column-filter"
        showClear
      />
    )
  }
  const locationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={locationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Location"
        className="p-column-filter"
        showClear
      />
    )
  }
  const formFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={formsFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Form"
        className="p-column-filter"
        showClear
      />
    )
  }
  const staffsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={staffFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Staff"
        className="p-column-filter"
        showClear
      />
    )
  }
  if (loading) return <LoaderFullPage />
  if (!data || data.length === 0) {
    return (
      <div className="layout-with-sidebar">
        <ParticipantSummaryInfo />
        <div className="main-content">
          <h2 className={styles.title}>Custom Data Forms</h2>
          <div>No data available.</div>
        </div>
      </div>
    )
  }
  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      <div className="main-content">
        <div>
          <h2 className={styles.title}>Custom Data Forms</h2>
          <DataTable value={data} responsiveLayout="scroll">
            <Column
              field="organization"
              header="Organization"
              filter
              filterElement={organizationsFilter}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
            />
            <Column
              field="locations"
              header="Location"
              filter
              filterElement={locationsFilter}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
            />
            <Column
              field="form"
              header="Form"
              filter
              filterElement={formFilter}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
            />
            <Column
              field="date"
              sortable
              header="Engagement Date"
              filterField="date"
              dataType="date"
              filter
              filterElement={dateFilterTemplate}
              body={dateBodyTemplate}
            />
            <Column field="formDuration" header="Duration" />
            <Column
              field="facilitating_staff"
              header="Staff name"
              filter
              filterElement={staffsFilter}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
            />
            <Column field="view" header="View" />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

import * as Yup from "yup"

export default function validation_number(field, acc) {

  const { field_name, field_validation } = field

  const checkBool = () => {
    if (!field_validation.required) return false
    return field_validation.required[0] === "true" ? true : false
  }

  const isRequired = checkBool()
  const isRequiredTxt = (field_validation.required && field_validation.required[1]) || "Required field."
  
  const positive = (field_validation.positive && field_validation.positive[0]) || true
  const positiveTxt = (field_validation.positive && field_validation.positive[1]) || "Must be a Positive number."

  const integer = (field_validation.integer && field_validation.integer[0]) || true
  const integerTxt = (field_validation.integer && field_validation.integer[1]) || "Must be an Integer."

  if (!isRequired) return acc

  if (positive && integer) {
    return {
      [field_name]: 
        Yup.number()
          .required(isRequiredTxt)
          .positive(positiveTxt)
          .integer(integerTxt),
      ...acc
    }  
  } else if (positive) {
    return {
      [field_name]: 
        Yup.number()
          .required(isRequiredTxt)
          .positive(positiveTxt),
      ...acc
    }
  } else if (integer) {
    return {
      [field_name]: 
        Yup.number()
          .required(isRequiredTxt)
          .integer(integerTxt),
      ...acc
    }
  } else {
    return {
      [field_name]: 
        Yup.number()
          .required(isRequiredTxt),
      ...acc
    }   
  }
}
import { DateTime } from "luxon"

export default function TypeTime({ value, description }) {
  // const date =    DateTime.fromISO(value).hour + ":" + DateTime.fromISO(value).minute

  const date = DateTime.fromISO(value).toFormat("hh:mm a")
  return (
    <div>
      <strong>{description} </strong>
      {date}
    </div>
  )
}

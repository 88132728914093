import { useContext } from "react"
import { UserContext } from "../../../context/userContext"

//Styles
import docSignStyles from "../../../styles/documentSigning/DocSign.documents.module.css"

//PrimeReact
import { Card } from "primereact/card"
import { Tooltip } from "primereact/tooltip"

const RequestsViewCard = ({
  request,
  stripMetaKey,
  users,
  onDownloadRequest,
  onSignRequest,
}) => {
  const user = useContext(UserContext)

  const cardHeader = () => {
    return (
      <div className={docSignStyles.cardTitle}>
        {request.title || "[No Title]"}
      </div>
    )
  }
  const loadSigners = () => {
    let signers = []

    request?.signatures?.forEach((sig) => {
      const roleName = sig.signerRole
      const signerUserId = stripMetaKey(request?.metadata[roleName])

      if (sig.signerName !== roleName) {
        signers.push(sig.signerName)
      } else if (!!users) {
        //CHeck all user datasets for the signer ID. If never found, we will
        if (
          !users.some((userSet) => {
            const user = userSet.items.filter(
              (x) => x.signerId === signerUserId
            )

            if (user.length > 0) {
              signers.push(`${user[0].name}`)
              return true
            }

            return false
          })
        ) {
          //This fires if we did not find the userId in the list.
          signers.push(`[${roleName}]`)
        }
      } else {
        signers.push(`[${roleName}]`)
      }
    })

    if (signers.length > 0) {
      return signers.map((signer, index) => <div key={index}>{signer}</div>)
    } else {
      return ""
    }
  }

  return (
    <>
      <Card
        header={cardHeader}
        className={docSignStyles.card}
        key={request.signatureRequestId}
      >
        <Tooltip target=".t-tip" />
        <div className={docSignStyles.documentCardInfo}>
          <div className={docSignStyles.documentCardInfoRow}>
            <b>Requested by: </b>
            {request?.requestCreatorName || "Unknown creator"}
          </div>
          <div className={docSignStyles.documentCardInfoRow}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <b>Signers: </b>
              </div>
              <div style={{ marginLeft: "5px" }}>{loadSigners()}</div>
            </div>
          </div>
          <p className={docSignStyles.documentCardInfoRow}>
            <b>Date Requested: </b>
            {request?.requestCreatedAtLocal}
          </p>
          <p className={docSignStyles.documentCardInfoRow}>
            <b>Status: </b>
            {request.status}
          </p>
          <p className={docSignStyles.documentCardInfoRow}>
            <b>Date Completed: </b>
            {request?.completedDateLocal}
          </p>
          <p className={docSignStyles.documentCardInfoRow}>
            <b>Order: </b>
            {request.orderText}
          </p>
          <div className={docSignStyles.cardOptions}>
            <button
              type="button"
              className={`btn t-tip ${docSignStyles.cardOptionButton}`}
              data-pr-tooltip={"Sign document"}
              data-pr-position="bottom"
              onClick={(e) => {
                onSignRequest(request)
              }}
              disabled={!request.canSignNow}
            >
              <span className={`material-icons`}>{"draw"}</span>
            </button>
            <button
              type="button"
              className={`btn t-tip ${docSignStyles.cardOptionButton}`}
              style={{ padding: "5px 8px" }}
              data-pr-tooltip={"Download document"}
              data-pr-position="bottom"
              onClick={(e) => {
                onDownloadRequest(request.signatureRequestId)
              }}
              disabled={!request.isComplete}
            >
              <span className={`material-icons`}>{"download"}</span>
            </button>
          </div>
        </div>
      </Card>
    </>
  )
}
export default RequestsViewCard

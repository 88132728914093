const isDevelopment = process.env.REACT_APP_STAGE === "development"
const isStaging = process.env.REACT_APP_STAGE === "staging"
const isProduction = process.env.REACT_APP_STAGE === "production"
const isQAProduction = process.env.REACT_APP_STAGE === "qaproduction"

const handleUrl = () => {
  let url = "http://localhost:4000"

  if (isDevelopment) {
    return (url = "https://rl-server-yet4uwgw5a-uc.a.run.app")
  }
  if (isStaging) {
    return (url = "https://rl-server-ketiatcuqq-uc.a.run.app")
  }
  if (isProduction) {
    return (url = "https://rl-server-qyya2n6abq-uc.a.run.app")
  }
  if (isQAProduction) {
    return (url = "https://rl-server-kzgi3c75lq-uc.a.run.app")
  }
  return url
}

const url = handleUrl()

export default url

import { gql } from "@apollo/client"

export const REFERRAL_LIST_PARTICIPANT = gql`
  query Referrals($pid: ID!) {
    referrals: referralblocksSaved(participant_id: $pid) {
      data
    }
    orgs: organizationsByPid(participant_id: $pid) {
      organization_id
      organization_name
    }
  }
`

export const REFERRAL_BY_IDSTAMP = gql`
  query Referrals($idStamp: ID!) {
    referrals: getReferralBlockByIdStamp(idStamp: $idStamp) {
      data
    }
  }
`

export const REFERRAL_EXTRA_DETAIL = gql`
  query ReferralExtra($organization_id: ID!) {
    referral_block: getBlockMetadata(
      block_name: "referral"
      organization_id: $organization_id
    ) {
      idBlock
      block_name
      fields {
        idField
        description
        field_label
        field_name
        field_type
      }
    }
  }
`

export const GET_USERS_FROM_LIST = gql`
  query Users($list: [ID!]) {
    users: usersFromList(list: $list) {
      staff_id
      staff_alias
      staff_name_first
      staff_name_last
      staff_email_address
      staff_user_status
      staff_role_name
    }
  }
`

export const GET_AUTHORIZED_USER = gql`
  query Users($participant_id: ID!) {
    user: authenticatedUser(participant_id: $participant_id) {
      participant_id
      participant_alias
      participant_name_first
      participant_name_last
      participant_email_account
      staff_role_name
    }
  }
`

export const UPDATE_REFERRAL_Block = gql`
  mutation updateBlockReferral(
    $staff_id: String
    $organization_id: String
    $referral_status: String
    $referral_participants_notes: String
    $user_type: String
    $idStamp: String
  ) {
    updateBlockReferral(
      staff_id: $staff_id
      organization_id: $organization_id
      referral_status: $referral_status
      referral_participants_notes: $referral_participants_notes
      user_type: $user_type
      idStamp: $idStamp
    ) {
      idBlock
      block_name
    }
  }
`

import axios from "axios";

export async function axiosPut(jwt, fullEndpointURL, data){
    const axiosObj = axios.create();

    const requestConfig = {
        headers: {
           Authorization: "Bearer " + jwt,
           'Content-Type': 'application/json'
        }
     }

    const x =  await axiosObj.put(fullEndpointURL, data, requestConfig).then((responseBody) => { return(responseBody.data)})

    return(x)
}

export async function axiosPost(jwt, fullEndpointURL, data){
    const axiosObj = axios.create();

    const requestConfig = {
        headers: {
           Authorization: "Bearer " + jwt,
           'Content-Type': 'application/json'
        }
     }

    const x =  await axiosObj.post(fullEndpointURL, data, requestConfig).then((responseBody) => { 
        return(responseBody.data)})

    return(x)
}

export async function axiosGet(jwt, fullEndpointURL){
    const axiosObj = axios.create();

    const requestConfig = {
        headers: {
           Authorization: "Bearer " + jwt
        }
     }  

    const x =  await axiosObj.get(fullEndpointURL, requestConfig).then((responseBody) => { return(responseBody.data)})

    return(x)
}

export async function axiosDelete(jwt, fullEndpointURL){
    const axiosObj = axios.create();

    const requestConfig = {
        headers: {
           Authorization: "Bearer " + jwt
        }
     }  

    const x =  await axiosObj.delete(fullEndpointURL, requestConfig).then((responseBody) => { return(responseBody.data)})

    return(x)
}
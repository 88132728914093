import { useContext, useEffect, useState } from "react"

//PrimeReact
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Checkbox } from "primereact/checkbox"
import { FilterMatchMode } from "primereact/api"

//Styles
import myStyles from "../../../styles/assessments/AssessmentBlock.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"

// Context
import { UserContext } from "../../../context/userContext"

export default function AddAssessment({
  availableAssessments,
  onClose,
  onAdd,
}) {
  const userCtx = useContext(UserContext)

  const [filter, setFilter] = useState("")
  const [assessments, setAssessments] = useState([])

  const CheckBodyTemplate = (rowData) => {
    return (
      <Checkbox
        checked={rowData.checked}
        onChange={(e) => {
          const index = assessments.indexOf(rowData)
          let newAssessments = [...assessments]
          newAssessments[index] = { ...rowData, checked: e.checked }
          setAssessments(newAssessments)
        }}
      />
    )
  }

  useEffect(() => {
    setAssessments(availableAssessments)
  }, [availableAssessments])

  return (
    <Dialog
      header={"Add assessment"}
      visible={true}
      onHide={onClose}
      className={myStyles.addAssessmentDialog}
    >
      <div className={myStyles.addAssessmentLayout}>
        <div className={myStyles.addAssessmentLayoutSearch}>
          <span
            className={`p-input-icon-right ${myStyles.addAssessmentSearch}`}
          >
            <i className="pi pi-search" />
            <InputText
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value)
              }}
              placeholder="Search assessment"
              className={myStyles.addAssessmentSearch}
            />
          </span>
        </div>
        <div className={myStyles.addAssessmentLayoutBody}>
          <DataTable
            className={`${assessStyles.dataTable} ${myStyles.hideTableHeader}`}
            value={assessments}
            filters={
              filter
                ? {
                    name: {
                      value: filter,
                      matchMode: FilterMatchMode.CONTAINS,
                    },
                  }
                : {}
            }
          >
            <Column body={CheckBodyTemplate} />
            <Column field="name" filter />
          </DataTable>
        </div>
        <div className={myStyles.addAssessmentLayoutButton}>
          <Button
            type="button"
            label="Add"
            className={`${assessStyles.button} ${myStyles.addAssessmentButton}`}
            onClick={() => {
              const selectedAssessments = assessments?.filter(
                (assessment) => assessment.checked
              )

              if (selectedAssessments && selectedAssessments.length > 0) {
                onAdd(
                  selectedAssessments.map((assessment) => {
                    return {
                      assessment_id: assessment.id,
                      completed_assessment_id: null,
                      session_assessment: true,
                      required: false,
                      assessment_name: assessment.name,
                    }
                  })
                )
              } else {
                userCtx.setNotificationError("No assessments selected")
              }
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

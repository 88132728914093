import { useEffect, useState, useContext } from "react"
import { TabView, TabPanel } from "primereact/tabview"
import TaskAssigned from "./TaskAssigned"

//GraphQL
import { useQuery } from "@apollo/client"
import { GET_USERS_FROM_LIST } from "../taskQueries"

//style
import taskStyle from "../../../styles/TasksForm.module.css"

// Context
import { UserContext } from "../../../context/userContext"

export default function TasksForm(props) {
  const { users, onSetUsers } = props

  const user = useContext(UserContext)
  const uid = user.participant_id

  const [loading, setLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [refreshTasks, setRefreshTasks] = useState(new Date().getTime())
  const [refreshUsers, setRefreshUsers] = useState(false)

  const { refetch } = useQuery(GET_USERS_FROM_LIST, {
    fetchPolicy: "cache-and-network",
    skip: true,
    variables: {
      uid,
    },
    onError: (e) => {
      console.log(e)
      props.onShowError(
        "Unable to retrieve user information, please refer to the console for more information"
      )
      setRefreshUsers(false)
      setLoading(false)
    },
    onCompleted: (data) => {
      if (data.users !== null) {
        let newUsers = data.users
          .filter((x) => x !== null)
          .map((x) => {
            return {
              id: x.staff_id,
              uid: x.staff_id,
              pid: undefined,
              alias: x.staff_alias,
              status: x.staff_user_status,
              email: x.staff_email_address,
              name2: `${x.staff_name_first} ${x.staff_name_last}`,
            }
          })

        onSetUsers([...users, ...newUsers])
      }
      setRefreshUsers(false)
      setLoading(false)
    },
  })

  useEffect(() => {
    if (refreshUsers) {
      refetch()
    }
  }, [refreshUsers, refetch])

  useEffect(() => {
    setSelectedIndex(props.activeKey === "assigned-tasks" ? 0 : 1)
  }, [props.activeKey])

  function checkUserCache(uids) {
    if (!!users) {
      const missingUsers = uids.filter(
        (uid) => users.findIndex((y) => y.uid === uid) < 0
      )
      if (missingUsers.length > 0) {
        setLoading(true)
        refetch({ list: missingUsers })
      }
    } else {
      refetch(uids)
    }
  }

  function getUser(id) {
    if (users) {
      const usr = users?.filter((x) => x.uid === id)

      if (usr.length > 0) {
        return usr[0]
      } else {
        return null
      }
    } else {
      return null
    }
  }

  async function handleAction(actionUrl, payload, task) {
    switch (actionUrl) {
      case "#ScheduledAssessment":
        user.setNotificationAction({ actionUrl, payload })
        break
      default:
        window.location.href = actionUrl
    }
  }

  function getActionButton(label, url, payload, gridClass, task) {
    return (
      <>
        {url ? (
          <div className={gridClass}>
            <button
              className={`btn ${taskStyle.taskActionButton}`}
              onClick={() => {
                handleAction(url, payload, task)
              }}
            >
              {label}
            </button>
          </div>
        ) : null}
      </>
    )
  }

  return (
    <div style={{ position: "relative" }}>
      <TabView
        activeIndex={selectedIndex}
        onTabChange={(e) => setSelectedIndex(e.index)}
        id="SearchMe"
      >
        <TabPanel header="Assigned to me">
          <TaskAssigned
            parentLoading={loading}
            refreshTasks={refreshTasks}
            activeOptionPanel={props.activeSubKey}
            onRefreshTasks={setRefreshTasks}
            users={users}
            onShowError={props.onShowError}
            loadUsers={(x) => {
              checkUserCache(x)
            }}
            getUser={getUser}
            getActionButton={getActionButton}
          />
        </TabPanel>
      </TabView>
      <button
        type="button"
        className="btn"
        style={{
          position: "absolute",
          right: 22,
          top: 5,
          width: 40,
          height: 33,
          display: "flex",
          padding: "4px 9px",
        }}
        onClick={(e) => {
          setRefreshTasks(new Date().getTime())
        }}
      >
        <span className={`material-icons`}> {"autorenew"} </span>
      </button>
    </div>
  )
}

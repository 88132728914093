import { DateTime } from "luxon"
import { engagementChannels, getDate } from "./utils"

export function filterServiceTemplates(serviceTemplates) {
  const excludedTemplateNames = [
    "ad_hoc_recovery_capital_assessment",
    "ad_hoc_referral",
    "assessment_8_dimensions",
    "group_supervision_session",
    "housing_assistance_grant_award",
    "individual_supervision_session",
    "manage_services",
    "nicu_engagement",
    "plan_self_care_engagement",
    "supervision_plan",
    "test_template",
  ]

  if (serviceTemplates) {
    //Filter templates to not include names in the excluded list
    serviceTemplates = serviceTemplates.filter(
      (x) =>
        !excludedTemplateNames.some((exclude) => x.template_name === exclude) &&
        x.template_availability !== 0
    )

    //Filter the following templates unles they have been customized
    const onlyAllowCustom = [
      "custom_session_template_1",
      "custom_session_template_2",
    ]

    serviceTemplates = serviceTemplates.filter(
      (x) =>
        !onlyAllowCustom.some((exclude) => x.template_name === exclude) ||
        x.template_collection === "custom"
    )

    //Filter out template_availability missing or 1
    return serviceTemplates.filter(
      (x) =>
        !excludedTemplateNames.some((exclude) => x.template_name === exclude) &&
        x.template_availability !== 0
    )
  } else {
    return []
  }
}

function checkFilterDate(startDateTime, dateFilterRange) {
  if (!!startDateTime) {
    const startDateTimeValue = getDate(startDateTime)
    const firstDate = DateTime.fromJSDate(dateFilterRange[0]).startOf("day")
    const lastDate = DateTime.fromJSDate(dateFilterRange[1]).endOf("day")

    return (
      startDateTimeValue.toUnixInteger() > firstDate.toUnixInteger() &&
      startDateTimeValue.toUnixInteger() <= lastDate.toUnixInteger()
    )
  } else {
    return false
  }
}

function checkFilterDateRecurringController(
  startDateTime,
  endDateTime,
  dateFilterRange
) {
  if (!!startDateTime) {
    const startDateTimeValue = getDate(startDateTime)
    const endDateTimeValue = getDate(endDateTime)
    const firstDate = DateTime.fromJSDate(dateFilterRange[0]).startOf("day")
    const lastDate = DateTime.fromJSDate(dateFilterRange[1]).endOf("day")

    return (
      //Check if the controller starts befor the firt filter date and ends after it
      (startDateTimeValue <= firstDate && endDateTimeValue >= firstDate) ||
      //Check if the controller started after the first date and before the last date
      (startDateTimeValue > firstDate && startDateTimeValue <= lastDate)
    )
  } else {
    return false
  }
}

export function applyAppointmentFilters(
  appointmentList,
  selectedFilters,
  servicesList,
  dateRangeFilter,
  isCalendarView,
  isRecurringController
) {
  let filteredList = appointmentList || []

  //Get the filter setups
  const filteringType = selectedFilters.filter((x) => servicesList.includes(x))
  const filterCancelledStatus = selectedFilters.includes("Cancelled")
  const filterActiveStatus = selectedFilters.includes("Active")
  const filteringChannel = selectedFilters.filter((x) =>
    engagementChannels.includes(x.trimRight())
  )

  if (filterCancelledStatus || filterActiveStatus) {
    filteredList = filteredList.filter((appointment) => {
      if (filterCancelledStatus && filterActiveStatus) {
        return true
      } else if (filterCancelledStatus) {
        return appointment.status === "Cancelled"
      } else {
        return appointment.status !== "Cancelled"
      }
    })
  }

  if (filteringType.length > 0) {
    if (!!isRecurringController) {
      filteredList = filteredList.filter((appointment) =>
        filteringType.includes(
          appointment.recurringController?.serviceName ||
            appointment.engagementType
        )
      )
    } else {
      filteredList = filteredList.filter((appointment) =>
        filteringType.includes(
          !!appointment.engagementType
            ? appointment.engagementType
            : appointment.serviceName
        )
      )
    }
  }

  if (filteringChannel.length > 0) {
    filteredList = filteredList.filter((x) => {
      if (x.recurringController)
        return filteringChannel.includes(
          x.recurringController.engagementChannel?.toLowerCase()
        )
      else if (x.engagementChannel) {
        return filteringChannel.includes(x.engagementChannel?.toLowerCase())
      } else {
        return filteringChannel.includes(
          x.metadata.engagementChannel?.toLowerCase()
        )
      }
    })
  }

  if (!isCalendarView && !dateRangeFilter.includes(null)) {
    filteredList = filteredList.filter((x) => {
      if (x.isRecurringController) {
        return checkFilterDateRecurringController(
          x.recurringController?.startDate || x.startDate,
          x.recurringController?.endDate || x.endDate,
          dateRangeFilter
        )
      } else {
        return checkFilterDate(x.startDateTime, dateRangeFilter)
      }
    })
  }

  return filteredList
}

import React from "react"
import { useContext, useState } from "react"
import { UserContext } from "../../context/userContext"
import { useQuery } from "@apollo/client"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import { Calendar } from "primereact/calendar"
import { Link } from "react-router-dom"
import { ENGAGEMENTS_PARTICIPANT_GROUP_BY_TEMPLATE } from "./queries"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import styles from "../../styles/Page-with-Sidebar.module.css"
import _ from "lodash"
import { Dropdown } from "primereact/dropdown"

import LoaderFullPage from "../../components/Loaders"

export default function Engagements() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const user = useContext(UserContext)
  const [typesFilter, setTypesFilter] = useState(null)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [locationFilter, setLocationFilter] = useState(null)

  const ID_TEMPLATES = [
    "brief_check_in",
    "care_coordination_session",
    "recovery_plan_engagement",
    "group_session",
    "intake_session",
    "discharge_session",
    "intake_reactivation_session",
  ]
  const ID_BLOCKS = [
    "session",
    "referral",
    "session__assessment_recovery_capital",
  ]

  useQuery(ENGAGEMENTS_PARTICIPANT_GROUP_BY_TEMPLATE, {
    variables: {
      participant_id: user.participant_id,
      idTemplates: ID_TEMPLATES,
      idBlocks: ID_BLOCKS,
    },
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      if (data.engagementsParticipant === null) {
        setData(null)
        return setLoading(false)
      }
      const dataFormatted = JSON.parse(data.engagementsParticipant.data)
      const handleData = () =>
        _.map(dataFormatted, (template) => {
          const blocks = _.groupBy(template, "block_name")
          const session = _.isEmpty(blocks?.session) ? {} : blocks?.session[0]

          const handleLocations = () => {
            const locationsList = _.map(
              session?.values?.session__location_ids,
              ({ label }) => label
            )
            return _.join(locationsList, ", ")
          }

          return {
            organization: session?.organization_name,
            date: new Date(session?.values?.session_date),
            locations: handleLocations(),
            engagement_type: _.startCase(
              session?.idTemplate?.replace(/_/g, " ")
            ),
            length: `${session?.values?.session_length} minutes`,
            facilitating_staff: _.unescape(session?.staff_full_name),
            view: (
              <Link
                to={`/form/view/${session?.idTemplate}/${session?.idStamp}`}
                className="btn"
              >
                View
              </Link>
            ),
          }
        })
      setData(handleData())

      const allTypes = _.map(
        handleData(),
        ({ engagement_type }) => engagement_type || ""
      )
      setTypesFilter(_.uniq(allTypes).sort())

      const allLocations = _.map(handleData(), ({ locations }) => locations)
      setLocationFilter(_.uniq(allLocations).sort())

      const allOrganizations = _.map(
        handleData(),
        ({ organization }) => organization
      )
      setOrganizationFilter(_.uniq(allOrganizations).sort())

      setData(handleData())

      setLoading(false)
    },
  })
  const libraryRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options?.value}
        options={typesFilter}
        onChange={(e) => options?.filterApplyCallback(e.value)}
        placeholder="Select a Type"
        className="p-column-filter"
        showClear
      />
    )
  }

  const locationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={locationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Location"
        className="p-column-filter"
        showClear
      />
    )
  }
  const organizationsFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={organizationFilter}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select an Organization"
        className="p-column-filter"
        showClear
      />
    )
  }

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    )
  }

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date)
  }

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  }

  if (loading) return <LoaderFullPage />

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        <div>
          <div>
            <h2 className={styles.title}>Engagements</h2>

            <DataTable
              value={_.compact(data)}
              responsiveLayout="scroll"
              sortField="organization"
              sortOrder={1}
            >
              <Column
                field="organization"
                header="Organization"
                filter
                filterElement={organizationsFilter}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
              />
              <Column
                field="locations"
                header="Location"
                filter
                filterElement={locationsFilter}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
              />
              <Column
                field="engagement_type"
                header="Engagement Type"
                filter
                filterElement={libraryRowFilterTemplate}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
              />
              <Column
                field="date"
                sortable
                header="Engagement Date"
                filterField="date"
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
                body={dateBodyTemplate}
              />

              <Column field="length" header="Engagement Duration" />

              <Column field="facilitating_staff" header="Staff" />
              <Column field="view" header="View" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

import axios from "axios"
import { DateTime } from "luxon"

import { notificationsUrl as api_url } from "../../config/microservicesUrls"

async function axiosPut(jwt, endpoint, data) {
  const axiosObj = axios.create()

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
  }

  const url = api_url()
  console.log(url)
  const result = await axiosObj
    .put(`${url}/${endpoint}`, data, requestConfig)
    .then((responseBody) => {
      return responseBody.data
    })

  return result
}

async function axiosPost(jwt, endpoint, data) {
  const axiosObj = axios.create()

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
  }

  const url = api_url()
  const x = await axiosObj
    .post(`${url}/${endpoint}`, data, requestConfig)
    .then((responseBody) => {
      return responseBody.data
    })

  return x
}

async function axiosGet(jwt, endpoint) {
  const axiosObj = axios.create()

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  }

  const url = api_url()
  const x = await axiosObj
    .get(`${url}/${endpoint}`, requestConfig)
    .then((responseBody) => {
      return responseBody.data
    })

  return x
}

export async function noteApiMarkAllAsReadPut(jwt, user) {
  return await axiosPut(jwt, "Notification/MarkAllRead", {
    groupId: "All",
    userId: user.participant_id,
    alternateUserId: user.participant_alias || "0",
    alternateGroupId: user.organization_alias,
  })
}

export async function noteApiMarkAllSystemAsReadPut(jwt, user) {
  return await axiosPut(jwt, "Notification/MarkAllRead/System", {
    enterpriseId: user.enterprise_id || "0",
    alternateEnterpriseId: user.enterprise_alias || "0",
    groupId: user.organization_id,
    alternateGroupId: user.organization_alias || "0",
    userId: user.participant_id,
    alternateUserId: user.participant_alias || "0",
  })
}

export async function noteApiMarkAsReadPut(jwt, notificationGuid) {
  return await axiosPut(jwt, "Notification/MarkRead", notificationGuid)
}

export async function noteApiMarkSystemAsReadPut(
  jwt,
  notificationGuid,
  userId
) {
  return await axiosPut(jwt, "Notification/MarkRead/System", {
    notificationId: notificationGuid,
    userId: userId,
  })
}

export async function noteApiMarkAsUnreadPut(jwt, notificationGuid) {
  return await axiosPut(jwt, "Notification/MarkUnread", notificationGuid)
}

export async function noteApiMarkSystemAsUnreadPut(
  jwt,
  notificationGuid,
  userId
) {
  return await axiosPut(jwt, "Notification/MarkUnread/System", {
    notificationId: notificationGuid,
    userId: userId,
  })
}

export async function noteApiCountPost(
  jwt,
  enterpriseId,
  groupIds,
  uid,
  alias,
  creationDate
) {
  return await axiosPost(jwt, "Notification/Count", {
    enterpriseId: enterpriseId || "0",
    groupId: "All",
    groupIds: groupIds,
    userId: uid,
    alternateUserId: alias,
    peerId: "0",
    caseId: "0",
    systemNotificationFilters: ["Participants", "All"],
    CreateDateStartFilter: creationDate,
  })
}

export async function noteApiListMutedPost(jwt, organization_id, uid) {
  return await axiosPost(jwt, "Notification/Muted/List", {
    groupId: organization_id || "0",
    userId: uid,
  })
}

export async function noteApiMuteNotificationsPOST(
  jwt,
  organization_id,
  participant_id,
  eventTypes
) {
  return await axiosPost(jwt, "Notification/Mute", {
    groupId: organization_id,
    userId: participant_id,
    eventTypes: eventTypes,
  })
}

export async function noteApiListNotificationsPOST(
  jwt,
  userid,
  user_alias,
  organizationid,
  organization_alias,
  readFilter,
  eventTypeFilter,
  createDateStartFilter,
  createDateEndFilter,
  readDateStartFilter,
  readDateEndFilter,
  titleFilter,
  sortOrder,
  pageNum,
  pageSize
) {
  return await axiosPost(jwt, `Notification/List`, {
    groupId: organizationid || "0",
    alternateGroupId: organization_alias,
    userId: userid,
    alternateUserId: user_alias,
    readFilter: readFilter,
    eventTypeFilter: eventTypeFilter,
    createDateStartFilter: createDateStartFilter
      ? DateTime.fromJSDate(createDateStartFilter).toUTC()
      : null,
    createDateEndFilter: createDateEndFilter
      ? DateTime.fromJSDate(createDateEndFilter).toUTC()
      : null,
    readDateStartFilter: readDateStartFilter
      ? DateTime.fromJSDate(readDateStartFilter).toUTC()
      : null,
    readDateEndFilter: readDateEndFilter
      ? DateTime.fromJSDate(readDateEndFilter).toUTC()
      : null,
    titleFilter: titleFilter,
    sortOrder: sortOrder,
    pageNum: pageNum,
    pageSize: pageSize,
  })
}

export async function noteApiListSystemNotificationsPOST(
  jwt,
  user,
  readFilter,
  eventTypeFilter,
  createDateStartFilter,
  createDateEndFilter,
  readDateStartFilter,
  readDateEndFilter,
  titleFilter,
  sortOrder,
  pageNum,
  pageSize,
  organizationIds
) {
  return await axiosPost(jwt, `Notification/List/System`, {
    enterpriseId: user.enterprise_id || "0",
    groupId: "All",
    userId: user.participant_id,
    readFilter: readFilter,
    eventTypeFilter: eventTypeFilter,
    createDateStartFilter: createDateStartFilter
      ? DateTime.fromJSDate(createDateStartFilter).toUTC()
      : null,
    createDateEndFilter: createDateEndFilter
      ? DateTime.fromJSDate(createDateEndFilter).toUTC()
      : null,
    readDateStartFilter: readDateStartFilter
      ? DateTime.fromJSDate(readDateStartFilter).toUTC()
      : null,
    readDateEndFilter: readDateEndFilter
      ? DateTime.fromJSDate(readDateEndFilter).toUTC()
      : null,
    titleFilter: titleFilter,
    systemNotificationFilters: ["Participants", "All"],
    sortOrder: sortOrder,
    pageNum: pageNum,
    pageSize: pageSize,
    GroupIds: organizationIds,
  })
}

export async function noteApiListEventTypesGet(jwt) {
  return await axiosGet(jwt, "Notification/List/EventTypes")
}

export async function noteApiUnmuteNotificationsGET(
  jwt,
  organization_id,
  user_id
) {
  return await axiosGet(
    jwt,
    `Notification/Unmute/${organization_id}/${user_id}`
  )
}

export async function noteApiListNotificationsSinceLastQueryPOST(
  user,
  pageNum,
  pageSize
) {
  let organization_id = user.organization_id

  organization_id = "All"

  return await axiosPost(user.tokenNotification, `Notification/ListSinceLast`, {
    groupId: organization_id,
    userId: user.participant_id,
    alternateGroupId: user.organization_alias,
    alternateUserId: user.participant_alias,
    pageNum: pageNum,
    pageSize: pageSize,
  })
}

import _ from "lodash"
import { useFormikContext, FieldArray } from "formik"

import MFBuilder from "./MFBuilder"
import styles from "../../../styles/Form-MultiBlock.module.css"

export default function TypeMultiField({ field, index }) {
  const { field_name, field_label, multi_fields } = field
  const formik = useFormikContext()
  const mfDefinition = _.reduce(
    multi_fields,
    (acc, field) => {
      const { field_type, field_name, multi_fields } = field
      if (field_type === "multi_field_nested") {
        const nestedField = _.reduce(
          multi_fields,
          (acc, { field_name }) => {
            return {
              ...acc,
              [field_name]: "",
            }
          },
          {}
        )
        return {
          ...acc,
          [field_name]: [nestedField],
        }
      }
      return {
        ...acc,
        [field_name]: "",
      }
    },
    {}
  )
  const handleMFBlocks = () => {
    return (
      <FieldArray
        name={field_name}
        render={(arrayHelpers) =>
          _.map(formik.values[field_name], (value, index) => (
            <MFBuilder
              arrayHelpers={arrayHelpers}
              fields={multi_fields}
              key={index}
              mfDefinition={mfDefinition}
              mfFieldName={field_name}
              mfLength={formik.values[field_name].length}
              mfIndex={index}
            />
          ))
        }
      />
    )
  }

  return (
    <div className={styles.multiBlock} style={{ order: index }}>
      <div className={styles.head}>
        <h3>{field_label} (Multi-field)</h3>
      </div>
      <div className={styles.blocks}>{handleMFBlocks()}</div>
    </div>
  )
}

import { Tooltip } from "primereact/tooltip"
import docSignStyles from "../../../styles/documentSigning/DocSign.filters.module.css"
const FilterButton = ({
  iconName,
  toolTip,
  disabled,
  onClickCallBack,
  addSpaceRight,
  doHighlight,
}) => {
  return (
    <>
      <Tooltip target={".tp-filterButton"} />
      <button
        type="button"
        className={`btn tp-filterButton ${docSignStyles.filterHeaderButton} ${
          addSpaceRight ? docSignStyles.filterbuttonSpaceRight : ""
        } ${doHighlight ? docSignStyles.filterbuttonHighlight : ""}`}
        data-pr-tooltip={toolTip}
        data-pr-position="bottom"
        onClick={(e) => {
          e.stopPropagation()
          if (!!onClickCallBack) {
            onClickCallBack()
          }
        }}
        disabled={disabled}
      >
        <span className={`material-icons`}>{iconName}</span>
      </button>
    </>
  )
}
export default FilterButton

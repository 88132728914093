const isProduction = process.env.REACT_APP_STAGE === "production"

const handleConfig = () => {
  if (isProduction) {
    return {
      apiKey: "AIzaSyDsa-wMO69HxFiQVbzJrw4W-Bfpe2iEFLc",
      authDomain: "recoverylink-err-platform.firebaseapp.com",
      projectId: "recoverylink-err-platform",
      storageBucket: "recoverylink-err-platform.appspot.com",
      messagingSenderId: "560031736184",
      appId: "1:560031736184:web:dbcbf4a88f37b8c8e6288d",
    }
  }
  switch (process.env.REACT_APP_STAGE) {
    case "qaproduction":
      return {
        apiKey: "AIzaSyDWAv0GmCEpZFJkIs4whRYx75_ljuFcQ1A",
        authDomain: "recoverylink-qa-platform.firebaseapp.com",
        projectId: "recoverylink-qa-platform",
        storageBucket: "recoverylink-qa-platform.appspot.com",
        messagingSenderId: "1091427460345",
        appId: "1:1091427460345:web:4bbc090b04187440ef6e28",
        measurementId: "G-B6VR3J3Q3J",
      }
    case "staging":
      return {
        apiKey: "AIzaSyD0yQt1qrFp216fQehEttAxrjk9tHgYOzA",
        authDomain: "recoverylink-err-stage.firebaseapp.com",
        projectId: "recoverylink-err-stage",
        storageBucket: "recoverylink-err-stage.appspot.com",
        messagingSenderId: "323043065409",
        appId: "1:323043065409:web:875a37b26a703691c590c0",
      }
    default:
      return {
        apiKey: "AIzaSyAZ-wrMfNLeIxdXZ2bConeb75qX2uPE4oM",
        authDomain: "recoverylink-err-dev.firebaseapp.com",
        projectId: "recoverylink-err-dev",
        storageBucket: "recoverylink-err-dev.appspot.com",
        messagingSenderId: "435913833905",
        appId: "1:435913833905:web:aec5fb9988fd0ed9b35190",
        measurementId: "G-3HM8YWJ9SS",
      }
  }
}

const config = handleConfig()

export default config


import { useEffect, useContext } from "react"
import { useFormikContext } from "formik"
import styles from "../../../styles/Form.module.css"

import { UserContext } from "../../../context/userContext"

export default function ParticipantId({ field, index }) {
  const formik = useFormikContext()
  const user = useContext(UserContext)

  useEffect(() => {
    const currentParticipantId = user.participant_id

    if (!currentParticipantId) {
      return formik.setFieldValue(field.field_name, "not-set")
    }
    return formik.setFieldValue(field.field_name, currentParticipantId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.hidden} style={{ order: index }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

import _ from "lodash"
import { useFormikContext, FieldArray } from "formik"

import NestedMFBuilder from "./NestedMFBuilder"

import styles from "../../../../styles/Form-NestedMB.module.css"

export default function TypeNestedMultiField(props) {
  const { mfFieldName, mfIndex, field, index } = props
  const { field_name, field_label, multi_fields } = field

  const formik = useFormikContext()
  const mfDefinition = _.reduce(
    multi_fields,
    (acc, { field_name }) => {
      return {
        ...acc,
        [field_name]: "",
      }
    },
    {}
  )
  const handleNestedMFBlocks = () => {
    const nested = formik.values[mfFieldName][mfIndex][field_name]
    const name = `${mfFieldName}.${mfIndex}.${field_name}`
    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) =>
          _.map(nested, (value, index) => (
            <NestedMFBuilder
              arrayHelpers={arrayHelpers}
              fields={multi_fields}
              key={index}
              mfDefinition={mfDefinition} // ??
              mfFieldName={mfFieldName}
              nestedMfFielName={field_name}
              mfIndex={mfIndex}
              nestedMfIndex={index}
              mfLength={nested.length} // ?
            />
          ))
        }
      />
    )
  }

  return (
    <div className={styles.nestedMultiBlock} style={{ order: index }}>
      <div className={styles.head}>
        <h4>{field_label} (Nested Multi-field)</h4>
      </div>
      <div className={styles.blocks}>{handleNestedMFBlocks()}</div>
    </div>
  )
}

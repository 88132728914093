import { useCallback, useContext, useEffect, useState } from "react"

// Context
import { UserContext } from "../../../context/userContext"

//PrimeReact
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"

//Styles
import myStyles from "../../../styles/assessments/AssessmentBlock.module.css"
import { useLazyQuery } from "@apollo/client"
import { GET_LOCATIONS } from "../queries"
import { LogError } from "../../../helpers/logger"

export default function AssessmentBlockSession({
  locationId,
  onLocationIdChange,
  sessionDate,
  onSessionDateChange,
  readOnly,
}) {
  const userCtx = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState([])
  const [placeholder, setPlaceHolder] = useState("Loading...")

  const [loadDataQuery] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: userCtx.staff_id,
      organization_id: userCtx.organization_id,
    },
  })

  const loadData = useCallback(async () => {
    try {
      const results = await loadDataQuery({
        variables: {
          staff_id: userCtx.staff_id,
          organization_id: userCtx.organization_id,
        },
      })

      if (results.data) {
        if (results.data.locations) {
          setLocations(
            results.data.locations.filter(
              (location) => location.location_status === "ACTIVE"
            ) || []
          )
        } else {
          setLocations([])
        }

        setPlaceHolder("Please select an option")
        setIsLoading(false)
      } else {
        LogError(
          "Unable to load the active locations and programs for this organization: No data returned"
        )
        userCtx.setNotificationError(
          "Unable to load available locations and programs"
        )
        setLocations([])
        setPlaceHolder("No values available")
        setIsLoading(false)
      }
    } catch (error) {
      LogError(
        "Unable to load the active locations and programs for this organization",
        error
      )
      userCtx.setNotificationError(
        "Unable to load available locations and programs"
      )
      setLocations([])
      setIsLoading(false)
      setPlaceHolder("No values available")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.organization_id])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div className={myStyles.sessionLayout}>
      <div className={`${myStyles.sessionLayoutTitle} ${myStyles.title}`}>
        Session
      </div>
      <div className={myStyles.sessionLayoutDate}>
        <div>Session date</div>
        <div style={{ marginBottom: "10px" }}>
          <small style={{ color: "gray" }}>
            {"The date for which this session is being captured"}
          </small>
        </div>
        <div className={myStyles.sessionDropdownContainer}>
          <Calendar
            value={sessionDate}
            onChange={(e) => {
              onSessionDateChange(e.value)
            }}
            className={myStyles.sessionDate}
            disabled={readOnly}
          />
        </div>
      </div>
      <div className={myStyles.sessionLayoutLocation}>
        <div>Location</div>
        <div style={{ marginBottom: "10px" }}>
          <small style={{ color: "gray" }}>
            {"Specify the location to be captured for this session"}
          </small>
        </div>
        <div className={myStyles.sessionDropdownContainer}>
          <Dropdown
            className={myStyles.sessionDropDown}
            showClear
            disabled={isLoading || readOnly}
            options={locations}
            optionLabel="location_name"
            placeholder={placeholder}
            value={locationId}
            onChange={(e) => {
              onLocationIdChange(e.value)
            }}
          />
        </div>
      </div>
    </div>
  )
}

import _ from "lodash"
import GLOBALS_STATUS from "../config/globalsStatus"
import PropTypes from "prop-types"

export default function isParticipantActive(participant_membership_status) {
  return _.includes(GLOBALS_STATUS.ACTIVE_STATUS, participant_membership_status)
}

isParticipantActive.propTypes = {
  participant_membership_status: PropTypes.string.isRequired,
}

//PrimeReact
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"

//Styles
import myStyles from "../../../styles/developerTools/DevToolsServiceUrls.module.css"
import { useEffect, useState } from "react"

export default function ServiceUrl({ service, onOverride }) {
  const [url, setUrl] = useState("")

  useEffect(() => {
    //Check if the URL is currently overridden
    if (service.newOverride) {
      setUrl(service.newOverride)
    } else if (service.activeOverride?.overrideUrl) {
      setUrl(service.activeOverride.overrideUrl)
    } else {
      setUrl(service.defaultUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.defaultUrl, service.activeOverride, service.reset])

  const setOverrideValue = (value) => {
    setUrl(value)
    onOverride(value)
  }

  let status = url === service.defaultUrl ? "" : "Overridden"

  if (status !== "" && url !== service.activeOverride.overrideUrl) {
    status = `${status} (New)`
  } else if (status !== "") {
    status = `${status}`
  }

  return (
    <div className={myStyles.serviceLayout}>
      <div className={myStyles.mainBodyLayoutName}>
        {service?.name || "Unknown service"}{" "}
        {status !== "" ? (
          <span className={myStyles.overriddenServiceTag}>{status}</span>
        ) : null}
      </div>
      <div className={myStyles.mainBodyLayoutUrl}>
        <InputText
          className={myStyles.urlInput}
          value={url}
          onChange={(e) => {
            setOverrideValue(e.target.value)
          }}
        />
      </div>
      <div className={myStyles.mainBodyLayoutReset}>
        <Button
          className={myStyles.resetButton}
          onClick={() => {
            setOverrideValue(service.defaultUrl)
          }}
        >
          <span className="material-icons">refresh</span>
        </Button>
      </div>
    </div>
  )
}

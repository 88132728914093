import React from "react"
import { ProgressSpinner } from "primereact/progressspinner"
import { ProgressBar } from 'primereact/progressbar';

import styles from "../../styles/Loader.module.css"

export function LoaderSmall() {
  return (
    <div className={styles.wrapperSm}>
      <ProgressSpinner
        style={{ width: "30px", height: "30px" }}
        strokeWidth="4"
        animationDuration=".2s"
      />
    </div>
  )
}

export function LoaderProgressBar() {
  return (
    <div>
      <ProgressBar mode="indeterminate" style={{ height: '3px' }}></ProgressBar>
    </div>
  )
}

export function LoaderMedium() {
  return (
    <div className={styles.wrapperMed}>
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="4"
        animationDuration=".4s"
      />
    </div>
  )
}

export default function LoaderFullPage() {
  return (
    <div className={styles.wrapperFull}>
      <ProgressSpinner />
    </div>
  )
}

import { useState, useContext } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useQuery } from "@apollo/client"
import _ from "lodash"
import ParticipantProfileBoxSmall from "../../components/ParticipantProfileBoxSmall"
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import { Link } from "react-router-dom"
// Context
import { UserContext } from "../../context/userContext"

// Queries
import { GET_TEMPLATE_VIEW_MODE } from "./queries"

// Helpers
import FieldValuePerType from "./FieldValuePerType"

export default function FormViewMode() {
  const [formValues, setFormValues] = useState({})
  const navigate = useNavigate()
  const [titlePage, setTitle] = useState({})
  const [descriptionPage, setDescription] = useState({})
  const user = useContext(UserContext)
  const { idTemplate, idStamp = null } = useParams()
  const { state } = useLocation()
  const staffId = state?.staffId
  const TYPE = "system"

  const variables = {
    staff_id: staffId ? staffId : "0",
    organization_id: user.organization_id,
    enterprise_id: user.enterprise_id,
    idTemplate,
    type: TYPE,
    participant_id: user.participant_id,
    idStamp,
  }

  const HIDE_TEMPLATES = ["intake_session", "discharge_session"]
  const hidden = _.includes(HIDE_TEMPLATES, idTemplate)

  const { loading, data } = useQuery(GET_TEMPLATE_VIEW_MODE, {
    variables,
    //  skip: !user.staff_id,
    onCompleted: (data) => {
      try {
        const parsedFormValues = JSON.parse(data.template.values)
        const filterEmptyValues = _.reduce(
          parsedFormValues,
          (acc, value, key) => {
            if (_.isEmpty(value)) return acc
            return {
              ...acc,
              [key]: value,
            }
          },
          {}
        )
        setTitle(data.template.template_title)
        setDescription(data.template.template_description)

        setFormValues(filterEmptyValues)
      } catch (error) {
        console.log(error)
      }
    },
  })

  const handleFieldsPerBlock = (fields, block_name) => {
    return _.map(
      fields,
      ({
        field_label,
        field_name,
        field_type,
        field_options,
        field_description,
      }) => {
        const blockValues = formValues[block_name]
        const skipField = field_type === "hidden" || field_type === "sys"
        if (skipField) return null
        const fieldValuePerTypeArg = {
          blockValues,
          fieldName: field_name,
          fieldType: field_type,
          fieldOptions: field_options,
          description: field_description,
        }
        const handleLabel = () => {
          if (field_type === "file") {
            return "File"
          }
          return field_label
        }
        return (
          <div id={field_name} key={field_name} className="view-data-field">
            <div className="field-name">
              <strong>{handleLabel()}</strong>
            </div>
            <div className="field-value">
              <FieldValuePerType fieldValuePerTypeArg={fieldValuePerTypeArg} />
            </div>
          </div>
        )
      }
    )
  }

  const getTitle = () => {
    if (!_.isNull(titlePage)) return titlePage.toString()
  }

  const getDescription = () => {
    if (!_.isNull(descriptionPage)) return descriptionPage.toString()
  }
  const handleBlocks = () => {
    const { blocks } = data.template
    const blocksOrderedByIndex = _.orderBy(blocks, ["index"])
    return _.map(
      blocksOrderedByIndex,
      ({ block_title, block_name, fields }) => {
        if (!_.has(formValues, block_name)) return null
        return (
          <div id={block_name} key={block_name}>
            <hr></hr>
            <h2>{block_title}</h2>
            {handleFieldsPerBlock(fields, block_name)}
          </div>
        )
      }
    )
  }

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <div className="layout-with-sidebar view-mode" id="top-view-form">
        <ParticipantSummaryInfo isFormViewActive={true} />

        <div className="main-content">
          <br />
          <ParticipantProfileBoxSmall />

          <div>
            <h1> {getTitle()}</h1>
            <div className="description-links-block">
              <div
                className="links-block"
                style={{ width: "100%", marginTop: -27 }}
              >
                <Link
                  className="btn-sm edit-button"
                  onClick={() => navigate(-1)}
                  to="#"
                >
                  Close View
                </Link>
              </div>
            </div>

            <div>{handleBlocks()}</div>
            <div>
              <a href="#top-view-form" className="btn-sm scroll-top">
                Back to Top
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import _ from "lodash"
const tags = {
  participantTag: "{participantFullName}",
  staffTag: "{staffFullName}",
}

export function anyNotificationHasTags(notifications) {
  const hasTags = notifications.some((notification) =>
    notificationHasTags(notification)
  )
  return hasTags
}

export function notificationCheckForTag(notification, tagValue) {
  return (
    (notification.description && notification.description.includes(tagValue)) ||
    (notification.title && notification.title.includes(tagValue)) ||
    (!!notification.primaryActionLabel &&
      notification.primaryActionLabel.includes(tagValue)) ||
    (!!notification.secondaryActionLabel &&
      notification.secondaryActionLabel.includes(tagValue))
  )
}

export function notificationHasTags(notification) {
  const hasTag = Object.keys(tags).some((tag) => {
    return notificationCheckForTag(notification, tags[tag])
  })

  return hasTag
}

export function notificationHasParticipantTag(notification) {
  return notificationCheckForTag(notification, tags.participantTag)
}

export function notificationHasStaffTag(notification) {
  return notificationCheckForTag(notification, tags.staffTag)
}

function replaceTag(originalValue, tagValue, newValue) {
  return originalValue.replace(tagValue, newValue)
}

function replaceNotificationTag(notification, tagValue, newValue) {
  notification.description = replaceTag(
    notification.description,
    tagValue,
    newValue
  )

  notification.title = replaceTag(notification.title, tagValue, newValue)

  if (!!notification.primaryActionLabel) {
    notification.primaryActionLabel = replaceTag(
      notification.primaryActionLabel,
      tagValue,
      newValue
    )
  }

  if (!!notification.secondaryActionLabel) {
    notification.secondaryActionLabel = replaceTag(
      notification.secondaryActionLabel,
      tagValue,
      newValue
    )
  }

  return notification
}

async function findProfile(
  profileIdField, //participant_id or staff_id
  profileAliasField, //participant_alias or staff_alias
  profileId, //This could be participant_id or staff_id value
  organization_id,
  profileList,
  profileSearchFunctionAsync
) {
  let profile = undefined

  //If we have a list, check the list for the profile info
  if (!!profileList) {
    const profiles = profileList.filter(
      (profile) =>
        profile[profileIdField] === profileId ||
        profile[profileAliasField] === profileId
    )
    if (!!profiles && profiles.length > 0) {
      profile = profiles[0]
    }
  }

  //If no profile found from listing, check server
  if (!profile && !!profileSearchFunctionAsync) {
    profile = await profileSearchFunctionAsync(profileId, organization_id)
  }

  return profile
}

async function replaceParticipantNameTag(
  notification,
  participantList,
  participantSearchFunctionAsync
) {
  let unknownParticipant = true

  const participant = await findProfile(
    "participant_id",
    "participant_alias",
    notification.caseId,
    notification.groupId,
    participantList,
    participantSearchFunctionAsync
  )

  if (!!participant) {
    unknownParticipant = false

    const userName = `${_.unescape(
      participant.participant_name_first
    )} ${_.unescape(participant.participant_name_last)}`
    replaceNotificationTag(notification, tags.participantTag, userName)
  }

  if (unknownParticipant) {
    console.error(
      "Notifications: Unable to retrieve participant data for enrichment"
    )

    replaceNotificationTag(
      notification,
      tags.participantTag,
      "Unknown Participant"
    )
  }

  return notification
}

async function replaceStaffNameTag(
  notification,
  staffList,
  staffSearchFunctionAsync
) {
  let unknownStaff = true

  const staff = await findProfile(
    "staff_id",
    "staff_alias",
    notification.staffId,
    notification.groupId,
    staffList,
    staffSearchFunctionAsync
  )

  if (!!staff) {
    unknownStaff = false

    const userName = `${_.unescape(staff.staff_name_first)} ${_.unescape(
      staff.staff_name_last
    )}`
    replaceNotificationTag(notification, tags.staffTag, userName)
  }

  if (unknownStaff) {
    console.error("Notifications: Unable to retrieve staff data for enrichment")

    replaceNotificationTag(notification, tags.staffTag, "Unknown Staff member")
  }

  return notification
}

export async function replaceNotificationTags(
  notification,
  participantList,
  callbackFunction,
  participantSearchFunctionAsync,
  staffList,
  staffSearchFunctionAsync
) {
  if (notificationHasTags(notification)) {
    if (notificationCheckForTag(notification, tags.participantTag)) {
      notification = await replaceParticipantNameTag(
        notification,
        participantList,
        participantSearchFunctionAsync
      )
    }

    if (notificationCheckForTag(notification, tags.staffTag)) {
      notification = await replaceStaffNameTag(
        notification,
        staffList,
        staffSearchFunctionAsync
      )
    }
  }

  if (!!callbackFunction) {
    callbackFunction(notification)
  } else {
    return notification
  }
}

import { parseCronExpression } from "cron-schedule"
import { DateTime } from "luxon"
import { LogError } from "../../helpers/logger"
import { getClosestDuration } from "./utilsRecurringControllers"

export function formatDate(x) {
  let startDateKey = getDateKey(x.startDateTime)
  let endDateKey = getDateKey(x.endDateTime)

  if (x.timezone !== "" && x.timezone !== null) {
    if (x.startDateTime.includes("T")) {
      x.startDateTime = DateTime.fromISO(x.startDateTime, {
        zone: x.timezone,
      }).setZone(DateTime.local().toFormat("z")) //conversion;
      x.endDateTime = DateTime.fromISO(x.endDateTime, {
        zone: x.timezone,
      }).setZone(DateTime.local().toFormat("z"))
      x.scheduledTime =
        x.startDateTime.toFormat("hh:mm a") +
        "-" +
        x.endDateTime.toFormat("hh:mm a")

      x.startDateTime = x.startDateTime.toISO()
      x.endDateTime = x.endDateTime.toISO()
    } else {
      x.startDateTime = DateTime.fromFormat(x.startDateTime, startDateKey, {
        zone: x.timezone,
      }).setZone(DateTime.local().toFormat("z")) //conversion;
      x.endDateTime = DateTime.fromFormat(x.endDateTime, endDateKey, {
        zone: x.timezone,
      }).setZone(DateTime.local().toFormat("z"))
      x.scheduledTime =
        x.startDateTime.toFormat("hh:mm a") +
        "-" +
        x.endDateTime.toFormat("hh:mm a")

      x.startDateTime = x.startDateTime.toISO()
      x.endDateTime = x.endDateTime.toISO()
    }
  } else {
    x.scheduledTime =
      DateTime.fromFormat(
        x.startDateTime,
        getDateKey(x.startDateTime)
      ).toFormat("hh:mm a") +
      "-" +
      DateTime.fromFormat(x.endDateTime, getDateKey(x.endDateTime)).toFormat(
        "hh:mm a"
      )
  }
  return x
}

export const timezones = [
  { name: "Eastern Standard Time", code: "America/New_York" },
  { name: "Pacific Standard Time", code: "America/Los_Angeles" },
  { name: "Mountain Standard Time", code: "America/Phoenix" },
  { name: "Mountain Daylight Savings Time", code: "America/Denver" },
  { name: "Central Standard Time", code: "America/Chicago" },
  { name: "Hawaii/Aleutian Standard Time", code: "Pacific/Honolulu" },
  { name: "Alaskan Standard Time", code: "America/Anchorage" },
]

export const services = [
  "Intake",
  "Recovery Planning Session",
  "Brief Check-in",
  "Care Coordination Session",
  "Group Session",
  "Discharge Session",
]

export const durations = [
  { code: "15m", name: "15 minutes", mins: "15" },
  { code: "30m", name: "30 minutes", mins: "30" },
  { code: "45m", name: "45 minutes", mins: "45" },
  { code: "60m", name: "60 minutes", mins: "60" },
  { code: "90m", name: "90 minutes", mins: "90" },
  { code: "2h", name: "2 hours", mins: "120" },
  { code: "3h", name: "3 hours", mins: "180" },
  { code: "4h", name: "4 hours", mins: "240" },
  { code: "5h", name: "5 hours", mins: "300" },
  { code: "6h", name: "6 hours", mins: "360" },
  { code: "7h", name: "7 hours", mins: "420" },
  { code: "8h", name: "8 hours", mins: "480" },
]

export const engagementChannels = [
  "in-person",
  "telephone",
  "video",
  "text/app message",
  "email",
  "other",
]

export const groupFilterTemplate = [
  {
    label: "Appointment Type",
    code: "TYPE",
    items: [
      { label: "Intake", value: "Intake" },
      {
        label: "Recovery Planning Session",
        value: "Recovery Planning Session",
      },
      { label: "Brief Check-in", value: "Brief Check-in" },
      {
        label: "Care Coordination Session",
        value: "Care Coordination Session",
      },
      { label: "Group Session", value: "Group Session" },
      { label: "Discharge Session", value: "Discharge Session" },
    ],
  },
  {
    label: "Status",
    code: "STATUS",
    items: [
      { label: "Active", value: "Active" },
      { label: "Cancelled", value: "Cancelled" },
    ],
  },
  {
    label: "Appointment Channel",
    code: "JP",
    items: [
      { label: "In-Person", value: "in-person" },
      { label: "Telephone", value: "telephone" },
      { label: "Video", value: "video" },
      { label: "Text/App Message", value: "text/app message" },
      { label: "Email", value: "email" },
      { label: "Other", value: "other " },
    ],
  },
]

export const customStyles = {
  content: {
    width: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },

  inputField: {
    color: "#757575",
    fontFamily: "Lato,sans-serif",
    fontWeight: "700",
    fontSize: "16px",
    width: "45%",
  },
  inputStyle: { marginBottom: "10px", marginTop: "5px", width: "100%" },
}

export const isFormFieldValid = (activeAppointment, name) =>
  activeAppointment.errors[name]

export function checkValidWeeklyDateFromStart(
  isBiWeekly,
  LuxonControllerStartDate,
  luxonAppointmentDate
) {
  //If this is a biweekly appointment, isBiWeekly will be TRUE
  if (!isBiWeekly) {
    return true
  }

  //If the appointment date is before the start date, this is invalid.
  if (luxonAppointmentDate < LuxonControllerStartDate) {
    return false
  }

  //Get the start of the week for the dates
  const startDateStartOfWeek = LuxonControllerStartDate.startOf("week")
  const appointmentDateStartOfWeek = luxonAppointmentDate.startOf("week")

  //Calculate the weeks between the controller start date and appointment date.
  const dateDifference = appointmentDateStartOfWeek
    .diff(startDateStartOfWeek, "weeks")
    .toObject()

  //If the week calculator is less than one, it is in the same week.
  if (dateDifference.weeks < 1) {
    return true
  } else if (Math.floor(dateDifference.weeks) % 2 === 0) {
    return true
  } else {
    return false
  }
}

export function getDateKey(startDateTime, luxonFormat) {
  let dateKey = ""
  if (startDateTime.includes("/")) {
    dateKey = "MM/dd/yyyy hh:mm a"
  } else if (!startDateTime.includes("T")) {
    dateKey = "yyyy-MM-dd hh:mm a"
  }

  if (startDateTime.split(":").length === 4) {
    dateKey = dateKey.replace("hh:mm", "hh:mm:ss")
  }

  if (startDateTime.includes(" -") || startDateTime.includes(" +")) {
    dateKey += " ZZ"
  }

  if (luxonFormat) {
    dateKey = dateKey.replace("DD", "dd")
    dateKey = dateKey.replace("yyyy", "yyyy")
    dateKey = dateKey.replace("A", "a")
  }

  return dateKey
}

//convert a datestring into a Luxon DateTime object
export function getDate(dateString, luxonFormat) {
  const dateKey = getDateKey(dateString, luxonFormat)

  if (!!dateKey) {
    return DateTime.fromFormat(dateString, dateKey)
  } else {
    return DateTime.fromISO(dateString, { setZone: true })
  }
}

//Convert a datestring into a luxon DateTime object and force the timezone to the specified zone, keeping the time as is
export function getDateOverrideZone(dateString, luxonFormat, timezone) {
  let date = getDate(dateString, luxonFormat)

  return date.setZone(timezone, {
    keepLocalTime: true,
  })
}

export function getDateTimeValue(dateString, luxonFormat) {
  const dateKey = getDateKey(dateString, luxonFormat)

  //If no dateKey is found, assume that the string is in ISO format.
  return !!dateKey
    ? DateTime.fromFormat(dateString, dateKey)
    : DateTime.fromISO(dateString)
}

export function reformatAppointmentRequest(
  activeAppointment,
  user,
  data,
  forModal,
  isEditing,
  hasAvailabilities,
  cron,
  cronPeriod
) {
  if (forModal) {
    let date = getDateTimeValue(data.startDateTime)
    const startDateTime = date
    let endDate = getDateTimeValue(data.endDateTime)
    const closestDuration = getClosestDuration(date, endDate)

    if (data.isRecurringController) {
      date = getDateTimeValue(data.startDate)
      endDate = getDateTimeValue(data.endDate)
    }

    let payload = {
      id: data.id,
      serviceName: data.engagementType,
      duration: durations.find(
        (x) => x.mins === `${parseInt(closestDuration)}`
      ),
      engagementChannel: data.engagementChannel,
      startDate: date.toJSDate(),
      endDate: endDate.toJSDate(),
      selectedTime: hasAvailabilities
        ? startDateTime.toFormat("hh:mm a")
        : startDateTime.toJSDate(),
      isRecurring: data.cron != null,
      isRecurringController: data.isRecurringController,
      cronDescription: data.frequency,
      type: data.type,
      cron: data.cron,
    }
    return payload
  } else {
    let time =
      typeof activeAppointment.values.selectedTime == "object"
        ? DateTime.fromJSDate(activeAppointment.values.selectedTime).toFormat(
            "hh:mm a"
          )
        : activeAppointment.values.selectedTime

    const id = activeAppointment.values.isRecurringController
      ? `recurring_${activeAppointment.values.id}`
      : activeAppointment.values.id

    let payload = {
      id: id,
      caseId: user.participant_alias,
      peerId: user.staff_assigment_list[0].staff_alias,
      serviceName: activeAppointment.values.serviceName,
      participantName:
        user.participant_name_first + " " + user.participant_name_last,
      participantEmail: user.participant_email_account,
      startDateTime:
        DateTime.fromJSDate(activeAppointment.values.startDate).toFormat(
          "MM/dd/yyyy"
        ) +
        " " +
        time,
      endDateTime: DateTime.fromFormat(
        DateTime.fromJSDate(activeAppointment.values.startDate).toFormat(
          "MM/dd/yyyy"
        ) +
          " " +
          time,
        "MM/dd/yyyy hh:mm a"
      )
        .plus({ minutes: parseInt(activeAppointment.values.duration.mins) })
        .toFormat("MM/dd/yyyy hh:mm a"),
      duration: activeAppointment.values.duration?.mins,
      engagementChannel: activeAppointment.values.engagementChannel,
      timezone: DateTime.local().toFormat("z"),
      orgId: user.organization_alias,
      cron: cron.cronValue,
      isRecurring: activeAppointment.values.isRecurring,
      isRecurringController: activeAppointment.values.isRecurringController,
      recurringStartDate: DateTime.fromFormat(
        DateTime.fromJSDate(activeAppointment.values.startDate).toFormat(
          "MM/dd/yyyy"
        ),
        "MM/dd/yyyy"
      ).toISO(),
      recurringEndDate: DateTime.fromJSDate(
        activeAppointment.values.endDate
      ).toISO(),
      alias: {
        orgAlias: user.organization_id,
        staffAlias: user.staff_assigment_list[0].staff_id,
        participantAlias: user.participant_id,
      },
      selectedPeriod: cronPeriod,
    }

    if (payload.isRecurring) {
      if (
        payload.recurringEndDate === undefined ||
        DateTime.fromJSDate(payload.recurringEndDate) <
          DateTime.fromJSDate(payload.recurringStartDate)
      ) {
        user.setNotificationError(
          "You must select a valid end date for the recurring rules."
        )
        return
      }

      //Calculate the startDateTime and endDateTime based on RecurringStartDate and duration.
      //Staff client will calculate the first occurrence of this schedule.
      const selectedStartDate = DateTime.fromISO(payload.recurringStartDate)
      const selectedEndDate = DateTime.fromISO(payload.recurringEndDate)
      const selectedStartTime = DateTime.fromJSDate(
        activeAppointment.values.selectedTime
      ).setZone(activeAppointment.values.timezone?.code || payload.timezone)

      let startDateTime = DateTime.now()
        .set({
          day: selectedStartDate.day,
          month: selectedStartDate.month,
          year: selectedStartDate.year,
          hour: selectedStartTime.hour,
          minute: selectedStartTime.minute,
          second: 0,
          millisecond: 0,
        })
        .setZone(activeAppointment.values.timezone?.code || payload.timezone, {
          keepLocalTime: true,
        })

      let durationType =
        activeAppointment.values.duration.code[
          activeAppointment.values.duration.code.length - 1
        ]
      let duration = parseInt(activeAppointment.values.duration?.code)

      let momentsType
      if (durationType === "h") {
        momentsType = "hours"
      } else {
        momentsType = "minutes"
      }

      let plusObj = {}
      plusObj[momentsType] = duration

      let endDateTime = startDateTime.plus(plusObj)
      let recurringEndDateTime = DateTime.now()
        .set({
          day: selectedEndDate.day,
          month: selectedEndDate.month,
          year: selectedEndDate.year,
          hour: endDateTime.hour,
          minute: endDateTime.minute,
          second: 0,
          millisecond: 0,
        })
        .setZone(activeAppointment.values.timezone?.code || payload.timezone, {
          keepLocalTime: true,
        })

      payload.recurringStartDate = startDateTime.toISO()
      payload.startDateTime = startDateTime.toISO()
      payload.recurringEndDate = recurringEndDateTime.toISO()
      payload.endDateTime = endDateTime.toISO() //This is the endTime based on duration from startDateTime and not recurring endDateTime.
    }

    return payload
  }
}

export const formatOrgCalDate = (rowData) => {
  return DateTime.fromISO(rowData.startDateTime).toFormat("MM/dd/yyyy")
}

export function getDaysArrayByMonth(dateIndex) {
  let daysInMonth = dateIndex.daysInMonth
  let arrDays = []

  while (daysInMonth) {
    let current = dateIndex.set({ day: daysInMonth })
    arrDays.push(current)
    daysInMonth--
  }

  return arrDays
}

export function getLocalControllerDescription(cronDescription, startDateTime) {
  try {
    let frequency = cronDescription.split(" | ")
    frequency[2] = startDateTime
      .setZone(DateTime.local().toFormat("z"))
      .toFormat("hh:mm a")

    return frequency.join(" | ")
  } catch (error) {
    LogError("Unable to parse local controller description", error)
    return cronDescription
  }
}

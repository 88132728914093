import _ from "lodash"
import * as Types from "../index"

import {
  MdRemoveCircleOutline as IcoRm,
  MdAddCircleOutline as IcoPlus,
} from "react-icons/md"

import TypeNestedMultiField from "./TypeNestedMultiField"

import styles from "../../../styles/Form-MultiBlock.module.css"

export default function MFBuilder(props) {
  const { arrayHelpers, fields, mfDefinition, mfFieldName, mfLength, mfIndex } =
    props

  const handleMFTypes = () =>
    _.map(fields, (field, index) => {
      if (field.field_type === "multi_field_nested") {
        return (
          <TypeNestedMultiField
            key={field.field_name}
            field={field}
            mfIndex={mfIndex}
            mfFieldName={mfFieldName}
            index={index}
          />
        )
      }
      const newProps = {
        type: "multi-field",
        field,
        mfIndex,
        mfFieldName,
      }
      switch (field.field_type) {
        case "text":
          return <Types.TypeText {...newProps} key={field.field_name} />
        case "radio":
          return <Types.TypeRadio {...newProps} key={field.field_name} />
        default:
          return null
      }
    })
  return (
    <div className={styles.block}>
      {handleMFTypes()}
      <div className={styles.actions}>
        <IcoPlus onClick={() => arrayHelpers.insert(mfIndex, mfDefinition)} />
        {mfLength > 1 && <IcoRm onClick={() => arrayHelpers.remove(mfIndex)} />}
      </div>
    </div>
  )
}

import _ from "lodash"
import { useEffect } from "react"

import { useFormikContext } from "formik"

import styles from "../../../styles/Form.module.css"

export default function AssessmentScore({ field, block, index }) {
  const formik = useFormikContext()
  useEffect(() => {
    const totalSumOfAssessmentValues = _.reduce(
      formik.values,
      (acc, value, key) => {
        const pattern = /\d_assessment/
        const isAssessmentField = pattern.test(key)
        return isAssessmentField ? acc + Number(value) : acc
      },
      0
    )
    return formik.setFieldValue(field.field_name, totalSumOfAssessmentValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])
  return (
    <div className={styles.hidden} style={{ order: index }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

import { useContext } from "react"

//Context:
import { UserContext } from "../../../context/userContext"

//docuSign:
import docSignStyles from "../../../styles/documentSigning/DocSign.documents.module.css"

//PrimeReact
import { Tooltip } from "primereact/tooltip"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

const RequestsViewTable = ({
  documents,
  stripMetaKey,
  appliedSorting,
  users,
  onDownloadRequest,
  onSignRequest,
  onSortRequests,
}) => {
  const user = useContext(UserContext)

  function SignerBody(rowData) {
    let signers = []

    rowData.signatures?.forEach((sig) => {
      const roleName = sig.signerRole
      const signerUserId = stripMetaKey(rowData.metadata[roleName])

      if (sig.signerName !== roleName) {
        signers.push(sig.signerName)
      }
      if (!!users) {
        //CHeck all user datasets for the signer ID. If never found, we will
        if (
          !users.some((userSet) => {
            const user = userSet.items.filter(
              (x) => x.signerId === signerUserId
            )

            if (user.length > 0) {
              signers.push(`${user[0].name}`)
              return true
            }

            return false
          })
        ) {
          //This fires if we did not find the userId in the list.
          signers.push(`[${roleName}]`)
        }
      } else {
        signers.push(`[${roleName}]`)
      }
    })

    if (signers.length > 0) {
      return signers.map((signer) => <div>{signer}</div>)
    } else {
      return ""
    }
  }

  function ActionsBody(rowData) {
    return (
      <div>
        <Tooltip target=".t-tip" />
        <button
          type="button"
          className={`btn t-tip ${docSignStyles.cardOptionButton}`}
          data-pr-tooltip={"Sign document"}
          data-pr-position="bottom"
          onClick={(e) => {
            onSignRequest(rowData)
          }}
          disabled={!rowData.canSignNow}
        >
          <span className={`material-icons`}>{"draw"}</span>
        </button>
        <button
          type="button"
          className={`btn t-tip ${docSignStyles.cardOptionButton}`}
          data-pr-tooltip={"Download document"}
          data-pr-position="bottom"
          onClick={(e) => {
            onDownloadRequest(rowData.signatureRequestId)
          }}
          disabled={!rowData.isComplete}
        >
          <span className={`material-icons`}>{"download"}</span>
        </button>
      </div>
    )
  }

  return (
    <DataTable
      value={documents?.items}
      sortField={appliedSorting.sortField}
      sortOrder={appliedSorting.sortOrder}
      onSort={(event) => {
        onSortRequests(event.sortField, event.sortOrder)
      }}
      style={{ fontSize: "small" }}
    >
      <Column field="title" header="Title" sortable />
      <Column field="requestCreatorName" header="Requested By" sortable />
      <Column header="Signers" body={SignerBody} />
      <Column
        field="requestCreatedAt"
        header="Request date"
        body={(rowData) => {
          return <>{rowData.requestCreatedAtLocal}</>
        }}
        style={{ width: "120px" }}
        sortable
      />
      <Column
        field="status"
        header="Status"
        style={{ width: "180px" }}
        sortable
      />
      <Column
        field="completedDate"
        header="Date Completed"
        style={{ width: "120px" }}
        body={(rowData) => {
          return <>{rowData.completedDateLocal}</>
        }}
        sortable
      />
      <Column field="orderText" header="Order" />
      <Column header="Actions" body={ActionsBody} style={{ width: "135px" }} />
    </DataTable>
  )
}
export default RequestsViewTable

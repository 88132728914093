import { useContext, useRef } from "react"
import { Formik, Form } from "formik"
import { Link } from "react-router-dom"

// Context
import { UserContext } from "../../../context/userContext"

// Prime
import { InputText as Input } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Password } from "primereact/password"

import ProfilePhoto from "../../../components/ProfilePhoto"

// Static Files
import styles from "../../../styles/Form.module.css"
import us_states from "../../../resources/us_states.json"

export default function FormProfile({ fields = {}, onSubmitHandler }) {
  const defaultFields = {
    participant_name_first: "",
    participant_name_last: "",
    participant_email_address: "",
    participant_password: "",
  }

  const initialValues = {
    ...defaultFields,
    ...fields,
  }

  const fileInputElement = useRef()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => onSubmitHandler({ variables: values })}
    >
      {({ values, isValid, setFieldValue }) => (
        <Form className="form-section">
          <div className="form-field my-account">
            <label htmlFor="participant_name_first">First name:</label>
            <Input
              id="participant_name_first"
              name="participant_name_first"
              value={values.participant_name_first}
              onChange={({ target: { value } }) =>
                setFieldValue("participant_name_first", value)
              }
            />
          </div>
          <div className="form-field my-account">
            <label htmlFor="participant_name_last">Last name:</label>
            <Input
              id="participant_name_last"
              name="participant_name_last"
              value={values.participant_name_last}
              onChange={({ target: { value } }) =>
                setFieldValue("participant_name_last", value)
              }
            />
          </div>
          <div className="form-field my-account">
            <label htmlFor="participant_email_address">
              Primary account email address:
            </label>
            <div className={styles.description}>
              A valid email address. All emails from the system will be sent to
              this address. <br />
              The email address is not made public and will only be used if you
              wish to receive a new password or wish to receive certain news or
              notifications by email.
            </div>
            <Input
              id="participant_email_address"
              name="participant_email_address"
              value={values.participant_email_account}
              onChange={({ target: { value } }) =>
                setFieldValue("participant_email_address", value)
              }
            />
          </div>
          <div className="form-field my-account">
            <label htmlFor="participant_password">Password</label>
            <Password
              value={values.participant_password}
              onChange={(e) =>
                setFieldValue("participant_password", e.target.value)
              }
            />
          </div>

          <div className="form-field my-account">
            <label htmlFor="participant_photo">Change Profile Photo</label>
          </div>
          <div>
            <input
              accept="image/*"
              id="file"
              name="media"
              type="file"
              ref={fileInputElement}
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0])
              }}
            />
          </div>
          <div>
            <br></br>
            Current profile Photo:
            <ProfilePhoto classes="border-2 border-white rounded-full w-11 h-11 aaa" />
          </div>
          <br></br>

          <div>
            <button type="submit" className="btn" disabled={!isValid}>
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import _ from "lodash"
import { useMutation, gql } from "@apollo/client"
import { useParams } from "react-router-dom"

import { useRef, useState, useContext, useEffect } from "react"
import { FirebaseContext } from "../../firebase"

// PR
import { FileUpload } from "primereact/fileupload"
import { Toast } from "primereact/toast"
import { ProgressBar } from "primereact/progressbar"
import { Button } from "primereact/button"

//comps
import { LoaderSmall } from "../../components/Loaders"
import { confirmPopup } from "primereact/confirmpopup"

//context
import { useFormikContext } from "formik"
import { FormContext } from "../../context/formContext"
import { UserContext } from "../../context/userContext"

import styles from "../../styles/Form.module.css"
import fieldVisibility from "../../helpers/fieldVisibility"

export default function TypeFileImage({ field, block, index }) {
  const { v4: uuidv4 } = require("uuid")
  const [progress, setProgress] = useState(0)
  const [fileUrl, setFileUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const {
    file_is_shared,
    file_extension,
    file_firestore_storage,
    file_multi_files,
    file_size,
    file_is_renameable,
  } = field.field_validation

  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)
  const form = useContext(FormContext)

  const {
    getStorage,
    ref,
    uploadBytesResumable,
    deleteObject,
    getDownloadURL,
  } = firebase.storage

  const { idStamp } = useParams()

  const storage = getStorage()
  const toast = useRef(null)

  const isEditMode = form?.editMode

  const formik = useFormikContext()
  const FIELD_NAME = field.field_name

  let FIELD_PATH = `${file_firestore_storage}/${user.organization_name}`

  if (file_is_shared) FIELD_PATH = `${file_firestore_storage}`

  const REMOVE_FILE_QUERY = gql`
    mutation RemoveFileFromBlock($idStamp: String!) {
      removeFileRef(idStamp: $idStamp) {
        status
      }
    }
  `

  const [removeFileRefFromBlock] = useMutation(REMOVE_FILE_QUERY, {
    variables: {
      idStamp,
    },
  })

  useEffect(() => {
    const currentField = formik.values[FIELD_NAME] || null
    const getDownloadURLFromStorage = async () => {
      const storage = getStorage()
      const fileRef = ref(storage, currentField)
      try {
        const downloadURL = await getDownloadURL(fileRef)
        setFileUrl(downloadURL)
      } catch (error) {
        setFileUrl(null)
      }
      setLoading(false)
    }
    if (isEditMode && currentField) {
      return getDownloadURLFromStorage()
    } else {
      return setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkIfRequired = () => {
    try {
      return JSON.parse(field.field_validation.required[0])
    } catch (error) {
      return false
    }
  }

  const isRequired = checkIfRequired()

  const removeHandler = ({ file }) => {
    const fullFilePath = `${FIELD_PATH}/${file.name}`
    const fileRef = ref(storage, fullFilePath)
    deleteObject(fileRef)
      .then(async () => {
        formik.setFieldValue(FIELD_NAME, "")
        setFileUrl(null)
        await removeFileRefFromBlock()
        toast.current.show({
          severity: "info",
          summary: "Success",
          detail: "File Deleted and Refence removed from Block.",
        })
      })
      .catch((error) => {
        formik.setFieldValue(FIELD_NAME, "")
        console.log(error) // please handle ERR!!
      })
  }

  const uploadComponent = () => {
    const customUploadHander = (e) => {
      const [file] = e.files
      const idFile = uuidv4()

      const myRenamedFile = new File([file], idFile)

      const path = `${FIELD_PATH}/${
        file_is_renameable ? myRenamedFile.name : file.name
      }`
      const storageRef = ref(storage, path)
      const metadata = {
        contentType: file_extension,
      }

      const uploadTask = uploadBytesResumable(
        storageRef,
        file_is_renameable ? myRenamedFile : file,
        metadata
      )

      uploadTask.on(
        "state_changed",
        (snapshot) =>
          setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),

        (error) => {
          console.log("error es", error)
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break
            case "storage/canceled":
              // User canceled the upload
              break
            default:
              console.log(error)
          }
        },
        () => {
          const { fullPath } = uploadTask.snapshot.metadata
          toast.current.show({
            severity: "info",
            summary: "Success",
            detail: "File Uploaded",
          })
          return formik.setFieldValue(FIELD_NAME, fullPath)
        }
      )
    }
    if (file_multi_files) {
      return (
        <>
          <FileUpload
            name={FIELD_NAME}
            customUpload
            uploadHandler={customUploadHander}
            onRemove={removeHandler}
            onClear={() => formik.setFieldValue(FIELD_NAME, "")}
            accept={file_extension}
            maxFileSize={file_size} // 10Mb
            multiple
          />
          <ProgressBar value={progress} style={{ margin: "10px 0" }} />
          {formik.touched[FIELD_NAME] && formik.errors[FIELD_NAME] && (
            <div className={styles.errFieldMsg}>
              {formik.errors[FIELD_NAME]}
            </div>
          )}
        </>
      )
    } else {
      return (
        <>
          <FileUpload
            name={FIELD_NAME}
            customUpload
            uploadHandler={customUploadHander}
            onRemove={removeHandler}
            onClear={() => formik.setFieldValue(FIELD_NAME, "")}
            accept={file_extension}
            maxFileSize={file_size} // 10Mb
          />
          <ProgressBar value={progress} style={{ margin: "10px 0" }} />
          {formik.touched[FIELD_NAME] && formik.errors[FIELD_NAME] && (
            <div className={styles.errFieldMsg}>
              {formik.errors[FIELD_NAME]}
            </div>
          )}
        </>
      )
    }
  }

  const editComponent = () => {
    const getFilename = () => {
      const urlSplit = _.split(formik.values[FIELD_NAME], "/")
      return urlSplit[urlSplit.length - 1]
    }
    if (loading) return <LoaderSmall />
    if (!fileUrl) return uploadComponent()
    const filename = getFilename()
    const accept = () =>
      removeHandler({
        file: {
          name: filename,
        },
      })
    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "Rejected",
        detail: "You have rejected",
        life: 3000,
      })
    }
    const confirm = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Do you want to delete this file?.",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
      })
    }
    return (
      <div>
        <div style={{ marginBottom: 5 }}>
          Filename:
          <a
            download
            className="file-upload"
            target="_blank"
            rel="noreferrer"
            href={fileUrl}
            style={{ marginLeft: 4, fontWeight: "bold" }}
          >
            {filename}
          </a>
        </div>
        <Button
          onClick={confirm}
          icon="pi pi-times"
          label="Delete"
          className="p-button-danger p-button-outlined p-button-sm"
        />
        <small style={{ display: "block" }}>
          Deleting the file removes it immediately and requires a new file
          upload.
        </small>
      </div>
    )
  }

  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
      onBlur={() => formik.setFieldTouched(FIELD_NAME)}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && <span className={styles.asterisk}> *</span>}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Toast ref={toast} />
      {form?.editMode ? editComponent() : uploadComponent()}
    </div>
  )
}

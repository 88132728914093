import { useCallback, useContext, useEffect, useState } from "react"

// Context
import { UserContext } from "../../context/userContext"

//Styles
import dpStyles from "../../styles/dailyPlanner/DailyPlanner.module.css"

//DailyPlanner components
import {
  GetCounterPanel,
  GetSummaryPanel,
  GetDetailPanel,
} from "./DailyPlannerPanels"
import {
  getTasks,
  GetTaskPanel,
  GetNewTaskPanel,
  headerButtonAction,
} from "./DailyPlannerTasks"
import {
  getNotifications,
  GetNewNotificationsPanel,
  notificationHeaderButtonAction,
} from "./DailyPlannerNotifications"
import {
  getAppointments,
  GetAppointmentsPanel,
  headerButtonAction as appointmentsHeaderButtonAction,
  GetAppointmentInfo,
} from "./DailyPlannerAppointments"
import { getDocuments } from "./DailyPlannerDocumentSigning"

//GraphQL
import { useLazyQuery } from "@apollo/client"
import { GET_PARTICIPANT_ORGNIZATIONS } from "../../components/notifications/queries"
import { GET_NOTIFICATION_USERS } from "../../components/notifications/notificationsQueries"

import { LogError } from "../../helpers/logger"
import { useNavigate } from "react-router"
import ROUTES from "../Routes/routesContants"

export default function DailyPlannerPopup({ onClosePopup }) {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const [initialLoad, setInitialLoad] = useState(true)

  //tasks
  const [isLoadingTasks, setIsLoadingTasks] = useState(true)
  const [taskCounter, setTaskCounter] = useState(undefined)
  const [newTasks, setNewTasks] = useState(undefined)
  const [tasksToday, setTasksToday] = useState(undefined)

  //Notifications
  const [isLoadingNotes, setIsLoadingNotes] = useState(true)
  const [newNotifications, setNewNotifications] = useState(true)
  const [organizations, setOrganizations] = useState([])
  const notificationServiceStaffId = "-9" //Service staff account: RecoveryLInk Microservices

  //Apointments
  const [isLoadingAppointments, setIsLoadingAppointments] = useState(true)
  const [appointmentsToday, setAppointmentsToday] = useState(undefined)
  const [showAppointmentInfo, setShowAppointmentInfo] = useState(undefined)

  //DocumentSigning
  const [documentsToSign, setDocumentsToSign] = useState(undefined)

  const [getOrganizations] = useLazyQuery(GET_PARTICIPANT_ORGNIZATIONS, {
    fetchPolicy: "network-only",
    variables: {
      pid: user.participant_id,
    },
  })

  const [getNotificationUsers] = useLazyQuery(GET_NOTIFICATION_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: notificationServiceStaffId,
      organization_id: user.organization_id,
    },
  })

  const RefreshTasks = useCallback(() => {
    setIsLoadingTasks(true)
    setTaskCounter(undefined)
    setNewTasks(undefined)
    setTasksToday(undefined)
    getTasks(user, setTaskCounter, setNewTasks, setTasksToday)
    setIsLoadingTasks(false)
  }, [user])

  const RefreshNotifications = useCallback(async () => {
    setIsLoadingNotes(true)
    setNewNotifications(undefined)
    const orgs = await getOrganizations()
    setOrganizations(orgs.data.organizations)
    getNotifications(user, setNewNotifications, getNotificationUsers)
    setIsLoadingNotes(false)
  }, [getOrganizations, user])

  const RefreshAppointments = useCallback(async () => {
    try {
      setIsLoadingAppointments(true)
      setAppointmentsToday(undefined)
      await getAppointments(user, setAppointmentsToday)
      setIsLoadingAppointments(false)
    } catch (error) {
      setAppointmentsToday(undefined)
      LogError(error.message)
      user.setNotificationError("Unable to load appointments")
      setIsLoadingAppointments(false)
    }
  }, [user])

  const RefreshDocuments = useCallback(async () => {
    try {
      const orgs = await getOrganizations()
      setOrganizations(orgs.data.organizations)
      await getDocuments(user, orgs.data.organizations, setDocumentsToSign)
    } catch (error) {
      setDocumentsToSign("Err")
      user.setNotificationError("Unable to load documents to sign")
    }
  }, [user, getOrganizations])

  useEffect(() => {
    if (initialLoad) {
      RefreshTasks()
      RefreshNotifications()
      RefreshAppointments()
      RefreshDocuments()
      setInitialLoad(false)
    }
  }, [
    RefreshTasks,
    RefreshNotifications,
    initialLoad,
    RefreshAppointments,
    RefreshDocuments,
  ])

  function taskHeaderButtonCallback() {
    headerButtonAction(user)
  }

  function GetTaskTemplate(task) {
    return GetTaskPanel(task, user, setIsLoadingTasks, RefreshTasks)
  }

  function GetNewTaskTemplate(task) {
    return GetNewTaskPanel(task, user, setIsLoadingTasks, RefreshTasks)
  }

  function GetNewNotificationTemplate(notification) {
    return GetNewNotificationsPanel(notification, organizations)
  }

  function notificationsHeaderButtonCallback() {
    notificationHeaderButtonAction(user)
  }

  function appointmentHeaderButtonCallback() {
    appointmentsHeaderButtonAction(user)
  }

  function GetAppointmentTemplate(appointment) {
    return GetAppointmentsPanel(appointment, setShowAppointmentInfo)
  }

  return (
    <div className={dpStyles.mainGridContainer}>
      <GetAppointmentInfo
        appointment={showAppointmentInfo}
        onHideDialog={() => {
          setShowAppointmentInfo(undefined)
        }}
      />
      <div className={dpStyles.gridItemCounters}>
        <div className={dpStyles.counterGridContainer}>
          <div
            className={`${dpStyles.counterGridItemDocuments} ${dpStyles.CounterGridItem}`}
          >
            {GetCounterPanel(
              "Documents to sign",
              "orange",
              documentsToSign,
              () => {
                navigate(ROUTES.PARTICIPANT_DOCUMENTS, {
                  replace: true,
                  state: { overrideDefaultFilter: "awaiting_signature" },
                })
                onClosePopup()
              },
              "Go to Documents interface"
            )}
          </div>
          <div
            className={`${dpStyles.counterGridItemTasks} ${dpStyles.CounterGridItem}`}
          >
            {GetCounterPanel(
              "Tasks to complete",
              "salmon",
              taskCounter,
              taskHeaderButtonCallback,
              "Go to Tasks interface"
            )}
          </div>
          <div
            className={`${dpStyles.counterGridItemParticipantCaseLoad} ${dpStyles.CounterGridItem}`}
          >
            {GetCounterPanel("Participant caseload", "lightCyan", "N/A")}
          </div>
          <div
            className={`${dpStyles.counterGridItemReferrals} ${dpStyles.CounterGridItem}`}
          >
            {GetCounterPanel("Referrals to review", "DarkOrchid", "N/A")}
          </div>
        </div>
      </div>
      <div className={dpStyles.gridItemNewNotifications}>
        {GetSummaryPanel(
          "New notifications",
          "lightGreen",
          newNotifications,
          isLoadingNotes,
          GetNewNotificationTemplate,
          "No new notifications",
          notificationsHeaderButtonCallback,
          "Go to Notifications interface"
        )}
      </div>
      <div className={dpStyles.gridItemNewTasks}>
        {GetSummaryPanel(
          "New tasks",
          "salmon",
          newTasks,
          isLoadingTasks,
          GetNewTaskTemplate,
          "No new tasks",
          taskHeaderButtonCallback,
          "Go to Tasks interface"
        )}
      </div>
      <div className={dpStyles.gridItemNewParticipants}>
        {GetSummaryPanel("New participants", "lightCyan", [])}
      </div>
      <div className={dpStyles.gridItemTasksToday}>
        {GetDetailPanel(
          "Today's Tasks",
          "salmon",
          tasksToday,
          isLoadingTasks,
          GetTaskTemplate,
          "No tasks due today",
          taskHeaderButtonCallback,
          "Go to Tasks interface"
        )}
      </div>
      <div className={dpStyles.gridItemAppointmentToday}>
        {GetDetailPanel(
          "Today's Appointments",
          "orange",
          appointmentsToday,
          isLoadingAppointments,
          GetAppointmentTemplate,
          !appointmentsToday
            ? "Unable to retrieve appointments for today"
            : "No appointments due today",
          appointmentHeaderButtonCallback,
          "Go to Appointments interface"
        )}
      </div>
    </div>
  )
}

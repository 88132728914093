import axios from "axios"

function handleEndpoint() {
  const isDev = process.env.NODE_ENV === "development"
  const isActive =
    process.env.REACT_APP_ENABLE_LOGGING === "TRUE" ? true : false

  if (!isActive) {
    return null
  } else if (isActive && isDev) {
    return process.env.REACT_APP_CLOUD_FUNCTIONS_DEV
  } else {
    return process.env.REACT_APP_CLOUD_FUNCTIONS_PROD
  }
}

const endpoint = handleEndpoint()

export default function useLogs() {
  if (endpoint === null) {
    return [false, null]
  }
  return [true, writeLog]
}

async function writeLog(args) {
  const { type, path, message, stack = "" } = args
  const body = {
    type,
    log: {
      source: "client",
      path,
      message,
      stack,
    },
  }
  try {
    return await axios.post(`${endpoint}/logger`, body)
  } catch (error) {
    throw error
  }
}

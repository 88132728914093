import { Tooltip } from "primereact/tooltip"
import docSignStyles from "../../../styles/documentSigning/DocSign.filters.module.css"
const LayoutButton = ({
  iconName,
  toolTip,
  value,
  setLayoutOption,
  layoutOption,
  disabled,
}) => {
  return (
    <>
      <Tooltip target=".tp-layoutButton" />
      <button
        type="button"
        className={`btn tp-layoutButton ${docSignStyles.filterHeaderButton} ${
          layoutOption === value ? docSignStyles.layoutButtonSelected : ""
        }`}
        data-pr-tooltip={toolTip}
        data-pr-position="bottom"
        onClick={(e) => {
          e.stopPropagation()
          setLayoutOption(value)
        }}
        disabled={disabled}
      >
        <span className={`material-icons`}>{iconName}</span>
      </button>
    </>
  )
}
export default LayoutButton

import * as Yup from "yup"

export default function validation_date(field, acc) {
  const { field_name, field_validation } = field

  const checkBool = () => {
    if (!field_validation.required) return false
    return field_validation.required[0] === "true" ? true : false
  }

  const isRequired = checkBool()
  const isRequiredTxt =
    (field_validation.required && field_validation.required[1]) ||
    "Required field."

  if (!isRequired) return acc

  return {
    [field_name]: Yup.date().required(isRequiredTxt),
    ...acc,
  }
}

import { gql } from "@apollo/client"

export const GET_TEMPLATE_VIEW_MODE = gql`
  query FormRequestViewMode(
    $staff_id: ID!
    $organization_id: ID
    $enterprise_id: ID
    $participant_id: ID!
    $idTemplate: ID!
    $type: ID!
    $idStamp: ID
  ) {
    template(
      staff_id: $staff_id
      participant_id: $participant_id
      idTemplate: $idTemplate
      organization_id: $organization_id
      enterprise_id: $enterprise_id
      type: $type
      idStamp: $idStamp
    ) {
      template_title
      template_description
      blocks {
        idBlock
        block_name
        block_title
        total_fields
        index
        fields {
          field_name
          field_label
          field_type
          description
          field_description
          field_options {
            label
            value
          }
        }
      }
      values
    }
  }
`

import { gql } from "@apollo/client"

export const GET_USERS = gql`
  query Users($uid: ID!, $organization_id: ID!) {
    users: staffsByPid(participant_id:$uid, organization_id: $organization_id) {
        staff_id
        staff_alias
        staff_user_status
        staff_name_first
        staff_name_last
        staff_email_address
        staff_role_name
    }
  }
`

export const GET_USERS_FROM_LIST = gql`
  query Users($list: [ID!]) {
    users: usersFromList(list: $list) {
      staff_id
      staff_alias
      staff_name_first
      staff_name_last
      staff_email_address
      staff_user_status
    }
  }
`




import { useCallback, useEffect, useRef, useState } from "react"

import { LogError } from "../../../helpers/logger"
import { useLazyQuery } from "@apollo/client"
import { GET_STAFF } from "../queries"

export default function AssessmentBlockTaskStaff({ taskProfile }) {
  const [isLoadingStaff, setIsLoadingStaff] = useState(true)
  const [loader, setLoader] = useState("Loading.")
  const [staffName, setStaffName] = useState("")

  const loaderValue = useRef("Loading")
  const loaderIntervalRef = useRef()

  const [getStaff] = useLazyQuery(GET_STAFF, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: taskProfile?.staff_id,
      organization_id: taskProfile?.organizationId,
    },
  })

  useEffect(() => {
    loaderIntervalRef.current = setInterval(() => {
      if (isLoadingStaff) {
        if (loaderValue.current.includes("......")) {
          loaderValue.current = `Loading`
        } else {
          loaderValue.current = `${loaderValue.current}.`
        }
        setLoader(loaderValue.current)
      } else {
        clearInterval(loaderIntervalRef.current)
      }
    }, 1000)
    return () => {
      clearInterval(loaderIntervalRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadStaffName = useCallback(async () => {
    try {
      if (taskProfile) {
        if (taskProfile?.staff_id) {
          const staff = await getStaff({
            variables: {
              staff_id: taskProfile?.staff_id,
              organization_id: taskProfile?.organization_id,
            },
          })

          const name = staff?.data?.user?.staff_full_name || ""

          setStaffName(name)
        } else {
          setStaffName("")
        }

        setIsLoadingStaff(false)
      }
    } catch (error) {
      LogError("Unable to load staff name for Assignment task", error)
      setStaffName("Unable to load name")
      setIsLoadingStaff(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskProfile])

  useEffect(() => {
    loadStaffName()
  }, [loadStaffName])

  return <>{isLoadingStaff ? loader : staffName}</>
}
